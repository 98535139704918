import * as ActionTypes from '../../actions/types'
import {fromJS} from "immutable";
import {mapMultiple} from "../../helpers/map";
import {mapProduct} from "../../types/product/map";
import {mapFilter} from "../../types/filters/map";

const INITIAL_STATE = {
	filters: {},
	searchResultsIndexTable: {
		loading: false,
		items: [],
		count: 0
	},
	loading: false,
	searchResultsFilters: {},
	message: null,
}

function setProductMessage(state, action) {
	const message = action.payload
	return state
	.set('message', fromJS(message ? {
		text: message?.text, variant: message?.variant
	} : null))
}

function setFilters(state, action) {
	const {values} = action.payload
	return state
	.set('filters', fromJS(values))
	.set('message', null)
}

function updateFilter(state, action) {
	const {field, value} = action.payload
	return state
	.setIn(['filters', field], value)
}

function getSearchResults(state) {
	return state
	.setIn(['searchResultsIndexTable', 'loading'], true)
	.setIn(['searchResultsIndexTable', 'count'], 0)
	.setIn(['searchResultsIndexTable', 'items'], fromJS([]))
	.set('message', null)
}

function getSearchResultsSucceeded(state, action) {
	const {payload: {items, count}} = action
	const mappedData = mapMultiple(items, mapProduct)
	return state
	.setIn(['searchResultsIndexTable', 'loading'], false)
	.setIn(['searchResultsIndexTable', 'count'], count ? count : items.length)
	.setIn(['searchResultsIndexTable', 'items'], fromJS(mappedData))
	.set('message', null)
}

function getSearchResultsFailed(state, action) {
	const {message} = action.payload
	return state
	.setIn(['searchResultsIndexTable', 'loading'], false)
	.set('message', fromJS({
		text: message, variant: 'error'
	}))
}

function getSearchResultsFilters(state) {
	return state
	.set('loading', true)
	.setIn(['searchResultsIndexTable', 'loading'], true)
	.set('searchResultsFilters', fromJS([]))
	.set('message', null)
}

function getSearchResultsFiltersSucceeded(state, action) {
	const {Attributes} = action.payload
	const mappedFilters = mapMultiple(Attributes, mapFilter)
	return state
	.set('loading', false)
	.set('searchResultsFilters', fromJS(mappedFilters))
	.set('message', null)
}

function getSearchResultsFiltersFailed(state, action) {
	const {message} = action.payload
	return state
	.set('loading', false)
	.set('message', fromJS({
		text: message, variant: 'error'
	}))
}

const product = {
	initialState: INITIAL_STATE, handlers: {
		[ActionTypes.SET_FILTERS]: setFilters,
		[ActionTypes.SET_PRODUCT_MESSAGE]: setProductMessage,
		[ActionTypes.UPDATE_FILTER]: updateFilter,
		[ActionTypes.GET_SEARCH_RESULTS]: getSearchResults,
		[ActionTypes.GET_SEARCH_RESULTS_SUCCEEDED]: getSearchResultsSucceeded,
		[ActionTypes.GET_SEARCH_RESULTS_FAILED]: getSearchResultsFailed,
		[ActionTypes.GET_SEARCH_RESULTS_FILTERS]: getSearchResultsFilters,
		[ActionTypes.GET_SEARCH_RESULTS_FILTERS_SUCCEEDED]: getSearchResultsFiltersSucceeded,
		[ActionTypes.GET_SEARCH_RESULTS_FILTERS_FAILED]: getSearchResultsFiltersFailed,
	}
}

export default product