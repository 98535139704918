// @flow
import React, {useMemo} from 'react';
import {FormControl, FormHelperText, TextField, Tooltip} from "@mui/material";
import {ErrorMessage} from "formik";
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";
import {FILTERS} from "../../../locales/components/namespaces";
import {ClearOutlined} from "@mui/icons-material";
import Button from "@mui/material/Button";

type Props = {};

const TripleInputs = (props: Props) => {
	const {
		formik,
		toggleInputs,
		setToggleInputs,
		search1Ref,
		search2Ref,
		search3Ref
	} = props
	const {t} = useTranslation(FILTERS)
	
	const inputsWidth = useMemo(() => ({
		xs: '100%',
		sm: toggleInputs === 1 ? '100%' : toggleInputs === 2 ? '50%' : '50%',
		md: toggleInputs === 1 ? '100%' : toggleInputs === 2 ? '50%' : '33.3%',
	}), [toggleInputs])
	
	const placeholder = useMemo(() => (t(formik.values.isAltCode ? 'Search supplier code' : 'Search dimension')), [formik.values.isAltCode, t])
	return (
		<>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					flexWrap: 'wrap',
					width: '100%'
				}}
			>
				<FormControl
					sx={{
						width: inputsWidth
					}}
					variant="standard"
					error={
						formik.touched['search1'] &&
						Boolean(formik.errors['search1'])
					}
				>
					<TextField
						defaultValue={formik.values.search1}
						inputRef={search1Ref}
						name={'search1'}
						onChange={(evt) => {
							formik.setFieldValue('search1', search1Ref.current.value)
						}}
						placeholder={placeholder}
						size={'small'}
						inputProps={{maxLength: 250}}
						variant="outlined"
					/>
					<FormHelperText>
						<ErrorMessage name={'search1'}/>
					</FormHelperText>
				</FormControl>
				{toggleInputs >= 2 && (
					<FormControl
						sx={{
							width: inputsWidth
						}}
						variant="standard"
						error={
							formik.touched['search2'] &&
							Boolean(formik.errors['search2'])
						}
					>
						<TextField
							defaultValue={formik.values.search2}
							inputRef={search2Ref}
							name={'search2'}
							onChange={(evt) => {
								formik.setFieldValue('search2', search2Ref.current.value)
							}}
							placeholder={placeholder}
							size={'small'}
							inputProps={{maxLength: 250}}
							variant="outlined"
						/>
						<Tooltip title={t('Remove search')}>
							<Button
								variant="text"
								sx={{
									padding: 0,
									minWidth: 30,
									maxHeight: 30,
									marginLeft: 0.1,
									marginRight: 0.1,
									position: 'absolute',
									top: 4,
									right: 2
								}}
								onClick={() => {
									setToggleInputs(prev => prev - 1)
									if (search3Ref?.current?.value) {
										if (search2Ref?.current?.value) {
											search2Ref.current.value = search3Ref.current.value
										}
										formik.setFieldValue('search2', search3Ref.current.value)
										search3Ref.current.value = ''
									}
									formik.setFieldValue('search2', formik.values.search3)
									formik.setFieldValue('search3', '')
								}}
							>
								<ClearOutlined fontSize={'small'}/>
							</Button>
						</Tooltip>
						<FormHelperText>
							<ErrorMessage name={'search2'}/>
						</FormHelperText>
					</FormControl>
				)}
				{toggleInputs >= 3 && (
					<FormControl
						sx={{
							width: inputsWidth
						}}
						variant="standard"
						error={
							formik.touched['search3'] &&
							Boolean(formik.errors['search3'])
						}
					>
						<TextField
							defaultValue={formik.values.search3}
							inputRef={search3Ref}
							name={'search3'}
							onChange={(evt) => {
								formik.setFieldValue('search3', search3Ref.current.value)
							}}
							placeholder={placeholder}
							size={'small'}
							inputProps={{maxLength: 250}}
							variant="outlined"
						/>
						<Tooltip title={t('Remove search')}>
							<Button
								variant="text"
								sx={{
									padding: 0,
									minWidth: 30,
									maxHeight: 30,
									marginLeft: 0.1,
									marginRight: 0.1,
									position: 'absolute',
									top: 4,
									right: 2
								}}
								onClick={() => {
									setToggleInputs(prev => prev - 1)
									if (search3Ref?.current?.value) {
										search3Ref.current.value = ''
									}
									formik.setFieldValue('search3', '')
								}}
							>
								<ClearOutlined fontSize={'small'}/>
							</Button>
						</Tooltip>
						<FormHelperText>
							<ErrorMessage name={'search3'}/>
						</FormHelperText>
					</FormControl>
				)}
			</Box>
		</>
	);
};

export default TripleInputs