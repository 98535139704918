//@flow
import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import FileRowMenu from "./fileRowMenu";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import {FILES} from "../../../locales/components/namespaces";
import CircularProgress from "@mui/material/CircularProgress";

type Props = {
	showAliasInput: boolean,
	file: {
		isTemporary: boolean,
		name: string,
		url: string,
		alias: {
			en?: string,
			el?: string,
		},
	},
	onRemoveFile: Function,
	onUpdateAlias: Function
}

const FileRow = (props: Props) => {
	const {showAliasInput, file, onRemoveFile, onUpdateAlias} = props
	const [modeEdit, setModeEdit] = useState(false)
	const {t} = useTranslation(FILES)
	const [text, setText] = useState('')
	useEffect(() => {
		if (modeEdit && file.alias) {
			setText(file.alias)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modeEdit])
	return (
		<ListItem
			disabled={file.isTemporary}
			sx={{py: .25}}
			secondaryAction={
				file.isTemporary ? (
					<CircularProgress
						color="inherit"
						size={16}
						sx={{mx: 1.25}}
					/>
				) : !modeEdit ? (
					<FileRowMenu
						showAliasInput={showAliasInput}
						onDelete={onRemoveFile}
						onEdit={() => setModeEdit(!modeEdit)}
						downloadUrl={file.url}
						downloadFileName={file.name}
					/>
				) : null}
		>
			{showAliasInput && modeEdit ? (
				<Box style={{flexGrow: 1}}>
					<TextField
						label={t('Description')}
						fullWidth
						size={"small"}
						name={'alias'}
						value={text}
						onChange={(e) => setText(e.target.value)}
						sx={{mb: 1}}
					/>
					<Stack
						sx={{mb: 1}}
						spacing={1}
						direction={"row"}
					>
						<Button
							size={"small"}
							variant={"contained"}
							color={"primary"}
							onClick={() => {
								onUpdateAlias(text)
								setModeEdit(false)
							}}
						>
							{t('Save')}
						</Button>
						<Button
							size={"small"}
							color={"error"}
							onClick={() => setModeEdit(false)}
						>
							{t('Cancel')}
						</Button>
					</Stack>
				</Box>
			) : (
				<ListItemText
					primary={
						file.isTemporary ? t('Uploading') + '...' :
							showAliasInput ?
								file.alias && file.alias !== '' ?
									file.alias : t('No description yet') :
								file.name ?? file.path
					}
					secondary={showAliasInput ? file.name : null}
				/>
			)}
		</ListItem>
	)
}

export default FileRow