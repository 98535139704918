import type {Filter, FilterValue, UnmappedFilter, UnmappedFilterValue} from "./types";
import {mapMultiple} from "../../helpers/map";

export const mapFilterValue: FilterValue = (datum: UnmappedFilterValue) => {
	if (!datum) return
	return {
		id: datum.valueId,
		weight: datum.OrderNumber ? Number(datum.OrderNumber) : 0,
		description: {
			el: datum.valueEl,
			en: datum.valueEn
		},
		count: datum?.count ? Number(datum.count) : 0,
		related: datum?.related
	}
}

export const mapFilter: Filter = (datum: UnmappedFilter) => {
	if (!datum) return
	return {
		id: datum.id,
		description: {
			el: datum.DescriptionEl,
			en: datum.DescriptionEl
		},
		weight: datum.OrderNumber ? Number(datum.OrderNumber) : 0,
		values: mapMultiple(datum.values, mapFilterValue)
	}
}