// @flow
import React, {useEffect, useMemo, useState} from 'react';
import {PAGE_LOGIN} from "../../../locales/pages/namespaces";
import {useTranslation} from "react-i18next";
import useUserData from "../../../hooks/user/useUserData";
import _ from "lodash";
import useUserAction from "../../../hooks/user/useUserAction";
import {Autocomplete, Box, Button, FormControl, TextField} from "@mui/material";
import './selectAddress.css'

type Props = {
	requireConfirmation?: boolean,
	updateAcrossApplication?: boolean,
	showAllOption?: boolean,
	onSelection?: Function,
	selected?: string,
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

const SelectAddress = (props: Props) => {
	const {t} = useTranslation(PAGE_LOGIN)
	const {
		requireConfirmation,
		updateAcrossApplication = true,
		selected = '',
		onSelection,
		color = '#000000'
	} = props
	const [selectedStore, setSelectedStore] = useState(selected);
	const {setUserStore} = useUserAction()
	const {stores, selectedStore: userSelectedStore} = useUserData()
	const sortedStores = useMemo(() => (_.sortBy(stores, ['customer', 'address'])), [stores])
	
	useEffect(() => {
		if (selected && selected !== selectedStore) {
			setSelectedStore(selected)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selected])
	
	useEffect(() => {
		if (userSelectedStore && updateAcrossApplication) {
			setSelectedStore(userSelectedStore.id)
		}
	}, [updateAcrossApplication, userSelectedStore])
	
	useEffect(() => {
		if (requireConfirmation && updateAcrossApplication) {
			if (stores && stores.length === 1) {
				setUserStore(stores[0].id)
			} else if (stores && stores.length > 0) {
				setSelectedStore(stores[0].id)
			}
		}
	}, [requireConfirmation, setSelectedStore, setUserStore, stores, updateAcrossApplication])
	
	const handleChange = (event, newValue, reason) => {
		if (reason === 'clear') return
		const storeId = newValue ? newValue.id : '';
		setSelectedStore(storeId)
		if (!requireConfirmation && updateAcrossApplication) {
			setUserStore(storeId)
		}
		if (onSelection) {
			onSelection(stores.find(s => s.id === storeId))
		}
	}
	
	return (
		<Box
			className={'selectAddress'}
		>
			<FormControl
				variant="standard"
				sx={{
					minWidth: {
						xs: 300,
						sm: 400,
						md: 500,
						lg: 600,
					}
				}}
			>
				<Autocomplete
					value={stores.find(store => store.id === selectedStore) || null}
					onChange={handleChange}
					options={sortedStores}
					getOptionLabel={option =>
						(!option.isAdmin ? option.address : `${option.customer} / ${option.address} / ${option.region} / ${option.countryCode}`.toUpperCase())
					}
					renderInput={params => <TextField {...params} sx={{color: color}} variant="standard"/>}
					color={color}
					sx={{
						color: color,
						'&.MuiAutocomplete-root .MuiInput-root': {
							color: color,
							'.MuiSvgIcon-root': {
								color: color
							}
						}
					}}
				/>
			</FormControl>
			{/*<FormControl variant="standard" sx={{minWidth: 320}}>
				<Select
					value={selectedStore}
					onChange={handleChange}
					MenuProps={MenuProps}
					sx={{color: color}}
				>
					{showAllOption && stores.length > 1 && (
						<MenuItem dense value={'all'}>
							{t('all')}
						</MenuItem>
					)}
					{sortedStores.map(store => (
						<MenuItem dense key={store.id} value={store.id}>
							{!store.isAdmin ? store.address : `${store.customer} / ${store.address} / ${store.region} / ${store.countryCode}`.toUpperCase()}
						</MenuItem>
					))}
				</Select>
			</FormControl>*/}
			{requireConfirmation && (
				<Box
					sx={{
						marginTop: 1,
						width: '100%'
					}}
				>
					<Button
						fullWidth
						size={"small"}
						variant={"contained"}
						color={"primary"}
						onClick={() => setUserStore(selectedStore)}
					>
						{t('Login')}
					</Button>
				</Box>
			)}
		</Box>
	);
};

export default SelectAddress