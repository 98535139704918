import useUserData from "../user/useUserData";
import {useTranslation} from "react-i18next";
import {PAGE_WARRANTIES} from "../../locales/pages/namespaces";
import {useFormik} from "formik";
import axios from "axios";
import {API} from "../../helpers/constants";
import {enqueueSnackbar} from "notistack";
import {handleAxiosErrors} from "../../helpers/errorHandling";
import {getRouteUrl} from "../../helpers/getRouteUrl";
import {ROUTE_PAGE_WARRANTIES} from "../../routers/routes";
import {useHistory} from "react-router-dom";
import * as yup from "yup";
import {useEffect, useState} from "react";

const useWarrantyAccident = ({list, id}) => {
	const {token, selectedStore} = useUserData()
	const [loading, setLoading] = useState(false)
	const [accidentDetails, setAccidentDetails] = useState([])
	const history = useHistory()
	const {t} = useTranslation(PAGE_WARRANTIES)
	
	
	useEffect(() => {
		setLoading(true)
		axios({
			method: 'get',
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			url: `${API}/el/b2b/guarantee/accident/details/${id}`,
		}).then(response => {
			const data = response.data.data
			setAccidentDetails({
				...data,
				KmImg: data.KmImg?.split('\\')?.pop(),
				dotImg: data.dotImg?.split('\\')?.pop(),
				licensePlateImg: data.licensePlateImg?.split('\\')?.pop(),
				tireProblemImg: data.tireProblemImg?.split('\\')?.pop(),
			})
			setLoading(false)
		}).catch((error) => {
			enqueueSnackbar(handleAxiosErrors(error, t('Failed!'), {
				variant: 'warning',
				autoHideDuration: 1000,
			}));
			setLoading(false)
		})
	}, [id, t, token])
	
	const validationSchema = yup.object({
		'PlacedAddrId': yup.object().required(t('Required')),
		'tireProblemImgPath': yup.string().required(t('Required')),
		'dotImgPath': yup.string().required(t('Required')),
		'KmImgPath': yup.string().required(t('Required')),
		'licensePlateImgPath': yup.string().required(t('Required')),
		'topBack': yup.boolean(),
		'topFront': yup.boolean(),
		'endFront': yup.boolean(),
		'endBack': yup.boolean().test('Required', t('Required'), function (value, context) {
			const {topFront, endFront, topBack} = context.parent;
			return !(!value && !topFront && !endFront && !topBack);
		})
	})
	
	const onSubmit = (values) => {
		let dot = ''
		if (values.topBack) {
			dot = '1'
		}
		if (values.topFront) {
			dot = '2'
		}
		if (values.endFront) {
			dot = '3'
		}
		if (values.endBack) {
			dot = '4'
		}
		axios({
			method: 'post',
			headers: {'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}`},
			data: {
				PlacedAddrId: values.PlacedAddrId.id,
				GuaranteeNumber: id,
				tireProblemImg: values.tireProblemImg,
				dotImg: values.dotImg,
				KmImg: values.KmImg,
				licensePlateImg: values.licensePlateImg,
				dot: dot
			},
			url: `${API}/el/b2b/guarantee/update`,
		}).then(response => {
			enqueueSnackbar(t('Accident report submitted successfully!'), {
				variant: 'success',
				autoHideDuration: 1000,
			});
			formik.resetForm()
			history.push(getRouteUrl(ROUTE_PAGE_WARRANTIES))
		}).catch((error) => {
			enqueueSnackbar(handleAxiosErrors(error, t('Error submitting accident report!'), {
				variant: 'warning',
				autoHideDuration: 1000,
			}));
		})
	}
	
	const formik = useFormik({
		initialValues: {
			PlacedAddrId: accidentDetails?.PlaceAddrId ? list.find((x) => x.id === accidentDetails?.PlaceAddrId) : list.find((x) => x.id === selectedStore?.id),
			tireProblemImg: '',
			dotImg: '',
			KmImg: '',
			licensePlateImg: '',
			tireProblemImgPath: accidentDetails?.tireProblemImg ? accidentDetails?.tireProblemImg : '',
			dotImgPath: accidentDetails?.dotImg ? accidentDetails?.dotImg : '',
			KmImgPath: accidentDetails?.KmImg ? accidentDetails?.KmImg : '',
			licensePlateImgPath: accidentDetails?.licensePlateImg ? accidentDetails?.licensePlateImg : '',
			topBack: accidentDetails.dot === '1',
			topFront: accidentDetails.dot === '2',
			endFront: accidentDetails.dot === '3',
			endBack: accidentDetails.dot === '4',
		},
		validationSchema: validationSchema,
		onSubmit: onSubmit,
		enableReinitialize: true
	})
	
	return {
		formik,
		accidentDetails,
		loading
	}
	
}

export default useWarrantyAccident