export default function Slider(theme) {
	return {
		MuiSlider: {
			styleOverrides: {
				track: {},
				thumb: {},
				mark: {},
				rail: {},
				root: {},
				valueLabel: {}
			}
		}
	};
}
