import type {Filter, FilterValue} from "./types";
import {parseMultiple} from "../../helpers/parse";

export const parseFilterValue: FilterValue = (datum, language) => {
	if (!datum) return;
	return {
		id: datum.get('id'),
		weight: datum.get('weight'),
		description: datum.getIn(['description', language]),
		count: datum.get('count'),
		related: datum.get('related'),
	}
}

export const parseFilter: Filter = (datum, language) => {
	if (!datum) return;
	return {
		id: datum.get('id'),
		description: datum.getIn(['description', language]),
		weight: datum.get('weight'),
		values: parseMultiple(datum.get('values'), (value) => parseFilterValue(value, language))
	}
}