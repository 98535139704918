export default function Chip(theme) {
	return {
		MuiChip: {
			styleOverrides: {
				root: {},
				sizeLarge: {},
				light: {},
				combined: {}
			}
		}
	};
}
