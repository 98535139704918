export const mapMultiple = (unMappedData, mapFunction) => {
	if (!unMappedData) return;
	return unMappedData.reduce((previous, datum, index) => {
		const mappedDatum = mapFunction(datum, index)
		return mappedDatum && mappedDatum.id
			? {
				[mappedDatum.id]: mappedDatum,
				...previous,
			}
			: previous;
	}, {});
}

export const preserveFieldsIfNotPresentInNew = (fieldsToPreserve: Array<string>) => (oldVal, newVal) => {
	let returnVal = newVal
	if (fieldsToPreserve && fieldsToPreserve.length > 0) {
		fieldsToPreserve.forEach(f => {
			const newHasField = newVal.has(f)
			const oldHasField = oldVal.has(f)
			if (!newHasField && oldHasField) {
				returnVal = returnVal.set(f, oldVal.get(f))
			}
		})
	}
	return returnVal
}