// @flow
import React, {useMemo} from 'react';
import {useTranslation} from "react-i18next";
import {PAGE_REPORTS} from "../../../locales/pages/namespaces";
import useBonusReport from "../../../hooks/reports/useBonusReport";
import _ from "lodash";
import Filter from "../budget/partials/filter";
import Box from "@mui/material/Box";
import {DataGridPremium} from "@mui/x-data-grid-premium";
import ContentLoader from "../../../components/general/contentLoader/contentLoader";
import {Typography} from "@mui/material";
import {muiGridLocales} from "../../../helpers/muiLocales";
import useUserData from "../../../hooks/user/useUserData";

type Props = {};

const Bonus = (props: Props) => {
	const {language} = useUserData()
	const {t} = useTranslation(PAGE_REPORTS)
	const {data, filters, loading, handleFilters, bonusType} = useBonusReport()
	const columns = useMemo(() => [
		{
			field: 'diameter',
			headerName: t('Diameter'),
			flex: 1,
			minWidth: 140
		},
		{
			field: 'quantity',
			headerName: t('Quantity'),
			flex: 1,
			type: 'number',
			aggregable: true,
			minWidth: 140
		},
		// TODO::Uncomment όταν ζητήσει ο Στάθης να ξανά εμφανιστούν
		// {
		// 	field: 'unit',
		// 	headerName: t('Unit'),
		// 	flex: 1,
		// 	type: 'number',
		// minWidth: 100
		// },
		// {
		// 	field: 'bonus',
		// 	headerName: t('Bonus'),
		// 	flex: 1,
		// 	type: 'number',
		// 	aggregable: true,
		// minWidth: 100
		// }
	], [t])
	
	const displayYears = useMemo(() => {
		const years = filters?.years
		if (!_.isEmpty(years)) {
			return (
				<Filter
					filterKey={'years'}
					filter={years}
					handleFilters={handleFilters}
					bgColor={'#f5f5f5'}
				/>
			)
		}
	}, [filters?.years, handleFilters])
	
	return !loading ? (
		<Box p={1}>
			<Box>
				<Typography
					variant={'h6'}
					component={'div'}
					sx={{fontWeight: 'bold', textAlign: 'center', color: '#FFFFFF', backgroundColor: '#255b82'}}
				>
					{bonusType}
				</Typography>
			</Box>
			<Box p={4} width={'100%'} displa={'flex'} flexDirection={'column'} maxWidth={'1000px'}>
				<Box mb={3} pl={2} width={'90%'}>
					{displayYears}
				</Box>
				<Box width={'90%'}>
					<DataGridPremium
						loading={loading}
						rows={data}
						columns={columns}
						getRowId={row => row.diameter}
						localeText={muiGridLocales(language)}
						sx={{
							background: '#FFFFFF',
							'.MuiDataGrid-aggregationColumnHeaderLabel': {
								display: 'none'
							}
						}}
						initialState={{
							aggregation: {
								model: {
									bonus: 'sum',
									quantity: 'sum',
								},
							},
							density: 'compact'
						}}
					/>
				</Box>
			</Box>
		</Box>
	) : <ContentLoader loading={loading}/>
};

export default Bonus