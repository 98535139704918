import dayjs from "dayjs";

const formatTypes = {
	DEFAULT: 0,
	TITLE: 1,
	DATE_ONLY: 2,
	DATE_ONLY_LONG: 3,
	TITLE_HALF: 4,
	TIME_ONLY: 5,
	DAY: 6,
	MONTH: 7,
	MONTH_DOUBLE: 9,
	YEAR: 8,
	DATE_ONLY_FILTER: 10,
	DATE_AND_TIME: 11,
	DATE_DATABASE_FORMAT: 12,
	MONTH_YEAR: 13,
	DAY_MONTH_YEAR_HOUR: 14
}

export const formatTime = (time) => {
	const momentTime = dayjs(time, "HH:mm:ss")
	return momentTime.format("HH:mm")
}

export const mapDBDateFormats = (format): string => {
	switch (format) {
		case 'm-d-Y':
			return 'MM-DD-YYYY'
		case 'Y-m-d':
			return 'YYYY-MM-DD'
		case 'd-m-Y':
			return 'DD-MM-YYYY'
		default:
			return 'DD/MM/YYYY'
	}
}

export const mapDBTimeFormats = (format): string => {
	switch (format) {
		case 'H:i':
			return 'HH:mm'
		default:
			return 'HH:mm'
	}
}

export const formatDate = (date: Date, format: number = formatTypes.DEFAULT) => {
	const momentDate = dayjs(date)
	switch (format) {
		case formatTypes.DATE_ONLY_LONG:
			return momentDate.format('D MMM YYYY')
		case formatTypes.DATE_AND_TIME:
			return momentDate.format('DD/MM/YYYY HH:mm:ss')
		case formatTypes.DATE_ONLY:
			return momentDate.format('DD/MM/YYYY')
		case formatTypes.TITLE:
			return momentDate.format('dddd, D MMMM yyyy HH:mm:ss')
		case formatTypes.TIME_ONLY:
			return momentDate.format('HH:mm:ss')
		case formatTypes.TITLE_HALF:
			return momentDate.format('dddd, D MMMM yyyy')
		case formatTypes.DAY:
			return momentDate.format('DD')
		case formatTypes.MONTH:
			return momentDate.format('M')
		case formatTypes.MONTH_DOUBLE:
			return momentDate.format('MM')
		case formatTypes.DATE_ONLY_FILTER:
			return momentDate.format('YYYY-MM-DD')
		case formatTypes.YEAR:
			return momentDate.format('YYYY')
		case formatTypes.MONTH_YEAR:
			return momentDate.format('MM/YYYY');
		case formatTypes.DAY_MONTH_YEAR_HOUR:
			return momentDate.format('dddd, DD MMM YYYY HH:mm');
		case formatTypes.DEFAULT:
		default:
			return momentDate.format(format)
	}
}

formatDate.formatTypes = formatTypes
