export * from "./currentUser/types"

export const SET_REDIRECT_TO_ORDER = 'SET_REDIRECT_TO_ORDER';
export const SET_PRODUCT_MESSAGE = 'SET_PRODUCT_MESSAGE';
export const SET_ORDER_MESSAGE = 'SET_ORDER_MESSAGE';
export const SET_FILTERS = 'SET_FILTERS';
export const UPDATE_FILTER = 'UPDATE_FILTER';

export const GET_SEARCH_RESULTS = 'GET_SEARCH_RESULTS';
export const GET_SEARCH_RESULTS_SUCCEEDED = 'GET_SEARCH_RESULTS_SUCCEEDED';
export const GET_SEARCH_RESULTS_FAILED = 'GET_SEARCH_RESULTS_FAILED';

export const GET_SEARCH_RESULTS_FILTERS = 'GET_SEARCH_RESULTS_FILTERS'
export const GET_SEARCH_RESULTS_FILTERS_SUCCEEDED = 'GET_SEARCH_RESULTS_FILTERS_SUCCEEDED'
export const GET_SEARCH_RESULTS_FILTERS_FAILED = 'GET_SEARCH_RESULTS_FILTERS_FAILED'

export const GET_ORDER_DETAILS = 'GET_ORDER_DETAILS';
export const GET_ORDER_DETAILS_SUCCEEDED = 'GET_ORDER_DETAILS_SUCCEEDED';
export const GET_ORDER_DETAILS_FAILED = 'GET_ORDER_DETAILS_FAILED';

export const GET_ACTIVE_ORDER = 'GET_ACTIVE_ORDER';
export const GET_ACTIVE_ORDER_SUCCEEDED = 'GET_ACTIVE_ORDER_SUCCEEDED';
export const GET_ACTIVE_ORDER_FAILED = 'GET_ACTIVE_ORDER_FAILED';

export const CREATE_ORDER = 'CREATE_ORDER';
export const CREATE_ORDER_SUCCEEDED = 'CREATE_ORDER_SUCCEEDED';
export const CREATE_ORDER_FAILED = 'CREATE_ORDER_FAILED';

export const UPDATE_ORDER = 'UPDATE_ORDER';
export const UPDATE_ORDER_SUCCEEDED = 'UPDATE_ORDER_SUCCEEDED';
export const UPDATE_ORDER_FAILED = 'UPDATE_ORDER_FAILED';

export const COMPLETE_ORDER = 'COMPLETE_ORDER';
export const COMPLETE_ORDER_SUCCEEDED = 'COMPLETE_ORDER_SUCCEEDED';
export const COMPLETE_ORDER_FAILED = 'COMPLETE_ORDER_FAILED';

export const DELETE_ORDER = 'DELETE_ORDER';
export const DELETE_ORDER_SUCCEEDED = 'DELETE_ORDER_SUCCEEDED';
export const DELETE_ORDER_FAILED = 'DELETE_ORDER_FAILED';

export const REMOVE_PRODUCT_FROM_ORDER = 'REMOVE_PRODUCT_FROM_ORDER'
export const REMOVE_PRODUCT_FROM_ORDER_SUCCEEDED = 'REMOVE_PRODUCT_FROM_ORDER_SUCCEEDED'
export const REMOVE_PRODUCT_FROM_ORDER_FAILED = 'REMOVE_PRODUCT_FROM_ORDER_FAILED'

export const SET_ANNOUNCEMENTS_MESSAGE = 'SET_ANNOUNCEMENTS_MESSAGE';

export const GET_MESSAGES = 'GET_MESSAGES';
export const GET_MESSAGES_SUCCEEDED = 'GET_MESSAGES_SUCCEEDED';
export const GET_MESSAGES_FAILED = 'GET_MESSAGES_FAILED';

export const SET_MESSAGE_VIEWED = 'SET_MESSAGE_VIEWED';
export const SET_MESSAGE_VIEWED_SUCCEEDED = 'SET_MESSAGE_VIEWED_SUCCEEDED';
export const SET_MESSAGE_VIEWED_FAILED = 'SET_MESSAGE_VIEWED_FAILED';

export const GET_MESSAGE_ATTACHMENTS = 'GET_MESSAGE_ATTACHMENTS';
export const GET_MESSAGE_ATTACHMENTS_SUCCEEDED = 'GET_MESSAGE_ATTACHMENTS_SUCCEEDED';
export const GET_MESSAGE_ATTACHMENTS_FAILED = 'GET_MESSAGE_ATTACHMENTS_FAILED';

export const SET_MESSAGE_IS_OPEN = 'SET_MESSAGE_IS_OPEN';

export const GET_COUNT_UNREAD_MESSAGES = 'GET_COUNT_UNREAD_MESSAGES'
export const GET_COUNT_UNREAD_MESSAGES_SUCCEEDED = 'GET_COUNT_UNREAD_MESSAGES_SUCCEEDED'
export const GET_COUNT_UNREAD_MESSAGES_FAILED = 'GET_COUNT_UNREAD_MESSAGES_FAILED'

export const GET_MESSAGE_CATEGORIES = 'GET_MESSAGE_CATEGORIES'
export const GET_MESSAGE_CATEGORIES_SUCCEEDED = 'GET_MESSAGE_CATEGORIES_SUCCEEDED'
export const GET_MESSAGE_CATEGORIES_FAILED = 'GET_MESSAGE_CATEGORIES_FAILED'

export const GET_WARRANTY_DETAILS = 'GET_WARRANTY_DETAILS';
export const GET_WARRANTY_DETAILS_SUCCEEDED = 'GET_WARRANTY_DETAILS_SUCCEEDED';
export const GET_WARRANTY_DETAILS_FAILED = 'GET_WARRANTY_DETAILS_FAILED';

export const CREATE_WARRANTY = 'CREATE_WARRANTY';
export const CREATE_WARRANTY_SUCCEEDED = 'CREATE_WARRANTY_SUCCEEDED';
export const CREATE_WARRANTY_FAILED = 'CREATE_WARRANTY_FAILED';
export const SET_WARRANTY_NUMBER = 'SET_WARRANTY_NUMBER ';
export const SET_WARRANTY_MESSAGE = 'SET_WARRANTY_MESSAGE ';

export const GET_BUDGET_AND_GOALS_REPORT = 'GET_BUDGET_AND_GOALS_REPORT'
export const GET_BUDGET_AND_GOALS_REPORT_SUCCEEDED = 'GET_BUDGET_AND_GOALS_REPORT_SUCCEEDED'
export const GET_BUDGET_AND_GOALS_REPORT_FAILED = 'GET_BUDGET_AND_GOALS_REPORT_FAILED'
export const GET_CUSTOMER_CARD = 'GET_CUSTOMER_CARD'
export const GET_CUSTOMER_CARD_SUCCEEDED = 'GET_CUSTOMER_CARD_SUCCEEDED'
export const GET_CUSTOMER_CARD_FAILED = 'GET_CUSTOMER_CARD_FAILED'
export const SET_REPORTS_MESSAGE = 'SET_REPORTS_MESSAGE'

export const GET_SALES_REPORT_FILTERS = 'GET_SALES_REPORT_FILTERS'
export const GET_SALES_REPORT_FILTERS_SUCCEEDED = 'GET_SALES_REPORT_FILTERS_SUCCEEDED'
export const GET_SALES_REPORT_FILTERS_FAILED = 'GET_SALES_REPORT_FILTERS_FAILED'

export const GET_SALES_REPORT_DATA = 'GET_SALES_REPORT_DATA'
export const GET_SALES_REPORT_DATA_SUCCEEDED = 'GET_SALES_REPORT_DATA_SUCCEEDED'
export const GET_SALES_REPORT_DATA_FAILED = 'GET_SALES_REPORT_DATA_FAILED'

export const GET_BANNERS = 'GET_BANNERS'
export const GET_BANNERS_SUCCEEDED = 'GET_BANNERS_SUCCEEDED'
export const GET_BANNERS_FAILED = 'GET_BANNERS_FAILED'

export const REQUIRE_DAILY_CODE = 'REQUIRE_DAILY_CODE'
export const REQUIRE_DAILY_CODE_SUCCEEDED = 'REQUIRE_DAILY_CODE_SUCCEEDED'
export const REQUIRE_DAILY_CODE_FAILED = 'REQUIRE_DAILY_CODE_FAILED'

export const GET_IMPORTANT_ANNOUNCEMENT = 'GET_IMPORTANT_ANNOUNCEMENT'
export const GET_IMPORTANT_ANNOUNCEMENT_SUCCEEDED = 'GET_IMPORTANT_ANNOUNCEMENT_SUCCEEDED'
export const GET_IMPORTANT_ANNOUNCEMENT_FAILED = 'GET_IMPORTANT_ANNOUNCEMENT_FAILED'
export const TOGGLE_IMPORTANT_ANNOUNCEMENT = 'TOGGLE_IMPORTANT_ANNOUNCEMENT'

export const TOGGLE_ANNOUNCEMENTS = 'TOGGLE_ANNOUNCEMENTS'

export const CHECK_FOR_PENDING_ORDER_ITEMS = 'CHECK_FOR_PENDING_ORDER_ITEMS'
export const CHECK_FOR_PENDING_ORDER_ITEMS_SUCCEEDED = 'CHECK_FOR_PENDING_ORDER_ITEMS_SUCCEEDED'
export const CHECK_FOR_PENDING_ORDER_ITEMS_FAILED = 'CHECK_FOR_PENDING_ORDER_ITEMS_FAILED'

export const TOGGLE_ORDER_CONFIRMATION = 'TOGGLE_ORDER_CONFIRMATION'