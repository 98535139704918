// @flow
import React from 'react';
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Logo from "../../app/logo/logo";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import PermissionHOC from "../../../modules/permission/components/permissionHOC/permissionHOC";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import {Link} from "react-router-dom";
import {getRouteUrl} from "../../../helpers/getRouteUrl";
import {ROUTE_PAGE_WARRANTIES} from "../../../routers/routes";
import {useTranslation} from "react-i18next";
import {NAVIGATION} from "../../../locales/components/namespaces";
import {Permissions} from "../../../helpers/constants";

type Props = {
	mobileOpen: boolean,
	handleDrawerToggle: Function,
	navItems: Array<{
		id: number,
		url: string,
		title: string
	}>
};

const drawerWidth = 300;

const AppDrawer = (props: Props) => {
	const {
		mobileOpen,
		handleDrawerToggle,
		navItems,
	} = props
	const {t} = useTranslation(NAVIGATION)
	return (
		<nav>
			<Drawer
				variant="temporary"
				open={mobileOpen}
				onClose={handleDrawerToggle}
				ModalProps={{
					keepMounted: true, // Better open performance on mobile.
				}}
				sx={{
					display: {xs: 'block', sm: 'block', md: 'none'},
					'& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
				}}
			>
				<Box onClick={handleDrawerToggle} sx={{textAlign: 'center'}}>
					<Logo
						style={{
							maxWidth: '100px',
							backgroundColor: '#FFFFFF'
						}}
					/>
					<Divider/>
					<List>
						{navItems.map((item) => item.permissions && item.permissions.length > 0 ? (
							<PermissionHOC
								key={item.id}
								requiredPermissions={[...item.permissions]}>
								<ListItem disablePadding>
									<ListItemButton
										sx={{textAlign: 'center'}}
										component={Link}
										to={item.url}
									>
										<ListItemText primary={item.title}/>
									</ListItemButton>
								</ListItem>
							</PermissionHOC>
						) : (
							<ListItem key={item.id} disablePadding>
								<ListItemButton
									sx={{textAlign: 'center'}}
									component={Link}
									to={item.url}
								>
									<ListItemText primary={item.title}/>
								</ListItemButton>
							</ListItem>
						))}
						<PermissionHOC requiredPermissions={[Permissions.AccessGuarantees]}>
							<ListItem disablePadding>
								<ListItemButton
									sx={{textAlign: 'center'}}
									component={Link}
									to={getRouteUrl(ROUTE_PAGE_WARRANTIES)}
								>
									<ListItemText primary={t('Guarantees')}/>
								</ListItemButton>
							</ListItem>
						</PermissionHOC>
					</List>
				</Box>
			</Drawer>
		</nav>
	);
};

export default AppDrawer