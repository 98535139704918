import {createSelector} from "reselect";
import {parseUserMessage} from "../../types/user/parse";
import {useSelector} from "react-redux";
import {parseMultiple} from "../../helpers/parse";
import {parseFilter} from "../../types/filters/parse";
import {useMemo} from "react";
import _ from "lodash";

const selectLoading = createSelector(
	(state) => state.reports.get('loading'),
	(item) => item
)

const selectMessage = createSelector(
	(state) => state.reports.get('message'),
	(item) => parseUserMessage(item)
)

const selectBudgetReport = createSelector(
	(state) => state.reports.get('budgetReport'),
	(item) => item.toJS()
)

const selectBonusReport = createSelector(
	(state) => state.reports.get('bonusReport'),
	(item) => item.toJS()
)

const selectCustomerCard = createSelector(
	(state) => state.reports.get('customerCard'),
	(item) => item.toJS()
)

const selectSalesReportFilters = createSelector([
		(state) => state.reports.getIn(['sales', 'filters']),
		(state) => state.currentUser.get('language')
	],
	(filters, language) => parseMultiple(filters, (filter) => parseFilter(filter, language))
)

const selectSalesReportData = createSelector(
	[
		(state) => state.reports.getIn(['sales', 'data'])
	],
	(data) => ({
		loading: data.get('loading'),
		items: data.get('items').toJS(),
	})
)

const useReportsData = () => {
	const loading = useSelector(selectLoading)
	const message = useSelector(selectMessage)
	const budgetReport = useSelector(selectBudgetReport)
	const bonusReport = useSelector(selectBonusReport)
	const customerCard = useSelector(selectCustomerCard)
	const salesReportFilters = useSelector(selectSalesReportFilters)
	const salesReportData = useSelector(selectSalesReportData)
	const salesReportFiltersSorted = useMemo(() => {
		return _.orderBy(salesReportFilters.map(f => {
			const groups = _.uniqBy(f?.values, 'related')?.map(i => i?.related)
			const values = groups.map(group => {
				if (group) {
					const groupValues = _.orderBy(f.values?.filter(val => val?.related === group), ['weight', 'description'], ['asc', 'asc'])
					return {
						group: group,
						groupValues: groupValues
					}
				}
				return {
					group: null,
					groupValues: _.orderBy(f.values.filter(val => !val?.related), ['weight', 'description'], ['asc', 'asc'])
				}
			})
			return {
				...f,
				groups: groups.filter(group => group),
				values: _.orderBy(values, ['group'], ['asc'])
			}
		}), ['weight', 'description'], ['asc', 'asc'])
	}, [salesReportFilters])
	
	return {
		loading,
		message,
		budgetReport,
		bonusReport,
		customerCard,
		salesReportFilters: salesReportFiltersSorted,
		salesReportData
	}
}

export default useReportsData