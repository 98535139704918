import {useTranslation} from "react-i18next";
import {PAGE_LOGIN} from "../../locales/pages/namespaces";
import * as yup from "yup";
import {useFormik} from "formik";
import useUserAction from "../user/useUserAction";

const useLogin = () => {
	const {t} = useTranslation(PAGE_LOGIN)
	const {userLogin} = useUserAction()
	const onSubmit = (values) => {
		userLogin(values)
	}
	
	const validationSchema = yup.object({
		username: yup
		.string()
		.required(t('required')),
		password: yup
		.string()
		.required(t('required')),
		dailyPassword: yup
		.string()
		.required(t('required')),
	})
	
	const formik = useFormik({
		initialValues: {
			username: '',
			password: '',
			dailyPassword: '',
		},
		validationSchema: validationSchema,
		onSubmit: onSubmit,
	})
	
	return {
		formik
	}
}

export default useLogin