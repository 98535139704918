// @flow
import {useDispatch} from 'react-redux'
import * as actions from '../../actions/actions'
import {useCallback} from "react";
import {getCurrentUserPermissions} from "../../modules/permission/actions/actions";

const useUserAction = () => {
	const dispatch = useDispatch()
	
	const setUserLanguage = useCallback((language) => {
		dispatch(actions.setUserLanguage(language))
	}, [dispatch])
	
	const setUserMessage = useCallback((message) => {
		dispatch(actions.setUserMessage(message))
	}, [dispatch])
	
	const setResetPassword = useCallback((newValue) => {
		dispatch(actions.setResetPassword(newValue))
	}, [dispatch])
	
	const setUserStore = useCallback((storeId) => {
		dispatch(actions.setUserStore(storeId))
	}, [dispatch])
	
	const checkAPIConnectivity = useCallback(() => {
		dispatch(actions.checkAPIConnectivity())
	}, [dispatch])
	
	const userLogout = useCallback(() => {
		dispatch(actions.userLogout())
	}, [dispatch])
	
	const userLogin = useCallback((values) => {
		const bodyFormData = new FormData();
		bodyFormData.append('username', values.username);
		bodyFormData.append('password', values.password);
		bodyFormData.append('dailyPassword', values.dailyPassword);
		dispatch(actions.userLogin(bodyFormData))
	}, [dispatch])
	
	const requestResetPassword = useCallback((values) => {
		dispatch(actions.requestResetPassword(values))
	}, [dispatch])
	
	const resetPassword = useCallback((values) => {
		dispatch(actions.resetPassword(values))
	}, [dispatch])
	
	const getUserStores = useCallback((isLogin = 0) => {
		dispatch(actions.getUserStores())
	}, [dispatch])
	
	const getUserPermissions = useCallback(() => {
		dispatch(getCurrentUserPermissions())
	}, [dispatch])

	const getDailyCode = useCallback((values) => {
		dispatch(actions.getDailyCode(values))
	}, [dispatch])
	
	const getUserDetails = useCallback((storeId) => {
		dispatch(actions.getUserDetails({
			AddressId: storeId
		}))
	}, [dispatch])
	
	const requireDailyCode = useCallback((values) => {
		dispatch(actions.requiredDailyCode({
			...values
		}))
	}, [dispatch])
	
	return {
		setUserLanguage,
		setUserMessage,
		setResetPassword,
		setUserStore,
		checkAPIConnectivity,
		userLogout,
		userLogin,
		requestResetPassword,
		resetPassword,
		getUserStores,
		getUserPermissions,
		getUserDetails,
		getDailyCode,
		requireDailyCode
	}
}

export default useUserAction