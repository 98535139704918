export default function TableCell(theme) {
	return {
		MuiTableCell: {
			styleOverrides: {
				root: {
					padding: 0
				},
				sizeSmall: {},
				head: {},
				footer: {}
			}
		}
	};
}
