export default function Switch(theme) {
	return {
		MuiSwitch: {
			styleOverrides: {
				track: {},
				thumb: {},
				switchBase: {},
				root: {},
			}
		}
	};
}
