// @flow
import { AccessTimeOutlined, CheckCircleOutlined, InfoOutlined } from "@mui/icons-material";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import dayjs from "dayjs";
import React, { useMemo } from 'react';
import { NumericFormat } from "react-number-format";
import { Permissions } from "../../../../helpers/constants";
import useUserData from "../../../../hooks/user/useUserData";
import { useHasPermission } from "../../../../modules/permission/hooks/useHasPermission";

type Props = {};

const Warehouse = (props: Props) => {
	const {
		warehouse,
		quantity,
		index,
		handleAvailability,
		setQuantity,
		dateFormat
	} = props
	const { thousandSeparator, decimalSeparator } = useUserData()
	const isAdmin = useHasPermission([Permissions.AccessAdmin], { shouldView: true })
	const availability = useMemo(() => {
		if (warehouse.realStock >= warehouse.quantity) {
			return {
				tiresAvailable: warehouse.quantity,
				tiresInWaiting: 0
			}
		} else if (warehouse.realStock < warehouse.quantity) {
			return {
				tiresAvailable: warehouse.realStock,
				tiresInWaiting: warehouse.quantity - warehouse.realStock
			}
		}
		return {
			tiresAvailable: 0,
			tiresInWaiting: 0
		}
	}, [warehouse.quantity, warehouse.realStock])
	
	return (
		<Box
			pl={0.4}
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				...(index % 2 === 0 && { backgroundColor: 'grey.100' }),
				wordWrap: 'break-word'
			}}
		>
			<Box pr={0.1}
				 sx={{
					 width: '115px'
				 }}
			>
				<Typography variant={'overline'} component={'div'}>
					{warehouse.address}
				</Typography>
			</Box>
			<Box
				sx={{
					color: `${warehouse.realStock > 0 ? 'green' : 'red'}`,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'flex-start',
					flexWrap: 'wrap',
					flex: 1,
					gap: 1
				}}
			>
				<Typography
					variant={'body2'}
					component={'div'}
					sx={{
						width: '50px',
						textAlign: 'left',
						display: 'flex',
						alignItems: 'center'
					}}
				>
					<Box
						sx={{
							border: '1px solid rgba(0, 0, 0, 0.23)',
							width: '40px',
							fontWeight: 'bold'
						}}
						pl={1}
					>
						{Number(warehouse.realStock) > 0 ? (
								Number(warehouse.realStock) > Number(warehouse.displayStock) && !isAdmin ?
									`${Number(warehouse.displayStock)}+`
									: `${Number(warehouse.realStock)}`)
							: 0}
					</Box>
				</Typography>
				<InfoOutlined
					color={'primary'}
					sx={{ width: '16px', height: '16px' }}
					onMouseEnter={(evt) => handleAvailability({
						anchorEl: evt.currentTarget,
						availability: availability,
						warehouse: warehouse
					})}
					onMouseLeave={(evt) => handleAvailability({
						anchorEl: null,
						availability: null,
						warehouse: null
					})}
				/>
				<Box
					sx={{
						width: '40px',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						gap: 0.2
					}}
				>
					{availability.tiresAvailable > 0 && (
						<>
							<Typography
								variant={'body2'}
								component={'div'}
							>
								{availability.tiresAvailable}
							</Typography>
							<CheckCircleOutlined sx={{ width: '14px', height: '14px' }}/>
						</>
					)}
				</Box>
				<Box
					sx={{
						width: '40px',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						gap: 0.2
					}}
				>
					{availability.tiresInWaiting > 0 && (
						<>
							<Typography
								variant={'body2'}
								component={'div'}
								sx={{ color: 'orange'}}
							>
								{availability.tiresInWaiting}
							</Typography>
							<AccessTimeOutlined sx={{ width: '14px', height: '14px', color: 'orange' }}/>
						</>
					)}
				</Box>
				<Box
					sx={{flex: 1, minWidth: '90px'}}
				>
					{warehouse?.expectedDate && (
						<Box sx={{
							display: 'flex',
							alignItems: 'center',
							fontStyle: 'italic',
							justifyContent: {
								xs: 'center',
								sm: 'center',
								md: 'center',
								lg: 'center',
								xl: 'center'
							},
							pr: '4px'
						}}>
							<Typography color={'primary'} variant={'caption'} component={'div'}
										sx={{ fontSize: { xs: 11, sm: 11, md: 12, lg: 12, xl: 13 } }}
										pr={0.4}>
								<sup>*</sup>
								{dayjs(warehouse?.expectedDate).format(dateFormat)}
							</Typography>
							<LocalShippingIcon color={'primary'}
											   sx={{ fontSize: { xs: 14, sm: 14, md: 14, lg: 14, xl: 18 }, }}/>
						</Box>
					)}
				</Box>
			</Box>
			<Box
				sx={{
					width: '80px'
				}}
			>
				<NumericFormat
					thousandSeparator={thousandSeparator}
					decimalSeparator={decimalSeparator}
					value={quantity?.find(f => f.id === warehouse.id)?.quantity}
					onValueChange={(values, sourceInfo) => {
						setQuantity(quantity.map(f => ({
							...f,
							quantity: f.id === warehouse.id ? values.floatValue : f.quantity
						})))
					}}
					decimalScale={0}
					customInput={TextField}
					size={'small'}
					sx={{ width: '60px' }}
					type={'number'}
					inputProps={{ min: 0, style: { textAlign: 'center' }, type: 'number' }}
				/>
			</Box>
		</Box>
	);
};

export default Warehouse