// @flow
import React from 'react';
import './promo.css'
import Box from "@mui/material/Box";
import Images from "../../../assets";

type Props = {};

const Promo = (props: Props) => {
	return (
		// <Box
		// 	sx={{
		// 		backgroundImage: `url('${Images.promo}')`,
		// 		backgroundPosition: `center`,
		// 		backgroundSize: `cover`,
		// 		backgroundRepeat: 'no-repeat',
		// 		height: '100vh'
		// 	}}
		// />
		// <YouTube
		// 	className={'video-background'}
		// 	videoId={'PHHDTolz-vo'}
		// 	opts={{
		// 		height: '390',
		// 		width: '640',
		// 		playerVars: {
		// 			// https://developers.google.com/youtube/player_parameters
		// 			autoplay: 1,
		// 			controls: 0,
		// 			loop: 1,
		// 			start: 0,
		// 			mute: 1,
		// 		}
		// 	}}
		// />
		<Box sx={{
			display: 'flex',
			height: '100%',
			alignItems: 'center',
			justifyContent: 'center',
			backgroundColor: '#FFFFFF'
		}}>
			<img src={Images.promo} alt={'promo'} width={'100%'}/>
		</Box>
	);
};

export default Promo