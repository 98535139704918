// @flow
import React, {useState} from 'react';
import {
	Dialog,
	DialogActions,
	DialogContent,
	FormControl,
	FormHelperText,
	Grid,
	ListItemText,
	Typography
} from "@mui/material";
import FileUpload from "../../../../../../components/form/fileUpload/fileUpload";
import Button from "@mui/material/Button";
import ListItem from "@mui/material/ListItem";
import {useTranslation} from "react-i18next";
import {PAGE_WARRANTIES} from "../../../../../../locales/pages/namespaces";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import {ErrorMessage} from "formik";
import {AuthenticatedLink} from "../../../../../../components/general/authenticatedLink/authenticatedLink";
import {DownloadTwoTone} from "@mui/icons-material";

type Props = {};

const ImageWithPreview = (props: Props) => {
	const {
		isDetails,
		formik,
		label,
		fileField,
		pathField,
		getImageUrl
	} = props
	const {t} = useTranslation(PAGE_WARRANTIES);
	const [image, setImage] = useState('')
	const [openDialog, setOpenDialog] = useState(false);
	const handleCloseDialog = () => {
		setOpenDialog(false); // Close the dialog
	};
	return (
		<ListItem sx={{display: 'flex', flexWrap: 'wrap', width: '100%', maxWidth: '800px'}}>
			<ListItemText sx={{width: {md: '50%', sm: '100%'}}}>{t(label)}</ListItemText>
			<Grid container spacing={2} sx={{width: {md: '50%', sm: '100%'}}}>
				<Grid item>
					{!formik.values[pathField] && (
						<FormControl
							variant="standard"
							fullWidth
							error={
								formik.touched[pathField] &&
								Boolean(formik.errors[pathField])
							}
						>
							<FileUpload
								allowedFiles={{'image/*': ['.jpeg', '.png', '.jpg']}}
								onFilesUpload={(value) => {
									const file = value[0];
									formik.setFieldValue(fileField, file);
									formik.setFieldValue(pathField, value[0].path)
									const reader = new FileReader();
									reader.onload = (event) => {
										setImage(event.target.result); // Set image URL for preview
									};
									reader.readAsDataURL(file);
								}}
								onRemove={(value) => {
									formik.setFieldValue(pathField, value[0].path)
								}}
							/>
							<FormHelperText>
								<ErrorMessage name={pathField}/>
							</FormHelperText>
						</FormControl>
					)}
				</Grid>
				<Grid item>
					<List sx={{width: '100%'}}>
						{formik.values[pathField] && (
							<ListItem>
								<ListItemButton>
									{image ? (
										<Button onClick={() => setOpenDialog(true)}>{formik.values[pathField]}</Button>
									) : (
										<AuthenticatedLink
											url={getImageUrl}
											filename={[pathField]}
											isImg={true}
										>
											<Typography variant={'body1'} sx={{display: 'flex', alignItems: 'center'}}>
												{formik.values[pathField]}
												<DownloadTwoTone/>
											</Typography>
										</AuthenticatedLink>
									)}
								</ListItemButton>
								{!isDetails && <ListItemButton>
								<Button
									onClick={() => {
										formik.setFieldValue(pathField, '')
										formik.setFieldValue(fileField, '')
									}}>
									{t('Clear')}
								</Button>
							</ListItemButton>}
							</ListItem>
						)}
					</List>
				</Grid>
			</Grid>
			<Dialog open={openDialog} onClose={handleCloseDialog}>
				<DialogContent>
					<img src={image} alt="Uploaded" style={{width: '100%'}}/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseDialog}>{t('Close')}</Button>
				</DialogActions>
			</Dialog>
		</ListItem>
	);
};

export default ImageWithPreview