import requestHandler from "./api/api";
import * as Actions from "./actions/actions";
import * as ActionTypes from "./actions/types";
import reducer from "./reducer/reducer";

export const PermissionsModule = {
	name: "permissions",
	actions: Actions,
	types: ActionTypes,
	reducer: reducer,
	api: requestHandler,
}