import type {Bonus, Budget, SalesData, UnmappedBonus, UnmappedBudget, UnmappedSalesData} from "./type";
import {CustomerCard, UnmappedCustomerCard} from "./type";
import dayjs from "dayjs";

export const mapBudget: Budget = (datum: UnmappedBudget) => {
	if (!datum) return
	return {
		quarter: Number(datum.BQTR),
		year: Number(datum.BYEAR),
		category1: datum.CTGDESCR1.trim(),
		category2: datum.CTGDESCR2.trim(),
		salesQuantity: Number(datum.SQTY),
		budgetQuantity: Number(datum.BQTY)
	}
}

export const mapBonus: Bonus = (datum: UnmappedBonus) => {
	if (!datum) return
	const unit = datum.STOXOSSENARIO === 'BONUS PLUS' ? datum.UNITBONUSPLUS : datum.UNITBONUS
	return {
		year: dayjs(datum.BDATE, "DD/MM/YY").year(),
		diameter: Number(datum.DIAMETROS),
		quantity: Number(datum.SQTY),
		goalScenario: datum.STOXOSSENARIO,
		bonus: Number(datum.BONUS.replace(/,/g, ".")),
		unit: Number(unit.replace(/,/g, ".")),
	}
}

export const mapCustomerCard: CustomerCard = (datum: UnmappedCustomerCard) => {
	if (!datum) return
	const isOfFormat = datum?.TRNDATE && datum?.TRNDATE?.split('/')?.[2]?.length === 2
	return {
		date: datum.TRNDATE ? (isOfFormat ? dayjs(datum.TRNDATE, 'DD/MM/YY')?.format('YYYY-MM-DD') : dayjs(datum.TRNDATE, 'DD/MM/YYYY')?.format('YYYY-MM-DD')) : null,
		type: datum.DOCREASON,
		docNum: datum.DOCNUM,
		debit: datum.DEBIT,
		credit: datum.CREDIT,
		balance: datum.BALANCE,
		id: datum.DOCID
	}
}

export const mapSalesData: SalesData = (datum: UnmappedSalesData) => {
	if (!datum) return
	return {
		year: Number(datum.ForYear),
		quantity: Number(datum.TotalQty)
	}
}