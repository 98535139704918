import * as React from "react";
const Eurobank = (props) => (
    <svg
        height={153}
        viewBox="-1.38 -0.38 34 10.44"
        width={200}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M32.62 5.58c0-.6-.24-1.14-.66-1.58-.42-.42-.98-.66-1.58-.66H3.06v4.48c0 .6.24 1.16.66 1.58s.98.66 1.58.66h27.32v-.32z"
            fill="#fff"
        />
        <path
            d="M32.28 9.74h-27c-1.06 0-1.9-.84-1.9-1.9V3.68h27c1.06 0 1.9.86 1.9 1.9z"
            fill="#1e4485"
        />
        <path
            d="M12.3 8.18c-.22.24-.46.38-.76.38-.48 0-.84-.3-.84-.92V6.06h.66v1.48c0 .36.16.58.46.58.22 0 .48-.16.48-.6V6.06h.66V8.5h-.66zm12.88-1.86c.22-.24.52-.36.84-.36.48 0 .88.3.88.92v1.58h-.66V7c0-.36-.2-.58-.52-.58-.22 0-.52.16-.52.6v1.46h-.66V6.02h.66c-.02 0-.02.3-.02.3m-10.84.14s.26-.48.54-.48c.16 0 .3.08.44.22l-.24.46c-.1-.04-.18-.08-.3-.08-.22 0-.44.18-.44.7v1.2h-.66V6.02h.66zm13.32-1.58h.64v2.1h.04l.86-1h.58l-.92 1.12 1.12 1.38h-.74l-.92-1.3h-.02v1.3h-.64zM9.36 8.02c-.48 0-.62-.18-.62-.46v-.64h1.12v-.46H8.74v-.94H9.9v-.46H8.06V7.6c0 .46.24.86.8.86h1.28V8h-.76v.02zm13.08.52c-.52 0-.8-.32-.8-.68 0-.54.32-.8.9-.94l.58-.18c-.02-.22-.22-.38-.44-.38-.28 0-.48.1-.64.26l-.22-.3c.3-.26.6-.32.94-.32.46 0 1.02.16 1.02.9v.98c0 .24.02.42.1.6h-.7c-.04-.1-.04-.22-.06-.36 0 0-.26.42-.68.42m-5.62 0c.7 0 1.28-.58 1.28-1.28s-.58-1.3-1.28-1.3-1.3.58-1.3 1.3c.02.7.58 1.28 1.3 1.28m3.28-2.56c-.3 0-.58.16-.76.4v-1.5h-.66v3.6h.66v-.3c.2.22.48.38.76.38.6 0 1.1-.58 1.1-1.28-.02-.74-.5-1.3-1.1-1.3m2.68 1.2c-.3.08-.48.28-.48.58 0 .22.14.36.28.36.28 0 .58-.28.54-1.04zm-5.96-.86c.36 0 .6.3.6.92s-.24.92-.6.92-.6-.3-.6-.92c0-.58.24-.92.6-.92m3.12 0c.36 0 .6.3.6.92s-.24.92-.6.92-.6-.3-.6-.92c-.02-.58.24-.92.6-.92M2.7 7.76c2.24 0 4.06-1.82 4.06-4.06S4.94-.38 2.7-.38s-4.08 1.82-4.08 4.06S.44 7.76 2.7 7.76"
            fill="#fff"
        />
        <path
            d="M-1.02 3.68c0 2.06 1.68 3.74 3.74 3.74s3.74-1.68 3.74-3.74S4.78-.06 2.72-.06c-2.1.02-3.74 1.68-3.74 3.74"
            fill="#d2242a"
        />
        <g fill="#fff">
            <path d="M3.16 1.04C3.1 1.02 3.04 1 3 .98c-.44-.18-.94.04-1.26.5L.06 4c-.26.38-.22.9.14 1.24.2.2.44.28.7.28h4.02l-.8-.92H.76z" />
            <path d="m3.66 3.1-.54.86H1.98l1.56-2.42 1.8 2.06c.44.48.38 1.06.06 1.5z" />
        </g>
    </svg>
);
export default Eurobank;
