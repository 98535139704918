// @flow
import React from 'react';
import {Chip, Tooltip} from "@mui/material";
import {useTranslation} from "react-i18next";
import {PAGE_WARRANTIES} from "../../../locales/pages/namespaces";
import {normalizeGreek} from "../../../helpers/normalizeGreek";
import {WarrantyStatus as Status} from "../../../helpers/constants";

type Props = {};

const WarrantyStatus = (props: Props) => {
	const {status} = props
	const {t} = useTranslation(PAGE_WARRANTIES)
	let color = ''
	let backgroundColor = ''
	switch (status) {
		case Status.Initialized:
			color = '#FFFFFF'
			backgroundColor = '#ffa726'
			break;
		case Status.Activated:
			color = '#FFFFFF'
			backgroundColor = '#2E7D32'
			break;
		case Status.Pending:
			color = '#FFFFFF'  //pending
			backgroundColor = '#CE7C56'
			break;
		case Status.Approved:
			color = '#FFFFFF'  //approved
			backgroundColor = '#009688'
			break;
		case Status.Rejected:
			color = '#FFFFFF'  //rejected
			backgroundColor = '#607d8b'
			break;
		case Status.ActivationTimeExpired:
			color = '#FFFFFF'  //activationExp
			backgroundColor = '#795548'
			break;
		case Status.Expired:
			color = '#FFFFFF'  //error
			backgroundColor = '#d32f2f'
			break;
		default:
			backgroundColor = '#1877f2'
			color = '#FFFFFF'
	}
	return (
		<Tooltip title={normalizeGreek(t(status)).toUpperCase()}>
			<Chip label={normalizeGreek(t(status)).toUpperCase()}
				  sx={{fontSize: 'xx-small', backgroundColor: backgroundColor, color: color}} size={'small'}/>
		</Tooltip>
	);
};

export default WarrantyStatus