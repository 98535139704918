// @flow
import React from 'react';
import {FormControlLabel, Typography} from "@mui/material";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";
import {PAGE_SEARCH_RESULTS} from "../../../locales/pages/namespaces";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import axios from "axios";
import {API} from "../../../helpers/constants";
import {enqueueSnackbar} from "notistack";
import {handleAxiosErrors} from "../../../helpers/errorHandling";

type Props = {};

const PriceOptions = (props: Props) => {
    const {
        displayWithTax,
        setDisplayWithTax,
        displayWithEnvTax,
        setDisplayWithEnvTax,
        displayWithPaymentDiscount,
        setDisplayWithPaymentDiscount
    } = props
    const {t} = useTranslation(PAGE_SEARCH_RESULTS)

    const handleChangeFlags = (isVat, isOther, isMetritis) => {
        const isVatValue = isVat ? 1 : 0;
        const isOtherValue = isOther ? 1 : 0;
        const isMetritisValue = isMetritis ? 1 : 0;
        axios.post(`${API}/en/b2b/sts/flags/save`, {
            isVat: isVatValue,
            isOther: isOtherValue,
            isMetritis: isMetritisValue
        }).then(response => {
        }).catch((error) => {
            enqueueSnackbar(handleAxiosErrors(error, t('Failed to save your selections!'), {
                variant: 'error',
                autoHideDuration: 1000,
            }));
        })
    };

    return (
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', maxWidth: '1400px'}}>
            <Box>
                <FormControlLabel
                    sx={{ml: 'auto', pb: 0.1}}
                    value={t('Display values with tax')}
                    control={
                        <Switch
                            size={'small'}
                            checked={displayWithTax}
                            onChange={(evt) => {
                                setDisplayWithTax(evt.target.checked);
                                handleChangeFlags(evt.target.checked, displayWithEnvTax, displayWithPaymentDiscount);
                            }}
                        />
                    }
                    label={t('Display values with tax')}
                    labelPlacement="end"
                />
                <FormControlLabel
                    sx={{ml: 'auto', pb: 0.1}}
                    value={t('Display values with env. tax')}
                    control={
                        <Switch
                            size={'small'}
                            checked={displayWithEnvTax}
                            onChange={(evt) => {
                                setDisplayWithEnvTax(evt.target.checked);
                                handleChangeFlags(displayWithTax, evt.target.checked, displayWithPaymentDiscount);
                            }}
                        />
                    }
                    label={t('Display values with env. tax')}
                    labelPlacement="end"
                />
                <FormControlLabel
                    sx={{ml: 'auto', pb: 0.1}}
                    value={t('Display values with payment discount')}
                    control={
                        <Switch
                            size={'small'}
                            checked={displayWithPaymentDiscount}
                            onChange={(evt) => {
                                setDisplayWithPaymentDiscount(evt.target.checked);
                                handleChangeFlags(displayWithTax, displayWithEnvTax, evt.target.checked);
                            }}
                        />
                    }
                    label={t('Display values with payment discount')}
                    labelPlacement="end"
                />
            </Box>
            <Box sx={{display: 'flex', alignItems: 'center', fontStyle: 'italic'}}>
                <Typography color={'primary'} mr={1.5} variant={'caption'} component={'div'}>
                    <sup>*</sup>
                    <Box component={'span'} mr={1}>{t('New shipment arrival')}</Box>
                </Typography>
                <LocalShippingIcon color={'primary'}/>
            </Box>
        </Box>
    );
};

export default PriceOptions