import type {Store, User} from "./types";
import {parseMultiple} from "../../helpers/parse";
import {isImmutable} from "immutable";

export const parseUserMessage: User = (datum) => {
	if (!datum) return;
	return {
		text: datum.get('text'),
		variant: datum.get('variant')
	}
}

export const parseStore: Store = (datum, language) => {
	if (!datum) return;
	return {
		id: datum.get('id'),
		address: datum.getIn(['address', language]),
		countryCode: datum.get('countryCode'),
		customerId: datum.get('customerId'),
		customer: datum.get('customer'),
		isAdmin: datum.get('isAdmin'),
		region: datum.get('region')
	}
}

const parseOrderType = (datum, language) => {
	if (!(datum && isImmutable(datum))) return
	return {
		id: datum.get('id'),
		title: datum.getIn(['title', language]),
	}
}

export const parseUser: User = (datum, language) => {
	if (!datum) return;
	return {
		id: datum.get('id'),
		loginName: datum.getIn(['loginName', language]),
		username: datum.get('username'),
		customer: datum.get('customer'),
		userId: datum.get('userId'),
		hasExpandedAnnouncements: datum.get('hasExpandedAnnouncements'),
		addressId: datum.get('addressId'),
		customerId: datum.get('customerId'),
		currencySymbol: datum.get('currencySymbol'),
		orderTypes: parseMultiple(datum.get('orderTypes'), (i) => parseOrderType(i, language)),
		paymentDiscount: datum.get('paymentDiscount'),
		secondPaymentDiscount: datum.get('secondPaymentDiscount'),
		preserveForBackend: datum.get('preserveForBackend')?.toJS(),
		erpCode: datum.get('erpCode')
	}
}

