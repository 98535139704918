// @flow
import React from "react"

const Images = {
	FilterIcon: () => (
		<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
			<path d="M0 0h24v24H0z" fill="none"/>
			<path fill="currentColor" d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z"/>
		</svg>
	),
	ExpandIcon: () => (
		<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
			<path d="M0 0h24v24H0z" fill="none"/>
			<path
				d="M12 5.83L15.17 9l1.41-1.41L12 3 7.41 7.59 8.83 9 12 5.83zm0 12.34L8.83 15l-1.41 1.41L12 21l4.59-4.59L15.17 15 12 18.17z"
				fill="currentColor"
			/>
		</svg>
	),
	CollapseIcon: () => (
		<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
			<path
				d="M0 0h24v24H0z"
				fill="none"
			/>
			<path
				fill="currentColor"
				d="M7.41 18.59L8.83 20 12 16.83 15.17 20l1.41-1.41L12 14l-4.59 4.59zm9.18-13.18L15.17 4 12 7.17 8.83 4 7.41 5.41 12 10l4.59-4.59z"/>
		</svg>
	),
	SortDown: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			enableBackground="new 0 0 24 24"
			height="24" viewBox="0 0 24 24" width="24">
			<rect fill="none" height="24" width="24"/>
			<path d="M19,15l-1.41-1.41L13,18.17V2H11v16.17l-4.59-4.59L5,15l7,7L19,15z" fill="currentColor"/>
		</svg>
	),
	SortUp: () => (
		<svg xmlns="http://www.w3.org/2000/svg"
			 enableBackground="new 0 0 24 24" height="24" viewBox="0 0 24 24"
			 width="24">
			<rect fill="none" height="24" width="24"/>
			<path d="M5,9l1.41,1.41L11,5.83V22H13V5.83l4.59,4.59L19,9l-7-7L5,9z" fill="currentColor"/>
		</svg>
	)
}

export default Images