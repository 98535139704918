//@flow
import {createSelector} from 'reselect'
import {useSelector} from "react-redux";
import {parseMultiple, parseMultipleFromReference} from "../../helpers/parse";
import {parseOrder} from "../../types/order/parse";
import { parsePendingOrderItem } from "../../types/product/parse";
import {parseUserMessage} from "../../types/user/parse";

const selectLoading = createSelector(
	(state) => state.order.get('loading'),
	(item) => item
)

const selectRedirectToOrder = createSelector(
	(state) => state.order.get('redirectToOrder'),
	(item) => item
)

const selectMessage = createSelector(
	(state) => state.order.get('message'),
	(item) => parseUserMessage(item)
)

const selectIndexTable = createSelector(
	[
		(state) => state.order.get('indexTable'),
		(state) => state.order.get('byId'),
		(state) => state.currentUser.get('language'),
	],
	(indexTable, byId, language) => ({
		loading: indexTable.get('loading'),
		count: indexTable.get('count'),
		items: parseMultipleFromReference(indexTable.get('items'), byId, (i) => parseOrder(i, language, {})),
	})
)

const selectActiveOrder = createSelector(
	[
		(state) => state.order.get('activeOrder'),
		(state) => state.currentUser.get('language'),
	],
	(activeOrder, language) => parseMultiple(activeOrder, (i) => parseOrder(i, language)),
)

const selectTotalProductsInCart = createSelector(
	(state) => state.order,
	(item) => item.get('totalProductsInCart')
)

const selectOrderCompleted = createSelector(
	(state) => state.order,
	(item) => item.get('orderCompleted')
)

const selectOrderNoLongerExists = createSelector(
	(state) => state.order,
	(item) => item.get('orderNoLongerExists')
)

const selectLoadingSubmitting = createSelector(
	(state) => state.order,
	(item) => item.get('loadingSubmitting')
)

const selectPendingOrderItems = createSelector(
	[
		(state) => state.order.get('pendingOrderItems'),
		(state) => state.currentUser.get('language'),
	],
	(pendingOrderItems, language) => parseMultiple(pendingOrderItems, (i) => parsePendingOrderItem(i, language))
)

const selectLoadingPendingItems = createSelector(
	(state) => state.order,
	(item) => item.get('loadingPendingItems')
)

const selectOpenOrderConfirmation = createSelector(
	(state) => state.order,
	(item) => item.get('openOrderConfirmation')
)

export const useOrderData = () => {
	const loading = useSelector(selectLoading)
	const redirectToOrder = useSelector(selectRedirectToOrder)
	const message = useSelector(selectMessage)
	const indexTable = useSelector(selectIndexTable)
	const activeOrder = useSelector(selectActiveOrder)
	const totalProductsInCart = useSelector(selectTotalProductsInCart)
	const orderCompleted = useSelector(selectOrderCompleted)
	const orderNoLongerExists = useSelector(selectOrderNoLongerExists)
	const loadingSubmitting = useSelector(selectLoadingSubmitting)
	const pendingOrderItems = useSelector(selectPendingOrderItems)
	const loadingPendingItems = useSelector(selectLoadingPendingItems)
	const openOrderConfirmation = useSelector(selectOpenOrderConfirmation)

	return {
		loading,
		redirectToOrder,
		indexTable,
		activeOrder,
		totalProductsInCart,
		message,
		orderCompleted,
		orderNoLongerExists,
		loadingSubmitting,
		pendingOrderItems,
		loadingPendingItems,
		openOrderConfirmation
	}
}