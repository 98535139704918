import type {FileType, Message, UnmappedFileType, UnmappedMessage} from "./type";
import {API} from "../../helpers/constants";
import {mapMultiple} from "../../helpers/map";

export const mapFile: FileType = (datum: UnmappedFileType) => {
    if (!datum) return
    return {
        id: datum.id,
        name: datum.OriginFileName,
        url: `${API}/en/messages/attachments/download/${datum.id}`
    }
}

export const mapMessage: Message = (datum: UnmappedMessage, urlId = null, hasExpandedAnnouncements = false) => {
    if (!datum) return
    return {
        id: datum.id,
        date: datum.SendDt ? datum.SendDt : null,
        title: datum.Subject,
        text: datum.Body,
        files: mapMultiple(datum.files, mapFile),
        viewedDate: datum.Viewed ? datum.Viewed : null,
        actionId: datum.ActionId,
        actionTypeId: datum.ActionTypeId,
        lastModifiedAt: datum.LastModifiedAt,
        lastModifiedBy: datum.LastModifiedBy,
        senderUsername: datum.username,
        needReply: datum.NeedReply === '1',
        parentId: datum.ParentId,
        replyUntilDate: datum.ReplyUntilDt,
        replyUntilTime: datum.ReplyUntilTm,
        senderId: datum.SenderId,
        type: datum.Type,
        typeId: Number(datum.TypeId),
        isOpen: urlId === datum.id || hasExpandedAnnouncements
    }
}
