// @flow
import {
	Bookmarks,
	CalculateOutlined,
	FiberManualRecord,
	InfoOutlined,
	LinkedCamera,
	ShoppingCartOutlined,
	Stars
} from "@mui/icons-material";
import { Divider, Grid, Tooltip, Typography, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import { enqueueSnackbar } from "notistack";
import React, { useCallback, useState } from 'react';
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import { getTotalProductsInCart } from "../../../helpers/helpers";
import { normalizeGreek } from "../../../helpers/normalizeGreek";
import useOrderAction from "../../../hooks/order/useOrderAction";
import useProductCalculations from "../../../hooks/product/useProductCalculations";
import './product.css'
import useProductQuantity from "../../../hooks/product/useProductQuantity";
import useUserData from "../../../hooks/user/useUserData";
import { PAGE_ORDERS } from "../../../locales/pages/namespaces";
import AvailabilityPopOver from "./partials/availabilityPopOver";
import PricesPopOver from "./partials/pricesPopOver";
import Warehouse from "./partials/warehouse";

type Props = {};

const Product = (props: Props) => {
	const {
		product: {
			alternativeCode,
			displayAttribute,
			eanCode,
			description,
			id,
			taxPercentage,
			priceWithoutTax,
			priceWithTax,
			priceAfterDiscounts,
			priceAfterDiscountsWithTax,
			priceWithPaymentDiscount,
			priceWithPaymentDiscountWithTax,
			priceWithSecondPaymentDiscount,
			priceWithSecondPaymentDiscountWithTax,
			priceAfterDiscountsWithEnvTax,
			priceAfterDiscountsWithEnvTaxAndItemTax,
			priceWithPaymentDiscountWithEnvTax,
			priceWithPaymentDiscountWithEnvTaxAndItemTax,
			priceWithSecondPaymentDiscountWithEnvTax,
			priceWithSecondPaymentDiscountWithEnvTaxAndItemTax,
			envCharge,
			totalDiscountPercentage,
			totalDiscountValue,
			warehouses,
			oemConstructor,
			oemVehicleType,
			externalLabelLink,
			manufacturerImage,
			paymentDiscount,
			secondPaymentDiscount
		},
		displayWithTax,
		displayWithEnvTax,
		displayWithPaymentDiscount,
		handleCalculator,
		handleProductInfo
	} = props
	const {t} = useTranslation(PAGE_ORDERS)
	const theme = useTheme();
	const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
	const {thousandSeparator, decimalSeparator, maxAllowedProductsInCart, dateFormat} = useUserData()
	const {createOrder, updateOrder} = useOrderAction()
	
	const {
		quantity,
		setQuantity,
		activeOrder
	} = useProductQuantity({
		warehouses,
		productId: id,
	})
	
	const {
		envValue,
		netValue,
		discountValue,
		mappedWarehouses,
		priceWithDisplayPaymentDiscount,
		priceWithDisplayPaymentDiscountWithTax,
		priceWithDisplayPaymentDiscountWithEnvTax,
		priceWithDisplayPaymentDiscountWithEnvTaxAndItemTax
	} = useProductCalculations({
		priceWithoutTax,
		priceWithTax,
		priceAfterDiscounts,
		priceAfterDiscountsWithTax,
		priceWithPaymentDiscount,
		priceWithPaymentDiscountWithTax,
		priceAfterDiscountsWithEnvTax,
		priceAfterDiscountsWithEnvTaxAndItemTax,
		priceWithPaymentDiscountWithEnvTax,
		priceWithPaymentDiscountWithEnvTaxAndItemTax,
		warehouses,
		displayWithTax,
		displayWithEnvTax,
		displayWithPaymentDiscount,
		priceWithSecondPaymentDiscount,
		priceWithSecondPaymentDiscountWithTax,
		priceWithSecondPaymentDiscountWithEnvTax,
		priceWithSecondPaymentDiscountWithEnvTaxAndItemTax,
		paymentDiscount,
		secondPaymentDiscount
	})
	
	const [pricesPopOver, setPricesPopOver] = useState({
		anchorEl: null,
		prices: null
	})
	const handlePrices = (newValue) => {
		setPricesPopOver({...newValue});
	};
	
	const [availability, setAvailability] = useState({
		anchorEl: null,
		warehouse: null
	})
	const handleAvailability = (value) => {
		setAvailability({...value});
	};
	
	const addToCart = useCallback(() => {
		const orderProducts = activeOrder ? activeOrder?.products : []
		let totalProductQuantity = getTotalProductsInCart(orderProducts, [Number(id)])
		const productQuantity = quantity.reduce((acc, warehouse) => (
			acc + warehouse.quantity
		), 0)
		totalProductQuantity += productQuantity
		if (totalProductQuantity > maxAllowedProductsInCart) {
			enqueueSnackbar(t('max_allowed_products', {maxAllowedProductsInCart: maxAllowedProductsInCart}), {
				variant: 'warning',
				autoHideDuration: 3000
			})
		} else {
			if (activeOrder) {
				updateOrder({
					quantity: quantity,
					product: {
						...props.product
					},
					orderId: activeOrder?.id
				})
			} else {
				createOrder({
					quantity: quantity,
					product: {
						...props.product
					}
				})
			}
		}
	}, [activeOrder, createOrder, id, maxAllowedProductsInCart, props.product, quantity, t, updateOrder])
	
	return (
		<>
			<Grid container sx={{
				background: '#FFFFFF', padding: 0.4, marginBottom: 1,
				border: '1px solid rgba(37,91,130, 0.5)',
				boxShadow: 'rgba(37,91,130, 0.4) 0px 1px 2px 0px, rgba(37,91,130, 0.2) 0px 1px 3px 1px'
			}}>
				<Grid item xs={12}>
					<Box
						sx={{
							display: "flex",
							width: "100%",
							flexDirection: 'row',
							flexWrap: 'wrap',
							justifyContent: 'space-between',
							alignItems: 'center',
							pt: 0.1
						}}
					>
						<img src={manufacturerImage} alt={'logo'} style={{maxHeight: "40px", maxWidth: '120px'}}/>
						<Typography
							component={'div'}
							variant={'overline'}
						>
							<strong>{displayAttribute && normalizeGreek(displayAttribute)} </strong>
						</Typography>
					</Box>
					<Divider/>
				</Grid>
				<Grid
					item
					xs={12}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							flexWrap: 'wrap',
							width: '100%',
						}}
					>
						<Box
							sx={{
								width: {
									xs: '100%',
									sm: '50%',
									md: '20%',
									lg: '25%'
								},
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'space-between'
							}}
						
						>
							<Box>
								<Typography variant={'h5'} component={'div'} sx={{wordWrap: 'break-word'}}>
									{description}
								</Typography>
								<Typography variant={'body2'} component={'div'}>
									{t('EAN CODE')}: {eanCode}
								</Typography>
								<Typography variant={'body2'} component={'div'}>
									{t('Alternative Code')}: {alternativeCode}
								</Typography>
								<Box>
									<Tooltip title={t('Product images and info')}>
										<Button
											variant="text"
											sx={{
												padding: 0,
												minWidth: 30,
												maxHeight: 30,
												marginLeft: 0.1,
												marginRight: 0.1
											}}
											onClick={() => handleProductInfo({
												open: true,
												product: {...props.product}
											})}
										>
											<LinkedCamera fontSize={'small'}/>
										</Button>
									</Tooltip>
									<Tooltip title={t('EPREL - European Product Registry for Energy Labelling')}>
										<a
											href={externalLabelLink}
											target={'_blank'}
											rel="noreferrer"
										>
											<Button
												variant="text"
												sx={{
													padding: 0,
													minWidth: 30,
													maxHeight: 30,
													marginLeft: 0.1,
													marginRight: 0.1
												}}
											
											>
												<Bookmarks fontSize={'small'} color={'secondary'}/>
											</Button>
										</a>
									</Tooltip>
								</Box>
								{oemConstructor && oemVehicleType && (
									<>
										<Typography variant={'body2'} component={'div'}>
											<strong>OEM</strong>
										</Typography>
										<Typography variant={'body2'} component={'div'}>
											{t('OEM Constructor')}: {oemConstructor}
										</Typography>
										<Typography variant={'body2'} component={'div'}>
											{t('OEM Vehicle Type')}: {oemVehicleType}
										</Typography>
									</>
								)}
							</Box>
						</Box>
						<Box
							sx={{
								width: {
									xs: '100%',
									sm: '100%',
									md: '60%',
									lg: '50%'
								},
								p: 0.2,
								...(matchesMd && { order: 2 })
							}}
							className={'warehouses'}
						>
							<Box className={'warehouse'}>
								{mappedWarehouses.map((warehouse, index) => (
									<Warehouse
										productId={id}
										key={warehouse.id}
										warehouse={warehouse}
										index={index}
										handleAvailability={handleAvailability}
										warehouses={warehouses}
										quantity={quantity}
										setQuantity={setQuantity}
										activeOrder={activeOrder}
										dateFormat={dateFormat}
									/>
								))}
							</Box>
							<Box className={'warehouses-cart'}>
								<Tooltip title={t('Add to cart')}>
									<Button
										onClick={addToCart}
									>
										<ShoppingCartOutlined/>
									</Button>
								</Tooltip>
							</Box>
						</Box>
						<Box
							sx={{
								width: {
									xs: '100%',
									sm: '50%',
									md: '20%',
									lg: '25%'
								},
								position: 'relative'
							}}
						>
							<Box sx={{
								position: 'absolute',
								right: 0
							}}>
								<Tooltip title={'Υπολογισμοί'}>
									<Button
										variant={'text'}
										sx={{minHeight: 0, minWidth: 0, padding: 0}}
										onClick={() => handleCalculator({
											open: true,
											prices: {
												taxPercentage,
												priceWithoutTax,
												priceWithTax,
												priceAfterDiscounts,
												priceAfterDiscountsWithTax,
												priceWithPaymentDiscount: priceWithDisplayPaymentDiscount,
												priceWithPaymentDiscountWithTax: priceWithDisplayPaymentDiscountWithTax,
												priceAfterDiscountsWithEnvTax,
												priceAfterDiscountsWithEnvTaxAndItemTax,
												priceWithPaymentDiscountWithEnvTax: priceWithDisplayPaymentDiscountWithEnvTax,
												priceWithPaymentDiscountWithEnvTaxAndItemTax: priceWithDisplayPaymentDiscountWithEnvTaxAndItemTax,
												envCharge: envCharge.value,
												description: description
											}
										})}
									>
										<CalculateOutlined fontSize={'large'} p={0}/>
									</Button>
								</Tooltip>
							</Box>
							<Grid container pt={1}>
								<Grid item xs={12}>
									<InfoOutlined
										color={'primary'}
										sx={{width: '16px', height: '16px'}}
										onMouseEnter={(evt) => handlePrices({
											anchorEl: evt.currentTarget,
											prices: {
												taxPercentage,
												priceWithoutTax,
												priceWithTax,
												priceAfterDiscounts,
												priceAfterDiscountsWithTax,
												priceWithPaymentDiscount: priceWithDisplayPaymentDiscount,
												priceWithPaymentDiscountWithTax: priceWithDisplayPaymentDiscountWithTax,
												priceAfterDiscountsWithEnvTax,
												priceAfterDiscountsWithEnvTaxAndItemTax,
												priceWithPaymentDiscountWithEnvTax: priceWithDisplayPaymentDiscountWithEnvTax,
												priceWithPaymentDiscountWithEnvTaxAndItemTax: priceWithDisplayPaymentDiscountWithEnvTaxAndItemTax,
												envCharge: envCharge.value
											}
										})}
										onMouseLeave={(evt) => handlePrices({
											anchorEl: null,
											prices: null
										})}
									/>
								</Grid>
								<Grid item xs={8}>
									<Typography component={'div'} variant={'body2'}>
										{t('Wholesale Value')}
									</Typography>
								</Grid>
								<Grid item xs={4}>
									<NumericFormat
										thousandSeparator={thousandSeparator}
										decimalSeparator={decimalSeparator}
										displayType="text"
										value={netValue}
									/> €
								</Grid>
								<Grid item xs={8}>
									<Typography component={'div'} variant={'body2'}>
										{t('Invoice Value')}
									</Typography>
								</Grid>
								<Grid item xs={4}>
									<NumericFormat
										thousandSeparator={thousandSeparator}
										decimalSeparator={decimalSeparator}
										displayType="text"
										value={discountValue}
									/> €
								</Grid>
								<Grid item xs={8}>
									<Typography component={'div'} variant={'body2'}>
										{t('Final Cost (with payment discount)')}
									</Typography>
								</Grid>
								<Grid item xs={4}>
									<NumericFormat
										thousandSeparator={thousandSeparator}
										decimalSeparator={decimalSeparator}
										displayType="text"
										value={envValue}
									/> €
								</Grid>
								<Grid item>
									<Box>
										{totalDiscountValue > 0 && (
											<Typography variant={'caption'} component={'div'}
														sx={{display: 'flex', alignItems: 'center'}}>
												<Stars color={'error'}
													   sx={{width: '0.75rem', height: '0.75rem', mr: 0.1}}/>
												<strong>{t('Discount (per piece)')}</strong>
												<span style={{paddingLeft: '4px'}}>{` - `}</span>
												<NumericFormat
													thousandSeparator={thousandSeparator}
													decimalSeparator={decimalSeparator}
													displayType="text"
													value={totalDiscountValue}
												/> €
											</Typography>
										)}
										{totalDiscountPercentage > 0 && (
											<Typography variant={'caption'} component={'div'}
														sx={{display: 'flex', alignItems: 'center'}}>
												<FiberManualRecord color={'secondary'}
																   sx={{width: '0.75rem', height: '0.75rem', mr: 0.1}}/>
												<strong>{t('Discount (per piece)')}</strong>
												<span style={{paddingLeft: '4px'}}>{` - `}</span>
												<NumericFormat
													thousandSeparator={thousandSeparator}
													decimalSeparator={decimalSeparator}
													displayType="text"
													value={totalDiscountPercentage}
												/> %
											</Typography>
										)}
									</Box>
								</Grid>
							</Grid>
						</Box>
					</Box>
				</Grid>
			</Grid>
			<AvailabilityPopOver
				availability={availability}
				handleAvailability={handleAvailability}
			/>
			<PricesPopOver
				open={pricesPopOver}
				handlePrices={handlePrices}
			/>
		</>
	);
};

export default Product