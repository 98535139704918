import _ from "lodash"
import { API } from "../../helpers/constants";
import { applyDiscounts, applyPercentage, getPriceOfConsecutiveDiscounts, roundToDecimal } from "../../helpers/helpers";
import type { PendingOrderItem, Product, UnmappedPendingOrderItem, UnmappedProduct, } from "./types";

export const mapProduct: Product = (datum: UnmappedProduct) => {
	if (!datum) return
	const images = (datum.images && datum.images.length > 0) ? {
		images: datum.images.map(i => ({
			filePath: i.ImageFile ? API + '/' + i.ImageFile : null,
			isMain: i.isMainImage === "1"
		}))
	} : {}
	
	const warehouses = datum.warehouses && datum.warehouses.length > 0 ? {
		warehouses: datum.warehouses.map(i => ({
			id: Number(i.id),
			realStock: i.realStock ? roundToDecimal(Number(i.realStock), 0) : 0,
			displayStock: i.displayStock ? roundToDecimal(Number(i.displayStock), 0) : 0,
			quantity: i.Qty ? Number(i.Qty) : 0,
			expectedDate: i?.ExpectedDt ? i?.ExpectedDt : null
		}))
	} : {}

	const totalStock = datum.warehouses.reduce((acc, warehouse) => (
		acc + Number(warehouse.realStock)
	), 0)
	
	const productDiscounts = datum.discounts && datum.discounts.length > 0 ?
		_.orderBy(datum.discounts.map(discount => ({
			id: Number(discount?.id),
			percentage: Number(discount.DiscountPCT),
			value: Number(discount.DiscountValue),
			priority: Number(discount.Priority),
		})), 'priority', 'asc')
		: []
	
	const envCharge = datum.charges && datum.charges.length > 0 ? {
		id: datum.charges[0]?.id,
		value: Number(datum.charges[0]?.envCharge) || 0
	} : {}
	
	const taxPercentage = Number(datum.ItemVat)
	const priceWithoutTax = Number(datum.ItemValue)
	const priceWithTax = Number(applyPercentage(priceWithoutTax, taxPercentage))
	
	const discounts = getPriceOfConsecutiveDiscounts(productDiscounts, priceWithoutTax)
	
	const customerDiscount = discounts.filter(f => f.priority === 1)
	const extraDiscounts = discounts.filter(f => f.priority !== 1)
	
	const priceAfterDiscounts = applyDiscounts(discounts, priceWithoutTax)
	const priceAfterDiscountsWithTax = applyPercentage(priceAfterDiscounts, taxPercentage)
	
	const paymentDiscount = datum.PaymentTypeDiscount ? Number(datum.PaymentTypeDiscount) : 0
	const secondPaymentDiscount = datum.SecondPaymentDiscount ? Number(datum.SecondPaymentDiscount) : 0
	const priceWithPaymentDiscount = applyPercentage(priceAfterDiscounts, paymentDiscount, true)
	const priceWithSecondPaymentDiscount = applyPercentage(priceAfterDiscounts, secondPaymentDiscount, true)
	const priceWithPaymentDiscountWithTax = applyPercentage(priceWithPaymentDiscount, taxPercentage)
	const priceWithSecondPaymentDiscountWithTax = applyPercentage(priceWithSecondPaymentDiscount, taxPercentage)
	
	const priceAfterDiscountsWithEnvTax = envCharge?.value > 0 ? (priceAfterDiscounts + envCharge.value) : priceAfterDiscounts
	const priceAfterDiscountsWithEnvTaxAndItemTax = envCharge?.value > 0 ? parseFloat((priceAfterDiscountsWithTax + applyPercentage(envCharge.value, taxPercentage)).toFixed(2)) : priceAfterDiscountsWithTax
	const priceWithPaymentDiscountWithEnvTax = envCharge?.value > 0 ? parseFloat((priceWithPaymentDiscount + envCharge.value).toFixed(2)) : priceWithPaymentDiscount
	const priceWithPaymentDiscountWithEnvTaxAndItemTax = envCharge?.value > 0 ? parseFloat((priceWithPaymentDiscountWithTax + applyPercentage(envCharge.value, taxPercentage)).toFixed(2)) : priceWithPaymentDiscountWithTax
	const priceWithSecondPaymentDiscountWithEnvTax = envCharge?.value > 0 ? parseFloat((priceWithSecondPaymentDiscount + envCharge.value).toFixed(2)) : priceWithSecondPaymentDiscount
	const priceWithSecondPaymentDiscountWithEnvTaxAndItemTax = envCharge?.value > 0 ? parseFloat((priceWithSecondPaymentDiscountWithTax + applyPercentage(envCharge.value, taxPercentage)).toFixed(2)) : priceWithSecondPaymentDiscountWithTax

	const allDiscountPercentage = extraDiscounts.map(discount => discount.percentage).filter(f => f !== 0)
	const allDiscountValues = extraDiscounts.map(discount => discount.value).filter(f => f !== 0)
	
	const totalDiscountPercentage = allDiscountPercentage.length === 0 ? 0 : allDiscountPercentage.length === 1 ? allDiscountPercentage[0] : allDiscountPercentage.reduce((acc, discount) => (acc * (1 - discount / 100)), 1)
	const totalDiscountValue = allDiscountValues.reduce((acc, discount) => (acc + discount), 0)

	return {
		alternativeCode: datum.AlternativeCode,
		displayAttribute: {
			en: datum.DisplayAttributeEn,
			el: datum.DisplayAttributeEl,
		},
		eanCode: datum.EanCode,
		description: {
			en: datum.ItemDescriptionEn,
			el: datum.ItemDescriptionEl,
		},
		id: datum.ItemId,
		taxPercentage: Number(taxPercentage),
		priceWithoutTax: roundToDecimal(priceWithoutTax, 2),
		priceWithTax: roundToDecimal(priceWithTax, 2),
		priceAfterDiscounts: roundToDecimal(priceAfterDiscounts, 2),
		priceAfterDiscountsWithTax: roundToDecimal(priceAfterDiscountsWithTax, 2),
		priceWithPaymentDiscount: roundToDecimal(priceWithPaymentDiscount, 2),
		priceWithPaymentDiscountWithTax: roundToDecimal(priceWithPaymentDiscountWithTax, 2),
		priceWithSecondPaymentDiscount: roundToDecimal(priceWithSecondPaymentDiscount, 2),
		priceWithSecondPaymentDiscountWithTax: roundToDecimal(priceWithSecondPaymentDiscountWithTax, 2),
		priceAfterDiscountsWithEnvTax: roundToDecimal(priceAfterDiscountsWithEnvTax, 2),
		priceAfterDiscountsWithEnvTaxAndItemTax: roundToDecimal(priceAfterDiscountsWithEnvTaxAndItemTax, 2),
		priceWithPaymentDiscountWithEnvTax: roundToDecimal(priceWithPaymentDiscountWithEnvTax, 2),
		priceWithPaymentDiscountWithEnvTaxAndItemTax: roundToDecimal(priceWithPaymentDiscountWithEnvTaxAndItemTax, 2),
		priceWithSecondPaymentDiscountWithEnvTax: roundToDecimal(priceWithSecondPaymentDiscountWithEnvTax, 2),
		priceWithSecondPaymentDiscountWithEnvTaxAndItemTax: roundToDecimal(priceWithSecondPaymentDiscountWithEnvTaxAndItemTax, 2),
		totalDiscountPercentage: totalDiscountPercentage !== 0 ? Number.isInteger(totalDiscountPercentage) ? totalDiscountPercentage : roundToDecimal(totalDiscountPercentage * 100, 2) : 0,
		totalDiscountValue: totalDiscountValue !== 0 ? roundToDecimal(totalDiscountValue, 2) : 0,
		paymentDiscount: paymentDiscount,
		secondPaymentDiscount: secondPaymentDiscount,
		oemConstructor: datum.OemConstructor,
		oemVehicleType: datum.OemVehicleType,
		...images,
		externalLabelLink: datum.itemLabelLink,
		manufacturerImage: datum.manufacturerImage ? API + '/' + datum.manufacturerImage : null,
		...warehouses,
		discounts: discounts,
		envCharge: envCharge,
		totalStock: totalStock,
		totalValueWithQty: datum?.TotalValueWithQty ? Number(datum.TotalValueWithQty) : 0,
		totalValuePerUnit: datum?.TotalValuePerUnit ? Number(datum.TotalValuePerUnit) : 0,
	}
}

export const mapPendingOrderItem: PendingOrderItem = (datum: UnmappedPendingOrderItem) => {
	if (!datum) return
	return {
		id: datum.ItemId,
		numberOfPending: Number(datum.Pending),
		description: {
			el: datum.ItemDescriptionEl,
			en: datum.ItemDescriptionEn,
		}
	}
}