// @flow
import React from 'react';
import {Popover, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import ContentLoader from "../../../general/contentLoader/contentLoader";
import {useTranslation} from "react-i18next";
import {PRODUCT_DETAIL} from "../../../../locales/components/namespaces";

type Props = {};

const AvailabilityPopOver = (props: Props) => {
	const {availability, handleAvailability} = props
	const {t} = useTranslation(PRODUCT_DETAIL)
	const handlePopoverClose = () => {
		handleAvailability({
			anchorEl: null,
			availability: null,
			warehouse: null
		})
	}
	return (
		<Popover
			sx={{
				pointerEvents: 'none',
			}}
			open={Boolean(availability.anchorEl)}
			anchorEl={availability.anchorEl}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'left',
			}}
			onClose={handlePopoverClose}
			disableRestoreFocus
			slotProps={{
				paper: {
					sx: {
						backgroundColor: '#f6ecf8'
					}
				}
			}}
		>
			{availability.warehouse && availability.availability ? (
				<Box p={0.4} maxWidth={300}>
					<Typography variant={'h5'}
								color={availability.warehouse?.realStock > 0 ? 'green' : 'error'}>{availability.warehouse?.realStock > 0 ? t('AVAILABLE') : t('NOT AVAILABLE')}</Typography>
					<Typography variant={'body2'} component={'div'}>
						{`${availability.warehouse?.quantity} ${t('pieces requested')}`}
						<br/>
						{`${availability.availability?.tiresAvailable} ${t('pieces confirmed')}`}
						<br/>
						{`${availability.availability?.tiresInWaiting} ${t('in backorder')}`}
					</Typography>
				</Box>
			) : (
				<ContentLoader loading={true}/>
			)}
		</Popover>
	);
};

export default AvailabilityPopOver