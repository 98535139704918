import type {UnmappedWarranty,Warranty} from "./types";

export const mapWarranty: Warranty = (datum:UnmappedWarranty) => {
    if(!datum) return
    const data = datum.dataTable ? datum.dataTable : []
    const data2 = datum.data2Table ? datum.data2Table : []
    return{
        data: data,
        data2:data2,
        id: datum.id ? datum.id :'',
        ActivationDate:datum.ActivationDate ? datum.ActivationDate :'',
        EndDate:datum.EndDate ? datum.EndDate :'',
        Name:datum.Name ? datum.Name :'',
        Mobile:datum.Mobile ? datum.Mobile :'',
        Email:datum.Email ? datum.Email :'',
        Address:datum.Address ? datum.Address :'',
        City:datum.City ? datum.City :'',
        Zipcode:datum.Zipcode ? datum.Zipcode:'',
        LicensePlate:datum.LicensePlate ? datum.LicensePlate :"",
        Km:datum.Km ? datum.Km:'',
        CarBrand:datum.CarBrand ? datum.CarBrand:'',
        CarModel:datum.CarModel ? datum.CarModel :'',
    }
}