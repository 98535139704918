import React, {useContext} from "react";
import {InvoiceContext} from "./invoice";

const InvoiceInfo = () => {
    const {data} = useContext(InvoiceContext)
    return (
        <div className="form-box form-box_12 custom-box">
            <table>
                <tbody>
                <tr>
                    {data && data.invoiceInfo.map((item, index) => (
                        <th key={index}>{item.title}</th>
                    ))}
                </tr>
                </tbody>
                <tbody>
                <tr>
                    {data && data.invoiceInfo.map((item, index) => (
                        <td key={index}>{item.value}</td>
                    ))}
                </tr>
                </tbody>
            </table>
        </div>
    )
}

export default InvoiceInfo;