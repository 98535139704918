// @flow
import React from 'react';
import {Divider, Grid, Popover, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {NumericFormat} from "react-number-format";
import useUserData from "../../../../hooks/user/useUserData";
import {useTranslation} from "react-i18next";
import {PRODUCT_DETAIL} from "../../../../locales/components/namespaces";

type Props = {};

const PricesPopOver = (props: Props) => {
	const {open, handlePrices} = props
	const {thousandSeparator, decimalSeparator} = useUserData()
	const {t} = useTranslation(PRODUCT_DETAIL)
	const handlePopoverClose = () => {
		handlePrices({
			anchorEl: null,
			prices: null
		})
	}
	return (
		<Popover
			sx={{
				pointerEvents: 'none',
			}}
			open={Boolean(open.anchorEl)}
			anchorEl={open.anchorEl}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'left',
			}}
			onClose={handlePopoverClose}
			disableRestoreFocus
			slotProps={{
				paper: {
					sx: {
						backgroundColor: '#f6ecf8'
					}
				}
			}}
		>
			<Box p={0.4} maxWidth={300}>
				<Typography variant={'h5'} component={'div'}>{t('Values')}</Typography>
				<Grid container>
					<Grid item xs={8}>
						<Typography component={'div'} variant={'body2'}>
							{t('Wholesale Value')}
						</Typography>
					</Grid>
					<Grid item xs={4}>
						<Typography component={'div'} variant={'subtitle2'}>
							{open.prices?.priceWithoutTax ? (
								<NumericFormat
									thousandSeparator={thousandSeparator}
									decimalSeparator={decimalSeparator}
									displayType="text"
									value={open.prices?.priceWithoutTax}
								/>
							) : ' - '} €
						</Typography>
					</Grid>
					<Grid item xs={8}>
						<Typography component={'div'} variant={'body2'}>
							{t('Invoice Value')}
						</Typography>
					</Grid>
					<Grid item xs={4}>
						<Typography component={'div'} variant={'subtitle2'}>
							{open.prices?.priceAfterDiscounts ? (
								<NumericFormat
									thousandSeparator={thousandSeparator}
									decimalSeparator={decimalSeparator}
									displayType="text"
									value={open.prices?.priceAfterDiscounts}
								/>
							) : ' - '} €
						</Typography>
					</Grid>
					<Grid item xs={8}>
						<Typography component={'div'} variant={'body2'}>
							{t('Final Cost (with payment discount)')}
						</Typography>
					</Grid>
					<Grid item xs={4}>
						<Typography component={'div'} variant={'subtitle2'}>
							{open.prices?.priceWithPaymentDiscount ? (
								<NumericFormat
									thousandSeparator={thousandSeparator}
									decimalSeparator={decimalSeparator}
									displayType="text"
									value={open.prices?.priceWithPaymentDiscount}
								/>
							) : ' - '} €
						</Typography>
					</Grid>
				</Grid>
				<Divider/>
				<Typography variant={'h5'} component={'div'}>{t('Values (With Tax)')}</Typography>
				<Grid container>
					<Grid item xs={8}>
						<Typography component={'div'} variant={'body2'}>
							{t('Wholesale Value')}
						</Typography>
					</Grid>
					<Grid item xs={4}>
						<Typography component={'div'} variant={'subtitle2'}>
							{open.prices?.priceWithTax ? (
								<NumericFormat
									thousandSeparator={thousandSeparator}
									decimalSeparator={decimalSeparator}
									displayType="text"
									value={open.prices?.priceWithTax}
								/>
							) : ' - '} €
						</Typography>
					</Grid>
					<Grid item xs={8}>
						<Typography component={'div'} variant={'body2'}>
							{t('Invoice Value')}
						</Typography>
					</Grid>
					<Grid item xs={4}>
						<Typography component={'div'} variant={'subtitle2'}>
							{open.prices?.priceAfterDiscountsWithTax ? (
								<NumericFormat
									thousandSeparator={thousandSeparator}
									decimalSeparator={decimalSeparator}
									displayType="text"
									value={open.prices?.priceAfterDiscountsWithTax}
								/>
							) : ' - '} €
						</Typography>
					</Grid>
					<Grid item xs={8}>
						<Typography component={'div'} variant={'body2'}>
							{t('Final Cost (with payment discount)')}
						</Typography>
					</Grid>
					<Grid item xs={4}>
						<Typography component={'div'} variant={'subtitle2'}>
							{open.prices?.priceWithPaymentDiscountWithTax ? (
								<NumericFormat
									thousandSeparator={thousandSeparator}
									decimalSeparator={decimalSeparator}
									displayType="text"
									value={open.prices?.priceWithPaymentDiscountWithTax}
								/>
							) : ' - '} €
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Divider/>
					</Grid>
					<Grid item xs={8}>
						<Typography component={'div'} variant={'body2'} color={'error'}>
							{t('Env. Tax')}
						</Typography>
					</Grid>
					<Grid item xs={4} color={'error'}>
						<Typography component={'div'} variant={'subtitle2'} color={'error'}>
							{open.prices?.envCharge ? (
								<NumericFormat
									thousandSeparator={thousandSeparator}
									decimalSeparator={decimalSeparator}
									displayType="text"
									value={open.prices?.envCharge}
								/>
							) : ' - '} €
						</Typography>
					</Grid>
				</Grid>
			</Box>
		</Popover>
	);
};

export default PricesPopOver