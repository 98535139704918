import {useTranslation} from "react-i18next";
import {PAGE_LOGIN} from "../../locales/pages/namespaces";
import useUserAction from "../user/useUserAction";
import * as yup from "yup";
import {useFormik} from "formik";
import useUserData from "../user/useUserData";
import dayjs from "dayjs";
import {enqueueSnackbar} from "notistack";
import {useEffect} from "react";

const useForgotPassword = ({handleClose}) => {
	const {t} = useTranslation(PAGE_LOGIN)
	const {passwordReset, resetTime, recoveryPwdTime, successResetPass} = useUserData()
	const {requestResetPassword, resetPassword, setResetPassword} = useUserAction()
	const onSubmit = (values) => {
		if (passwordReset) {
			let timeNow = new Date();
			let recoverUntil = dayjs(recoveryPwdTime).add(resetTime, 'minutes').toDate()
			if (dayjs(timeNow).isBefore(recoverUntil) || dayjs(timeNow).isSame(recoverUntil)) {
				resetPassword({
					...values,
					oneTimePassword: values.sixDigitCode,
					password: values.newPassword
				})
			} else {
				enqueueSnackbar(t('The six digit number has expired! Please request new six digit code and try again!'), {
						variant: 'warning'
					}
				)
				setResetPassword(false)
			}
		} else {
			requestResetPassword(values)
		}
	}
	
	useEffect(() => {
		if (successResetPass) {
			handleClose()
		}
	}, [successResetPass])
	
	const validationSchema = yup.object({
		username: yup
		.string()
		.required(t('required')),
		...(passwordReset && {
			sixDigitCode: yup.string().required(t('required')),
			newPassword: yup.string().required(t('required')).matches(/^.{60}$|(^((?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)|(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[^a-zA-Z\d])|(?=.*?[A-Z])(?=.*?\d)(?=.*?[^a-zA-Z\d])|(?=.*?[a-z])(?=.*?\d)(?=.*?[^a-zA-Z\d])).{7,16}$)/,
				t('Password must be between 7-16 latin characters and contain at least 3 of the following. -Upper case character -Lower case character -Numeric digit -Special character')),
			confirmPassword: yup.string().required(t('required')).oneOf([yup.ref('newPassword'), null], t('Passwords must match')),
		})
	})
	
	const formik = useFormik({
		initialValues: {
			username: '',
			sixDigitCode: '',
			newPassword: '',
			confirmPassword: ''
		},
		validationSchema: validationSchema,
		onSubmit: onSubmit,
	})
	
	return {
		formik
	}
}

export default useForgotPassword