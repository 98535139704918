import {createSelector} from "reselect";
import {useSelector} from "react-redux";
import {parseProduct, sortProducts} from "../../types/product/parse";
import {parseMultiple} from "../../helpers/parse";
import {parseFilter} from "../../types/filters/parse";
import type {Filter} from "../../types/filters/types";
import {useMemo} from "react";
import _ from "lodash";
import {parseUserMessage} from "../../types/user/parse";

const selectMessage = createSelector(
	(state) => state.product.get('message'),
	(item) => parseUserMessage(item)
)

const selectFilters = createSelector(
	(state) => state.product,
	(item) => item.get('filters').toJS()
)

const selectLoading = createSelector(
	(state) => state.product,
	(item) => item.get('loading')
)

const selectSearchResultsIndexTable = createSelector(
	[
		(state) => state.product.get('searchResultsIndexTable'),
		(state) => state.currentUser.get('language'),
	],
	(indexTable, language) => ({
		loading: indexTable.get('loading'),
		count: indexTable.get('count'),
		items: sortProducts(parseMultiple(indexTable.get('items'), (i) => parseProduct(i, language))),
	})
)

const selectSearchResultsFilters = createSelector([
		(state) => state.product.get('searchResultsFilters'),
		(state) => state.currentUser.get('language')
	],
	(filters, language) => parseMultiple(filters, (filter) => parseFilter(filter, language))
)

const useProductData = () => {
	const loading = useSelector(selectLoading)
	const message = useSelector(selectMessage)
	const filterSelections = useSelector(selectFilters)
	const searchResultsIndexTable = useSelector(selectSearchResultsIndexTable)
	const sideFilters: Array<Filter> = useSelector(selectSearchResultsFilters)
	const sideFiltersSorted = useMemo(() => {
		return _.orderBy(sideFilters.map(f => ({
			...f,
			values: _.orderBy(f.values, ['weight', 'description'], ['asc', 'asc'])
		})), ['weight', 'description'], ['asc', 'asc'])
	}, [sideFilters])
	return {
		loading,
		message,
		filterSelections,
		searchResultsIndexTable,
		sideFilters: sideFiltersSorted
	}
}


export default useProductData