export default function Alert(theme) {
	return {
		MuiAlert: {
			styleOverrides: {
				root: {},
				icon: {},
				message: {},
				filled: {},
				border: {},
				action: {}
			}
		}
	};
}
