// @flow
import {FormControl, FormHelperText, TextField, Typography} from "@mui/material";
import './form.css'
import Box from "@mui/material/Box";
import {ErrorMessage, FormikProps, FormikProvider} from "formik";
import React from "react";
import {LoadingButton} from "@mui/lab";
import {useTranslation} from "react-i18next";
import {PAGE_LOGIN} from "../../../../locales/pages/namespaces";
import PasswordInput from "../../../form/passwordInput/passwordInput";

type Props = {
	formik: FormikProps,
	loading: Boolean
};

const Form = (props: Props) => {
	const {formik, loading} = props
	const {t} = useTranslation(PAGE_LOGIN)
	return (
		<FormikProvider value={formik}>
			<Box
				className={'login-form'}
				sx={{
					padding: {
						xs: '6px 14px',
						sm: '12px 28px',
						md: '46px'
					}
				}}
				component="form"
				onSubmit={formik.handleSubmit}
				noValidate
			>
				<Typography
					sx={{
						marginBottom: 2,
						fontWeight: 400
					}}
					color={'secondary'}
					variant={'h4'}
					componend={'div'}
				>
					{t('Connection')}
				</Typography>
				<Box
					className={'login-inputs-container'}
				>
					<FormControl
						sx={{
							'& .MuiFormHelperText-root':{
								'& .Mui-error':{
									color:'#fbc324'
								}
							}
						}}
						variant="standard"
						fullWidth
						error={
							formik.touched['username'] &&
							Boolean(formik.errors['username'])
						}
					>
						<TextField
							autoFocus
							id={'username'}
							value={formik.values.username}
							name={'username'}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							placeholder={t('Enter your username')}
							size={'small'}
							inputProps={{maxLength: 250}}
							variant="outlined"
							required
							sx={{
								'& .MuiOutlinedInput-root': {
									':focus-within': {border: '0.0px solid #ffffff !important'},
									bgcolor: "background.paper"
								},
							}}
						/>
						<FormHelperText style={{color:'#fbc324'}}>
							<ErrorMessage className={'error-message'} name={'username'}/>
						</FormHelperText>
					</FormControl>
					<FormControl
						sx={{
							'& .MuiFormHelperText-root':{
									color:'#fbc324'
							}
						}}
						variant="standard"
						fullWidth
						error={
							formik.touched['password'] &&
							Boolean(formik.errors['password'])
						}
					>
						<PasswordInput
							formik={formik}
							allowBrowserAutocomplete
							name={'password'}
							required
							otherProps={{
								size: 'small',
								variant: 'outlined',
								placeholder: t('Password')
							}}
						/>
						<FormHelperText>
							<ErrorMessage name={'password'}/>
						</FormHelperText>
					</FormControl>
					<FormControl
						sx={{
							'& .MuiFormHelperText-root':{
								color:'#fbc324'
							}
						}}
						variant="standard"
						fullWidth
						error={
							formik.touched['dailyPassword'] &&
							Boolean(formik.errors['dailyPassword'])
						}
					>
						<TextField
							id={'dailyPassword'}
							value={formik.values.dailyPassword}
							name={'dailyPassword'}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							placeholder={t('Daily password')}
							size={'small'}
							inputProps={{maxLength: 250}}
							variant="outlined"
							required
							sx={{
								'& .MuiOutlinedInput-root': {
									':focus-within': {border: '0.0px solid #ffffff !important'},
									bgcolor: "background.paper"
								},
							}}
						/>
						<FormHelperText>
							<ErrorMessage name={'dailyPassword'}/>
						</FormHelperText>
					</FormControl>
					<LoadingButton
						color={'secondary'}
						loading={loading}
						fullWidth
						variant="contained"
						type={'submit'}
						sx={{
							paddingTop: '10px',
							paddingBottom: '10px',
						}}
					>
						{t('Login')}
					</LoadingButton>
				</Box>
			</Box>
		</FormikProvider>
	);
};

export default Form

