export default function IconButton(theme) {
	return {
		MuiIconButton: {
			styleOverrides: {
				root: {
					borderRadius: 4,
					'&:hover': {
						backgroundColor: 'primary',
						color: '#FFFFFF'
					},
				},
				sizeLarge: {},
				sizeMedium: {},
				sizeSmall: {}
			}
		}
	};
}
