export default function LinearProgress(theme) {
	return {
		MuiLinearProgress: {
			styleOverrides: {
				root: {},
				bar: {}
			}
		}
	};
}
