import {useCallback, useMemo, useState} from "react";
import useUserData from "../../../hooks/user/useUserData";

export const useAuthenticatedFile = () => {
	const [localLink, setLocalLink] = useState(null)
	const [loading, setLoading] = useState(false)
	const {token} = useUserData()
	const headers = useMemo(() => ({'Authorization': `Bearer ${token}`}), [token])
	
	const fetchFile = useCallback((url, onSuccess) => {
		setLoading(true)
		fetch(url, {
			headers: headers
		})
		.then(r => r.blob())
		.then(blob => window.URL.createObjectURL(blob))
		.then(href => {
			setLocalLink(href)
			setLoading(false)
			if (onSuccess) {
				onSuccess(href)
			}
		})
		.catch(() => setLoading(false))
	}, [headers])
	
	return {
		loading,
		localLink,
		fetchFile
	}
}
