// @flow
import React from 'react';
import MainCard from "../mainCard/mainCard";

type Props = {};

const Kpi = (props: Props) => {
	const {
		customStyle
	} = props
	
	return (
		<MainCard
			elevation={0}
			sx={{
				position: 'relative',
				border: 'none',
				boxShadow: '0px 1px 4px 0px #214965',
				height: '100%',
				...customStyle
			}}>
			{props.children}
		</MainCard>
	);
};

export default Kpi