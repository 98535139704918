//@flow
import * as React from 'react'
import {Fragment, useCallback, useEffect} from 'react'

type Props = {
	children: React.Node,
	dataManager: Function,
	setData: Function,
	setPageCount: Function,
	setLoading: Function,
	data: {
		loading: boolean,
		items: Array<Object>,
		count: number,
	},
	pageSize: number
}

const ReduxTable = (props: Props) => {
	const {pageSize, data, children, dataManager, setData, setPageCount, setLoading} = props
	
	// const fetchData = useCallback(({ pageSize, pageIndex, sortBy }) => {
	//   dataManager(pageIndex, pageSize, sortBy)
	// }, [dataManager])
	//
	const fetchData = useCallback(({pageSize, pageIndex}) => {
		dataManager(pageIndex, pageSize)
	}, [dataManager])
	
	useEffect(() => {
		setLoading(data.loading)
	}, [data.loading, setLoading])
	
	useEffect(() => {
		if (Array.isArray(data.items)) {
			setData(data.items)
			setPageCount(Math.ceil(parseInt(data.count) / pageSize))
		}
	}, [data.items, data.count, setData, setPageCount, pageSize])
	
	return <Fragment>{children(fetchData)}</Fragment>
}

export default ReduxTable