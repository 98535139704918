//@flow
import {useCallback} from 'react'
import {useDispatch} from 'react-redux'
import { toggleOrderConfirmation } from "../../actions/actions";
import * as actions from '../../actions/actions'
import {calculateOrderSums, convertActiveOrderProducts, convertRequestProducts} from "../../helpers/helpers";
import useUserData from "../user/useUserData";
import {useOrderData} from "./useOrderData";
import _ from "lodash";

const useOrderAction = () => {
	const dispatch = useDispatch()
	const {selectedStore, data} = useUserData()
	const {activeOrder} = useOrderData()
	
	const setRedirectToOrder = useCallback((redirectToOrder) => {
		dispatch(actions.setRedirectToOrder(redirectToOrder))
	}, [dispatch])
	
	const setOrderMessage = useCallback((message) => {
		dispatch(actions.setOrderMessage(message))
	}, [dispatch])
	
	const createOrder = useCallback(({quantity, product}) => {
		const requestProducts = convertRequestProducts(product, quantity)
		const {
			sumWithTax,
			sumWithoutTax,
			sumDiscount,
			sumTax
		} = calculateOrderSums(requestProducts)
		dispatch(actions.createOrder({
			AddressId: selectedStore.id,
			CustomerId: data?.customerId,
			totalPriceWithoutTax: sumWithoutTax,
			totalOrderDiscount: sumDiscount,
			totalOrderTax: sumTax,
			totalPriceWithTax: sumWithTax,
			products: requestProducts
		}))
	}, [data?.customerId, dispatch, selectedStore.id])
	
	const updateOrder = useCallback(({quantity, product, orderId}) => {
		const requestProducts = convertRequestProducts(product, quantity)
		const activeOrderProducts = convertActiveOrderProducts((activeOrder.length > 0 && activeOrder[0].products?.length > 0) ? activeOrder[0].products : [])
		const allProducts = _.unionWith(requestProducts, activeOrderProducts, (a, b) => a.ItemId === b.ItemId && a.WarhId === b.WarhId);
		const {
			sumWithTax,
			sumWithoutTax,
			sumDiscount,
			sumTax
		} = calculateOrderSums(allProducts)
		dispatch(actions.updateOrder({
			AddressId: selectedStore.id,
			CustomerId: data?.customerId,
			totalPriceWithoutTax: sumWithoutTax,
			totalOrderDiscount: sumDiscount,
			totalOrderTax: sumTax,
			totalPriceWithTax: sumWithTax,
			products: requestProducts,
			id: orderId
		}, data))
	}, [activeOrder, data, dispatch, selectedStore.id])
	
	const confirmOrder = useCallback(({orderId, notes}) => {
		dispatch(actions.completeOrder({
			AddressId: selectedStore.id,
			CustomerId: data?.customerId,
			id: orderId,
			...(notes && {Comments: notes})
		}, data))
	}, [data, dispatch, selectedStore.id])
	
	const deleteOrder = useCallback((id) => {
		dispatch(actions.deleteOrder({
			AddressId: selectedStore.id,
			id: id,
		}))
	}, [dispatch, selectedStore.id])
	
	const removeProductFromOrder = useCallback((id, itemId) => {
		dispatch(actions.removeProductFromOrder({
			AddressId: selectedStore.id,
			id: id,
			ItemId: itemId
		}))
	}, [dispatch, selectedStore.id])
	
	const getActiveOrder = useCallback(() => {
		dispatch(actions.getActiveOrder({
			AddressId: selectedStore.id,
		}))
	}, [dispatch, selectedStore.id])
	
	const getOrderDetails = useCallback((id) => {
		dispatch(actions.getOrderDetails({
			AddressId: selectedStore.id,
			id
		}))
	}, [dispatch, selectedStore.id])
	
	const checkForPendingOrderItems = useCallback((id) => {
		dispatch(actions.checkForPendingOrderItems({
			id: id
		}))
	}, [dispatch])
	
	const toggleOrderConfirmation = useCallback((value) => {
		dispatch(actions.toggleOrderConfirmation({value}))
	}, [dispatch])
	
	return {
		getOrderDetails,
		getActiveOrder,
		createOrder,
		updateOrder,
		deleteOrder,
		removeProductFromOrder,
		setOrderMessage,
		confirmOrder,
		setRedirectToOrder,
		checkForPendingOrderItems,
		toggleOrderConfirmation
	}
}

export default useOrderAction