import * as ActionTypes from '../../actions/types'
import {fromJS} from "immutable";
import {mapMultiple} from "../../helpers/map";
import {mapAppParams, mapStore, mapUser} from "../../types/user/map";
import {mapDBDateFormats, mapDBTimeFormats} from "../../helpers/date";
import {mapWarehouse} from "../../types/warehouse/map";
import {mapFilter} from "../../types/filters/map";
import {getRouteUrl} from "../../helpers/getRouteUrl";
import {ROUTE_PAGE_LOGIN} from "../../routers/routes";
import dayjs from "dayjs";

const INITIAL_STATE = {
	token: null,
	language: 'el',
	decimalSeparator: ',',
	thousandSeparator: '.',
	dateFormat: 'DD/MM/YYYY',
	timeFormat: 'HH:mm',
	loading: false,
	isLoggedIn: false,
	message: null,
	tokenExpirationDateTime: null,
	passwordReset: false,
	recoveryPwdTime: null,
	resetTime: null,
	data: {},
	loadingStores: false,
	storesById: {},
	selectedStore: null,
	changeFormatsWithLang: true,
	warehouses: {},
	topFilters: {},
	successDailyCode: false,
	successResetPass: false,
	appParameters: {
		maxAllowedProductsInCart: 30,
		displayStock: 40,
		maxAllowedReturnItems: 8,
		dayLimitForReturns: 15,
		monthLimitForWarranties: 3
	},
	requiredDailyCodeTime: null
}

function setUserLanguage(state, action) {
	return state
	.set('language', action.payload)
	.set('decimalSeparator', state.get('changeFormatsWithLang') ? action.payload === 'el' ? ',' : '.' : state.get('decimalSeparator'))
	.set('thousandSeparator', state.get('changeFormatsWithLang') ? action.payload === 'el' ? '.' : ',' : state.get('thousandSeparator'))
	.set('dateFormat', state.get('changeFormatsWithLang') ? action.payload === 'el' ? 'DD/MM/YYYY' : 'MM/DD/YYYY' : state.get('dateFormat'))
	.set('timeFormat', state.get('changeFormatsWithLang') ? action.payload === 'el' ? 'HH:mm' : 'HH:mm' : state.get('timeFormat'))
}

function setUserMessage(state, action) {
	const message = action.payload
	return state
	.set('message', fromJS(message ? {
		text: message?.text, variant: message?.variant
	} : null))
}

function setResetPassword(state, action) {
	const passwordReset = action.payload
	return state
	.set('passwordReset', passwordReset)
}

function setUserStore(state, action) {
	return state
	.set('selectedStore', action.payload);
}

function checkAPIConnectivity(state) {
	return state
	.set('loading', true)
	.set('message', null)
	.set('passwordReset', false)
}

function checkAPIConnectivitySucceeded(state) {
	return state
	.set('loading', false)
	.set('message', null)
}

function checkAPIConnectivityFailed(state) {
	return state
	.set('loading', false)
	.set('message', fromJS({
		text: 'Connection to API failed!', variant: 'error'
	}))
}

function userLogoutSucceeded(state, action) {
	//TODO::Uncomment
	window.location.href = getRouteUrl(ROUTE_PAGE_LOGIN)
	return state
	.set('message', null)
	.set('token', null)
	.set('loading', false)
	.set('isLoggedIn', false)
}

function userLogin(state) {
	return state
	.set('message', null)
	.set('token', null)
	.set('loading', true)
	.set('isLoggedIn', false)
	.set('selectedStore', null)
	.set('passwordReset', false)
}

function userLoginSucceeded(state, action) {
	const {
		PortalLangBehavior,
		access_token,
		dateFormat,
		decimalChar,
		expires_in,
		langcode,
		thousandsChar,
		timeFormat
	} = action.payload
	const tokenExpirationDateTime = new Date()
	tokenExpirationDateTime.setSeconds(tokenExpirationDateTime.getSeconds() + expires_in)
	return state
	.set('storesById', fromJS({}))
	.set('message', null)
	.set('loading', false)
	.set('token', access_token)
	.set('tokenExpirationDateTime', tokenExpirationDateTime)
	.set('isLoggedIn', true)
	.set('changeFormatsWithLang', PortalLangBehavior === '1')
	.set('language', langcode ? langcode !== 'EL' && langcode !== 'EN' ? 'en' : langcode.toLowerCase() : INITIAL_STATE.language)
	.set('dateFormat', dateFormat ? mapDBDateFormats(dateFormat) : INITIAL_STATE.dateFormat)
	.set('timeFormat', timeFormat ? mapDBTimeFormats(timeFormat) : INITIAL_STATE.timeFormat)
	.set('decimalSeparator', decimalChar ? decimalChar : INITIAL_STATE.decimalSeparator)
	.set('thousandSeparator', thousandsChar ? thousandsChar : INITIAL_STATE.thousandSeparator)
}

function userLoginFailed(state, action) {
	const {message} = action.payload
	return state
	.set('message', fromJS({
		text: message, variant: 'error'
	}))
	.set('token', null)
	.set('loading', false)
	.set('isLoggedIn', false)
}

function requestResetPassword(state) {
	return state
	.set('message', null)
	.set('loading', true)
	.set('passwordReset', false)
}

function requestResetPasswordSucceeded(state, action) {
	const {recoveryPwdTime, resetTime} = action.payload
	return state
	.set('message', fromJS({
		text: 'request_new_pass_success', variant: 'success'
	}))
	.set('loading', false)
	.set('recoveryPwdTime', recoveryPwdTime)
	.set('resetTime', resetTime)
	.set('passwordReset', true)
}

function requestResetPasswordFailed(state, action) {
	const {message} = action.payload
	return state
	.set('message', fromJS({
		text: message, variant: 'error'
	}))
	.set('token', null)
	.set('loading', false)
	.set('isLoggedIn', false)
	.set('passwordReset', false)
}

function resetPassword(state) {
	return state
	.set('message', null)
	.set('loading', true)
	.set('successResetPass', false)
}

function resetPasswordSucceeded(state, action) {
	return state
	.set('message', fromJS({
		text: 'reset_success', variant: 'success'
	}))
	.set('loading', false)
	.set('recoveryPwdTime', null)
	.set('resetTime', null)
	.set('passwordReset', false)
	.set('recoveryPwdTime', null)
	.set('resetTime', null)
	.set('successResetPass', true)
}

function resetPasswordFailed(state, action) {
	const {message} = action.payload
	return state
	.set('message', fromJS({
		text: message, variant: 'error'
	}))
	.set('token', null)
	.set('loading', false)
	.set('isLoggedIn', false)
	.set('successResetPass', false)
}

function getUserStores(state) {
	return state
	.set('message', null)
	.set('loadingStores', true)
}

function getUserStoresSucceeded(state, action) {
	const {addresses} = action.payload;
	const mappedData = mapMultiple(addresses, mapStore)
	return state
	.set('message', null)
	.set('loadingStores', false)
	.set('storesById', state.get('storesById').merge(fromJS(mappedData)))
}

function getUserStoresFailed(state, action) {
	const {message} = action.payload
	return state
	.set('message', fromJS({
		text: message, variant: 'error'
	}))
	.set('loadingStores', false);
}

function getUserDetails(state) {
	return state
	.set('loading', true)
	.set('message', null)
}

function getUserDetailsSucceeded(state, action) {
	const {user, warehouses, topAttributes, parameters} = action.payload;
	const mappedUser = mapUser(user[0])
	const mappedWarehouses = mapMultiple(warehouses, mapWarehouse)
	const mappedFilters = mapMultiple(topAttributes, mapFilter)
	const mappedAppParameters = mapAppParams(parameters)
	return state
	.set('loading', false)
	.set('data', fromJS(mappedUser))
	.set('warehouses', fromJS(mappedWarehouses))
	.set('appParameters', fromJS(mappedAppParameters))
	.set('topFilters', fromJS(mappedFilters))
	.set('message', null)
}

function getUserDetailsFailed(state, action) {
	const {message} = action.payload
	return state
	.set('loading', false)
	.set('message', fromJS({
		text: message, variant: 'error'
	}))
}

function getDailyCode(state) {
	return state
	.set('loading', true)
	.set('successDailyCode', false)
}

function getDailyCodeSucceeded(state, action) {
	return state
	.set('message', fromJS({
		text: 'daily_code_success', variant: 'success'
	}))
	.set('loading', false)
	.set('successDailyCode', true)
}

function getDailyCodeFailed(state, action) {
	const {message} = action.payload
	return state
	.set('loading', false)
	.set('message', fromJS({
		text: message, variant: 'error'
	}))
	.set('successDailyCode', false)
}

function requireDailyCode(state) {
	return state
	.set('loading', true)
	.set('message', null)
	.set('message', null)
}

function requireDailyCodeSucceeded(state, action) {
	return state
	.set('loading', false)
	.set('requiredDailyCodeTime', dayjs().format('YYYY-MM-DD HH:mm:ss'))
	.set('message', null)
}

function requireDailyCodeFailed(state, action) {
	const {message} = action.payload
	return state
	.set('loading', false)
	.set('message', fromJS({
		text: message, variant: 'error'
	}))
}

const currentUser = {
	initialState: INITIAL_STATE,
	handlers: {
		[ActionTypes.SET_USER_LANGUAGE]: setUserLanguage,
		[ActionTypes.SET_USER_MESSAGE]: setUserMessage,
		[ActionTypes.SET_RESET_PASSWORD]: setResetPassword,
		[ActionTypes.SET_USER_STORE]: setUserStore,
		[ActionTypes.CHECK_API_CONNECTIVITY]: checkAPIConnectivity,
		[ActionTypes.CHECK_API_CONNECTIVITY_SUCCEEDED]: checkAPIConnectivitySucceeded,
		[ActionTypes.CHECK_API_CONNECTIVITY_FAILED]: checkAPIConnectivityFailed,
		[ActionTypes.USER_LOGOUT_SUCCEEDED]: userLogoutSucceeded,
		[ActionTypes.USER_LOGIN]: userLogin,
		[ActionTypes.USER_LOGIN_SUCCEEDED]: userLoginSucceeded,
		[ActionTypes.USER_LOGIN_FAILED]: userLoginFailed,
		[ActionTypes.REQUEST_RESET_PASSWORD]: requestResetPassword,
		[ActionTypes.REQUEST_RESET_PASSWORD_SUCCEEDED]: requestResetPasswordSucceeded,
		[ActionTypes.REQUEST_RESET_PASSWORD_FAILED]: requestResetPasswordFailed,
		[ActionTypes.RESET_PASSWORD]: resetPassword,
		[ActionTypes.RESET_PASSWORD_SUCCEEDED]: resetPasswordSucceeded,
		[ActionTypes.RESET_PASSWORD_FAILED]: resetPasswordFailed,
		[ActionTypes.GET_USER_STORES]: getUserStores,
		[ActionTypes.GET_USER_STORES_SUCCEEDED]: getUserStoresSucceeded,
		[ActionTypes.GET_USER_STORES_FAILED]: getUserStoresFailed,
		[ActionTypes.GET_USER_DETAILS]: getUserDetails,
		[ActionTypes.GET_USER_DETAILS_SUCCEEDED]: getUserDetailsSucceeded,
		[ActionTypes.GET_USER_DETAILS_FAILED]: getUserDetailsFailed,
		[ActionTypes.GET_DAILY_CODE]: getDailyCode,
		[ActionTypes.GET_DAILY_CODE_SUCCEEDED]: getDailyCodeSucceeded,
		[ActionTypes.GET_DAILY_CODE_FAILED]: getDailyCodeFailed,
		[ActionTypes.REQUIRE_DAILY_CODE]: requireDailyCode,
		[ActionTypes.REQUIRE_DAILY_CODE_SUCCEEDED]: requireDailyCodeSucceeded,
		[ActionTypes.REQUIRE_DAILY_CODE_FAILED]: requireDailyCodeFailed,
	}
}

export default currentUser