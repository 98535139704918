import history from './store/history'
import {loadModules} from "./store/loadModule";
import {ConnectedRouter} from "connected-react-router";
import './App.css';
import Main from "./pages/main/main";
import {CssBaseline} from "@mui/material";
import SnackbarProviderExtended from "./components/messages/snackbarProviderExtended/snackbarProviderExtended";
import ThemeCustomization from "./theme";
import {PermissionsModule} from "./modules/permission";

export const ENABLED_MODULES = loadModules([PermissionsModule])
export const ROUTES = ENABLED_MODULES.loadRoutes()

function App() {
	return (
		<ThemeCustomization>
			<SnackbarProviderExtended>
				<ConnectedRouter history={history}>
					<CssBaseline/>
					<Main/>
				</ConnectedRouter>
			</SnackbarProviderExtended>
		</ThemeCustomization>
	);
}

export default App;
