import _ from "lodash";
import { parseMultiple } from "../../helpers/parse";
import type { Product } from "./types";

export const sortProducts = _.memoize((items) => _.orderBy(items,
	['totalStock', 'description'],
	['desc', 'desc'])
)

const parseProductImage = (datum) => {
	if (!datum) return
	return {
		filePath: datum.get('filePath'),
		isMain: datum.get('isMain')
	}
}

const parseProductWarehouses = (datum) => {
	if (!datum) return
	return {
		id: datum.get('id'),
		realStock: datum.get('realStock'),
		displayStock: datum.get('displayStock'),
		quantity: datum.get('quantity'),
		expectedDate: datum.get('expectedDate')
	}
}

const parseDiscounts = (datum) => {
	if (!datum) return
	return {
		id: datum.get('id'),
		percentage: datum.get('percentage'),
		value: datum.get('value'),
		priority: datum.get('priority'),
		priceAfterDiscount: datum.get('priceAfterDiscount')
	}
}

export const parseProduct: Product = (datum, language) => {
	if (!datum) return
	const id = datum.get('id')
	return {
		alternativeCode: datum.get('alternativeCode'),
		displayAttribute: datum.getIn(['displayAttribute', language]),
		eanCode: datum.get('eanCode'),
		description: datum.getIn(['description', language]),
		id: id,
		taxPercentage: datum.get('taxPercentage'),
		priceWithoutTax: datum.get('priceWithoutTax'),
		priceWithTax: datum.get('priceWithTax'),
		priceAfterDiscounts: datum.get('priceAfterDiscounts'),
		priceAfterDiscountsWithTax: datum.get('priceAfterDiscountsWithTax'),
		priceWithPaymentDiscount: datum.get('priceWithPaymentDiscount'),
		priceWithPaymentDiscountWithTax: datum.get('priceWithPaymentDiscountWithTax'),
		priceWithSecondPaymentDiscount: datum.get('priceWithSecondPaymentDiscount'),
		priceWithSecondPaymentDiscountWithTax: datum.get('priceWithSecondPaymentDiscountWithTax'),
		priceAfterDiscountsWithEnvTax: datum.get('priceAfterDiscountsWithEnvTax'),
		priceAfterDiscountsWithEnvTaxAndItemTax: datum.get('priceAfterDiscountsWithEnvTaxAndItemTax'),
		priceWithPaymentDiscountWithEnvTax: datum.get('priceWithPaymentDiscountWithEnvTax'),
		priceWithPaymentDiscountWithEnvTaxAndItemTax: datum.get('priceWithPaymentDiscountWithEnvTaxAndItemTax'),
		priceWithSecondPaymentDiscountWithEnvTax: datum.get('priceWithSecondPaymentDiscountWithEnvTax'),
		priceWithSecondPaymentDiscountWithEnvTaxAndItemTax: datum.get('priceWithSecondPaymentDiscountWithEnvTaxAndItemTax'),
		totalDiscountPercentage: datum.get('totalDiscountPercentage'),
		totalDiscountValue: datum.get('totalDiscountValue'),
		paymentDiscount: datum.get('paymentDiscount'),
		secondPaymentDiscount: datum.get('secondPaymentDiscount'),
		oemConstructor: datum.get('oemConstructor'),
		oemVehicleType: datum.get('oemVehicleType'),
		images: parseMultiple(datum.get('images'), parseProductImage),
		externalLabelLink: datum.get('externalLabelLink'),
		manufacturerImage: datum.get('manufacturerImage'),
		warehouses: parseMultiple(datum.get('warehouses'), parseProductWarehouses),
		discounts: parseMultiple(datum.get('discounts'), parseDiscounts),
		envCharge: {
			id: datum.getIn(['envCharge', 'id']),
			value: datum.getIn(['envCharge', 'value']),
		},
		totalStock: datum.get('totalStock'),
		totalValueWithQty: datum.get('totalValueWithQty'),
		totalValuePerUnit: datum.get('totalValuePerUnit'),
	}
}

export const parsePendingOrderItem = (datum, language) => {
	if (!datum) return
	return {
		id: datum.get('id'),
		numberOfPending: datum.get('numberOfPending'),
		description: datum.getIn(['description', language])
	}
}