import React, {createContext} from "react";
import './invoice.css'
import {useTranslation} from "react-i18next";
import {INVOICE} from "../../../locales/pages/namespaces";
import HeaderInvoice from "./header";
import InvoiceInfo from "./invoiceInfo";
import CustomerDetails from "./customerDetails";
import TransactionDetails from "./transactionDetails";
import TableItemsInvoice from "./tableItemsInvoice";
import FooterInvoice from "./footerInvoice";
import Button from "@mui/material/Button";
import html2canvas from "html2canvas";
import JsPDF from "jspdf";

export const InvoiceContext = createContext({})

const Invoice = (props) => {
	const {data, header, items, handleCloseInvoice, closeButton} = props
	const {t} = useTranslation(INVOICE)
	const generatePDF = () => {
		const content = document.getElementById('pdf-content');
		html2canvas(content).then((canvas) => {
			const imgWidth = 210;
			const pageHeight = 295;
			const imgHeight = (canvas.height * imgWidth) / canvas.width;
			let heightLeft = imgHeight;
			let position = 0;
			heightLeft -= pageHeight;
			const doc = new JsPDF('p', 'mm', 'a4');
			doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
			while (heightLeft >= 0) {
				position = heightLeft - imgHeight;
				doc.addPage();
				doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
				heightLeft -= pageHeight;
			}
			doc.save('Invoice.pdf');
		}).catch((error) => {
			console.error("Error converting HTML to canvas:", error);
		})
	};
	return data !== null && items !== null ?
		(
			<InvoiceContext.Provider
				value={{
					data,
					items
				}}
			>
				<div style={{
					background: '#ffffff',
					/* position: 'absolute',
					 top: '50%',
					 left: '50%',
					 transform: 'translate(-50%, -50%)',
					 width: 400,
					 bgcolor: 'background.paper',
					 border: '2px solid #000',
					 boxShadow: 24,
					 p: 4,*/
				}}>
					{closeButton &&
						<Button
							variant={'outlined'}
							color={'error'}
							onClick={handleCloseInvoice}
							sx={{m: 2}}
						>
							{t('Close')}
						</Button>
					}
					<Button
						variant={'outlined'}
						onClick={generatePDF}
					>
						{data.invoiceIndexHeaders.exportPdfButton}
					</Button>
					<div id="pdf-content" style={{minWidth: '850px', maxWidth: '1350px'}}>
						<div className="form_container">
							<div style={{textAlign: 'center'}}>
								<h2>{data.invoiceIndexHeaders.documentTitle}</h2>
							</div>
							<div className="form-flex">
								{header}
								{/*<HeaderInvoice/>*/}
								<InvoiceInfo/>
								<CustomerDetails/>
								<TransactionDetails/>
								<div className="form-box form-box_6" style={{marginTop: '-10px'}}>
									<div className="box_column">
										<div className="box_column_item" style={{gridTemplateColumns: '0.1fr 4fr'}}>
											<span className="box_column_itemTitle">{data.invoiceIndexHeaders.commentsBox}:</span>
											<span
												className="box_column_itemValue">{data.invoiceIndexData.commentsBox}</span>
										</div>
									</div>
								</div>
								{/*<TableItemsInvoice items={items}/>*/}
								{props.children}
								<FooterInvoice/>
							</div>
						</div>
					</div>
				</div>
			</InvoiceContext.Provider>
		) :
		null
}

export default Invoice;