import * as Namespaces from './namespaces'

const locales = {
	[Namespaces.NAVIGATION]: {
		el: {
			'Address': 'Διεύθυνση',
			'Customer Code': 'Κωδικός Πελάτη',
			'Home': 'Αρχική',
			'Orders': 'Παραγγελίες',
			'Warranties': 'Εγγυήσεις',
			'Reports': 'Στατιστικά & Οικονομικά Στοιχεία',
			'Announcements': 'Νέα',
			'Contact': 'Επικοινωνία',
			'Index': 'Κεντρική',
			'New Warranty': 'Νέα εγγύηση',
			'Your cart is empty!': 'Δεν υπάρχουν προϊόντα στο καλάθι σας!',
			'Order created successfully': 'Η παραγγελία σας δημιουργήθηκε επιτυχώς.',
			'Order updated successfully': 'Το καλάθι σας ενημερώθηκε επιτυχώς.',
			'Invoices': 'Παραστατικά',
			'My Profile': 'Το Προφίλ μου',
			'Privacy policy': 'Πολιτική Απορρήτου',
			'Logout': 'Αποσύνδεση',
			'Order submitted successfully!': 'Η παραγγελία σας έχει υποβληθεί επιτυχώς.',
			'Order deleted successfully.': 'Η παραγγελία σας έχει διαγραφεί επιτυχώς.',
			'Product removed from cart.': 'Το προϊόν έχει αφαιρεθεί από το καλάθι επιτυχώς.',
			'Order created successfully!': 'Η παραγγελία δημιουργήθηκε επιτυχώς!',
			'Order updated successfully!': 'Το καλάθι ενημερώθηκε επιτυχώς!',
			'Pending Orders': 'Παραγγελίες Εκκρεμοτήτων',
			'Returns': 'Επιστροφές',
			'Accident':'Δήλωση Ατυχήματος',
			'Guarantees':'Πρόγραμμα Ασφάλισης Ελαστικών',
			'Completed Orders': 'Ολοκληρωμένες Παραγγελίες',
			'Day Orders': 'Παραγγελίες ημέρας',
			'Customer Card': 'Καρτέλα Πελάτη',
			'No Order Found': 'Η παραγγελία αυτή δεν υπάρχει πια ή έχει ήδη ολοκληρωθεί από άλλον χρήστη',
			'order_not_found': 'Η παραγγελία αυτή δεν υπάρχει πια ή έχει ήδη ολοκληρωθεί από άλλον χρήστη',
			'order_not_in_progress': 'Η παραγγελία έχει ήδη υποβληθεί!',
			'order_item_not_found': 'Το προϊόν έχει ήδη αφαιρεθεί από το καλάθι σας'
		},
		en: {
			'order_not_in_progress': 'Order already submitted!',
			'No Order Found': 'Order completed or deleted from an other user',
			'order_not_found': 'Order completed or deleted from an other user',
			'order_item_not_found': 'Product already removed from your cart'
		},
	},
	[Namespaces.FILES]: {
		el: {
			'Description': 'Περιγραφή',
			'Cancel': 'Ακύρωση',
			'Uploading': 'Μεταμόρφωση',
			'No description yet': 'Χωρίς περιγραφή',
			'Download': 'Λήψη',
			'Edit': 'Επεξεργασία',
			'Delete': 'Διαγραφή',
			'Upload File': 'Ανέβασμα Αρχείου',
			'Drag \'n\' drop some files here, or click to select files': 'Σύρετε αρχεία εδώ, ή πατήστε για να επιλέξετε.',
			'Uploaded File(s)': 'Ανεβασμένα Αρχεία'
		},
		en: {},
	},
	[Namespaces.CONFIRMATION]: {
		el: {
			'NO': 'ΟΧΙ',
			'YES': 'ΝΑΙ'
		},
		en: {},
	},
	[Namespaces.TABLE_COMPONENT]: {
		el: {
			Show: "Εμφάνιση",
			Showing: "Εμφάνιση",
			results: 'αποτελέσματα',
			Loading: "Φόρτωση",
			'per page': "ανα σελίδα",
			'Go to page': 'Πήγαινε στην σελίδα',
			'Page': 'Σελίδα',
			'of': 'από',
			'no_results': 'Δεν υπάρχουν αποτελέσματα',
			'All': 'Όλα',
			'search': 'Αναζήτηση σε {{count}} εγγραφές',
			'min': 'Ελάχιστη τιμή {{min}}',
			'max': 'Μέγιστη τιμή {{max}}',
			'to': 'έως',
			'Load More': 'Περισσότερα'
		},
		en: {
			'min': 'Minimum value {{min}}',
			'max': 'Maximum value {{max}}',
			'no_results': 'No Results',
			'search': 'Search {{count}} records',
		}
	},
	[Namespaces.FILTERS]: {
		el: {
			'Search Tires': 'Αναζήτηση Ελαστικών',
			'Search dimension': 'Αναζήτηση διάστασης - τύπου',
			'More filters': 'Περισσότερα Φίλτρα',
			'Manufacturer': 'Κατασκευαστής',
			'Category': 'Κατηγορία',
			'Passenger tires': 'Ελαστικά Επιβατικά',
			'Truck tires': 'Ελαστικά Φορτηγών',
			'Alloy wheels': 'Ζάντες Αλουμινίου',
			'Season': 'Εποχή',
			'Summer': 'Θερινά',
			'Winter': 'Χειμερινά',
			'Technology': 'Τεχνολογία',
			'Search': 'Αναζήτηση',
			'Add search': 'Προσθήκη αναζήτησης',
			'Remove search': 'Αφαίρεση πεδίου αναζήτησης',
			'Search by supplier code': 'Αναζήτηση με κωδικό προμηθευτή',
			'Search supplier code': 'Αναζήτηση κωδικού προμηθευτή',
			'Clear filters': 'Καθαρισμός φίλτρων',
			'PRODUCT BRAND': 'ΜΑΡΚΑ',
			'Select at least one filter submit your search!': 'Επιλέξτε τουλάχιστον ένα φίλτρο για να κάνετε αναζήτηση!',
			'Search must contain at least 3 characters.': 'Η αναζήτηση πρέπει να περιέχει τουλάχιστον 3 χαρακτήρες.',
			'Pending shipment arrival': 'Σε αναμονή παραλαβής',
			'In stock': 'Σε απόθεμα',
		},
		en: {
			'Search dimension': 'Search dimension - type',
		},
	},
	[Namespaces.AXIOS_ERROR_MESSAGE]: {
		el: {
			'More': 'Περισσότερα..',
			'Less': 'Λιγότερα',
			'Unauthorized': 'Ο χρήστης δεν έχει πρόσβαση στην ιστοσελίδα.',
			'Failed to get notifications.': 'Αδυναμία φόρτωσης ειδοποιήσεων.',
			'Duplicate Entry': 'Υπάρχει ήδη αυτή η εγγραφή',
			'Not Found': 'Δεν βρέθηκε',
			errorMessage: 'Μήνυμα',
			'Message': 'Μήνυμα',
			moreMessage: '',
			'Code': 'Κωδικός',
			'Status': 'Status',
		},
		en: {
			'More': 'More..',
		}
	},
	[Namespaces.COPY_TO_CLIPBOARD]: {
		el: {
			'Copy': 'Αντιγραφή',
			'Copied': 'Αντιγράφηκε',
		},
		en: {}
	},
	[Namespaces.PRODUCT_DETAIL]: {
		el: {
			'Product Details': 'Πληροφορίες Προϊόντος',
			'Alternative Code': 'Εναλλακτικός Κωδικός',
			'EPREL - European Product Registry for Energy Labelling': 'EPREL - Ευρωπαϊκό μητρώο προϊόντων για την ενεργειακή σήμανση',
			'OEM Constructor': 'OEM Κατασκευαστής',
			'OEM Vehicle Type': 'OEM Τύπος Οχήματος',
			'Cancel': 'Ακύρωση',
			'Category': 'Κατηγορία',
			'AVAILABLE': 'ΔΙΑΘΕΣΙΜΟ',
			'NOT AVAILABLE': 'ΜΗ ΔΙΑΘΕΣΙΜΟ',
			'pieces requested': 'τεμάχια ζητήθηκαν',
			'pieces confirmed': 'τεμάχια επιβεβαιώθηκαν',
			'in backorder': 'προς παραγγελία αναμονής',
			'Values': 'Τιμές',
			'Wholesale Value': 'Τιμή Χονδρικής',
			'Invoice Value': 'Τιμή Επί Τιμολογίου',
			'Final Cost (with payment discount)': 'Τελικό Κόστος (μετρητοίς)',
			'Env. Tax': 'Περιβαλλοντική Εισφορά',
			'Values (With Tax)': 'Τιμές (Με Φ.Π.Α)'
		},
		en: {}
	},
	[Namespaces.MUI_GRID]: {
		el: {
			'Add': 'Προσθήκη',
			'Actions': 'Ενέργειες',
			'Press Yes to confirm entry deletion.': 'Πατήστε ΝΑΙ για να επιβεβαιώσετε τη διαγραφή της εγγραφής.',
			'Delete Entry Confirmation.': 'Επιβεβαίωση Διαγραφής.',
			'no': 'Όχι',
			'yes': 'Ναι',
			noRowsLabel: 'Δεν υπάρχουν καταχωρίσεις',
			noResultsOverlayLabel: 'Δεν βρέθηκαν αποτελέσματα.',
			toolbarDensity: 'Ύψος σειράς',
			toolbarDensityLabel: 'Ύψος σειράς',
			toolbarDensityCompact: 'Συμπαγής',
			toolbarDensityStandard: 'Προκαθορισμένο',
			toolbarDensityComfortable: 'Πλατύ',
			toolbarColumns: 'Στήλες',
			toolbarColumnsLabel: 'Επιλέξτε στήλες',
			toolbarFilters: 'Φίλτρα',
			toolbarFiltersLabel: 'Εμφάνιση φίλτρων',
			toolbarFiltersTooltipHide: 'Απόκρυψη φίλτρων',
			toolbarFiltersTooltipShow: 'Εμφάνιση φίλτρων',
			toolbarFiltersTooltipActive: (count) =>
				count !== 1 ? `${count} ενεργά φίλτρα` : `${count} ενεργό φίλτρο`,
			toolbarQuickFilterPlaceholder: 'Αναζήτηση...',
			toolbarQuickFilterLabel: 'Αναζήτηση',
			toolbarQuickFilterDeleteIconLabel: 'Καθαρισμός',
			toolbarExport: 'Εξαγωγή',
			toolbarExportLabel: 'Εξαγωγή',
			toolbarExportCSV: 'Λήψη ως CSV',
			toolbarExportPrint: 'Εκτύπωση',
			toolbarExportExcel: 'Κατέβασμα ως Excel',
			columnsPanelTextFieldLabel: 'Εύρεση στήλης',
			columnsPanelTextFieldPlaceholder: 'Επικεφαλίδα στήλης',
			columnsPanelDragIconLabel: 'Αναδιάταξη στήλης',
			columnsPanelShowAllButton: 'Προβολή όλων',
			columnsPanelHideAllButton: 'Απόκρυψη όλων',
			filterPanelAddFilter: 'Προσθήκη φίλτρου',
			filterPanelRemoveAll: 'Αφαίρεση όλων',
			filterPanelDeleteIconLabel: 'Διαγραφή',
			filterPanelLogicOperator: 'Λογικός τελεστής',
			filterPanelOperator: 'Τελεστές',
			filterPanelOperatorAnd: 'και',
			filterPanelOperatorOr: 'ή',
			filterPanelColumns: 'Στήλες',
			filterPanelInputLabel: 'Τιμή',
			filterPanelInputPlaceholder: 'Τιμή φίλτρου',
			filterOperatorContains: 'περιέχει',
			filterOperatorEquals: 'ισούται',
			filterOperatorStartsWith: 'ξεκινάει με',
			filterOperatorEndsWith: 'τελειώνει με',
			filterOperatorIs: 'είναι',
			filterOperatorNot: 'δεν είναι',
			filterOperatorAfter: 'είναι μετά',
			filterOperatorOnOrAfter: 'είναι ίσο ή μετά',
			filterOperatorBefore: 'είναι πριν',
			filterOperatorOnOrBefore: 'είναι ίσο ή πριν',
			filterOperatorIsEmpty: 'είναι κενό',
			filterOperatorIsNotEmpty: 'δεν είναι κενό',
			filterOperatorIsAnyOf: 'είναι οποιοδήποτε από',
			filterValueAny: 'οποιοδήποτε',
			filterValueTrue: 'αληθής',
			filterValueFalse: 'ψευδής',
			columnMenuLabel: 'Μενού',
			columnMenuShowColumns: 'Εμφάνιση στηλών',
			columnMenuManageColumns: 'Διαχείριση στηλών',
			columnMenuFilter: 'Φίλτρο',
			columnMenuHideColumn: 'Απόκρυψη',
			columnMenuUnsort: 'Απενεργοποίηση ταξινόμησης',
			columnMenuSortAsc: 'Ταξινόμηση σε αύξουσα σειρά',
			columnMenuSortDesc: 'Ταξινόμηση σε φθίνουσα σειρά',
			columnHeaderFiltersTooltipActive: (count) =>
				count !== 1 ? `${count} ενεργά φίλτρα` : `${count} ενεργό φίλτρο`,
			columnHeaderFiltersLabel: 'Εμφάνιση φίλτρων',
			columnHeaderSortIconLabel: 'Ταξινόμηση',
			footerRowSelected: (count) =>
				count !== 1
					? `${count.toLocaleString()} επιλεγμένες γραμμές`
					: `${count.toLocaleString()} επιλεγμένη γραμμή`,
			footerTotalRows: 'Σύνολο Γραμμών:',
			footerTotalVisibleRows: (visibleCount, totalCount) =>
				`${visibleCount.toLocaleString()} από ${totalCount.toLocaleString()}`,
			checkboxSelectionHeaderName: 'Επιλογή πλαισίου ελέγχου',
			checkboxSelectionSelectAllRows: 'Επιλέξτε όλες τις σειρές',
			checkboxSelectionUnselectAllRows: 'Καταργήστε την επιλογή όλων των σειρών',
			checkboxSelectionSelectRow: 'Επιλέξτε σειρά',
			checkboxSelectionUnselectRow: 'Καταργήστε την επιλογή σειράς',
			booleanCellTrueLabel: 'Ναι',
			booleanCellFalseLabel: 'Όχι',
			actionsCellMore: 'περισσότερα',
			pinToLeft: 'Καρφιτσώστε αριστερά',
			pinToRight: 'Καρφιτσώστε δεξιά',
			unpin: 'Ξεκαρφίτσωμα',
			treeDataGroupingHeaderName: 'Ομαδοποίησης Δεδομένων ',
			treeDataExpand: 'Ανάπτυξη',
			treeDataCollapse: 'Σύμπτυξη ',
			groupingColumnHeaderName: 'Ομαδοποίησης στηλών',
			groupColumn: name => `Ομαδοποίηση κατά ${name}`,
			unGroupColumn: name => `Διακοπή ομαδοποίησης κατά ${name}`,
			detailPanelToggle: 'Εναλλαγή πίνακα λεπτομερειών',
			expandDetailPanel: 'Ανάπτυξη',
			collapseDetailPanel: 'Σύμπτυξη',
			rowReorderingHeaderName: 'Αναδιάταξη σειρών',
			aggregationMenuItemHeader: 'Συσσωμάτωση',
			aggregationFunctionLabelSum: 'άθροισμα',
			aggregationFunctionLabelAvg: 'μέσος όρος',
			aggregationFunctionLabelMin: 'ελάχ.',
			aggregationFunctionLabelMax: 'μέγ.',
			aggregationFunctionLabelSize: 'μέγεθος',
		},
		en: {
			'no': 'No',
			'yes': 'Yes',
			noRowsLabel: 'No entries found',
			noResultsOverlayLabel: 'No results found.',
			toolbarDensity: 'Row height',
			toolbarDensityLabel: 'Row height',
			toolbarDensityCompact: 'Compact',
			toolbarDensityStandard: 'Standard',
			toolbarDensityComfortable: 'Comfortable',
			toolbarColumns: 'Columns',
			toolbarColumnsLabel: 'Select columns',
			toolbarFilters: 'Filters',
			toolbarFiltersLabel: 'Show filters',
			toolbarFiltersTooltipHide: 'Hide filters',
			toolbarFiltersTooltipShow: 'Show filters',
			toolbarFiltersTooltipActive: (count) =>
				count !== 1 ? `${count} active filters` : `${count} active filter`,
			toolbarQuickFilterPlaceholder: 'Search...',
			toolbarQuickFilterLabel: 'Search',
			toolbarQuickFilterDeleteIconLabel: 'Clear',
			toolbarExport: 'Export',
			toolbarExportLabel: 'Export',
			toolbarExportCSV: 'Download as CSV',
			toolbarExportPrint: 'Print',
			toolbarExportExcel: 'Download as Excel',
			columnsPanelTextFieldLabel: 'Find column',
			columnsPanelTextFieldPlaceholder: 'Column header',
			columnsPanelDragIconLabel: 'Reorder column',
			columnsPanelShowAllButton: 'Show all',
			columnsPanelHideAllButton: 'Hide all',
			filterPanelAddFilter: 'Add filter',
			filterPanelRemoveAll: 'Remove all',
			filterPanelDeleteIconLabel: 'Delete',
			filterPanelLogicOperator: 'Logic operator',
			filterPanelOperator: 'Operators',
			filterPanelOperatorAnd: 'and',
			filterPanelOperatorOr: 'or',
			filterPanelColumns: 'Columns',
			filterPanelInputLabel: 'Value',
			filterPanelInputPlaceholder: 'Filter value',
			filterOperatorContains: 'contains',
			filterOperatorEquals: 'equals',
			filterOperatorStartsWith: 'starts with',
			filterOperatorEndsWith: 'ends with',
			filterOperatorIs: 'is',
			filterOperatorNot: 'is not',
			filterOperatorAfter: 'is after',
			filterOperatorOnOrAfter: 'is on or after',
			filterOperatorBefore: 'is before',
			filterOperatorOnOrBefore: 'is on or before',
			filterOperatorIsEmpty: 'is empty',
			filterOperatorIsNotEmpty: 'is not empty',
			filterOperatorIsAnyOf: 'is any of',
			filterValueAny: 'any',
			filterValueTrue: 'true',
			filterValueFalse: 'false',
			columnMenuLabel: 'Menu',
			columnMenuShowColumns: 'Show columns',
			columnMenuManageColumns: 'Manage columns',
			columnMenuFilter: 'Filter',
			columnMenuHideColumn: 'Hide',
			columnMenuUnsort: 'Disable sorting',
			columnMenuSortAsc: 'Sort ascending',
			columnMenuSortDesc: 'Sort descending',
			columnHeaderFiltersTooltipActive: (count) =>
				count !== 1 ? `${count} active filters` : `${count} active filter`,
			columnHeaderFiltersLabel: 'Show filters',
			columnHeaderSortIconLabel: 'Sort',
			footerRowSelected: (count) =>
				count !== 1
					? `${count.toLocaleString()} selected rows`
					: `${count.toLocaleString()} selected row`,
			footerTotalRows: 'Total Rows:',
			footerTotalVisibleRows: (visibleCount, totalCount) =>
				`${visibleCount.toLocaleString()} of ${totalCount.toLocaleString()}`,
			checkboxSelectionHeaderName: 'Checkbox selection',
			checkboxSelectionSelectAllRows: 'Select all rows',
			checkboxSelectionUnselectAllRows: 'Unselect all rows',
			checkboxSelectionSelectRow: 'Select row',
			checkboxSelectionUnselectRow: 'Unselect row',
			booleanCellTrueLabel: 'Yes',
			booleanCellFalseLabel: 'No',
			actionsCellMore: 'more',
			pinToLeft: 'Pin to left',
			pinToRight: 'Pin to right',
			unpin: 'Unpin',
			treeDataGroupingHeaderName: 'Data Grouping',
			treeDataExpand: 'Expand',
			treeDataCollapse: 'Collapse',
			groupingColumnHeaderName: 'Grouping Columns',
			groupColumn: (name) => `Group by ${name}`,
			unGroupColumn: (name) => `Stop grouping by ${name}`,
			detailPanelToggle: 'Detail panel toggle',
			expandDetailPanel: 'Expand',
			collapseDetailPanel: 'Collapse',
			rowReorderingHeaderName: 'Row Reordering',
			aggregationMenuItemHeader: 'Aggregation',
			aggregationFunctionLabelSum: 'Sum',
			aggregationFunctionLabelAvg: 'Avg',
			aggregationFunctionLabelMin: 'Min',
			aggregationFunctionLabelMax: 'Max',
			aggregationFunctionLabelSize: 'Size',
		},
	},
}

export default locales
