// @flow
import React, {useEffect} from 'react';
import {useParams} from "react-router";
import {useTranslation} from "react-i18next";
import {PAGE_ORDERS} from "../../locales/pages/namespaces";
import useOrderAction from "../../hooks/order/useOrderAction";
import useOrderDetailsData from "../../hooks/order/useOrderDetailsData";
import {useOrderData} from "../../hooks/order/useOrderData";
import Box from "@mui/material/Box";
import {Typography} from "@mui/material";
import Overview from "../../components/app/overview/overview";
import OrderPrices from "../../components/app/orderPrices/orderPrices";
import ContentLoader from "../../components/general/contentLoader/contentLoader";
import ProductPreview from "../../components/app/productPreview/productPreview";
import './order.css'
import {OrderStatus} from "../../helpers/constants";

type Props = {};

const Order = (props: Props) => {
	const {id} = useParams()
	const {t} = useTranslation(PAGE_ORDERS)
	const {getOrderDetails} = useOrderAction()
	const {order} = useOrderDetailsData(id)
	const {loading} = useOrderData()
	
	useEffect(() => {
		getOrderDetails(id)
	}, [getOrderDetails, id])

	return order ? (
		<Box>
			<Box p={1}>
				<Typography
					pb={1}
					variant={'h2'}
					component={'div'}
					sx={{
						textAlign: 'center'
					}}
				>
					{t('Order Preview')}
				</Typography>
				<Overview order={order} finalize/>
			</Box>
			<Box p={4} sx={{backgroundColor: '#FFFFFF'}}>
				{order?.products && (
					order.products.map(product => (
						<ProductPreview
							key={`product-${product.id}`}
							product={{...product}}
							orderSubmitted={order.status !== OrderStatus.InProgress}
						/>
					))
				)}
			</Box>
			<Box p={4} className={'order-info'}>
				<Box sx={{width: {xs: '100%', sm: '100%', md: '50%'}}}>
					<Typography
						pb={1}
						variant={'h4'}
						component={'div'}
					>
						{t('Notes')}
					</Typography>
					<div className={'order-notes'}>
						{order?.notes}
					</div>
				</Box>
				<Box sx={{width: {xs: '100%', sm: '100%', md: '50%'}}} pt={1}>
					<OrderPrices order={order} orderSubmitted={order.status !== OrderStatus.InProgress}/>
				</Box>
			</Box>
		</Box>
	) : (
		<ContentLoader loading={loading}/>
	)
};

export default Order