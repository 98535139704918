// @flow
import React from 'react';
import Box from "@mui/material/Box";
import {Divider, Stack, Typography} from "@mui/material";

type Props = {};

const Footer = (props: Props) => {

    return (
        <Box
            sx={{
                width: '100%',
                backgroundColor: 'primary.light',
                color: '#FFFFFF',
                marginTop: 'auto'
            }}
        >
            <Divider/>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                    mt: 'auto',
                }}
            >
                <Typography variant="caption">&copy; {'All rights reserved'}</Typography>
            </Stack>
        </Box>
    );
};

export default Footer