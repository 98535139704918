// @flow
import React, {useEffect, useRef, useState} from 'react';
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {Link, useHistory} from "react-router-dom";
import {getRouteUrl} from "../../../helpers/getRouteUrl";
import {
	ROUTE_PAGE_HOME,
	ROUTE_PAGE_NEW_WARRANTY,
	ROUTE_PAGE_ORDERS,
	ROUTE_PAGE_PENDING_ORDERS,
	ROUTE_PAGE_REPORTS,
	ROUTE_PAGE_RETURNS,
	ROUTE_PAGE_WARRANTIES
} from "../../../routers/routes";
import {useTranslation} from "react-i18next";
import {NAVIGATION} from "../../../locales/components/namespaces";
import {menuStyle} from "../header/header";
import PermissionHOC from "../../../modules/permission/components/permissionHOC/permissionHOC";
import {Permissions} from "../../../helpers/constants";

type Props = {
	navItems: Array<{
		id: number,
		url: string,
		title: string
	}>
};

const MainMenu = (props: Props) => {
	const {t} = useTranslation(NAVIGATION)
	const history = useHistory()
	const [openSubMenu, setOpenSubMenu] = useState(false)
	const [openSubMenuOrders, setOpenSubMenuOrders] = useState(false)
	
	const ordersSubMenuRef = useRef();
	const warrantiesSubMenuRef = useRef();
	
	useEffect(() => {
		const handleOuterClick = (evt) => {
			if (ordersSubMenuRef?.current?.contains(evt.target)) {
				setOpenSubMenu(false);
			} else if (warrantiesSubMenuRef?.current?.contains(evt.target)) {
				setOpenSubMenuOrders(false);
			} else {
				setOpenSubMenu(false);
				setOpenSubMenuOrders(false);
			}
		};
		
		const handleEscape = (evt) => {
			if (evt.key === 'Escape' || evt.key === 'Tab') {
				setOpenSubMenu(false);
				setOpenSubMenuOrders(false);
			}
		};
		
		document.addEventListener('mousedown', handleOuterClick);
		document.addEventListener('keydown', handleEscape, false);
		
		return () => {
			document.removeEventListener('mousedown', handleOuterClick);
			document.removeEventListener('keydown', handleEscape, false);
		};
	}, []);
	
	return (
		<Box sx={{display: {xs: 'none', sm: 'none', md: 'flex'}, marginRight: 'auto', flexWrap: 'wrap'}}>
			<Box pr={2}>
				<Button
					component={Link}
					to={getRouteUrl(ROUTE_PAGE_HOME)}
					sx={{
						...menuStyle
					}}
					aria-controls="submenuHome"
					aria-haspopup="true"
				>
					{t('Home')}
				</Button>
			</Box>
			<Box pr={2}>
				<Button
					component={Link}
					to={getRouteUrl(ROUTE_PAGE_ORDERS)}
					sx={{...menuStyle}}
					aria-controls="submenuOrders"
					aria-haspopup="true"
					onMouseEnter={() => {
						setOpenSubMenuOrders(true)
						setOpenSubMenu(false)
					}}
				>
					{t('Orders')}
				</Button>
				<Box
					sx={{
						position: 'absolute',
						display: openSubMenuOrders ? 'flex' : 'none',
						flexDirection: 'column',
						backgroundColor: '#FFFFFF',
						width: '100%',
						maxWidth: '242px',
						top: '65px',
						p: 1,
						border: '1px solid rgba(37,91,130, 0.2)',
						boxShadow: '0px 1px 4px rgba(33, 33, 33, 0.08)'
					}}
					ref={ordersSubMenuRef}
					onMouseLeave={(e) => {
						const rect = e.target.getBoundingClientRect();
						if (rect.top > e.clientY) return
						setOpenSubMenuOrders(false)
					}}
				>
					<Button
						onClick={() => {
							setOpenSubMenuOrders(false)
							history.push(getRouteUrl(ROUTE_PAGE_ORDERS))
						}}
						sx={{width: '100%', ...menuStyle}}
					>
						{t('Completed Orders')}
					</Button>
					<Button
						onClick={() => {
							setOpenSubMenuOrders(false)
							history.push(getRouteUrl(ROUTE_PAGE_PENDING_ORDERS))
						}}
						sx={{width: '100%', ...menuStyle}}
					>
						{t('Pending Orders')}
					</Button>
				</Box>
			</Box>
			<Box pr={2}>
				<Button
					component={Link}
					to={getRouteUrl(ROUTE_PAGE_RETURNS)}
					sx={{...menuStyle}}
					aria-controls="submenuReturns"
					aria-haspopup="true"
				>
					{t('Returns')}
				</Button>
			</Box>
			<PermissionHOC requiredPermissions={Permissions.AccessGuarantees}>
				<Box>
					<Button
						component={Link}
						to={getRouteUrl(ROUTE_PAGE_WARRANTIES)}
						sx={{...menuStyle}}
						aria-controls="submenuWarranties"
						aria-haspopup="true"
						onMouseEnter={() => {
							setOpenSubMenu(true)
							setOpenSubMenuOrders(false)
						}}
					>
						{t('Guarantees')}
					</Button>
					<Box
						sx={{
							position: 'absolute',
							display: openSubMenu ? 'flex' : 'none',
							flexDirection: 'column',
							backgroundColor: '#FFFFFF',
							width: '100%',
							maxWidth: '242px',
							top: '65px',
							p: 1,
							border: '1px solid rgba(37,91,130, 0.2)',
							boxShadow: '0px 1px 4px rgba(33, 33, 33, 0.08)'
						}}
						ref={warrantiesSubMenuRef}
						onMouseLeave={(e) => {
							const rect = e.target.getBoundingClientRect();
							if (rect.top > e.clientY) return
							setOpenSubMenu(false)
						}}
					>
						<Button
							onClick={() => {
								setOpenSubMenu(false)
								history.push(getRouteUrl(ROUTE_PAGE_NEW_WARRANTY))
							}}
							sx={{width: '100%', ...menuStyle}}
						>
							{t('New Warranty')}
						</Button>
						<Button
							onClick={() => {
								setOpenSubMenu(false)
								history.push(getRouteUrl(ROUTE_PAGE_WARRANTIES))
							}}
							sx={{width: '100%', ...menuStyle}}
						>
							{t('Warranties')}
						</Button>
					</Box>
				</Box>
			</PermissionHOC>
			<Box pr={2}>
				<Button
					component={Link}
					to={getRouteUrl(ROUTE_PAGE_REPORTS)}
					sx={{...menuStyle}}
				>
					{t('Reports')}
				</Button>
			</Box>
		</Box>
	);
};

export default MainMenu