import {useTranslation} from "react-i18next";
import {PAGE_LOGIN} from "../../locales/pages/namespaces";
import * as yup from "yup";
import {useFormik} from "formik";
import useUserAction from "../user/useUserAction";
import useUserData from "../user/useUserData";
import {useEffect} from "react";

const useDailyCode = ({handleClose}) => {
    const {t} = useTranslation(PAGE_LOGIN)
    const {getDailyCode} = useUserAction()
    const {successDailyCode} = useUserData()
    const onSubmit = (values) => {
        getDailyCode(values)
    }

    useEffect(() => {
        if (successDailyCode) {
            handleClose()
        }
    }, [successDailyCode])

    const validationSchema = yup.object({
        email: yup
            .string()
        .email(t('Insert a valid email'))
            .required(t('required'))
    })

    const formik = useFormik({
        initialValues: {
            email: ''
        },
        validationSchema: validationSchema,
        onSubmit: onSubmit,
    })

    return {
        formik
    }
}

export default useDailyCode