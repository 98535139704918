export const checkPermissions = (requiredPermissions, currentUserPermissions) => {
	const hasRequiredPermissions = currentUserPermissions.filter(p => requiredPermissions.includes(p.id))
	
	if (!(hasRequiredPermissions && hasRequiredPermissions.length > 0)) {
		return {
			canView: false,
			canEdit: false
		}
	}
	
	const canView = hasRequiredPermissions.find(p => p.canView)
	const canEdit = hasRequiredPermissions.find(p => p.canEdit)
	// const isAction = hasRequiredPermissions.find(p => p.isAction);
	
	return {
		canView: typeof canView !== 'undefined',
		canEdit: typeof canEdit !== 'undefined'
	}
}