import {createSelector} from "reselect";
import {useSelector} from "react-redux";
import {parseUserMessage} from "../../types/user/parse";

const selectCreatingWarranty = createSelector(
    (state) => state.warranty,
    (item)=> item.get('warrantyNumber')
)
const selectLoading = createSelector(
    (state) => state.warranty.get('loading'),
    (item) => item
)
const selectMessage = createSelector(
    (state) => state.warranty.get('message'),
    (item) => parseUserMessage(item)
)

export const useWarrantyData = () =>{
    const loading = useSelector(selectLoading)
    const warrantyNumber = useSelector(selectCreatingWarranty)
    const message = useSelector(selectMessage)
    return {
        loading,
        warrantyNumber,
        message
    }
}