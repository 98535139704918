// @flow
import Box from "@mui/material/Box";
import React, {useCallback} from 'react';
import Table from "../../grids/table/table";
import Product from "../product/product";
import useUserData from "../../../hooks/user/useUserData";
import useProductAction from "../../../hooks/product/useProductAction";
import useProductData from "../../../hooks/product/useProductData";
import './product.css'
import Calculator from "../dialogs/calculator/calculator";
import ProductInfo from "../dialogs/productInfo/productInfo";
import {useProductPopUps} from "../../../hooks/product/useProductPopUps";
import Confirmation from "../dialogs/confirmation/confirmation";
import {useTranslation} from "react-i18next";
import {PAGE_ORDERS} from "../../../locales/pages/namespaces";
import {getRouteUrl} from "../../../helpers/getRouteUrl";
import {ROUTE_PAGE_ORDER_FINALIZE} from "../../../routers/routes";
import {useOrderData} from "../../../hooks/order/useOrderData";
import {useHistory} from "react-router-dom";
import useOrderAction from "../../../hooks/order/useOrderAction";
import ContentLoader from "../../general/contentLoader/contentLoader";
import {useMediaQuery} from "@mui/material";
import ProductsHeader from "../productsHeader/productsHeader";
import {useTheme} from "@mui/material/styles";

type Props = {};

const Products = (props: Props) => {
	const {
		urlFilters,
		displayWithTax,
		displayWithEnvTax,
		displayWithPaymentDiscount
	} = props
	const history = useHistory()
	const {t} = useTranslation(PAGE_ORDERS)
	const {data} = useUserData()
	const {getSearchResults} = useProductAction()
	const {setRedirectToOrder} = useOrderAction()
	const {searchResultsIndexTable} = useProductData()
	const {activeOrder, redirectToOrder, loading} = useOrderData()
	const theme = useTheme();
	const matchesMd = useMediaQuery(theme.breakpoints.up('md'));
	
	const dataManager = useCallback((pageIndex, pageSize) => {
		getSearchResults(pageIndex, pageSize, urlFilters, data.preserveForBackend)
	}, [data.preserveForBackend, urlFilters, getSearchResults])
	
	const {
		calculatorOpen,
		handleCalculator,
		productInfo,
		handleProductInfo
	} = useProductPopUps()
	
	return (
		<Box sx={{maxWidth: '1400px'}}>
			{matchesMd && <ProductsHeader/>}
			<Table
				customStyle={'fullWidthTable'}
				data={searchResultsIndexTable}
				dataManager={dataManager}
				columns={[
					{
						accessor: 'id',
						Cell: ({value, row}) => (
							<Product
								key={`product-${row.id}`}
								displayWithTax={displayWithTax}
								displayWithEnvTax={displayWithEnvTax}
								displayWithPaymentDiscount={displayWithPaymentDiscount}
								handleCalculator={handleCalculator}
								handleProductInfo={handleProductInfo}
								product={{...row.original}}
							/>
						)
					}
				]}
				pageSize={10}
				noHeader
			/>
			<Calculator
				open={calculatorOpen}
				handleDialog={handleCalculator}
			/>
			<ProductInfo
				open={productInfo}
				handleDialog={handleProductInfo}
			/>
			<Confirmation
				open={redirectToOrder}
				handleDialog={(newValue) => setRedirectToOrder(newValue)}
				title={t('Proceed to order submission')}
				onYes={() => {
					setRedirectToOrder(false)
					if (activeOrder && activeOrder.length > 0) {
						history.push(getRouteUrl(ROUTE_PAGE_ORDER_FINALIZE, {
							id: activeOrder[0]?.id
						}))
					}
				}}
				onNo={() => setRedirectToOrder(false)}
				yesBtnText={t('Proceed to cart')}
				noBtnText={t('Continue')}
				hasBtnIcons={false}
			/>
			<ContentLoader
				loading={loading}
				color={'#fbc324'}
				withBackDrop
				useCircularLoader
				message={t('Processing order...')}
			/>
		</Box>
	);
};

export default Products