import {useFormik} from "formik";
import axios from "axios";
import useUserData from "../../../hooks/user/useUserData";
import {API} from "../../../helpers/constants";
import {useTranslation} from "react-i18next";
import {PAGE_WARRANTIES} from "../../../locales/pages/namespaces";
import {enqueueSnackbar} from "notistack";
import {handleAxiosErrors} from "../../../helpers/errorHandling";
import * as yup from "yup";
import {useState} from "react";

const useReturnForm = ({refresh, onClose}) => {
	const {selectedStore} = useUserData()
	const {t} = useTranslation(PAGE_WARRANTIES)
	const [loading, setLoading] = useState(false)
	const onSubmit = (values) => {
		setLoading(true)
		axios({
			method: 'post',
			data: {
				type: 3,
				CustomerId: selectedStore?.customerId,
				AddressId: selectedStore?.id,
				comments: values.comments,
				returnList: values.returnList.map((item) => ({
					supplierCode: item.supplierCode,
					itemId: item.itemId,
					quantity: item.quantity,
					dots: item.dots
				}))
				
			},
			url: `${API}/en/b2b/return/tires/store`,
		}).then(response => {
			setLoading(false)
			enqueueSnackbar(t('Return submitted!'), {
				variant: 'success',
				autoHideDuration: 1000,
			})
			onClose()
			refresh()
		}).catch((error) => {
			setLoading(false)
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to submit return!'), {
				variant: 'warning',
				autoHideDuration: 1000,
			}))
		})
	}
	
	const validationSchema = yup.object().shape({
		returnList: yup.array().of(
			yup.object().shape({
				itemId: yup.string().required(t("Required")),
				supplierCode: yup.string().required(t("Required")),
				description: yup.string().required(t("Required")),
				tireBrand: yup.string().required(t("Required")),
				quantity: yup.number().min(1, t('Minimum allowed quantity is 1')).required("Required"),
				dots: yup.mixed().test(
					'required', t('Please fill all dot fields. Dot must be a number of exact 4 digits.'), function (value) {
						return value.length === this.parent.quantity && value.filter(f => Boolean(f) && String(f).trim()?.length === 4)?.length === this.parent.quantity
					}
				)
			})
		),
	});
	
	const initialValues = {
		comments: '',
		returnList: [
			{
				supplierCode: '',
				itemId: '',
				quantity: 0,
				tireBrand: '',
				description: '',
				dots: []
			},
		],
	};
	
	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: onSubmit
	});
	
	return {
		formik,
		loading
	};
};

export default useReturnForm;
