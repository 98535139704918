// @flow
import React from 'react';
import Box from "@mui/material/Box";
import {Checkbox, FormControl, FormControlLabel, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {PAGE_REPORTS} from "../../../../locales/pages/namespaces";
import {DateRangePicker, LocalizationProvider, SingleInputDateRangeField} from "@mui/x-date-pickers-pro";
import {AdapterDayjs} from "@mui/x-date-pickers-pro/AdapterDayjs";
import useUserData from "../../../../hooks/user/useUserData";
import {muiDatePickersLocales} from "../../../../helpers/muiLocales";

type Props = {};

const SalesFilters = (props: Props) => {
	const {filters, formik, dateRange, setDateRange} = props
	const {t} = useTranslation(PAGE_REPORTS)
	const {dateFormat, language} = useUserData()
	return (
		<>
			{
				filters.map((filter, index) => {
					const {id, description, values, groups} = filter
					return (
						<Box
							key={`filter-${index}`}
							className={'topFilters-filter'}
							pb={0.5}
							sx={{
								backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#f5f5f5',
								flexDirection: {
									xs: 'column',
									sm: 'row'
								},
								flexWrap: 'none'
							}}
						>
							<Typography
								variant={'body1'}
								component={'div'}
								className={'topFilters-filter_title'}
								color={'#2f4f4f'}
								sx={{
									paddingRight: {
										xs: 0,
										md: 1
									},
									...(groups.length > 0 && {width: '100%'})
								}}
							>
								<strong>{t(description)}</strong>
							</Typography>
							<Box
								className={'topFilters-filter_values'}
								sx={{
									...(groups.length > 0 ? {
										alignItems: 'start !important',
										flexDirection: 'column',
									} : {})
								}}
							>
								{values.map((value, index) => {
									const {group, groupValues} = value
									return (
										<Box key={`values-${index}`}>
											{groups.length > 0 && (
												<Typography
													variant={'body2'}
													component={'div'}
													className={'topFilters-filter_title'}
													color={'#2f4f4f'}
													sx={{
														paddingRight: {
															xs: 0,
															md: 1
														}
													}}
												>
													<strong>{t(group ? group : 'Other')}</strong>
												</Typography>
											)}
											{groupValues.map(val => {
												const {id, description} = val
												return (
													<FormControl
														key={`groups-${id}`}
														variant="standard"
														sx={{
															color: '#255b82',
															minWidth: '55px',
															ml: 1
														}}
													>
														<FormControlLabel
															control={
																<Checkbox
																	checked={formik.values[`value-${id}`]}
																	onChange={(evt) => {
																		formik.setFieldValue(`value-${id}`, evt.target.checked)
																	}}
																	size={'small'}
																	name={`value-${id}`}
																	sx={{p: 0}}
																/>
															}
															label={t(description)}
															labelPlacement="end"
														/>
													</FormControl>
												)
											})}
										</Box>
									)
								})}
							</Box>
						</Box>
					)
				})
			}
			<Box
				className={'topFilters-filter'}
				pb={0.5}
				sx={{
					backgroundColor: '#FFFFFF',
					flexDirection: {
						xs: 'column',
						sm: 'row'
					},
					flexWrap: 'none'
				}}
			>
				<Typography
					variant={'body1'}
					component={'div'}
					className={'topFilters-filter_title'}
					color={'#2f4f4f'}
					sx={{
						paddingRight: {
							xs: 0,
							md: 1
						}
					}}
				>
					<strong>{t('Date range filter')}</strong>
				</Typography>
				<Box
					className={'topFilters-filter_values'}
					pl={1}
				>
					<LocalizationProvider dateAdapter={AdapterDayjs} localeText={muiDatePickersLocales(language)}>
						<DateRangePicker
							format={dateFormat}
							slots={{field: SingleInputDateRangeField}}
							value={dateRange}
							onChange={(newValue) => setDateRange(newValue)}
						/>
					</LocalizationProvider>
				</Box>
			</Box>
		</>
	);
};

export default SalesFilters