// ==============================|| OVERRIDES - DATA GRID ||============================== //
import {grey} from '@mui/material/colors';

export default function DataGrid(theme) {
	return {
		MuiDataGrid: {
			styleOverrides: {
				cell: {
					borderBottom: `2px solid ${theme.palette.primary['lighter']}`,
				},
				columnHeaders: {
					background: '#f5f5f5',
					fontSize: '14px',
					fontWeight: 400,
					lineHeight: '22px',
					columnHeaderTitle: {
						fontSize: '14px',
						fontWeight: 400,
						lineHeight: '22px',
					}
				},
				root: {
					'& .MuiDataGrid-columnHeaderTitle':
						{
							fontSize: '14px',
							fontWeight: 600,
							lineHeight: '22px',
							color: '#255b82'
						},
					'& .MuiDataGrid-footerContainer': {
						height: '40px',
						borderTop: '1px solid #E1E2E9'
					},
					'& .MuiDataGrid-booleanCell[data-value="false"]': {
						color: grey[30]
					},
					'& .MuiTablePagination-selectLabel': {
						color: grey[20],
						alignSelf: 'stretch',
						marginLeft: 0,
					},
					'& .MuiSelect-select': {
						display: 'flex',
						alignItems: 'center',
					},
					'& .MuiTablePagination-displayedRows': {
						color: '#666666',
						fontSize: '14px',
						fontWeight: 400,
						lineHeight: '22px'
					},
				},
			},
		},
	}
}
