//@flow
import * as React from 'react'
import {Fragment, useCallback, useRef} from 'react'

type Props = {
	children: React.Node,
	dataManager: Function,
	setData: Function,
	setPageCount: Function,
	setLoading: Function
}

const AxiosTable = (props: Props) => {
	const {children, dataManager, setData, setPageCount, setLoading} = props
	const fetchIdRef = useRef(0)
	
	const fetchData = useCallback(({pageSize, pageIndex}) => {
		const fetchId = ++fetchIdRef.current
		setLoading(true)
		dataManager(pageIndex, pageSize)
		.then((response) => {
			const {data: {count, result}} = response
			// Only update the data if this is the latest fetch
			if (fetchId === fetchIdRef.current) {
				setData(result)
				setPageCount(Math.ceil(parseInt(count) / pageSize))
				setLoading(false)
			}
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataManager])
	
	return <Fragment>{children(fetchData)}</Fragment>
}

export default AxiosTable