import Box from "@mui/material/Box";
import {
	Autocomplete,
	createFilterOptions,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	FormHelperText,
	Paper,
	TextField,
	Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {PAGE_NEW_WARRANTY} from "../../../../locales/pages/namespaces";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers-pro";
import {AdapterDayjs} from "@mui/x-date-pickers-pro/AdapterDayjs";
import React, {useEffect, useState} from "react";
import Checkbox from '@mui/material/Checkbox';
import Button from "@mui/material/Button";
import {getRouteUrl} from "../../../../helpers/getRouteUrl";
import {ROUTE_PAGE_WARRANTIES} from "../../../../routers/routes";
import {useHistory} from "react-router-dom";
import useWarrantyForm from "../../../../hooks/warranties/useWarrantyForm";
import {ErrorMessage, FormikProvider} from "formik";
import useUserData from "../../../../hooks/user/useUserData";
import axios from "axios";
import {handleAxiosErrors} from "../../../../helpers/errorHandling";
import {enqueueSnackbar} from "notistack";
import dayjs from "dayjs";
import {API, Permissions} from "../../../../helpers/constants";
import {useWarrantyData} from "../../../../hooks/warranties/useWarrantyData";
import useWarrantyAction from "../../../../hooks/warranties/useWarrantyAction";
import {NumericFormat} from "react-number-format";
import {v4 as uuidv4} from "uuid";
import {LoadingButton} from "@mui/lab";
import {ExportExcelPdf} from "../../../../components/exportExcelPdf/exportExcelPdf";
import {withPermissionHOC} from "../../../../modules/permission/components/permissionHOC/permissionHOC";
import {muiDatePickersLocales} from "../../../../helpers/muiLocales";

const filter = createFilterOptions()

const NewWarranty = () => {
	const {formik} = useWarrantyForm()
	const history = useHistory()
	const {t} = useTranslation(PAGE_NEW_WARRANTY);
	const [clear, setClear] = useState(false);
	const {token, thousandSeparator, decimalSeparator, selectedStore, monthLimitForWarranties, language} = useUserData()
	const {warrantyNumber, message, loading} = useWarrantyData()
	const {setWarrantyNumber, setWarrantyMessage} = useWarrantyAction()
	const [loadingSearch, setLoadingSearch] = useState(false)
	const [carlist, setCarlist] = useState([])
	const [carmodel, setCarmodel] = useState([])
	const pdfUrl = `${API}/en/b2b/guarantee/pdf?GuaranteeNumber=${warrantyNumber}&final=0`
	
	useEffect(() => {
		axios({
			method: 'post',
			url: `${API}/en/b2b/warranty/dropdown/data`,
			headers: {
				'Authorization': `Bearer ${token}`,
				'Content-Type': 'application/json'
			}
		}).then(response => {
			setCarlist(response.data.results.map((brand, index) => ({
				id: index,
				Description: brand
			})))
		}).catch((error) => {
			console.log('error', error)
			enqueueSnackbar(handleAxiosErrors(error, t('Wrong code!'), {
				variant: 'warning',
				autoHideDuration: 1000,
			}))
		})
	}, [t, token])
	
	const handleModel = (newValue) => {
		if (newValue !== null) {
			axios({
				method: 'post',
				url: `${API}/en/b2b/warranty/dropdown/data`,
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json'
				},
				data: {
					models: newValue.Description
				}
			}).then(response => {
				setCarmodel(response.data.results.map((brand, index) => ({
					id: index,
					Description: brand
				})))
			}).catch((error) => {
				console.log('error', error)
				enqueueSnackbar(handleAxiosErrors(error, t('Wrong code!'), {
					variant: 'warning',
					autoHideDuration: 1000,
				}))
			})
		} else {
			setCarmodel([])
			formik.setFieldValue('CarModel', '')
		}
		
	}
	
	const handleClose = (event, reason) => {
		if (reason && (reason === "backdropClick" || reason === "escapeKeyDown")) return
		setWarrantyNumber(null)
	}
	
	useEffect(() => {
		if (message) {
			enqueueSnackbar(t(message?.text), {
					variant: message?.variant
				}
			)
			setWarrantyMessage(null)
		}
	}, [message, setWarrantyMessage, t])
	
	const handleCheckboxChange = (event) => {
		formik.setFieldValue('dimensionCheck', event.target.checked);
	};
	
	const handleDate = (newValue) => {
		const new2Value = dayjs(newValue, 'DD/MM/YYYY')
		formik.setFieldValue('ActivationDate', new2Value)
		const newDate = dayjs(new2Value).add(12, 'months')
		formik.setFieldValue('EndDate', newDate)
	}
	
	useEffect(() => {
		if (clear) {
			const timeout = setTimeout(() => {
				setClear(false);
			}, 1500);
			
			return () => clearTimeout(timeout);
		}
		return () => {
		};
	}, [clear]);
	
	const handleChange = () => {
		setLoadingSearch(true)
		axios({
			method: 'get',
			url: `${API}/en/b2b/warranty/search/data?isAltCode=true&AltCode=${formik.values.SupplierCode}&AddressId=${selectedStore?.id}`,
			headers: {
				'Authorization': `Bearer ${token}`,
				'Content-Type': 'application/json'
			}
		}).then(response => {
			formik.setFieldValue('Description', response.data.results[0].ItemDescriptionEn)
			formik.setFieldValue('TireBrand', response.data.results[0].ManufacturerEN)
			formik.setFieldValue('itemId1', response.data.results[0].ItemId)
			setLoadingSearch(false)
		}).catch((error) => {
			setLoadingSearch(false)
			enqueueSnackbar(t(error?.response?.data?.message, {monthLimitForWarranties: monthLimitForWarranties}), {
				variant: 'warning',
				autoHideDuration: 3000,
			})
		})
	}
	
	const handle2Change = () => {
		setLoadingSearch(true)
		axios({
			method: 'get',
			url: `${API}/en/b2b/warranty/search/data?isAltCode=true&AltCode=${formik.values.SupplierCode2}&AddressId=${selectedStore?.id}`,
			headers: {
				'Authorization': `Bearer ${token}`,
				'Content-Type': 'application/json'
			}
		}).then(response => {
			formik.setFieldValue('Description2', response.data.results[0].ItemDescriptionEn)
			formik.setFieldValue('TireBrand2', response.data.results[0].ManufacturerEN)
			formik.setFieldValue('itemId2', response.data.results[0].ItemId)
			setLoadingSearch(false)
		}).catch((error) => {
			setLoadingSearch(false)
			enqueueSnackbar(t(error?.response?.data?.message, {monthLimitForWarranties: monthLimitForWarranties}), {
				variant: 'warning',
				autoHideDuration: 3000,
			})
		})
	}
	
	return (
		<FormikProvider value={formik}>
			<Box p={1} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
				<Typography
					variant={'h2'}
					component={'div'}
					pb={1}
					sx={{
						textAlign: 'center',
					}}
				>
					{t('Create New Warranty')}
				</Typography>
				<Paper elevation={0} sx={{maxWidth: '1100px', width: '100%'}}>
					<Box p={1}>
						<Box display={'flex'} justifyContent={'space-between'}>
							<Button
								variant={'outlined'}
								color={'error'}
								onClick={() => history.push(getRouteUrl(ROUTE_PAGE_WARRANTIES))}
							>
								{t('Cancel')}
							</Button>
							<LoadingButton
								color={'success'}
								variant={'outlined'}
								type={'submit'}
								onClick={formik.submitForm}
								loading={loading}
							>
								{t('Save')}
							</LoadingButton>
						</Box>
						<Box display={'flex'} justifyContent={'space-around'} pt={0.2} flexWrap={'wrap'}>
							<Box
								display={'flex'}
								flexDirection={'column'}
								width={{
									sm: '100%',
									lg: '30%',
								}}
							>
								<Box display={'flex'} flexDirection={'column'} p={1}>
									<Typography variant="subtitle1"
												sx={{textAlign: 'center'}}>{t('Product Details')}</Typography>
									<LocalizationProvider dateAdapter={AdapterDayjs}
														  localeText={muiDatePickersLocales(language)}>
										<DatePicker
											value={formik.values.StartDt}   //dayValue
											disabled
											sx={{
												"& .MuiInputBase-input.Mui-disabled": {
													WebkitTextFillColor: "#085c1f",
												},
												"& .MuiFormLabel-root.Mui-disabled": {
													WebkitTextFillColor: "#085c1f",
												},
												
											}}
											label={t('Publish Date')}
											format="DD/MM/YYYY"
											onChange={(newValue) => {
												handleDate(newValue)
											}}    //formik.setFieldValue('ActivationDate',newValue)
											renderInput={(params) =>
												<TextField
													{...params}
													placeholder={t('Publish Date')}
													variant={'standard'}
												/>}
											slotProps={{
												field: {
													//shouldRespectLeadingZeros: true,
													clearable: true,
													onClear: () => setClear(true),
												},
												textField: {variant: 'standard', placeholder: t('Publish Date')}
											}}
										/>
									</LocalizationProvider>
									<LocalizationProvider dateAdapter={AdapterDayjs}
														  localeText={muiDatePickersLocales(language)}>
										<DatePicker
											value={formik.values.EndDt}
											disabled
											sx={{
												"& .MuiInputBase-input.Mui-disabled": {
													WebkitTextFillColor: "#085c1f",
												},
												"& .MuiFormLabel-root.Mui-disabled": {
													WebkitTextFillColor: "#085c1f",
												},
												
											}}
											format="DD/MM/YYYY"
											label={t('Expiration Date')}
											//onChange={(newValue) => formik.setFieldValue('EndDate',newValue)}
											renderInput={(params) =>
												<TextField
													variant={'standard'}
													placeholder={t('Expiration Date')}
													{...params}
												/>}
											slotProps={{
												field: {
													clearable: true,
													onClear: () => setClear(true),
												},
												textField: {variant: 'standard', placeholder: t('Expiration Date')}
											}}
										/>
									</LocalizationProvider>
								</Box>
								<Box display={'flex'} flexDirection={'column'} p={1}>
									<Typography variant="subtitle1"
												sx={{textAlign: 'center'}}>{t('Vehicle Details')}</Typography>
									<FormControl
										variant="standard"
										fullWidth
										error={
											formik.touched['CarManufacturer'] &&
											Boolean(formik.errors['CarManufacturer'])
										}
									>
										<Autocomplete
											options={carlist}
											isOptionEqualToValue={(option, value) => option?.Description === value}
											getOptionLabel={(option) => {
												if (!option) {
													return '';
												}
												if (typeof option === 'string') {
													return option;
												}
												if (option.inputValue) {
													return option.inputValue;
												}
												return option.Description && option.Description.startsWith('Add') ? option.Description.replace(/^Add "|"$|^Add /g, '').trim() : option.Description;
											}}
											filterOptions={(options, params) => {
												const filtered = filter(options, params)
												const {inputValue} = params
												const isExisting = options.some((option) => inputValue === option?.Description)
												if (inputValue !== '' && !isExisting) {
													filtered.push({
														inputValue,
														id: uuidv4(),
														Description: `Add "${inputValue}"`,
													})
												}
												return filtered
											}}
											onChange={(event, newValue) => {
												if (newValue && newValue?.inputValue) {
													delete newValue.inputValue
													const description = newValue.Description.replace(/^"|"$/g, '').replace(/^Add "/, '').trim();
													newValue = {
														id: newValue.id,
														Description: description,
													};
													formik.setFieldValue('CarManufacturer', newValue);
												} else {
													formik.setFieldValue('CarManufacturer', newValue);
													handleModel(newValue);
												}
											}}
											selectOnFocus
											clearOnBlur
											handleHomeEndKeys
											freeSolo
											value={formik.values.CarManufacturer}
											renderOption={(props, option) => (
												<li {...props}>{option.Description}</li>
											)}
											renderInput={(params) => (
												<TextField
													label={t('Car Brand')}
													size={'medium'}
													variant={'standard'}
													required
													{...params}
												/>
											)}
										/>
										<FormHelperText>
											<ErrorMessage name={'CarManufacturer'}/>
										</FormHelperText>
									</FormControl>
									<FormControl
										variant="standard"
										fullWidth
										error={
											formik.touched['CarModel'] &&
											Boolean(formik.errors['CarModel'])
										}
									>
										<Autocomplete
											options={carmodel}
											isOptionEqualToValue={(option, value) => option?.Description === value}
											getOptionLabel={(option) => {
												if (!option) {
													return '';
												}
												if (typeof option === 'string') {
													return option;
												}
												if (option.inputValue) {
													return option.inputValue;
												}
												return option.Description && option.Description.startsWith('Add') ? option.Description.replace(/^Add "|"$|^Add /g, '').trim() : option.Description;
											}}
											filterOptions={(options, params) => {
												const filtered = filter(options, params)
												const {inputValue} = params
												const isExisting = options.some((option) => inputValue === option?.Description)
												if (inputValue !== '' && !isExisting) {
													filtered.push({
														inputValue,
														id: uuidv4(),
														Description: `Add "${inputValue}"`,
													})
												}
												return filtered
											}}
											onChange={(event, newValue) => {
												if (newValue && newValue?.inputValue) {
													delete newValue.inputValue
													const description = newValue.Description.replace(/^"|"$/g, '').replace(/^Add "/, '').trim();
													newValue = {
														id: newValue.id,
														Description: description,
													};
													formik.setFieldValue('CarModel', newValue);
												} else {
													formik.setFieldValue('CarModel', newValue);
												}
											}}
											selectOnFocus
											clearOnBlur
											handleHomeEndKeys
											freeSolo
											value={formik.values.CarModel}
											renderOption={(props, option) => (
												<li {...props}>{option.Description}</li>
											)}
											renderInput={(params) => (
												<TextField
													label={t('Car Model')}
													size={'medium'}
													variant={'standard'}
													{...params}
													required
												/>
											)}
										/>
										<FormHelperText>
											<ErrorMessage name={'CarModel'}/>
										</FormHelperText>
									</FormControl>
									<FormControl
										variant="standard"
										fullWidth
										error={
											formik.touched['LicensePlate'] &&
											Boolean(formik.errors['LicensePlate'])
										}
									>
										<TextField
											label={t('License Pl.')}
											variant={'standard'}
											value={formik.values.LicensePlate}
											id="LicensePlate"
											onBlur={formik.handleBlur}
											onChange={formik.handleChange}
											required
											inputProps={{maxLength: 10}}
											maxLength={10}
										/>
										<FormHelperText>
											<ErrorMessage name={'LicensePlate'}/>
										</FormHelperText>
									</FormControl>
									<FormControl
										variant="standard"
										fullWidth
										error={
											formik.touched['CubicMeters'] &&
											Boolean(formik.errors['CubicMeters'])
										}
									>
										<NumericFormat
											thousandSeparator={thousandSeparator}
											decimalSeparator={decimalSeparator}
											value={formik.values.CubicMeters}
											onValueChange={(values, sourceInfo) => {
												formik.setFieldValue('CubicMeters', values.floatValue)
											}}
											decimalScale={0}
											customInput={TextField}
											size={'small'}
											variant={"standard"}
											label={t('CC')}
											//type={'number'}
											inputProps={{min: 0, maxLength: 12, style: {textAlign: 'right'}}}
											required
										/>
										<FormHelperText>
											<ErrorMessage name={'CubicMeters'}/>
										</FormHelperText>
									</FormControl>
									<FormControl
										variant="standard"
										fullWidth
										error={
											formik.touched['Km'] &&
											Boolean(formik.errors['Km'])
										}
									>
										<NumericFormat
											thousandSeparator={thousandSeparator}
											decimalSeparator={decimalSeparator}
											value={formik.values.Km}
											onValueChange={(values, sourceInfo) => {
												formik.setFieldValue('Km', values.floatValue)
											}}
											decimalScale={0}
											customInput={TextField}
											size={'small'}
											variant={"standard"}
											label={t('Km')}
											required
											inputProps={{min: 0, style: {textAlign: 'right'}}}
										/>
										<FormHelperText>
											<ErrorMessage name={'Km'}/>
										</FormHelperText>
									</FormControl>
								</Box>
							</Box>
							<Box
								display={'flex'}
								flexDirection={'column'}
								width={{
									sm: '100%',
									lg: '60%',
								}}
							>
								<Typography variant="subtitle1"
											sx={{textAlign: 'center'}}>{t('Tire Selection')}</Typography>
								<Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}
									 alignItems={'center'} flexWrap={'wrap'}>
									<FormControlLabel
										control={
											<Checkbox
												checked={formik.values.dimensionCheck}
												onChange={handleCheckboxChange}
											/>
										}
										label={t('Same dimensions with set 1')}
										labelPlacement="end"
									/>
								</Box>
								
								<Box display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'}>
									<Box display={'flex'} flexDirection={'column'}
										 sx={{width: formik.values.dimensionCheck ? '100%' : '49%'}}>
										<Box>
											<FormControl
												variant="standard"
												sx={{flexDirection: 'grid'}}
												error={
													formik.touched['SupplierCode'] &&
													Boolean(formik.errors['SupplierCode'])
												}
											>
												<TextField
													label={t('Supplier Code')}
													variant={'standard'}
													id="SupplierCode"
													value={formik.values.SupplierCode}
													onBlur={formik.handleBlur}
													required
													onChange={(e) => {
														formik.setFieldValue('SupplierCode', e.target.value)
													}}
												/>
												<FormHelperText>
													<ErrorMessage name={'SupplierCode'}/>
												</FormHelperText>
											</FormControl>
											<LoadingButton
												loading={loadingSearch}
												// loadingPosition="end"
												sx={{mt: 2, ml: 2, minWidth: '100px'}}
												onClick={() => handleChange()}
											>
												{t('Search')}
											</LoadingButton>
										</Box>
										<Box>
											<FormControl
												variant="standard"
												sx={{flexDirection: 'grid'}}
												error={
													formik.touched['TireBrand'] &&
													Boolean(formik.errors['TireBrand'])
												}
											>
												<TextField
													label={t('Tire Brand')}
													value={formik.values.TireBrand}
													variant={'standard'}
													disabled
													sx={{
														minWidth: '300px',
														"& .MuiInputBase-input.Mui-disabled": {
															WebkitTextFillColor: "#085c1f",
														},
														"& .MuiFormLabel-root.Mui-disabled": {
															WebkitTextFillColor: "#085c1f",
														},
													}}
												/>
												<FormHelperText>
													<ErrorMessage name={'TireBrand'}/>
												</FormHelperText>
											</FormControl>
										</Box>
										<FormControl
											variant="standard"
											sx={{flexDirection: 'grid'}}
											error={
												formik.touched['Description'] &&
												Boolean(formik.errors['Description'])
											}
										>
											<TextField
												label={t('Description')}
												value={formik.values.Description}
												disabled
												sx={{
													"& .MuiInputBase-input.Mui-disabled": {
														WebkitTextFillColor: "#085c1f",
													},
													"& .MuiFormLabel-root.Mui-disabled": {
														WebkitTextFillColor: "#085c1f",
													},
												}}
												variant={'standard'}
												id="Description"
											/>
											<FormHelperText>
												<ErrorMessage name={'Description'}/>
											</FormHelperText>
										</FormControl>
										<FormControl
											variant="standard"
											error={
												formik.touched['dot1'] &&
												Boolean(formik.errors['dot1'])
											}
										>
											<NumericFormat
												label={t('DOT1')}
												value={formik.values.dot1}
												variant={'standard'}
												id="dot1"
												required
												onBlur={formik.handleBlur}
												onChange={formik.handleChange}
												inputProps={{maxLength: 4}}
												thousandSeparator={''}
												decimalSeparator={decimalSeparator}
												onValueChange={(values, sourceInfo) => {
													formik.setFieldValue('dot1', values.value)
												}}
												decimalScale={0}
												allowNegative={false}
												customInput={TextField}
												size={'small'}
												allowLeadingZeros={true}
											/>
											<FormHelperText>
												<ErrorMessage name={'dot1'}/>
											</FormHelperText>
										</FormControl>
										<FormControl
											variant="standard"
											error={
												formik.touched['dot2'] &&
												Boolean(formik.errors['dot2'])
											}
										>
											<NumericFormat
												label={t('DOT2')}
												value={formik.values.dot2}
												variant={'standard'}
												id="dot2"
												required
												onBlur={formik.handleBlur}
												onChange={formik.handleChange}
												inputProps={{maxLength: 4}}
												thousandSeparator={''}
												decimalSeparator={decimalSeparator}
												onValueChange={(values, sourceInfo) => {
													formik.setFieldValue('dot2', values.value)
												}}
												decimalScale={0}
												allowNegative={false}
												customInput={TextField}
												size={'small'}
												allowLeadingZeros={true}
											/>
											<FormHelperText>
												<ErrorMessage name={'dot2'}/>
											</FormHelperText>
										</FormControl>
										{formik.values.dimensionCheck && (
											<>
												<FormControl
													variant="standard"
													error={
														formik.touched['dot3'] &&
														Boolean(formik.errors['dot3'])
													}
												>
													<NumericFormat
														label={t('DOT3')}
														value={formik.values.dot3}
														variant={'standard'}
														id="dot3"
														required
														onBlur={formik.handleBlur}
														onChange={formik.handleChange}
														inputProps={{maxLength: 4}}
														thousandSeparator={''}
														decimalSeparator={decimalSeparator}
														onValueChange={(values, sourceInfo) => {
															formik.setFieldValue('dot3', values.value)
														}}
														decimalScale={0}
														allowNegative={false}
														customInput={TextField}
														size={'small'}
														allowLeadingZeros={true}
													/>
													<FormHelperText>
														<ErrorMessage name={'dot3'}/>
													</FormHelperText>
												</FormControl>
												<FormControl
													variant="standard"
													error={
														formik.touched['dot4'] &&
														Boolean(formik.errors['dot4'])
													}
												>
													<NumericFormat
														label={t('DOT4')}
														value={formik.values.dot4}
														variant={'standard'}
														id="dot4"
														required
														onBlur={formik.handleBlur}
														onChange={formik.handleChange}
														inputProps={{maxLength: 4}}
														thousandSeparator={''}
														decimalSeparator={decimalSeparator}
														onValueChange={(values, sourceInfo) => {
															formik.setFieldValue('dot4', values.value)
														}}
														decimalScale={0}
														allowNegative={false}
														customInput={TextField}
														size={'small'}
														allowLeadingZeros={true}
													/>
													<FormHelperText>
														<ErrorMessage name={'dot4'}/>
													</FormHelperText>
												</FormControl>
											</>
										)}
									</Box>
									{!formik.values.dimensionCheck && (
										<Box display={'flex'} flexDirection={'column'}
											 sx={{width: formik.values.dimensionCheck ? '100%' : '49%'}}>
											<Box>
												<FormControl
													variant="standard"
													error={
														formik.touched['SupplierCode2'] &&
														Boolean(formik.errors['SupplierCode2'])
													}
												>
													<TextField
														label={t('Supplier Code')}
														variant={'standard'}
														id="SupplierCode2"
														value={formik.values.SupplierCode2}
														onBlur={formik.handleBlur}
														required
														onChange={(e) => {
															formik.setFieldValue('SupplierCode2', e.target.value)
														}}
													/>
													<FormHelperText>
														<ErrorMessage name={'SupplierCode2'}/>
													</FormHelperText>
												</FormControl>
												<LoadingButton
													loading={loadingSearch}
													// loadingPosition="end"
													sx={{mt: 2, ml: 2, minWidth: '100px'}}
													onClick={() => handle2Change()}>
													{t('Search')}
												</LoadingButton>
											</Box>
											<FormControl
												variant="standard"
												error={
													formik.touched['TireBrand2'] &&
													Boolean(formik.errors['TireBrand2'])
												}
											>
												<TextField
													label={t('Tire Brand')}
													variant={'standard'}
													id="TireBrand2"
													value={formik.values.TireBrand2}
													disabled
													sx={{
														"& .MuiInputBase-input.Mui-disabled": {
															WebkitTextFillColor: "#085c1f",
														},
														"& .MuiFormLabel-root.Mui-disabled": {
															WebkitTextFillColor: "#085c1f",
														},
													}}
												/>
												<FormHelperText>
													<ErrorMessage name={'TireBrand2'}/>
												</FormHelperText>
											</FormControl>
											<FormControl
												variant="standard"
												error={
													formik.touched['Description2'] &&
													Boolean(formik.errors['Description2'])
												}
											>
												<TextField
													label={t('Description')}
													variant={'standard'}
													id="Description2"
													value={formik.values.Description2}
													disabled
													sx={{
														"& .MuiInputBase-input.Mui-disabled": {
															WebkitTextFillColor: "#085c1f",
														},
														"& .MuiFormLabel-root.Mui-disabled": {
															WebkitTextFillColor: "#085c1f",
														},
													}}
												/>
												<FormHelperText>
													<ErrorMessage name={'Description2'}/>
												</FormHelperText>
											</FormControl>
											<FormControl
												variant="standard"
												error={
													formik.touched['dot3'] &&
													Boolean(formik.errors['dot3'])
												}
											>
												<NumericFormat
													label={t('DOT3')}
													value={formik.values.dot3}
													variant={'standard'}
													id="dot3"
													onBlur={formik.handleBlur}
													onChange={formik.handleChange}
													inputProps={{maxLength: 4}}
													required
													thousandSeparator={''}
													decimalSeparator={decimalSeparator}
													onValueChange={(values, sourceInfo) => {
														formik.setFieldValue('dot3', values.value)
													}}
													decimalScale={0}
													allowNegative={false}
													customInput={TextField}
													size={'small'}
													allowLeadingZeros={true}
												/>
												<FormHelperText>
													<ErrorMessage name={'dot3'}/>
												</FormHelperText>
											</FormControl>
											<FormControl
												variant="standard"
												error={
													formik.touched['dot4'] &&
													Boolean(formik.errors['dot4'])
												}
											>
												<NumericFormat
													label={t('DOT4')}
													value={formik.values.dot4}
													variant={'standard'}
													id="dot4"
													onBlur={formik.handleBlur}
													onChange={formik.handleChange}
													inputProps={{maxLength: 4}}
													required
													thousandSeparator={''}
													decimalSeparator={decimalSeparator}
													onValueChange={(values, sourceInfo) => {
														formik.setFieldValue('dot4', values.value)
													}}
													decimalScale={0}
													allowNegative={false}
													customInput={TextField}
													size={'small'}
													allowLeadingZeros={true}
												/>
												<FormHelperText>
													<ErrorMessage name={'dot4'}/>
												</FormHelperText>
											</FormControl>
										</Box>
									)}
								</Box>
							</Box>
						</Box>
					</Box>
				</Paper>
				<Dialog
					open={Boolean(warrantyNumber)}
					onClose={handleClose}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<DialogTitle sx={{textAlign: 'center'}}>
						<Typography
							variant={'h4'}
							component={'div'}
						>
							{t('Warranty Number')}
						</Typography>
					</DialogTitle>
					<DialogContent sx={{width: 400, textAlign: 'center'}}>
						<Typography variant={'subtitle1'}>{warrantyNumber}</Typography>
						<Box sx={{display: 'flex', justifyContent: 'center', mt: 1, ml: 1}}>
							<Typography sx={{mt: 1}}>{t('Download Pdf')}</Typography>
							<ExportExcelPdf url={pdfUrl} isPdf={true}/>
						</Box>
					
					</DialogContent>
					<DialogActions>
						<Button
							variant={"contained"}
							color="primary"
							size={"small"}
							onClick={() => {
								setWarrantyNumber(null)
								history.push(getRouteUrl(ROUTE_PAGE_WARRANTIES))
							}}
							sx={{
								marginRight: 'auto'
							}}
						>
							{t('Go to warranties')}
						</Button>
						<Button
							color="success"
							size={"small"}
							variant={"contained"}
							onClick={() => {
								setWarrantyNumber(null)
								formik.resetForm()
							}}
						>
							{t('New Warranty')}
						</Button>
					</DialogActions>
				</Dialog>
			</Box>
		</FormikProvider>
	);
};

export default withPermissionHOC(NewWarranty, [Permissions.AccessGuarantees])
