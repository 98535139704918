import * as React from "react";
const PiraeusBank = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={200}
        height={150}
        viewBox="0 0 15.42 15.42"
        {...props}
    >
        <path
            d="M5.838 6.569h-.321v-.465h.339c.142 0 .233.1.233.246 0 .158-.102.219-.25.219m-.324.869v-.576h.412a.474.474 0 0 0 .483-.395.6.6 0 0 0 .011-.138c0-.064-.004-.071-.011-.115-.044-.244-.248-.392-.51-.392h-.718v1.616zm1.163-1.616h.332v1.616h-.332zm1.382.703h-.39v-.423h.406c.095 0 .184.031.219.124a.2.2 0 0 1 .015.089.2.2 0 0 1-.015.091c-.035.093-.142.12-.235.12m-.726-.703v1.616h.332v-.627h.352c.208 0 .231.089.242.257.002.1 0 .204.02.304q.005.031.024.066h.385c-.086-.075-.082-.228-.082-.228s.003-.254-.039-.388c-.026-.083-.093-.131-.173-.16.168-.062.239-.213.239-.383 0-.235-.144-.441-.392-.452q-.064-.006-.124-.005zm1.985 1.015.224-.691.228.691zm.414-1.015h-.381l-.576 1.616h.348l.109-.332h.627l.108.332h.35zm1.892 0v.282h-.813v.343h.742v.279h-.742v.414h.855v.297h-1.183V5.822zm.618 1.008c0 .213.075.359.31.359.144 0 .288-.071.31-.226q.006-.069.007-.133V5.818h.335v1.051a1 1 0 0 1-.016.171c-.044.299-.355.445-.629.445-.395 0-.651-.211-.651-.616V5.818h.335v1.013zm1.881.372c.131 0 .293-.049.293-.206 0-.361-.966-.106-.966-.716 0-.375.297-.503.627-.503.335 0 .581.16.607.512h-.319c-.02-.173-.158-.242-.322-.242-.12 0-.275.049-.275.195 0 .355.971.093.971.714 0 .37-.312.525-.645.525-.364 0-.638-.155-.674-.543h.332c.02.193.199.264.37.264M5.881 8.626h-.352V8.28h.347c.115.005.239.027.239.171 0 .137-.122.175-.235.175m-.688-.625v1.616h.724c.319 0 .592-.144.592-.496a.39.39 0 0 0-.25-.377.35.35 0 0 0 .193-.322c0-.306-.237-.421-.507-.421h-.75zm.699 1.325h-.363v-.421h.361c.148 0 .273.062.273.219 0 .155-.14.202-.27.202m1.238-.31.224-.691.228.691zm.415-1.015h-.381l-.576 1.616h.348l.109-.332h.627l.109.332h.35zm1.097 0L9.3 9.113V8.001h.326v1.616h-.343l-.658-1.11v1.11h-.328V8.001zm1.628 0v.665l.632-.665h.414l-.643.654.7.962h-.403l-.528-.738-.173.171v.567h-.332V8.001zM.68 5.84h3.795v3.803H.68z"
            fill="#1a3b73"
        />
        <path
            d="M1.155 8.325a1.6 1.6 0 0 1-.439.335l-.036.018V8.62l.009-.005C1.85 7.784.783 7.124.78 7.122l-.024-.015.017-.022.099-.131.017-.022.021.019c.187.167.31.325.383.472a.76.76 0 0 1 .074.516.9.9 0 0 1-.212.388m.954-2.487h.153l.003.022c.143.928.288 1.199.325 1.255.137-.022 1.142-.179 1.856-.102l.03.002v.128l-.031.001c-1.508.06-1.974.359-1.976.36l-.024.015-.013-.025a3 3 0 0 1-.252-.813 5.4 5.4 0 0 1-.071-.817zm2.367 2.092V8l-.02.005c-.321.082-1.036.222-1.755.068-.722-.155-1.447-.604-1.784-1.7l-.006-.02.018-.01.194-.107.033-.018.005.037c.001.006.261 2.227 3.284 1.684zm0 1.098v.09l-.036-.034-.244-.224h-.001l-.635-.536-.036-.03h.244l.005.005.69.717.005.005zm0 .101v.064h-.025l-.193-.006-.157-.006.201.209.003.004-.03.031-.001-.001-.001.001-.283-.249-.336-.021-.074-.006-.024-.002.221.23-.03.031-.293-.269a18 18 0 0 1-1.04-.152 13 13 0 0 0-.489-.077h-.002c-.738-.102-.995.255-.995.256l-.023.031-.014-.036-.096-.245-.005-.013.009-.01c.001-.001.264-.287.885-.211.158.019.407.068.709.127.261.051.562.109.879.164l-.126-.116-.001-.001-.634-.535-.045-.038h.257l.006.007.679.706a16 16 0 0 0 .453.068l-.874-.768h.262l.756.787q.162.02.318.035l.063.006.063.005h.003z"
            fill="#ecb73a"
        />
    </svg>
);
export default PiraeusBank;

