// @flow
import React from 'react';
import {Checkbox, Divider, FormControl, FormControlLabel, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";
import {PAGE_REPORTS} from "../../../../locales/pages/namespaces";
import './filter.css'

type Props = {};

const Filter = (props: Props) => {
	const {
		filter: {
			description,
			uniqueValue,
			values
		},
		handleFilters,
		filterKey,
		bgColor = 'inherit'
	} = props
	const {t} = useTranslation(PAGE_REPORTS)
	return (
		<Box className={'filter-container'} pb={0.5} sx={{backgroundColor: bgColor}}>
			<Typography
				variant={'h5'}
				component={'div'}
				className={'filter-title'}
				pl={1}
			>
				{t(description)}
			</Typography>
			<Divider/>
			<Box className={'budget-filter'} p={0.5}>
				{values.map(option => (
					<FormControl
						key={option.description}
						variant="standard"
						sx={{ml: 2, color: '#255b82'}}
					>
						<FormControlLabel
							control={
								<Checkbox
									checked={option.value}
									onChange={(evt) => {
										handleFilters({
											value: evt.target.checked,
											filter: filterKey,
											description: option.description,
											uniqueValue: uniqueValue
										})
									}}
									size={'small'}
									sx={{p: 0, mb: 0, mt: 0, mr: 0.4, ml: 1}}
								/>
							}
							label={t(option.description)}
							labelPlacement="end"
						/>
					</FormControl>
				))}
			</Box>
		</Box>
	);
};

export default Filter