// @flow
import React, {useEffect, useMemo} from 'react';
import useOrderAction from "../hooks/order/useOrderAction";
import {useHistory} from "react-router-dom";

type Props = {};

const ActiveOrderHoc = (props: Props) => {
	const {getActiveOrder} = useOrderAction()
	const history = useHistory()
	const path = useMemo(() => history.location.pathname, [history.location.pathname])
	useEffect(() => {
		getActiveOrder()
	}, [getActiveOrder, path])
	return (
		<>{props.children}</>
	);
};

export default ActiveOrderHoc