// @flow
import React from 'react';
import {Card, CardContent, CardHeader, Chip, Tooltip, Typography} from "@mui/material";
import Link from "@mui/material/Link";
import {Link as RouterLink} from "react-router-dom";
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";
import {getRouteUrl} from "../../../helpers/getRouteUrl";
import {ROUTE_PAGE_ANNOUNCEMENT} from "../../../routers/routes";
import {formatDate} from "../../../helpers/date";
import {PAGE_ANNOUNCEMENTS} from "../../../locales/pages/namespaces";
import useUserData from "../../../hooks/user/useUserData";
import type {Message} from '../../../types/message/type'
import Button from "@mui/material/Button";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Attachments from "../../../components/app/attachments/attachments";

type Props = {
	announcement: Message
};

const AnnouncementsCard = (props: Props) => {
	const {announcement, setMessageViewed, setMessageIsOpen, getMessageAttachments} = props
	const {t} = useTranslation(PAGE_ANNOUNCEMENTS)
	const {dateFormat, timeFormat} = useUserData()
	const isRead = Boolean(announcement.viewedDate);
	return (
		<Card
			variant="outlined"
			sx={{
				marginBottom: 1
			}}
			id={announcement.id}
		>
			<CardHeader
				action={
					<Tooltip title={t(announcement.isOpen ? 'Read less' : 'Read more')}>
						<Button
							onClick={() => {
								if (!isRead) {
									setMessageViewed(announcement.id)
									setMessageIsOpen(announcement.id, true)
								} else {
									setMessageIsOpen(announcement.id, !announcement.isOpen)
								}
								if (!announcement.isOpen) {
									getMessageAttachments(announcement?.id)
								}
							}}
							color={!isRead ? 'primary' : 'warning'}
						>
							{announcement.isOpen ? <ExpandLess fontSize={'large'}/> : <ExpandMore fontSize={'large'}/>}
						</Button>
					</Tooltip>
				}
				title={
					<Link
						component={RouterLink}
						to={getRouteUrl(ROUTE_PAGE_ANNOUNCEMENT, {id: announcement.id})}
					>
						{announcement.title}
					</Link>
				}
				subheader={
					<Box
						sx={{
							width: '100%',
							display: 'flex',
							justifyContent: 'space-between',
						}}
					>
						{formatDate(announcement.date, `${dateFormat} ${timeFormat}`)}
						<Chip
							label={t(announcement.type)}
							color={'primary'}
							variant={'outlined'}
							sx={{fontWeight: 'bold', minWidth: '150px'}}
							size={'small'}
						/>
					</Box>
				}
				sx={{
					alignItems: 'center',
					backgroundColor: !isRead ? '#ffa726' : 'grey.100',
					'.MuiCardHeader-action ': {
						alignSelf: 'center',
					}
				}}
			/>
			
			{announcement.isOpen && (
				<CardContent>
					<Typography variant="body1" component={'div'}>
						<div className={'announcement'} dangerouslySetInnerHTML={{__html: announcement.text}}/>
					</Typography>
					<Attachments id={announcement.id} files={announcement.files}
								 getMessageAttachments={getMessageAttachments}/>
				</CardContent>
			)}
		</Card>
	);
};

export default AnnouncementsCard