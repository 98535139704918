// @flow
import React, { useMemo } from 'react';
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { PAGE_ORDERS } from "../../../locales/pages/namespaces";
import { calculateOrderSums, convertActiveOrderProducts } from "../../../helpers/helpers";
import useUserData from "../../../hooks/user/useUserData";
import { NumericFormat } from "react-number-format";

type Props = {};

const OrderPrices = (props: Props) => {
	const { order, orderSubmitted } = props
	const { t } = useTranslation(PAGE_ORDERS)
	const { thousandSeparator, decimalSeparator, hasSecondPaymentDiscount } = useUserData()
	const orderPrices = useMemo(() => {
		if (orderSubmitted) {
			return {
				sumWithTax: order?.totalPriceWithTax,
				sumWithoutTax: order?.totalPriceWithoutTax,
				sumDiscount: order?.totalOrderDiscount,
				sumTax: order?.totalOrderTax
			}
		} else {
			const orderProducts = convertActiveOrderProducts((order.products?.length > 0) ? order.products : [])
			return calculateOrderSums(orderProducts)
		}
	}, [order.products, order?.totalOrderDiscount, order?.totalOrderTax, order?.totalPriceWithTax, order?.totalPriceWithoutTax, orderSubmitted])
	
	const orderPriceWithSecondPaymentDiscount = useMemo(() => {
		const orderProducts = convertActiveOrderProducts((order.products?.length > 0) ? order.products : [], true)
		return calculateOrderSums(orderProducts)?.sumWithTax
	}, [order.products])
	
	return (
		<Box className={'order-prices'}>
			<Box className={'price-info'}>
				<Typography component={'div'} variant={'h5'}>
					{t('Total Cost (without tax)')}
				</Typography>
				<Typography component={'div'} variant={'overline'}>
					<NumericFormat
						thousandSeparator={thousandSeparator}
						decimalSeparator={decimalSeparator}
						displayType="text"
						value={orderPrices?.sumWithoutTax}
					/> €
				</Typography>
			</Box>
			<Box className={'price-info'}>
				<Typography component={'div'} variant={'h5'}>
					{t('Total Discount')}
				</Typography>
				<Typography component={'div'} variant={'overline'}>
					<NumericFormat
						thousandSeparator={thousandSeparator}
						decimalSeparator={decimalSeparator}
						displayType="text"
						value={orderPrices?.sumDiscount}
					/> €
				</Typography>
			</Box>
			<Box className={'price-info'}>
				<Typography component={'div'} variant={'h5'}>
					{t('Total Tax')}
				</Typography>
				<Typography component={'div'} variant={'overline'}>
					<NumericFormat
						thousandSeparator={thousandSeparator}
						decimalSeparator={decimalSeparator}
						displayType="text"
						value={orderPrices?.sumTax}
					/> €
				</Typography>
			</Box>
			{hasSecondPaymentDiscount && !orderSubmitted ? (
				<>
					<Box className={'price-info'}>
						<Typography component={'div'} variant={'h5'}>
							{t('Total Cost (with tax, without payment discount)')}
						</Typography>
						<Typography component={'div'} variant={'overline'}>
							<NumericFormat
								thousandSeparator={thousandSeparator}
								decimalSeparator={decimalSeparator}
								displayType="text"
								value={orderPrices?.sumWithTax}
							/> €
						</Typography>
					</Box>
					<Box className={'price-info'}>
						<Typography component={'div'} variant={'h5'}>
							{t('Total Cost (with tax)')}
						</Typography>
						<Typography component={'div'} variant={'overline'}>
							<NumericFormat
								thousandSeparator={thousandSeparator}
								decimalSeparator={decimalSeparator}
								displayType="text"
								value={orderPriceWithSecondPaymentDiscount}
							/> €
						</Typography>
					</Box>
				</>
			) : (
				<Box className={'price-info'}>
					<Typography component={'div'} variant={'h5'}>
						{t('Total Cost (with tax)')}
					</Typography>
					<Typography component={'div'} variant={'overline'}>
						<NumericFormat
							thousandSeparator={thousandSeparator}
							decimalSeparator={decimalSeparator}
							displayType="text"
							value={orderPrices?.sumWithTax}
						/> €
					</Typography>
				</Box>
			)}
		</Box>
	);
};

export default OrderPrices