//@flow
import React, {useEffect} from 'react'
import useUserData from "../../hooks/user/useUserData";
import useUserAction from "../../hooks/user/useUserAction";
import UnauthenticatedRouter from "../../routers/unauthenticatedRouter";
import Content from "./content";
import ContentLoader from "../../components/general/contentLoader/contentLoader";
import dayjs from "dayjs";
import 'dayjs/locale/el'
import useMessageAction from "../../hooks/message/useMessageAction";
import useMessageData from "../../hooks/message/useMessageData";

const Main = () => {
	const {isLoggedIn, language, loading, selectedStore} = useUserData()
	dayjs.locale(language);
	const {getUserPermissions, getUserDetails, getUserStores} = useUserAction()
	const {getImportantAnnouncement, getMessageAttachments} = useMessageAction()
	const {importantAnnouncement} = useMessageData()
	
	useEffect(() => {
		if (isLoggedIn) {
			getUserStores()
		}
	}, [getUserStores, isLoggedIn])
	
	useEffect(() => {
		if (isLoggedIn && selectedStore) {
			getUserDetails(selectedStore)
			getUserPermissions()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getUserDetails, isLoggedIn, selectedStore?.id])
	
	useEffect(() => {
		if (isLoggedIn && selectedStore) {
			getImportantAnnouncement()
		}
	}, [getImportantAnnouncement, isLoggedIn, selectedStore])
	
	useEffect(() => {
		if (importantAnnouncement && importantAnnouncement?.id) getMessageAttachments(importantAnnouncement?.id)
	}, [getImportantAnnouncement, getMessageAttachments, importantAnnouncement])
	
	if (!(isLoggedIn && selectedStore)) return (
		<UnauthenticatedRouter/>
	)
	return !loading ? <Content/> : <ContentLoader loading={loading}/>
}

export default Main