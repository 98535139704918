import * as ActionTypes from '../../actions/types'
import {mapMultiple, preserveFieldsIfNotPresentInNew} from "../../helpers/map";
import {mapBanner} from "../../types/banner/map";
import {fromJS} from "immutable";
//TODO:: Delete mock όταν οι φόρμες διαχειριστικού είναι έτοιμες
const mock = [
	{
		id: 1,
		isActive: 1,
		Image: null,
		TextColor: 'FFFFFF',
		OrderNum: 0,
		ButtonLink: 'https://www.falkentyre.com/en',
		Description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc elit mauris, rutrum a enim id, ornare pulvinar turpis. Proin aliquet eros vel lorem vulputate iaculis. Nam porttitor nisi felis, vestibulum interdum dui volutpat eu. Donec sit amet dictum quam, eu viverra nunc',
		DescriptionEn: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc elit mauris, rutrum a enim id, ornare pulvinar turpis. Proin aliquet eros vel lorem vulputate iaculis. Nam porttitor nisi felis, vestibulum interdum dui volutpat eu. Donec sit amet dictum quam, eu viverra nunc',
		Title: 'Τίτλος Banner',
		TitleEn: 'Banner Title',
		SubTitle: 'Υπότιτλος Banner',
		SubTitleEn: 'Banner Subtitle',
		ButtonText: 'Πατήστε Εδώ!',
		ButtonTextEn: 'Click Here!',
		VideoLink: 'https://www.youtube.com/embed/TChhycI3Yzw?si=uhSGB8CWAxZe5wRl',
	},
	{
		id: 2,
		isActive: 1,
		Image: '2887.jpg',
		TextColor: 'FFFFFF',
		OrderNum: 0,
		ButtonLink: null,
		Description: null,
		Title: 'Τίτλος Banner',
		TitleEn: 'Banner Title',
		SubTitle: null,
		SubTitleEn: null,
		ButtonText: null,
		ButtonTextEn: null,
		VideoLink: null,
	},
	{
		id: 3,
		isActive: 1,
		Image: '2887.jpg',
		TextColor: '000000',
		OrderNum: 0,
		ButtonLink: 'https://www.falkentyre.com/en',
		Description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc elit mauris, rutrum a enim id, ornare pulvinar turpis. Proin aliquet eros vel lorem vulputate iaculis. Nam porttitor nisi felis, vestibulum interdum dui volutpat eu. Donec sit amet dictum quam, eu viverra nunc',
		DescriptionEn: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc elit mauris, rutrum a enim id, ornare pulvinar turpis. Proin aliquet eros vel lorem vulputate iaculis. Nam porttitor nisi felis, vestibulum interdum dui volutpat eu. Donec sit amet dictum quam, eu viverra nunc',
		Title: 'Τίτλος Banner',
		TitleEn: 'Banner Title',
		SubTitle: 'Υπότιτλος Banner',
		SubTitleEn: 'Banner Subtitle',
		ButtonText: 'Πατήστε Εδώ!',
		ButtonTextEn: 'Click Here!',
		VideoLink: 'https://www.youtube.com/embed/TChhycI3Yzw?si=uhSGB8CWAxZe5wRl',
	},
	{
		id: 4,
		isActive: 1,
		Image: null,
		TextColor: 'FFFFFF',
		OrderNum: 0,
		ButtonLink: null,
		Description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc elit mauris, rutrum a enim id, ornare pulvinar turpis. Proin aliquet eros vel lorem vulputate iaculis. Nam porttitor nisi felis, vestibulum interdum dui volutpat eu. Donec sit amet dictum quam, eu viverra nunc',
		DescriptionEn: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc elit mauris, rutrum a enim id, ornare pulvinar turpis. Proin aliquet eros vel lorem vulputate iaculis. Nam porttitor nisi felis, vestibulum interdum dui volutpat eu. Donec sit amet dictum quam, eu viverra nunc',
		Title: 'Τίτλος Banner',
		TitleEn: 'Banner Title',
		SubTitle: 'Υπότιτλος Banner',
		SubTitleEn: 'Banner Subtitle',
		ButtonText: null,
		ButtonTextEn: null,
		VideoLink: 'https://www.youtube.com/embed/TChhycI3Yzw?si=uhSGB8CWAxZe5wRl',
	},
	{
		id: 5,
		isActive: 1,
		Image: null,
		TextColor: 'FFFFFF',
		BackgroundColor: '000000',
		OrderNum: 0,
		ButtonLink: null,
		Description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc elit mauris, rutrum a enim id, ornare pulvinar turpis. Proin aliquet eros vel lorem vulputate iaculis. Nam porttitor nisi felis, vestibulum interdum dui volutpat eu. Donec sit amet dictum quam, eu viverra nunc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc elit mauris, rutrum a enim id, ornare pulvinar turpis. Proin aliquet eros vel lorem vulputate iaculis. Nam porttitor nisi felis, vestibulum interdum dui volutpat eu. Donec sit amet dictum quam, eu viverra nunc',
		DescriptionEn: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc elit mauris, rutrum a enim id, ornare pulvinar turpis. Proin aliquet eros vel lorem vulputate iaculis. Nam porttitor nisi felis, vestibulum interdum dui volutpat eu. Donec sit amet dictum quam, eu viverra nunc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc elit mauris, rutrum a enim id, ornare pulvinar turpis. Proin aliquet eros vel lorem vulputate iaculis. Nam porttitor nisi felis, vestibulum interdum dui volutpat eu. Donec sit amet dictum quam, eu viverra nunc',
		Title: 'Τίτλος Banner',
		TitleEn: 'Banner Title',
		SubTitle: 'Υπότιτλος Banner',
		SubTitleEn: 'Banner Subtitle',
		ButtonText: null,
		ButtonTextEn: null,
		VideoLink: null,
	},
	{
		id: 6,
		isActive: 1,
		Image: null,
		TextColor: 'FFFFFF',
		OrderNum: 0,
		ButtonLink: 'https://www.falkentyre.com/en',
		Description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc elit mauris, rutrum a enim id, ornare pulvinar turpis. Proin aliquet eros vel lorem vulputate iaculis. Nam porttitor nisi felis, vestibulum interdum dui volutpat eu. Donec sit amet dictum quam, eu viverra nunc',
		DescriptionEn: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc elit mauris, rutrum a enim id, ornare pulvinar turpis. Proin aliquet eros vel lorem vulputate iaculis. Nam porttitor nisi felis, vestibulum interdum dui volutpat eu. Donec sit amet dictum quam, eu viverra nunc',
		Title: 'Τίτλος Banner',
		TitleEn: 'Banner Title',
		SubTitle: null,
		SubTitleEn: null,
		ButtonText: 'Πατήστε Εδώ!',
		ButtonTextEn: 'Click Here!',
		VideoLink: null,
	},

]

const INITIAL_STATE = {
	loading: false,
	byId: {},
}

function getBanners(state) {
	return state
	.set('loading', true)
}

function getBannersSucceeded(state, action) {
	const {payload: {banners}} = action
	//TODO:: Uncomment όταν οι φόρμες διαχειριστικού είναι έτοιμες
	const mappedData = mapMultiple(banners, mapBanner)
	//TODO:: Delete όταν οι φόρμες διαχειριστικού είναι έτοιμες
	// const mappedData = mapMultiple(mock, mapBanner)
	return state
	.set('loading', false)
	.set('byId',
		state.get('byId')
		.mergeWith(
			preserveFieldsIfNotPresentInNew([]), fromJS(mappedData)
		))
}

function getBannersFailed(state, action) {
	return state
	.set('loading', false)
}

const banner = {
	initialState: INITIAL_STATE,
	handlers: {
		[ActionTypes.GET_BANNERS]: getBanners,
		[ActionTypes.GET_BANNERS_SUCCEEDED]: getBannersSucceeded,
		[ActionTypes.GET_BANNERS_FAILED]: getBannersFailed,
	}
}

export default banner
