//@flow
import React, {useMemo} from 'react';
import Box from "@mui/material/Box";
import {FormikProvider} from "formik";
import {useTranslation} from "react-i18next";
import {FILTERS} from "../../../locales/components/namespaces";
import Button from "@mui/material/Button";
import {Search} from "@mui/icons-material";
import {Checkbox, FormControl, FormControlLabel, Typography} from "@mui/material";
import './sideFilters.css'
import ContentLoader from "../../general/contentLoader/contentLoader";
import useProductData from "../../../hooks/product/useProductData";

type Props = {};

const SideFilters = (props: Props) => {
	const {t} = useTranslation(FILTERS)
	const {
		formik,
		sideFilters: filters
	} = props
	
	const countFilterValues = useMemo(() => (filters.reduce((acc, filter) => (
		acc + filter.values.length
	), 0)), [filters])
	
	const {loading} = useProductData()
	
	return !loading ? (
		<FormikProvider value={formik}>
			<Box
				sx={{
					width: '100%',
				}}
				component="form"
				noValidate
			>
				{countFilterValues > 0 ? (
					<Box
						p={1}
						className={'sideFilters'}
					>
						<Box
							pl={1}
							pr={1}
							pb={1}
							sx={{
								backgroundColor: 'grey.100'
							}}
						>
							{filters.map((filter, index) => {
								const {id, description, values} = filter
								return values.length > 0 && (
									<Box
										key={id}
									>
										<Typography
											variant={'body1'}
											component={'div'}
											p={0.3}
											pr={1}
											className={'sideFilters-title'}
										>
											<strong>{t(description)}</strong>
										</Typography>
										<Box className={'sideFilters-filter'}>
											{values.map(value => {
												const {id, description, count} = value
												return (
													<FormControl
														key={id}
														variant="standard"
													>
														<FormControlLabel
															control={
																<Checkbox
																	checked={formik.values[`value-${id}`]}
																	onChange={(evt) => {
																		formik.setFieldValue(`value-${id}`, evt.target.checked)
																	}}
																	size={'small'}
																	name={`value-${id}`}
																	sx={{p: 0}}
																/>
															}
															label={`${description} (${count})`}
															labelPlacement="end"
														/>
													</FormControl>
												)
											})}
										</Box>
									</Box>
								)
							})}
						</Box>
						<Box>
							<Button
								size={"medium"}
								variant={'contained'}
								color={'secondary'}
								fullWidth
								onClick={(evt) => formik.submitForm(evt)}
							>
								<Search/>
								{t('Search')}
							</Button>
						</Box>
					</Box>
				) : (
					<Typography
						variant={'body1'}
						component={'div'}
						className={'sideFilters-title'}
						p={1}
					>
						<strong>{t('No filters found.')}</strong>
					</Typography>
				)}
			</Box>
		</FormikProvider>
	) : (
		<ContentLoader
			loading={loading}
		/>
	)
};

export default SideFilters