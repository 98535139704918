// @flow
import React, {useState} from 'react';
import Box from "@mui/material/Box";
import {Checkbox, FormControl, FormControlLabel, Tooltip, Typography} from "@mui/material";
import {FormikProvider} from "formik";
import {useTranslation} from "react-i18next";
import {FILTERS} from "../../../locales/components/namespaces";
import Button from "@mui/material/Button";
import {
	AddOutlined,
	ClearOutlined,
	KeyboardArrowDownOutlined,
	KeyboardArrowRightOutlined,
	Search
} from "@mui/icons-material";
import TripleInputs from "../../form/tripleInputs/tripleInputs";
import Switch from '@mui/material/Switch'
import './topFilters.css'

type Props = {};

const TopFilters = (props: Props) => {
	const {
		formik,
		attributeValueIds,
		search,
		topFilters: filters,
		search1Ref,
		search2Ref,
		search3Ref,
		clearFilters
	} = props
	const {t} = useTranslation(FILTERS)
	
	const [openFilters, setOpenFilters] = useState(true)
	const [toggleInputs, setToggleInputs] = useState(search.length > 0 ? search.length : 1)

	return (
		<FormikProvider value={formik}>
			<Box
				className={'topFilters'}
				component="form"
				onSubmit={formik.handleSubmit}
				noValidate
			>
				<Box
					className={'topFilters-wrap'}
					sx={{
						maxWidth: {
							xs: '90%',
							sm: '800px',
							md: '920px'
						}
					}}
				>
					<Box
						className={'topFilters-switch'}
					>
						<Button
							variant="text"
							onClick={() => clearFilters(formik.values)}
							size={'small'}
						>
							<ClearOutlined fontSize={'small'}/>
							{t('Clear filters')}
						</Button>
						<FormControlLabel
							sx={{ml: {xs: 0, sm: 'auto'}}}
							value={t('In stock')}
							control={
								<Switch
									checked={formik.values.hasStock}
									onChange={(evt) => {
										formik.setFieldValue('hasStock', evt.target.checked)
									}}
									size={'small'}
								/>
							}
							label={t('In stock')}
							labelPlacement="end"
						/>
						<FormControlLabel
							sx={{m: 0}}
							value={t('Pending shipment arrival')}
							control={
								<Switch
									checked={formik.values.hasIncoming}
									onChange={(evt) => {
										formik.setFieldValue('hasIncoming', evt.target.checked)
									}}
									size={'small'}
								/>
							}
							label={t('Pending shipment arrival')}
							labelPlacement="end"
						/>
						<FormControlLabel
							sx={{m: 0}}
							value={t('Search by supplier code')}
							control={
								<Switch
									checked={formik.values.isAltCode}
									onChange={(evt) => {
										formik.setFieldValue('isAltCode', evt.target.checked)
									}}
									size={'small'}
								/>
							}
							label={t('Search by supplier code')}
							labelPlacement="end"
						/>
					</Box>
					<Box
						className={'topFilters-inputs'}
					>
						<TripleInputs
							formik={formik}
							toggleInputs={toggleInputs}
							setToggleInputs={setToggleInputs}
							search1Ref={search1Ref}
							search2Ref={search2Ref}
							search3Ref={search3Ref}
						/>
						<Tooltip title={t('Add search')}>
							<Button
								variant="outlined"
								className={'topFilters-buttons'}
								onClick={() => {
									setToggleInputs(prev => toggleInputs >= 1 && toggleInputs < 3 ? prev + 1 : prev)
								}}
								disabled={toggleInputs >= 3}
							>
								<AddOutlined fontSize={'small'}/>
							</Button>
						</Tooltip>
						<Tooltip title={t('Search')}>
							<Button
								variant="outlined"
								className={'topFilters-buttons'}
								type={'submit'}
							>
								<Search fontSize={'small'}/>
							</Button>
						</Tooltip>
					</Box>
					<Box>
						<Typography
							variant={'h6'}
							component={'div'}
							p={0.3}
							onClick={() => setOpenFilters(!openFilters)}
							className={'topFilters-more'}
						>
							{openFilters ? <KeyboardArrowDownOutlined className={'topFilters-more_btn'}/> :
								<KeyboardArrowRightOutlined className={'topFilters-more_btn'}/>}
							{t('More filters')}
						</Typography>
						{openFilters && (
							filters.map((filter, index) => {
								const {id, description, values} = filter
								return (
									<Box
										key={id}
										className={'topFilters-filter'}
										sx={{
											backgroundColor: index % 2 === 0 ? 'grey.100' : 'inherit',
											flexDirection: {
												xs: 'column',
												sm: 'row'
											}
										}}
									>
										<Typography
											variant={'body1'}
											component={'div'}
											className={'topFilters-filter_title'}
											sx={{
												paddingRight: {
													xs: 0,
													md: 1
												}
											}}
										>
											<strong>{t(description)}</strong>
										</Typography>
										<Box
											className={'topFilters-filter_values'}
											sx={{
												justifyContent: {
													xs: 'space-around',
													sm: 'space-between'
												}
											}}
										>
											{values.map(value => {
												const {id, description} = value
												return (
													<FormControl
														key={id}
														variant="standard"
													>
														<FormControlLabel
															control={
																<Checkbox
																	checked={formik.values[`value-${id}`]}
																	onChange={(evt) => {
																		formik.setFieldValue(`value-${id}`, evt.target.checked)
																	}}
																	size={'small'}
																	name={`value-${id}`}
																	sx={{p: 0}}
																/>
															}
															label={description}
															labelPlacement="end"
														/>
													</FormControl>
												)
											})}
										</Box>
									</Box>
								)
							})
						)}
					</Box>
				</Box>
			</Box>
		</FormikProvider>
	)
		;
};

export default TopFilters