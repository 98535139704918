export default function InputLabel(theme) {
	return {
		MuiInputLabel: {
			styleOverrides: {
				root: {},
				outlined: {}
			}
		}
	};
}
