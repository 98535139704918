import {mapMultiple} from "../../helpers/map";
import _ from "lodash";
import {fromJS} from "immutable";
import {mapWarranty} from "../../types/warranties/map";
import * as ActionTypes from "../../actions/types";

export const INITIAL_STATE = {
    loading: false,
    warrantyNumber: null,
    message:null
}

function getWarrantyDetails(state){
    return state
        .set('loading',true)
}

function getWarrantyDetailsSucceeded(state, action) {
    const mappedData = mapWarranty(action.payload)
    return state
        .set('loading', false)
        .setIn(['byId', mappedData.id], fromJS(mappedData))

}
function getWarrantyDetailsFailed(state) {
    return state
        .set('loading', false);
}

function createWarranty(state) {
    return state
        .set('loading', true)
        .set('warrantyNumber', null)
}
function createWarrantySucceeded(state, action) {
    const {GuaranteeNumber} = action.payload
    return state
        .set('loading', false)
        .set('warrantyNumber', GuaranteeNumber)
        .set('message', fromJS({
            text: 'Warranty created successfully!', variant: 'success'
        }))
}

function createWarrantyFailed(state,action) {
    const {message} = action.payload
    return state
        .set('loading', false)
        .set('warrantyNumber', null)
        .set('message', fromJS({
            text: message, variant: 'error'
        }))
}

function setWarrantyNumber(state,action){
    const {warrantyNumber} = action.payload
    return state
        .set('warrantyNumber',warrantyNumber)

}

function setWarrantyMessage(state,action){
    const message = action.payload
    return state
        .set('message', fromJS(message ? {
            text: message?.text, variant: message?.variant
        } : null))
}

const warranty = {
    initialState:INITIAL_STATE,
    handlers:{
        [ActionTypes.CREATE_WARRANTY]:createWarranty,
        [ActionTypes.CREATE_WARRANTY_SUCCEEDED]:createWarrantySucceeded,
        [ActionTypes.CREATE_WARRANTY_FAILED]:createWarrantyFailed,

        [ActionTypes.GET_WARRANTY_DETAILS]:getWarrantyDetails,
        [ActionTypes.GET_WARRANTY_DETAILS_SUCCEEDED]:getWarrantyDetailsSucceeded,
        [ActionTypes.GET_WARRANTY_DETAILS_FAILED]:getWarrantyDetailsFailed,

        [ActionTypes.SET_WARRANTY_NUMBER]:setWarrantyNumber,
        [ActionTypes.SET_WARRANTY_MESSAGE]:setWarrantyMessage,
    }
}
export default warranty