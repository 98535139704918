// @flow
import { LoadingButton } from "@mui/lab";
import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Cancel, Check } from "@mui/icons-material";
import { CONFIRMATION } from "../../../../locales/components/namespaces";

type Props = {
	open: boolean,
	handleDialog: Function
};

const Confirmation = (props: Props) => {
	const {
		open,
		handleDialog,
		title,
		onYes = () => {
		},
		onNo = () => {
		},
		yesBtnText,
		noBtnText,
		hasBtnIcons = true,
		yesAction = true,
		noAction = true,
		maxWidth = 'xs',
		loading = false
	} = props
	const { t } = useTranslation(CONFIRMATION)
	const handleClose = (evt, details) => {
		if (details === 'backdropClick') return
		handleDialog(false)
	}
	return (
		<Dialog open={open} onClose={handleClose} maxWidth={maxWidth}>
			<DialogTitle>
				<Typography
					variant={'h4'}
					component={'div'}
				>
					{t(title)}
				</Typography>
			</DialogTitle>
			<DialogContent>
				<DialogContentText component={'div'}>
					{props.children}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				{noAction && (
					<LoadingButton
						color="error"
						size={"small"}
						variant={"contained"}
						onClick={onNo}
						startIcon={hasBtnIcons ? <Cancel/> : null}
						sx={{
							marginRight: 'auto'
						}}
						loading={loading}
					>
						{noBtnText ? noBtnText : t('NO')}
					</LoadingButton>
				)}
				{yesAction && (
					<LoadingButton
						size={"small"}
						variant={"contained"}
						color={'success'}
						onClick={onYes}
						startIcon={hasBtnIcons ? <Check/> : null}
						loading={loading}
					>
						{yesBtnText ? yesBtnText : t('YES')}
					</LoadingButton>
				)}
			</DialogActions>
		</Dialog>
	)
		;
};

export default Confirmation