import * as React from "react";
const AlphaBank = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        //xmlns="http://www.w3.org/2000/svg"
        width={200}
        height={58}
        viewBox="-0.312 -0.312 49.8 11.004"
        id="svg4474"
        {...props}
    >
        <defs id="defs4476" />
        <path
            d="M0 10.381h10.38V0H0z"
            id="path4240"
            style={{
                fill: "#0d3b70",
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
            }}
        />
        <path
            d="M8.991 5.212c0 2.701 -1.548 3.808 -3.807 3.808s-3.807 -1.107 -3.807 -3.808c0 -2.7 1.549 -3.808 3.808 -3.808s3.807 1.107 3.807 3.808"
            id="path4242"
            style={{
                fill: "#ffffff",
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
            }}
        />
        <path
            d="M1.954 5.212c0 2.291 1.17 3.23 3.23 3.23 2.061 0 3.23 -0.939 3.23 -3.23s-1.169 -3.23 -3.23 -3.23c-2.06 0 -3.23 0.938 -3.23 3.23"
            id="path4244"
            style={{
                fill: "#0d3b70",
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
            }}
        />
        <path
            d="M3.645 2.672v1.086H2.615c-0.099 0.303 -0.171 0.916 -0.178 1.236h1.208v2.762c0.257 0.102 0.891 0.201 1.194 0.207v-2.116l1.814 1.878c0.205 -0.06 0.842 -0.617 0.918 -0.806l-1.794 -1.925h2.155c-0.024 -0.393 -0.1 -0.927 -0.198 -1.236H4.84V2.471c-0.242 0.012 -1.099 0.153 -1.194 0.201"
            id="path4246"
            style={{
                fill: "#ffffff",
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
            }}
        />
        <path
            d="m38.502 4.119 0.713 1.38H37.816zM38.288 3.44l-1.794 3.46h0.62l0.493 -0.922h1.822l0.481 0.922h0.647L38.805 3.44zm9.956 0 -2.097 1.68V3.44h-0.593v3.46h0.593v-1.22l0.52 -0.414 1.706 1.635h0.804l-2.1 -1.957 1.865 -1.503zm-7.241 3.46h0.518v-2.884l2.432 2.884h0.612V3.44h-0.517v2.7L41.71 3.44h-0.708zm-6.072 -2.978c0.396 0 0.606 0.14 0.606 0.403 0 0.26 -0.058 0.536 -0.772 0.536H33.568V3.922zm0.024 1.413c0.54 0 0.791 0.184 0.791 0.58 0 0.332 -0.244 0.501 -0.723 0.501h-1.445v-1.081zm-1.97 -1.896v3.46h2.153c0.598 0 1.201 -0.306 1.201 -0.99 0 -0.034 -0.032 -0.709 -0.615 -0.857 0.254 -0.091 0.41 -0.354 0.41 -0.727 0 -0.409 -0.298 -0.886 -1.138 -0.886zM29.44 4.112l0.71 1.38h-1.396zm-0.217 -0.672 -1.794 3.46h0.62l0.493 -0.926h1.821l0.481 0.926h0.647l-1.748 -3.46zm-2.832 0v1.394h-2.131V3.44h-0.591v3.46h0.591v-1.576h2.131v1.576h0.591V3.44zM21.918 3.922c0.31 0 0.642 0.218 0.642 0.542 0 0.488 -0.2 0.652 -0.791 0.652h-1.074V3.922zM20.104 3.44v3.46h0.591v-1.289h0.964c0.64 0 1.494 -0.117 1.494 -1.13 0 -0.768 -0.615 -1.041 -1.142 -1.041zm-2.756 0v3.46h2.339v-0.484h-1.748V3.44zm-2.475 0.674 0.713 1.38h-1.399zM14.655 3.44l-1.792 3.46h0.618l0.496 -0.923h1.821l0.481 0.923h0.644L15.175 3.44z"
            id="path4264"
            style={{
                fill: "#231f20",
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
            }}
        />
    </svg>
);
export default AlphaBank;
