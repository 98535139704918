// @flow
import React, {useEffect} from 'react';
import {useBannerAction} from "../../../hooks/banner/useBannerAction";
import {useBannerData} from "../../../hooks/banner/useBannerData";
import Box from "@mui/material/Box";
import ContentLoader from "../../general/contentLoader/contentLoader";
import Card from "./partials/card";
import Button from "@mui/material/Button";

type Props = {};

const Banners = (props: Props) => {
    const {getBanners} = useBannerAction()
    const {banners, loading} = useBannerData()
    useEffect(() => {
        getBanners()
    }, [getBanners])

    return !loading ? (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-evenly',
                width: '100%',
                backgroundColor: '#FFFFFF',
                mt: 1,
                mb: 1,
            }}
        >
            {banners && banners.length > 0 && banners.map((banner, index) => (
                <Card
                    backgroundColor={banner.backgroundColor}
                    length={banners.length}
                    index={index}
                    key={banner.id}
                    textColor={banner.textColor}
                    image={banner.image}
                    preText={banner.subtitle}
                    title={banner.title}
                    text={banner.description}
                    video={banner.videoLink}
                    action={
                        banner.buttonLink &&
                        <Button
                            component={"a"}
                            target={"_blank"}
                            href={banner.buttonLink}
                            variant={'contained'}
                            color={'secondary'}
                        >
                            <span style={{color: banner.textColor}}>{banner.buttonText}</span>
                        </Button>
                    }
                />
            ))}
        </Box>
    ) : (
        <ContentLoader loading={loading}/>
    )
};

export default Banners