// @flow
import React, {useState} from "react";
import {FormikProps} from "formik";
import {IconButton, InputAdornment, TextField} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";

type Props = {
	name: string,
	formik: FormikProps,
	required?: boolean,
	allowBrowserAutocomplete?: boolean,
	otherProps?: Object
};

const PasswordInput = (props: Props) => {
	const {
		formik,
		allowBrowserAutocomplete,
		name,
		required,
		otherProps = {}
	} = props
	const [showPassword, setShowPassword] = useState(false);
	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};
	return (
		<TextField
			name={name}
			required={required}
			type={showPassword ? 'text' : 'password'}
			value={formik.values.password}
			onChange={formik.handleChange}
			onBlur={formik.handleBlur}
			sx={{
				'& .MuiOutlinedInput-root': {
					':focus-within': {border: '0.0px solid #ffffff !important'},
					bgcolor: "background.paper"
				},
			}}
			{...otherProps}
			inputProps={{
				...!allowBrowserAutocomplete && {
					autoComplete: 'new-password',
					form: {
						autoComplete: 'off',
					},
				}
			}}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<IconButton
							aria-label="toggle password visibility"
							onClick={handleClickShowPassword}
							onMouseDown={handleMouseDownPassword}
							edge="end"
						>
							{showPassword ? <VisibilityOff/> : <Visibility/>}
						</IconButton>
					</InputAdornment>
				)
			}}
		/>
	);
};

export default PasswordInput