import * as Namespaces from './namespaces'
import {AnnouncementTypes} from "../../helpers/constants";

const locales = {
	[Namespaces.PAGE_LOGIN]: {
		el: {
			'Connection': 'Σύνδεση',
			'Enter your username': 'Εισάγετε το όνομα χρήστη',
			'Password': 'Κωδικός',
			'Login': 'Είσοδος',
			'required': 'Απαιτείται',
			'Forgot password? Click here.': 'Ξεχάσατε τον κωδικό σας; Πατήστε εδώ.',
			'Get your daily code.': 'Αποστολή κωδικού ημέρας.',
			'Daily password': 'Κωδικός ημέρας',
			'Get you daily code': 'Αποστολή κωδικού ημέρας',
			'get_daily_code': 'Για να λάβετε τον κωδικό ημέρας, παρακαλώ εισάγετε το <strong>email του χρήστη σας</strong> εδώ.<br/> Στη συνέχεια, χρησιμοποιήστε τον κωδικό που θα λάβετε στο email σας για να εισέλθετε στο b2b.',
			'Cancel': 'Ακύρωση',
			'Send Code': 'Αποστολή Κωδικού',
			'Password reset': 'Ανάκτηση Κωδικού',
			'reset_password': 'Για να δηλώσετε νέο κωδικό, παρακαλώ εισάγετε το email σας εδώ. Θα λάβετε στο email σας έναν εξαψήφιο κωδικό. <br/> Χρησιμοποιείστε τον εξαψήφιο κωδικό για να δηλώσετε τον νέο κωδικό σας.',
			'Send 6 digit Code': 'Αποστολή εξαψήφιου κωδικού',
			"Not Found": "Το email που καταχωρήθηκε δεν υπάρχει.",
			'daily_code_success': 'Ο ημερήσιος κωδικός έχει σταλεί στο email σας.',
			'reset_success': 'Ο κωδικός σας έχει αλλάξει επιτυχώς. Προχωρήστε στη σύνδεση με τον νέο κωδικό σας.',
			'request_new_pass_success': 'Ο εξαψήφιος κωδικός έχει αποσταλεί επιτυχώς στο email σας. Αν δεν βλέπετε το email, παρακαλώ ελέγξτε τους φακέλους των SPAM ή JUNK emails.',
			'The six digit number has expired! Please request new six digit code and try again!': 'Ο εξαψήφιος κωδικός έχει λήξει! Παρακαλώ ζητήστε νέο εξαψήφιο κωδικό και ξανά προσπαθήστε!',
			'Password must be between 7-16 latin characters and contain at least 3 of the following. -Upper case character -Lower case character -Numeric digit -Special character': 'Ο κωδικός πρόσβασης πρέπει να αποτελείται από 7-16 λατινικούς χαρακτήρες και να περιέχει τουλάχιστον 3 από τα ακόλουθα. -Κεφάλαιο -Πεζό χαρακτήρα -Αριθμητικό ψηφίο -Ειδικός χαρακτήρας',
			'Passwords must match': 'Οι κωδικοί πρέπει να ταιριάζουν',
			'Six digit code': 'Εξαψήφιος κωδικός',
			'New password': 'Νέος κωδικός',
			'Confirm password': 'Επαλήθευση κωδικού',
			'Submit New Password': 'Υποβολή Νέου Κωδικού',
			'Send 6 Digit Code': 'Αποστολή Εξαψήφιου Κωδικού',
			'Select address': 'Επιλογή διεύθυνσης',
			'Address': 'Διεύθυνση',
			'Connection to API failed!': 'Η επικοινωνία με το API απέτυχε!',
			'Daily password is incorrect or it has expired.': 'Ο κωδικός ημέρας είναι λάθος ή έχει λήξει.',
			'Insert a valid email': 'Εισάγετε ένα έγκυρο email',
			'Wrong 6 digit password!': 'Μη έγκυρος ή ληγμένος εξαψήφιος κωδικός!',
			'Enter your email': 'Εισαγωγή email',
		},
		en: {
			"Not Found": "Email not found.",
			'daily_code_success': 'The daily code has been sent to your email..',
			'reset_success': 'Password reset successful. Please proceed to login.',
			'request_new_pass_success': 'The 6 digit code was successfully send to your email. If you cannot find this email, please check SPAM or JUNK folders in your email provider.',
			'required': 'Required',
		}
	},
	[Namespaces.PAGE_HOME]: {
		el: {
			'Username': 'Όνομα Χρήστη',
			'My Addresses': 'Οι διευθύνσεις μου',
			'My Profile': 'Το Προφίλ μου'
		},
		en: {}
	},
	[Namespaces.PAGE_RETURNS]: {
		el: {
			'In Progress': 'Σε Επεξεργασία',
			'Submitted': 'Εγκεκριμένη',
			'Canceled': 'Ακυρωμένη',
		},
		en: {}
	},
	[Namespaces.PAGE_ORDERS]: {
		el: {
			'Processing order...': 'Πραγματοποιείται επεξεργασία παραγγελίας...',
			'In Progress': 'Σε Επεξεργασία',
			'Submitted': 'Υποβεβλημένη',
			'Order Preview': 'Προεπισκόπηση Παραγγελίας',
			'Notes': 'Σημειώσεις',
			'Please add at least one product in your cart!': 'Παρακαλώ προσθέστε τουλάχιστον ένα προϊόν στο καλάθι σας!',
			'max_allowed_products': 'Μπορείτε να προσθέσετε μέχρι {{maxAllowedProductsInCart}} προϊόντα στο καλάθι σας. Παρακαλώ μεταβάλλετε καταλλήλως την ποσότητα που επιθυμείτε για να συνεχίσετε.',
			'No products found in your cart!': 'Δεν βρέθηκαν προϊόντα στο καλάθι σας!',
			'Order notes...': 'Σχόλια παραγγελίας...',
			'SAVE ORDER': 'ΑΠΟΘΗΚΕΥΣΗ ΠΑΡΑΓΓΕΛΙΑΣ',
			'This is a completed order! Check order details bellow!': 'Η παραγγελία αυτή έχει ολοκληρωθεί! Μπορείτε να δείτε τα στοιχεία της παραγγελίας παρακάτω!',
			'See Order Details': 'Προβολή Στοιχείων Παραγγελίας',
			'SEND ORDER': 'ΑΠΟΣΤΟΛΗ ΠΑΡΑΓΓΕΛΙΑΣ',
			'Thank you for your order!': 'Ευχαριστούμε για την παραγγελία σας!',
			'Code': 'Κωδικός',
			'Status': 'Κατάσταση Παραγγελίας',
			'Total Cost (without tax)': 'Σύνολο (χωρίς ΦΠΑ)',
			'Total Discount': 'Σύνολο Έκπτωσης',
			'Total Tax': 'Σύνολο Φόρου',
			'Total Cost (with tax)': 'Σύνολο (με ΦΠΑ, μετρητοίς & εναλλακτική διαχείριση)',
			'Total Cost (with tax, without payment discount)': 'Σύνολο (με ΦΠΑ & εναλλακτική διαχείριση)',
			'Creation Date': 'Ημερομηνία Δημιουργίας',
			'Type': 'Τύπος',
			'Invoice Code': 'Κωδικός Τιμολογίου',
			'B2B Code': 'Κωδικός Β2Β',
			'Invoice Date': 'Ημερομηνία Τιμολόγισης',
			'Submission Date': 'Ημερομηνία Υποβολής',
			'Transfer Date': 'Ημερομηνία Μεταφοράς',
			'My Orders': 'Οι Παραγγελίες μου',
			'Add to cart': 'Προσθήκη στο καλάθι',
			'EAN CODE': 'ΚΩΔΙΚΟΣ EAN',
			'Alternative Code': 'Εναλλακτικός Κωδικός',
			'Product images and info': 'Εικόνες και πληροφορίες είδους',
			'EPREL - European Product Registry for Energy Labelling': 'EPREL - Ευρωπαϊκό μητρώο προϊόντων για την ενεργειακή σήμανση',
			'OEM Constructor': 'OEM Κατασκευαστής',
			'OEM Vehicle Type': 'OEM Τύπος Οχήματος',
			'Wholesale Value': 'Τιμή Χονδρικής',
			'Invoice Value': 'Τιμή Επί Τιμολογίου',
			'Final Cost (with payment discount)': 'Τελικό Κόστος (μετρητοίς)',
			'Env. Tax': 'Περιβαλλοντική Εισφορά',
			'Discount (per piece)': 'Προσφορά (ανά τεμάχιο)',
			'Remove from cart': 'Αφαίρεση από το καλάθι',
			'Final piece value': 'Τελική τιμή μονάδας',
			'Final total value': 'Τελική τιμή συνόλου',
			'Proceed to order submission': 'Μετάβαση στην υποβολή παραγγελίας',
			'If you wish to preview your order press YES. Press NO if you wish to continue your product search.': 'Εάν επιθυμείτε να μεταβείτε στην προβολή παραγγελίας πατήστε ΝΑΙ. Πατήστε ΟΧΙ εάν θέλετε να συνεχίσετε την αναζήτηση προϊόντων.',
			'DELETE': 'ΔΙΑΓΡΑΦΗ',
			'Your order will be completely deleted. Please press YES to confirm.': 'Η παραγγελία σας θα διαγραφεί. Πατήστε ναι για επιβεβαίωση.',
			'Delete order confirmation': 'Επιβεβαίωση διαγραφής παραγγελίας',
			'Pending Orders': 'Παραγγελίες Εκκρεμοτήτων',
			'Date': 'Ημερομηνία',
			'FilePath': 'PDF',
			'Invoices': 'Παραστατικά',
			'Place at least one product in your cart to submit your order!': 'Εισάγετε τουλάχιστον ένα προϊόν στο καλάθι σας για να ολοκληρώσετε την παραγγελίας σας!',
			'These products are no longer available and have been removed from your cart!': 'Τα παρακάτω προϊόντα δεν είναι πια διαθέσιμα και έχουν αφαιρεθεί από το καλάθι σας!',
			'Description': 'Περιγραφή Είδους',
			'Warehouses': 'Αποθήκη',
			'Availability': 'Διαθεσιμότητα',
			'Prices': 'Τιμές',
			'Order Quantity': 'Ποσότητα Παραγγελίας',
			'Έχει εκτελεστεί Πλήρως': 'Έχει εκτελεστεί Πλήρως',
			'Canceled': 'Ακυρωμένη',
			'All invoice types': 'ΟΛΟΙ ΟΙ ΤΥΠΟΙ ΠΑΡΑΣΤΑΤΙΚΩΝ',
			'Search': 'Αναζήτηση',
			'Invoice date range': 'Εύρος ημερομηνίας τιμολόγησης',
			'Proceed to cart': 'Μετάβαση στο καλάθι',
			'Continue': 'Συνέχιση αγορών',
			'Return to home': 'Επιστροφή στην αρχική',
			'Total (with tax)': 'Σύνολο (με ΦΠΑ)',
			
			'Quick view': 'Γρήγορη προβολή',
			'Invoice': 'Παραστατικό',
			'Order': 'Παραγγελία',
			'Actions': 'Ενέργειες',
			'CODE': 'ΚΩΔΙΚΟΣ',
			'NAME': 'ΕΠΩΝΥΜΙΑ',
			'CITY': 'ΠΟΛΗ',
			'ADDRESS': 'ΔΙΕΥΘΥΝΣΗ',
			'PHONE NUMBER': 'ΤΗΛ.',
			'VAT REG NO': 'Α.Φ.Μ.',
			'PAYMENT TYPE': 'ΤΡΟΠΟΣ ΠΛΗΡΩΜΗΣ',
			'TRANSACTION TYPE': 'ΤΡΟΠΟΣ ΑΠΟΣΤΟΛΗΣ',
			'CUSTOMER DETAILS': 'ΣΤΟΙΧΕΙΑ ΣΥΝΑΛΛΑΣΣΟΜΕΝΟΥ',
			'TRANSACTION DETAILS': 'ΣΤΟΙΧΕΙΑ ΣΥΝΑΛΛΑΓΗΣ',
			'INVOICE TYPE': 'ΤΥΠΟΣ ΠΑΡΑΣΤΑΣΤΙΚΟΥ',
			'NUMBER': 'ΑΡΙΘΜΟΣ',
			'DATE': 'ΗΜΕΡΟΜΗΝΙΑ',
			'START TIME': 'ΩΡΑ ΕΝΑΡΞΗΣ',
			'JOB': 'ΕΠΑΓΓΕΛΜΑ',
			'RELATED INVOICE': 'ΣΧΕΤΙΚΑ ΠΑΡΑΣΤΑΤΙΚΑ',
			'ETIOLOGY': 'ΑΙΤΙΟΛΟΓΙΑ',
			'UoM': 'ΜΜ',
			'Quantity': 'Ποσότητα',
			'Price': 'Τιμή',
			'Discount': 'Εκπτώσεις',
			'PCT Value': 'Ποσοστ.',
			'Net value': 'Καθ. Αξία',
			'VAT': 'ΦΠΑ',
			'VALUE BEFORE DISCOUNT': 'ΑΞΙΑ ΠΡΟ ΕΚΠΤΩΣΗΣ',
			'SALE': 'ΕΚΠΤΩΣΗ',
			'NET VALUE': 'ΚΑΘΑΡΗ ΑΞΙΑ',
			'VAT VALUE': 'ΑΞΙΑ Φ.Π.Α.',
			'CHARGES': 'ΕΠΙΒΑΡΥΝΣΕΙΣ',
			'VAT CHARGES': 'ΦΠΑ ΕΠΙΒΑΡΥΝΣΕΩΝ',
			'TOTAL VALUE': 'ΠΛΗΡΩΤΕΟ ΠΟΣΟ',
			'COMMENTS': 'ΠΑΡΑΤΗΡΗΣΕΙΣ',
			'INVOICE TOTALS': 'ΣΥΝΟΛΑ ΠΑΡΑΣΤΑΤΙΚΟΥ',
			'TAX OFFICE': 'Δ.Ο.Υ.',
			'PAYMENT TYPE DISCOUNT': 'ΕΚΠΤΩΣΗ ΤΡΟΠΟΥ ΠΛΗΡΩΜΗΣ',
			'Value': 'Αξιακή',
			'Contribution': 'Εισφορά',
			'Contribution VAT': 'ΦΠΑ Εισφοράς',
			'Close': 'Κλείσιμο',
			'Export PDF': 'Εξαγωγή σε PDF',
			'TRANSACTION PLACE': 'ΤΟΠΟΣ ΑΠΟΣΤΟΛΗΣ',
			'Accompanying Return Document': 'Συνοδευτικό Παραστατικού Επιστροφής',
			'Supplier Code': 'Κωδ. Προμηθευτή',
			'Do you wish to finalize your order?': 'Θέλετε να οριστικοποιήσετε την υποβολή της παραγγελίας σας;',
			'Tire codes in pending order': "Έχετε ήδη τους παρακάτω κωδικούς σε παραγγελίες εκκρεμοτήτων.",
			'Final Submission': 'Τελική Υποβολή',
			'Cancel': 'Ακύρωση',
			'Tire code': 'Κωδικός ελαστικού',
			'Pending number': 'Σύνολο σε εκκρεμότητα'
		},
		en: {
			'max_allowed_products': 'You can add up to {{maxAllowedProductsInCart}} products in your cart. Please adjust products quantity accordingly to proceed.',
			'FilePath': 'PDF',
			'Έχει εκτελεστεί Πλήρως': 'Fully Executed',
		}
	},
	[Namespaces.PAGE_SEARCH_RESULTS]: {
		el: {
			'Prices Calculations': 'Υπολογισμός Τιμών Αγοράς Και Πώλησης',
			'Prices Per Piece': 'Τιμές Τεμαχίου',
			'Calculated Price': 'Υπολογισμός Τιμών Αγοράς',
			'Calculated Sale Price': 'Υπολογισμός Τιμών Πώλησης',
			'Without Tax': 'Χωρίς Φ.Π.Α',
			'With Tax': 'Με Φ.Π.Α',
			'Wholesale': 'Χονδρικής',
			'On Invoice': 'Επί Τιμολογίου',
			'Final Cost (payment discount & env. tax': 'Τελικό Κόστος (μετρητοίς & οικ. εισφορά)',
			'Pieces': 'Τεμάχια',
			'Clear': 'Καθαρισμός',
			'Desired Profit Rate (on total pieces)': 'Επιθυμητό Ποσοστό Κέρδους (στο σύνολο τεμαχίων)',
			'Desired Net Profit (in total pieces)': 'Επιθυμητό Καθαρό Κέρδος (στο σύνολο τεμαχίων)',
			'Sale Value': 'Τιμή Πώλησης',
			'Profit Margin (%)': 'Περιθώριο Κέρδους % (Margin)',
			'Close': 'Κλείσιμο',
			'Profit': 'Κέρδος',
			'Display values with tax': 'Προβολή τιμών με Φ.Π.Α',
			'Display values with env. tax': 'Προβολή τιμών με οικολογική εισφορά',
			'Display values with payment discount': 'Προβολή τιμών μετρητοίς',
			'additional_discount': 'Επιπλέον {{paymentTypeDiscount}}% έκπτωση για παραγγελίες που πληρώνονται άμεσα',
			'New shipment arrival': 'Αναμενόμενη νέα παραλαβή',
			'Failed to save your selections!': 'Αποτυχία αποθήκευσης των επιλογών σας!',
			'Failed to load your options.': 'Αποτυχία φόρτωσης των επιλογών σας.'
		},
		en: {
			'additional_discount': 'Additional {{paymentTypeDiscount}}% discount for on-time payment obligations'
		}
	},
	[Namespaces.PAGE_WARRANTIES]: {
		el: {
			'Warranty Number': 'Αριθμός Εγγύησης',
			'Name': 'Ονοματεπώνυμο',
			'Activation Date': 'Ημερ.Ενεργοποίησης',
			'End Date': 'Ημερ.Λήξης',
			'Warranty registration shop': 'Κατάστημα',
			'Number of tires': 'Αρ. ελαστικών',
			'Car brand': 'Μάρκα αυτοκινήτου',
			'Actions': 'Ενέργειες',
			'Details': 'Λεπτομέρειες',
			'Approval Request': 'Αίτημα Έγκρισης',
			'Download Pdf': 'Λήψη Pdf',
			'Open Pdf': 'Άνοιγμα Pdf',
			'Final Pdf': 'Tελικό Pdf',
			'Initial Pdf': 'Αρχικό Pdf',
			'New warranty': 'Νέα Εγγύηση',
			'Warranties': 'Εγγυήσεις',
			'Supplier Code': 'Κωδ.Προμηθευτή',
			'Search': 'Αναζήτηση',
			'Search by tire supplier code': 'Αναζήτηση με κωδικό προμηθευτή ελαστικού',
			'Search by warranty number': 'Αναζήτηση με αριθμό εγγύησης',
			'Successfully downloaded PDF!': 'Το αρχείο pdf λήφθηκε επιτυχώς',
			'Unable to download. PDF not found.!': 'Δεν έχει εκδοθεί αρχείο pdf!',
			'Failed to download PDF!': 'Αποτυχία λήψης αρχείου pdf!',
			'License Pl.': 'Αρ.Πινακίδας',
			'Tire Dimensions': 'Διαστάσεις ελαστικού',
			'Status': 'Κατάσταση',
			'Initialized': 'Αναμονή Ενεργοποίησης',
			'Activated': 'Ενεργή',
			'Pending Approval': 'Αναμονή Έγκρισης',
			'Approved': 'Εγκεκριμένη',
			'Rejected': 'Απορρίφθηκε',
			'Activation Time Expired': 'Ανενεργή',
			'Expired': 'Λήξη',
			'Returns': 'Επιστροφές',
			'New Return': 'Νέα Επιστροφή',
			'Date': 'Ημερ/νία',
			'Quantity': 'Ποσότητα',
			'Accepted Quantity': 'Εγκριθείσα Ποσότητα',
			'Tire Brand': 'Μάρκα ελαστικού',
			'Description': 'Περιγραφή',
			'Submit': 'Καταχώριση',
			'Return submitted!': 'Επιτυχία καταχώρισης επιστροφής!',
			'Failed to submit return!': 'Αποτυχία καταχώρισης επιστροφής!',
			'Guarantees': 'Πρόγραμμα Ασφάλισης Ελαστικών',
			'Clear': 'Καθαρισμός',
			'Return': 'Επιστροφή',
			'Delete': 'Διαγραφή',
			'Add': 'Προσθήκη',
			'Comments': 'Σχόλια',
			'Accident Report': 'Δήλωση Συμβάντος',
			'Store': 'Κατάστημα',
			'Send': 'Αποστολή',
			'Tire selection': 'Επιλογή ελαστικού',
			'Tire Problem': 'Πρόβλημα ελαστικού',
			'Close': 'Κλείσιμο',
			'Driver License': 'Άδεια οδήγησης',
			'Km': 'Χιλιόμετρα',
			'License Plate': 'Αριθμός Πινακίδας',
			'Are you sure you want to send the accident report?': 'Να σταλθούν τα στοιχεία της δήλωσης;',
			'Cancel': 'Ακύρωση',
			'Required': 'Απαιτείται',
			'Accident report submitted successfully!': 'Η δήλωση ατυχήματος καταχωρήθηκε επιτυχώς!',
			'Error submitting accident report!': 'Η καταχώρηση δήλωσης ατυχήματος απέτυχε!',
			'Save': 'Αποθήκευση',
			'Please fill all dot fields. Dot must be a number of exact 4 digits.': 'Παρακαλώ συμπληρώστε όλα τα dots. Κάθε dot πρέπει να είναι αριθμός με ακριβώς 4 ψηφία.',
			'You cannot add more that 10 tires for single code.': 'Δεν μπορείτε να προσθέσετε πάνω από 10 ελαστικά στον ίδιο κωδικό.',
			'Minimum allowed quantity is 1': 'Η ελάχιστη επιτρεπόμενη ποσότητα είναι 1',
			'Add new tire code': 'Προσθήκη νέου κωδικού ελαστικού',
			'Code': 'Κωδικός',
			'Accident Report Date': 'Ημερ. Δήλωσης',
			"We don't support this Tires.": 'Δεν υποστηρίζουμε αυτά τα λάστιχα!',
			'item_not_bought': 'Το ελαστικό δεν έχει αγοραστεί τις τελευταίες {{dayLimitForReturns}} ημέρες!',
			'At least one is required!': 'Απαιτείται τουλάχιστον 1!',
			'Warehouse': 'Αποθήκη',
			'item_not_found': 'Ο κωδικός προμηθευτή που πληκτρολογήσατε δεν αντιστοιχεί σε κάποιο ελαστικό.',
			'CODE': 'ΚΩΔΙΚΟΣ',
			'NAME': 'ΕΠΩΝΥΜΙΑ',
			'CITY': 'ΠΟΛΗ',
			'ADDRESS': 'ΔΙΕΥΘΥΝΣΗ',
			'PHONE NUMBER': 'ΤΗΛ.',
			'VAT REG NO': 'Α.Φ.Μ.',
			'PAYMENT TYPE': 'ΤΡΟΠΟΣ ΠΛΗΡΩΜΗΣ',
			'TRANSACTION TYPE': 'ΤΡΟΠΟΣ ΑΠΟΣΤΟΛΗΣ',
			'CUSTOMER DETAILS': 'ΣΤΟΙΧΕΙΑ ΣΥΝΑΛΛΑΣΣΟΜΕΝΟΥ',
			'TRANSACTION DETAILS': 'ΣΤΟΙΧΕΙΑ ΣΥΝΑΛΛΑΓΗΣ',
			'INVOICE TYPE': 'ΤΥΠΟΣ ΠΑΡΑΣΤΑΣΤΙΚΟΥ',
			'NUMBER': 'ΑΡΙΘΜΟΣ',
			'DATE': 'ΗΜΕΡΟΜΗΝΙΑ',
			'START TIME': 'ΩΡΑ ΕΝΑΡΞΗΣ',
			'JOB': 'ΕΠΑΓΓΕΛΜΑ',
			'RELATED INVOICE': 'ΣΧΕΤΙΚΑ ΠΑΡΑΣΤΑΤΙΚΑ',
			'ETIOLOGY': 'ΑΙΤΙΟΛΟΓΙΑ',
			'UoM': 'ΜΜ',
			'Price': 'Τιμή',
			'Discount': 'Εκπτώσεις',
			'PCT Value': 'Ποσοστ.',
			'Net value': 'Καθ. Αξία',
			'VAT': 'ΦΠΑ',
			'VALUE BEFORE DISCOUNT': 'ΑΞΙΑ ΠΡΟ ΕΚΠΤΩΣΗΣ',
			'SALE': 'ΕΚΠΤΩΣΗ',
			'NET VALUE': 'ΚΑΘΑΡΗ ΑΞΙΑ',
			'VAT VALUE': 'ΑΞΙΑ Φ.Π.Α.',
			'CHARGES': 'ΕΠΙΒΑΡΥΝΣΕΙΣ',
			'VAT CHARGES': 'ΦΠΑ ΕΠΙΒΑΡΥΝΣΕΩΝ',
			'TOTAL VALUE': 'ΠΛΗΡΩΤΕΟ ΠΟΣΟ',
			'COMMENTS': 'ΠΑΡΑΤΗΡΗΣΕΙΣ',
			'INVOICE TOTALS': 'ΣΥΝΟΛΑ ΠΑΡΑΣΤΑΤΙΚΟΥ',
			'TAX OFFICE': 'Δ.Ο.Υ.',
			'PAYMENT TYPE DISCOUNT': 'ΕΚΠΤΩΣΗ ΤΡΟΠΟΥ ΠΛΗΡΩΜΗΣ',
			'Value': 'Αξιακή',
			'Contribution': 'Εισφορά',
			'Contribution VAT': 'ΦΠΑ Εισφοράς',
			'Export PDF': 'Εξαγωγή σε PDF',
			'TRANSACTION PLACE': 'ΤΟΠΟΣ ΑΠΟΣΤΟΛΗΣ',
			'Accompanying Return Document': 'Συνοδευτικό Παραστατικού Επιστροφής'
		},
		en: {
			'item_not_bought': 'This item has not been bought in the last {{dayLimitForReturns}} days.',
			"We don't support this Tires.": "We don't support these tires.",
			'item_not_found': 'Supplier code not found.',
		}
	},
	[Namespaces.PAGE_NEW_WARRANTY]: {
		el: {
			'Create New Warranty': 'Εισαγωγή νέας εγγύησης',
			'Product Details': 'Στοιχεία προϊόντος',
			'Publish Date': 'Ημ.Έκδοσης',
			'Expiration Date': 'Ημ.Λήξης',
			'Vehicle Details': 'Στοιχεία οχήματος',
			'Car Brand': 'Μάρκα',
			'Car Model': 'Μοντέλο',
			'License Pl.': 'Αρ.Κυκλοφορίας',
			'CC': 'Κυβ.Εκατοστά',
			'Km': 'Χιλιόμετρα',
			'Owner Details': 'Στοιχεία ιδιοκτήτη',
			'Name': 'Όνομα',
			'Surname': 'Επίθετο',
			'Address': 'Διεύθυνση',
			'Zipcode': 'Ταχ.Κώδικας',
			'City': 'Περιοχή',
			'Phone': 'Τηλ.Επικοινωνίας',
			'Mobile': 'Κινητό',
			'Tire Selection': 'Επιλογή ελαστικών',
			'Tire Brand': 'Μάρκα',
			'Type': 'Τύπος',
			'D/T': 'Δ/Τ',
			'Same dimensions with set 1': 'Ίδιες διαστάσεις με το 1ο σετ',
			'|F| Car Brand': '|F| Μάρκα',
			'Width': 'Πλάτος',
			'Line': 'Σειρά',
			'Rim': 'Ζάντα',
			'Speed indicator': 'Δείκτης Ταχύτητας',
			'Car Sole': 'Πέλμα',
			'|B| Car Brand': '|B| Μάρκα',
			'Warranty Details': 'Λεπτομέρειες Εγγύησης',
			'Warranty Number': 'Αριθμός Εγγύησης',
			'Activation Date': 'Ημερ.Ενεργ.',
			'End Date': 'Ημερ.Λήξης',
			'Description': 'Περιγραφή',
			'Supplier Code': 'Κωδ.Προμηθευτή',
			'Search': 'Αναζήτηση',
			'Warranty created successfully!': 'Η εγγύηση καταχωρήθηκε επιτυχώς!',
			'Wrong code!': 'Λάθος κωδικός!',
			'New Warranty': 'Νέα εγγύηση',
			'Go to warranties': 'Επιστροφή στις εγγυήσεις',
			'Download Pdf': 'Λήψη Pdf',
			'Save': 'Αποθήκευση',
			'Cancel': 'Ακύρωση',
			'Required': 'Απαιτείται',
			'Successfully downloaded PDF!': 'Το αρχείο pdf λήφθηκε επιτυχώς',
			'Unable to download. PDF not found.!': 'Δεν έχει εκδοθεί αρχείο pdf!',
			'Failed to download PDF!': 'Αποτυχία λήψης αρχείου pdf!',
			'At least one dot is required': 'Απαιτείται η συμπλήρωση τουλάχιστον ενός Dot',
			"This item isn't bought in the last 6 months.": 'Δεν επιτρέπεται καταχώρηση εγγύησης στο συγκεκριμένο ελαστικό. Δεν υπάρχουν αγορές στους τελευταίους 6 μήνες.',
			'Max 4 numbers': 'Μέχρι 4 ψηφία',
			'Receipt': 'Απόδειξη',
			"We don't support this Tires.": 'Δεν υποστηρίζουμε αυτά τα λάστιχα!',
			'item_not_bought': 'Το ελαστικό δεν έχει αγοραστεί τους τελευταίους {{monthLimitForWarranties}} μήνες!',
			
		},
		en: {
			'item_not_bought': 'This item has not been bought in the last {{monthLimitForWarranties}} months.',
			"We don't support this Tires.": "We don't support these tires."
		}
	},
	[Namespaces.PAGE_ANNOUNCEMENTS]: {
		el: {
			'Collapse All': 'Κλείσιμο Όλων',
			'Expand All': 'Ανάπτυξη Όλων',
			'Latest Announcements': 'Τελευταία Νέα',
			['announcement_type_' + AnnouncementTypes.All]: 'ΟΛΑ ΤΑ ΝΕΑ',
			['announcement_type_' + AnnouncementTypes.Alert]: 'ΕΙΔΟΠΟΙΗΣΗ',
			['announcement_type_' + AnnouncementTypes.Warning]: 'ΠΡΟΕΙΔΟΠΟΙΗΣΗ',
			['announcement_type_' + AnnouncementTypes.Error]: 'ΣΦΑΛΜΑ',
			['announcement_type_' + AnnouncementTypes.Info]: 'ΠΛΗΡΟΦΟΡΙΕΣ',
			['announcement_type_' + AnnouncementTypes.Memo]: 'ΥΠΟΜΝΗΜΑ',
			['announcement_type_' + AnnouncementTypes.Report]: 'ΑΝΑΦΟΡΑ',
			['announcement_type_' + AnnouncementTypes.Eshop]: 'ESHOP',
			['announcement_type_' + AnnouncementTypes.Company]: 'ΕΤΑΙΡΙΚΑ ΝΕΑ',
			['announcement_type_' + AnnouncementTypes.Newsletter]: 'NEWSLETTER',
			read: 'ΔΙΑΒΑΣΜΕΝΑ',
			unread: 'ΑΔΙΑΒΑΣΤΑ',
			Attachments: 'Συνημμένα',
			Announcements: 'Ανακοινώσεις',
			'READ MORE...': 'ΔΙΑΒΑΣΤΕ ΠΕΡΙΣΣΟΤΕΡΑ...'
		},
		en: {
			['announcement_type_' + AnnouncementTypes.All]: 'ALL ANNOUNCEMENTS',
			['announcement_type_' + AnnouncementTypes.Alert]: 'ALERT',
			['announcement_type_' + AnnouncementTypes.Warning]: 'WARNING',
			['announcement_type_' + AnnouncementTypes.Error]: 'ERROR',
			['announcement_type_' + AnnouncementTypes.Info]: 'INFO',
			['announcement_type_' + AnnouncementTypes.Memo]: 'MEMO',
			['announcement_type_' + AnnouncementTypes.Report]: 'REPORT',
			['announcement_type_' + AnnouncementTypes.Eshop]: 'ESHOP',
			['announcement_type_' + AnnouncementTypes.Company]: 'COMPANY NEWS',
			['announcement_type_' + AnnouncementTypes.Newsletter]: 'NEWSLETTER',
			read: 'READ',
			unread: 'UNREAD',
		}
	},
	[Namespaces.PAGE_REPORTS]: {
		el: {
			'Reports': 'Στατιστικά & Οικονομικά Στοιχεία',
			'Customer Card': 'Καρτέλα Πελάτη',
			'Sales': 'Σύνολα Αγορών',
			'Invoices': 'Παραστατικά',
			'Date': 'Ημερομηνία',
			'Type': 'Τύπος',
			'Doc Number': 'Αριθμός Παραστατικού',
			'Debit': 'Χρέωση',
			'Date range filter': 'Φίλτρο διαστήματος',
			'Credit': 'Πίστωση',
			'Balance': 'Προοδευτικό Υπόλοιπο',
			'Category': 'Περιγραφή',
			'Budget': 'Στόχος Αγορών',
			'Percentage': 'Ποσοστό Ολοκλήρωσης %',
			'Year': 'Έτος',
			'Quarter': 'Τρίμηνο',
			'Diameter': 'Διάμετρος',
			'Quantity': 'Ποσότητα',
			'Bonus': ' Falken Year End Bonus',
			'Unit': 'Bonus/Τεμάχιο',
			'Brand': 'Μάρκα',
			'1': '1ο',
			'2': '2ο',
			'3': '3ο',
			'4': '4ο',
			'PRODUCT BRAND': 'ΜΑΡΚΑ',
			'YEAR': 'ΕΤΟΣ',
			'QUARTER': 'ΤΡΙΜΗΝΟ',
			'SEARCH': 'ΑΝΑΖΗΤΗΣΗ',
			'SALES TOTAL': 'ΣΥΝΟΛΟ ΑΓΟΡΩΝ',
			'pieces': 'τεμάχια',
			'Search': 'Αναζήτηση',
			'No data found': 'Δεν βρέθηκαν δεδομένα',
			'Other': 'Άλλα',
			'Re-enter your daily code to access reports': 'Εισάγετε τον κωδικό ημέρας για να μεταβείτε στα στατιστικά και οικονομικά στοιχεία.',
			'Enter your daily code': 'Εισάγετε τον κωδικό ημέρας',
			'Submit': 'Υποβολή',
			'Required': 'Απαιτείται',
			'Daily password is incorrect or it has expired.': 'Ο κωδικός ημέρας είναι λάθος ή έχει λήξει.',
			'Return to home': 'Επιστροφή στην αρχική',
			'Insert a valid date filter!': 'Παρακαλώ εισάγετε μία έγκυρη ημερομηνία!',
			'Export': 'Εξαγωγή',
			'Print': 'Εκτύπωση',
			'PDF downloaded successfully!': 'Το PDF κατέβηκε με επιτυχία',
			'Failed to download PDF!': 'Αποτυχία λήψης PDF!'
		},
		en: {
			'Percentage': 'Completion Percentage %',
			'Quarter': 'Quarters',
			'Bonus': ' Falken Year End Bonus',
			'Unit': 'Bonus/Unit',
			'1': '1st',
			'2': '2nd',
			'3': '3rd',
			'4': '4th',
		}
	},
	[Namespaces.PAGE_CONTACT]: {
		el: {
			'Contact': 'Επικοινωνία',
			'Contact Email': 'Email Επικοινωνίας',
		},
		en: {}
	},
	[Namespaces.PAGE_PRIVACY]: {
		el: {
			'Privacy Policy': 'Πολιτική Απορρήτου',
			'Personal Data Protection': 'Προστασία Προσωπικών Δεδομένων',
			'privacy_text': 'Η SUPER TIRE SERVICE (STS-SA) δεσμεύεται να σεβαστεί και να προστατεύσει το απόρρητό σας και να διατηρήσει την ασφάλεια όλων των πληροφοριών προσωπικού χαρακτήρα που θα λάβει από εσάς. Σχεδιάζουμε τα συστήματα επεξεργασίας δεδομένων μας με στόχο τη συλλογή, επεξεργασία και χρήση μόνο των προσωπικών δεδομένων που είναι απαραίτητα για την εύλογη και οικονομική χρήση των υπηρεσιών μας.'
		},
		en: {
			'privacy_text': 'SUPER TIRE SERVICE (STS-SA) is committed to respecting and protecting your privacy and maintaining the security of all personal information it receives from you. We design our data processing systems with the aim of collecting, processing and using only the personal data necessary for the reasonable and economical use of our services.'
		}
	},
	[Namespaces.INVOICE]: {
		el: {
			'Invoice': '',
			'CODE': 'ΚΩΔΙΚΟΣ',
			'NAME': 'ΕΠΩΝΥΜΙΑ',
			'CITY': 'ΠΟΛΗ',
			'ADDRESS': 'ΔΙΕΥΘΥΝΣΗ',
			'PHONE NUMBER': 'ΤΗΛ.',
			'VAT REG NO': 'Α.Φ.Μ.',
			'PAYMENT TYPE': 'ΤΡΟΠΟΣ ΠΛΗΡΩΜΗΣ',
			'TRANSACTION TYPE': 'ΤΡΟΠΟΣ ΑΠΟΣΤΟΛΗΣ',
			'CUSTOMER DETAILS': 'ΣΤΟΙΧΕΙΑ ΣΥΝΑΛΛΑΣΣΟΜΕΝΟΥ',
			'TRANSACTION DETAILS': 'ΣΤΟΙΧΕΙΑ ΣΥΝΑΛΛΑΓΗΣ',
			'INVOICE TYPE': 'ΤΥΠΟΣ ΠΑΡΑΣΤΑΣΤΙΚΟΥ',
			'NUMBER': 'ΑΡΙΘΜΟΣ',
			'DATE': 'ΗΜΕΡΟΜΗΝΙΑ',
			'START TIME': 'ΩΡΑ ΕΝΑΡΞΗΣ',
			'JOB': 'ΕΠΑΓΓΕΛΜΑ',
			'RELATED INVOICE': 'ΣΧΕΤΙΚΑ ΠΑΡΑΣΤΑΤΙΚΑ',
			'ETIOLOGY': 'ΑΙΤΙΟΛΟΓΙΑ',
			'Code': 'Κωδικός',
			'Description': 'Περιγραφή',
			'UoM': 'ΜΜ',
			'Quantity': 'Ποσότητα',
			'Price': 'Τιμή',
			'Discount': 'Εκπτώσεις',
			'PCT Value': 'Ποσοστ.',
			'Net value': 'Καθ. Αξία',
			'VAT': 'ΦΠΑ',
			'VALUE BEFORE DISCOUNT': 'ΑΞΙΑ ΠΡΟ ΕΚΠΤΩΣΗΣ',
			'SALE': 'ΕΚΠΤΩΣΗ',
			'NET VALUE': 'ΚΑΘΑΡΗ ΑΞΙΑ',
			'VAT VALUE': 'ΑΞΙΑ Φ.Π.Α.',
			'CHARGES': 'ΕΠΙΒΑΡΥΝΣΕΙΣ',
			'VAT CHARGES': 'ΦΠΑ ΕΠΙΒΑΡΥΝΣΕΩΝ',
			'TOTAL VALUE': 'ΠΛΗΡΩΤΕΟ ΠΟΣΟ',
			'COMMENTS': 'ΠΑΡΑΤΗΡΗΣΕΙΣ',
			'INVOICE TOTALS': 'ΣΥΝΟΛΑ ΠΑΡΑΣΤΑΤΙΚΟΥ',
			'TAX OFFICE': 'Δ.Ο.Υ.',
			'PAYMENT TYPE DISCOUNT': 'ΕΚΠΤΩΣΗ ΤΡΟΠΟΥ ΠΛΗΡΩΜΗΣ',
			'Value': 'Αξιακή',
			'Contribution': 'Εισφορά',
			'Contribution VAT': 'ΦΠΑ Εισφοράς',
			'Close': 'Κλείσιμο',
			'Export PDF': 'Εξαγωγή σε PDF',
			'TRANSACTION PLACE': 'ΤΟΠΟΣ ΑΠΟΣΤΟΛΗΣ',
			'Accompanying Return Document': 'Συνοδευτικό Παραστατικού Επιστροφής',
			'Supplier Code': 'Κωδ. Προμηθευτή'
		},
		en: {
			"search": "Search",
			select_all: 'SELECT ALL',
			un_select_all: 'UNSELECT ALL',
		}
	}
}

export default locales