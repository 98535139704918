import {useTranslation} from "react-i18next";
import * as yup from "yup";
import {useFormik} from "formik";
import {useCallback, useEffect, useMemo, useRef} from "react";
import useProductAction from "../product/useProductAction";
import {enqueueSnackbar} from "notistack";
import {getRouteUrl} from "../../helpers/getRouteUrl";
import {ROUTE_PAGE_SEARCH_RESULTS} from "../../routers/routes";
import {useHistory} from "react-router-dom";
import useUserData from "../user/useUserData";
import useProductData from "../product/useProductData";
import useQueryParams from "../useQueryParams";
import {FILTERS} from "../../locales/components/namespaces";

const useFilters = () => {
	const {t} = useTranslation(FILTERS)
	
	const search1Ref = useRef(null)
	const search2Ref = useRef(null)
	const search3Ref = useRef(null)
	
	const {topFiltersSorted: topFilters, data} = useUserData()
	const {sideFilters, filterSelections: storeFilters, message} = useProductData()
	const {setFilters, getSearchResultsFilters, setProductMessage} = useProductAction()
	
	useEffect(() => {
		if (message) {
			enqueueSnackbar(t(message?.text), {
					variant: message?.variant
				}
			)
			setProductMessage(null)
		}
	}, [message, setProductMessage, t])
	
	const history = useHistory()
	const path = useMemo(() => history.location.pathname, [history.location.pathname])
	const params = useQueryParams()
	const urlFilters = useMemo(() => {
		const filters = {};
		for (const [key, value] of params.entries()) {
			filters[key] = value === "true" ? true : value === "false" ? false : value
		}
		return filters
	}, [params])
	
	const filterSelections = useMemo(() => ({...storeFilters, ...urlFilters}), [storeFilters, urlFilters])
	
	const initialValues = useMemo(() => {
		const topFilterValues = topFilters.reduce((acc, filter) => {
			filter.values.forEach(value => {
				const initialValue = filterSelections[`value-${value.id}`]
				acc[`value-${value.id}`] = initialValue ? initialValue : false;
			});
			return acc;
		}, {})
		const sideFilterValues = sideFilters.reduce((acc, filter) => {
			filter.values.forEach(value => {
				const initialValue = filterSelections[`value-${value.id}`]
				acc[`value-${value.id}`] = initialValue ? initialValue : false;
			});
			return acc;
		}, {})
		return {
			...topFilterValues,
			...sideFilterValues
		}
	}, [filterSelections, sideFilters, topFilters, path])
	
	const search = useMemo(() => {
		return [filterSelections?.search1?.trim(), filterSelections?.search2?.trim(), filterSelections?.search3?.trim()].filter(Boolean)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterSelections.search1, filterSelections.search2, filterSelections.search3, history.location.pathname])
	
	const attributeValueIds = useMemo(() => (
		Object.entries(filterSelections).filter(([key, value]) => key.includes('value-') && value).map(([key]) => key.slice(key.indexOf('-') + 1))
	), [filterSelections])
	
	const onSubmit = (values) => {
		let attributeValueIds = []
		for (const [key, value] of Object.entries(values)) {
			if (key.includes('value-') && value) {
				attributeValueIds.push(key.slice(key.indexOf('-') + 1))
			}
		}
		const search = []
		if (search1Ref?.current?.value && search1Ref.current.value.trim() !== '') {
			search.push(search1Ref?.current?.value)
		}
		if (search2Ref?.current?.value && search2Ref.current.value.trim() !== '') {
			search.push(search2Ref?.current?.value)
		}
		if (search3Ref?.current?.value && search3Ref.current.value.trim() !== '') {
			search.push(search3Ref?.current?.value)
		}
		if (search.length === 0 && attributeValueIds.length === 0) {
			enqueueSnackbar(t('Select at least one filter submit your search!'), {
					variant: 'warning'
				}
			)
		} else {
			setFilters(values)
			getSearchResultsFilters(values, data.preserveForBackend)
			history.push(getRouteUrl(ROUTE_PAGE_SEARCH_RESULTS, {}, {...values}))
		}
	}
	
	const validationSchema = yup.object({
		'search1': yup.mixed().test(
			'required', t('Search must contain at least 3 characters.'), function (value) {
				return value?.length >= 3 || value?.trim() === '' || !value
			}
		),
		'search2': yup.mixed().test(
			'required', t('Search must contain at least 3 characters.'), function (value) {
				return value?.length >= 3 || value?.trim() === '' || !value
			}
		),
		'search3': yup.mixed().test(
			'required', t('Search must contain at least 3 characters.'), function (value) {
				return value?.length >= 3 || value?.trim() === '' || !value
			}
		),
	})
	
	const formik = useFormik({
		initialValues: {
			search1: filterSelections?.search1 || '',
			search2: filterSelections?.search2 || '',
			search3: filterSelections?.search3 || '',
			isAltCode: filterSelections?.isAltCode || false,
			hasStock: false,
			hasIncoming: false,
			...initialValues
		},
		validationSchema: validationSchema,
		validateOnChange: false,
		onSubmit: onSubmit
	})
	
	const clearFilters = useCallback(() => {
		if (search1Ref?.current?.value) search1Ref.current.value = ''
		if (search2Ref?.current?.value) search2Ref.current.value = ''
		if (search3Ref?.current?.value) search3Ref.current.value = ''
		const resetUrlFilters = {}
		for (const key of Object.keys(formik.values)) {
			resetUrlFilters[key] = key === 'search1' || key === 'search2' || key === 'search3' ? '' : false
		}
		setFilters(resetUrlFilters)
		formik.setValues(resetUrlFilters)
		if (path !== '/') {
			history.push(getRouteUrl(ROUTE_PAGE_SEARCH_RESULTS, {}, {...resetUrlFilters}))
		}
	}, [formik, history, path, setFilters])
	
	return {
		path,
		formik,
		sideFilters,
		topFilters,
		urlFilters,
		attributeValueIds,
		search,
		search1Ref,
		search2Ref,
		search3Ref,
		clearFilters
	}
}

export default useFilters