import * as React from 'react';
import {TextareaAutosize} from "@mui/material";
import {grey} from "@mui/material/colors";

const style = {
	minWidth: '300px',
	lineHeight: 1.5,
	padding: '8px 12px',
	borderRadius: '8px',
	border: `1px solid ${grey[200]}`,
	boxShadow: `0px 2px 2px ${grey[50]}`,
	background: '#FFFFFF',
	color: '#485776',
}

const Textarea = (props) => {
	const {
		value,
		onChange = () => {
		},
		minRows,
		placeholder,
		name,
		otherProps
	} = props
	
	return (
		<TextareaAutosize
			aria-label={name}
			minRows={minRows}
			value={value}
			onChange={onChange}
			name={name}
			style={style}
			placeholder={placeholder}
			{...otherProps}
		/>
	);
}

export default Textarea