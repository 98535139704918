//@flow
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useOrderData } from "../order/useOrderData";

const useProductQuantity = ({warehouses, productId}) => {
	const [quantity, setQuantity] = useState(warehouses.map(warehouse => ({
		id: warehouse.id,
		quantity: warehouse.quantity,
	})))
	const {activeOrder} = useOrderData()
	const order = useMemo(() => (activeOrder.length > 0 ? activeOrder[0] : null), [activeOrder])
	useEffect(() => {
		if (order) {
			const {products: activeOrderProducts} = order
			const productInActiveOrder = activeOrderProducts.find(p => p.id === Number(productId))
			if (productInActiveOrder) {
				const newQuantity = _.mergeWith(warehouses, productInActiveOrder.warehouses, (objValues, srcValue, key) => {
					return {
						id: objValues.id,
						quantity: srcValue.id === objValues.id ? srcValue.quantity : objValues.quantity,
						realStock: objValues.realStock,
						displayStock: objValues.displayStock,
						expectedDate: objValues.expectedDate
					}
				})
				setQuantity(newQuantity)
			} else {
				setQuantity(warehouses.map(warehouse => ({
					...warehouse,
					quantity: 0,
				})))
			}
		}
	}, [order, order?.id, order?.products, productId, warehouses])
	
	return {
		quantity,
		setQuantity,
		activeOrder: order,
	}
}

export default useProductQuantity