import useWarrantyAction from "./useWarrantyAction";
import {useFormik} from "formik";
import * as yup from "yup";
import dayjs from "dayjs";
import {useTranslation} from "react-i18next";
import {PAGE_NEW_WARRANTY} from "../../locales/pages/namespaces";

const useWarrantyForm = () => {
	const {t} = useTranslation(PAGE_NEW_WARRANTY);
	const defaultEndDate = dayjs().add(12, 'months').subtract(1, 'day')
	const {createWarranty} = useWarrantyAction()
	
	const onSubmit = (values) => {
		createWarranty(values)
		
	}
	const validationSchema = yup.object({
		LicensePlate: yup.string().required(t('Required')),
		Km: yup.string().required(t('Required')),
		CarManufacturer: yup.object().required(t('Required')),
		CarModel: yup.object().required(t('Required')),
		CubicMeters: yup.string().required(t('Required')),
		SupplierCode: yup.string().required(t('Required')),
		Description: yup.string().required(t("Required")),
		TireBrand: yup.string().required(t("Required")),
		dot1: yup.string().required(t('Required')).test('len', t('Max 4 numbers'), (val) => val.toString().length === 4),
		dot2: yup.string().required(t('Required')).test('len', t('Max 4 numbers'), (val) => val.toString().length === 4),
		dot3: yup.string().required(t('Required')).test('len', t('Max 4 numbers'), (val) => val.toString().length === 4),
		dot4: yup.string().required(t('Required')).test('len', t('Max 4 numbers'), (val) => val.toString().length === 4),
		SupplierCode2: yup.string().test('supplierCode2Required', t('Required'), function (value, context) {
			const {dimensionCheck} = context.parent;
			return !(!dimensionCheck && !value);
		}),
		Description2: yup.string().test('description2Required', t('Required'), function (value, context) {
			const {dimensionCheck} = context.parent;
			return !(!dimensionCheck && !value);
		}),
		TireBrand2: yup.string().test('tireBrand2Required', t('Required'), function (value, context) {
			const {dimensionCheck} = context.parent;
			return !(!dimensionCheck && !value);
		})
	});
	
	const formik = useFormik({
		initialValues: {
			StartDt: dayjs(),
			EndDt: defaultEndDate,
			LicensePlate: '',
			Km: '',
			CarManufacturer: '',
			CarModel: '',
			CubicMeters: '',
			TireBrand: '',
			TireBrand2: '',
			dimensionCheck: true,
			SupplierCode: '',
			Description: '',
			dot1: '',
			dot2: '',
			dot3: '',
			dot4: '',
			SupplierCode2: '',
			Description2: '',
			items: [],
			itemId1: '',
			itemId2: ''
		},
		onSubmit: onSubmit,
		validationSchema: validationSchema
	})
	const submitForm = (evt) => {
		formik.submitForm(evt)
	}
	
	return {
		formik,
		submitForm
	}
}
export default useWarrantyForm