import type {Order, UnmappedOrder} from "./types";
import {roundToDecimal} from "../../helpers/helpers";
import {mapMultiple} from "../../helpers/map";
import {mapProduct} from "../product/map";

export const mapOrderProducts = (datum, isActive) => {
	if (!datum) return []
	return Object
	.keys(datum)
	.map(ItemId => {
		const item = datum[ItemId]
		const withDiscountPolicyApplied = item.slice(1)
		const quantity = Number(item[0].quantity)
		return {
			id: ItemId,
			quantity: quantity,
			...!isActive ? {
				priceWithTax: Number(item[0].priceWithTaxInOrder) / quantity,
				priceWithoutTax: Number(item[0].priceWithoutTaxInOrder) / quantity,
			} : {},
			withDiscountPolicyApplied: withDiscountPolicyApplied.length > 0 ?
				withDiscountPolicyApplied.map(i => ({
					id: ItemId,
					quantity: Number(i.quantity),
				})) : null
		}
	})
}

export const mapOrder: Order = (datum: UnmappedOrder) => {
	if (!datum) return
	return {
		id: datum.OrderId,
		status: datum.B2BStatus,
		code: datum.SequenceNum,
		products: mapMultiple(datum.products, mapProduct),
		removedProducts: mapMultiple(datum.removedProducts, (i) => ({
			id: i.ItemId,
			description: {
				en: i.ItemDescriptionEn,
				el: i.ItemDescriptionEl,
			}
		})),
		totalOrderDiscount: roundToDecimal(Number(datum.totalOrderDiscount), 2),
		totalOrderTax: roundToDecimal(Number(datum.totalOrderTax), 2),
		totalPriceWithTax: roundToDecimal(Number(datum.totalPriceWithTax), 2),
		totalPriceWithoutTax: roundToDecimal(Number(datum.totalPriceWithoutTax), 2),
		notes: datum.Comments ? datum.Comments : '',
	}
}