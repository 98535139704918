// @flow
import React from 'react';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControl,
	FormHelperText,
	TextField,
	Typography
} from "@mui/material";
import Button from "@mui/material/Button";
import {Trans, useTranslation} from "react-i18next";
import {PAGE_LOGIN} from "../../../../locales/pages/namespaces";
import {ErrorMessage, FormikProvider} from "formik";
import Box from "@mui/material/Box";
import {LoadingButton} from "@mui/lab";
import {SendOutlined} from "@mui/icons-material";
import useUserData from "../../../../hooks/user/useUserData";
import useForgotPassword from "../../../../hooks/login/useForgotPassword";
import PasswordInput from "../../../form/passwordInput/passwordInput";
import useUserAction from "../../../../hooks/user/useUserAction";

type Props = {
	open: boolean,
	handleDialog: Function
};

const ForgotPassword = (props: Props) => {
	const {open, handleDialog} = props
	const {t} = useTranslation(PAGE_LOGIN)
	const {loading, passwordReset, loadingStores} = useUserData()
	const {setResetPassword} = useUserAction()
	const handleClose = (event, reason) => {
		if (reason && (reason === "backdropClick" || reason === "escapeKeyDown")) return
		formik.resetForm()
		setResetPassword(false)
		handleDialog(false)
	}
	const {formik} = useForgotPassword({handleClose})
	
	return (
		<Dialog open={open} onClose={handleClose} maxWidth={'xs'}>
			<FormikProvider value={formik}>
				<Box
					component="form"
					onSubmit={formik.handleSubmit}
					noValidate
					pt={1}
				>
					<DialogTitle>
						<Typography
							variant={'h4'}
							component={'div'}
						>
							{t('Password reset')}
						</Typography>
					</DialogTitle>
					<DialogContent>
						<DialogContentText>
							<Trans
								i18nKey={'reset_password'}
								ns={PAGE_LOGIN}
							>
								To reset your password, please enter your username here. <br/> You will receive a six
								digit code
								to your email. Use this code to set your new password.
							</Trans>
						</DialogContentText>
						<Box
							pt={1}
						>
							<FormControl
								variant="standard"
								fullWidth
								error={
									formik.touched['username'] &&
									Boolean(formik.errors['username'])
								}
							>
								<TextField
									value={formik.values.username}
									name={'username'}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									placeholder={t('Enter your username')}
									size={'small'}
									inputProps={{maxLength: 250}}
									variant="outlined"
									required
									disabled={passwordReset}
								/>
								<FormHelperText>
									<ErrorMessage name={'username'}/>
								</FormHelperText>
							</FormControl>
							{passwordReset && (
								<>
									<FormControl
										variant="standard"
										fullWidth
										error={
											formik.touched['sixDigitCode'] &&
											Boolean(formik.errors['sixDigitCode'])
										}
									>
										<TextField
											value={formik.values.sixDigitCode}
											name={'sixDigitCode'}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											placeholder={t('Six digit code')}
											size={'small'}
											inputProps={{maxLength: 250}}
											variant="outlined"
											required
										/>
										<FormHelperText>
											<ErrorMessage name={'sixDigitCode'}/>
										</FormHelperText>
									</FormControl>
									<FormControl
										variant="standard"
										fullWidth
										error={
											formik.touched['newPassword'] &&
											Boolean(formik.errors['newPassword'])
										}
									>
										<PasswordInput
											formik={formik}
											name={'newPassword'}
											required
											otherProps={{
												size: 'small',
												variant: 'outlined',
												placeholder: t('New password')
											}}
										/>
										<FormHelperText>
											<ErrorMessage name={'newPassword'}/>
										</FormHelperText>
									</FormControl>
									<FormControl
										variant="standard"
										fullWidth
										error={
											formik.touched['confirmPassword'] &&
											Boolean(formik.errors['confirmPassword'])
										}
									>
										<PasswordInput
											formik={formik}
											name={'confirmPassword'}
											required
											otherProps={{
												size: 'small',
												variant: 'outlined',
												placeholder: t('Confirm password')
											}}
										/>
										<FormHelperText>
											<ErrorMessage name={'confirmPassword'}/>
										</FormHelperText>
									</FormControl>
								</>
							)}
						</Box>
					</DialogContent>
					<DialogActions>
						<Button
							color="error"
							size={"small"}
							onClick={handleClose}
							sx={{
								marginRight: 'auto'
							}}
						>
							{t('Cancel')}
						</Button>
						<LoadingButton
							loading={loading}
							loadingPosition="start"
							size={"small"}
							variant={"contained"}
							color={'success'}
							type={'submit'}
							startIcon={<SendOutlined/>}
						>
							{t(passwordReset ? 'Submit New Password' : 'Send 6 Digit Code')}
						</LoadingButton>
					</DialogActions>
				</Box>
			</FormikProvider>
		</Dialog>
	);
};

export default ForgotPassword