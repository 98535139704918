//@flow
import * as React from 'react'
import useUserData from "../../../hooks/user/useUserData";
import {downloadFile} from "../../../helpers/fileHandle";

type Props = {
	url: string,
	isImg?:boolean,
	filename: string,
	children: React.Node,
}

export const AuthenticatedLink = (props: Props) => {
	const {
		url,
		filename,
		children,
		isImg = false,
		...otherProps
	} = props
	const {token} = useUserData()
	const headers = {'Authorization': `Bearer ${token}`}

	const handleAction = async () => {
		const result = await fetch(url, {
			headers: headers
		})
		const blob = await result.blob()
		const href = window.URL.createObjectURL(blob)
		isImg && window.open(href, '_blank');
		downloadFile(href, filename);
	}
	return (
		<a
			onClick={handleAction}
			{...otherProps}
		>
			{children}
		</a>
	)
}