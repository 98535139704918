import type {Order} from "./types";
import {parseMultiple} from "../../helpers/parse";
import {parseProduct} from "../product/parse";

export const parseRemovedProducts = (datum, language) => {
	if (!datum) return;
	return {
		id: datum.get('id'),
		description: datum.getIn(['description', language])
	}
}

export const parseOrder: Order = (datum, language) => {
	if (!datum) return;
	return {
		id: datum.get('id'),
		status: datum.get('status'),
		code: datum.get('code'),
		products: parseMultiple(datum.get('products'), (item) => parseProduct(item, language)),
		removedProducts: parseMultiple(datum.get('removedProducts'), (i) => parseRemovedProducts(i, language)),
		totalOrderDiscount: datum.get('totalOrderDiscount'),
		totalOrderTax: datum.get('totalOrderTax'),
		totalPriceWithTax: datum.get('totalPriceWithTax'),
		totalPriceWithoutTax: datum.get('totalPriceWithoutTax'),
		totalProductsInCart: datum.get('totalProductsInCart'),
		notes: datum.get('notes')
	}
}