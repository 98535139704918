import type {FileType} from "../../../types/message/type";
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";
import {PAGE_ANNOUNCEMENTS} from "../../../locales/pages/namespaces";
import {Divider, List, ListItemButton, ListItemText, Typography} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import {AttachFileOutlined} from "@mui/icons-material";
import {AuthenticatedLink} from "../../general/authenticatedLink/authenticatedLink";

type Props = {
	id: string,
	files: Array<FileType>,
}

const Attachments = (props: Props) => {
	const {id, files, getMessageAttachments} = props
	const {t} = useTranslation(PAGE_ANNOUNCEMENTS)
	// useEffect(() => {
	// 	getMessageAttachments(id)
	// }, [id, getMessageAttachments])
	return files && files.length > 0 ? (
		<Box>
			<Divider/>
			<Typography>
				{files.length} {t('Attachments')}
			</Typography>
			<Divider/>
			<List>
				{files.map(file => (
					<AuthenticatedLink
						url={file.url}
						filename={file.name}
						key={file.id}
					>
						<ListItem disablePadding>
							<ListItemButton
								size={'small'}
							>
								<ListItemIcon>
									<AttachFileOutlined/>
								</ListItemIcon>
								<ListItemText primary={file.name}/>
							</ListItemButton>
						</ListItem>
					</AuthenticatedLink>
				))}
			</List>
		</Box>
	) : null
}

export default Attachments