// @flow
import React from 'react';
import Box from "@mui/material/Box";
import {Typography} from "@mui/material";
import SelectAddress from "../selectAddress/selectAddress";
import useUserData from "../../../hooks/user/useUserData";
import {useTranslation} from "react-i18next";
import {NAVIGATION} from "../../../locales/components/namespaces";
import './userHeader.css'

const UserHeader = () => {
	const {data} = useUserData()
	const {t} = useTranslation(NAVIGATION)
	return (
		<Box
			className={'userHeader'}
		>
			<Box
				className={'userHeader-item'}
				sx={{
					borderRight: {
						sm: 'none',
						md: '1px solid #fbc324'
					},
					width: {
						sm: '100%',
						md: '50%'
					}
				}}
			>
				<Typography
					variant={'h5'}
					component={'div'}
					pr={1}
				>
					{t('Customer Code')}
				</Typography>
				<Typography
					variant={'body1'}
					component={'div'}
					pr={1}
				>
					{`${data?.erpCode} - ${data?.customer}`}
				</Typography>
			</Box>
			<Box
				className={'userHeader-item'}
				sx={{
					width: {
						sm: '100%',
						md: '50%'
					}
				}}
			>
				<Typography
					variant={'h5'}
					component={'div'}
					pr={1}
					pl={{
						sm: 0,
						md: '6px'
					}}
				>
					{t('Address')}
				</Typography>
				<SelectAddress color={'#FFFFFF'}/>
			</Box>
		</Box>
	);
};

export default UserHeader