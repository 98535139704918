//@flow
import {useTranslation} from 'react-i18next'
import Pagination from "../pagination/pagination";
import {Fragment} from "react";
import {TABLE_COMPONENT} from "../../../../../locales/components/namespaces";

type Props = {
	usingInfiniteScroll?: boolean,
	noMeta?: boolean,
	noPagination?: boolean,
	renderRow: Function,
	initialState: {
		pageIndex: number,
		pageSize: number,
	}
}

const UnstructuredTableView = (props: Props) => {
	const {t} = useTranslation(TABLE_COMPONENT)
	const {
		virtualized,
		usingInfiniteScroll,
		noPagination,
		noMeta,
		renderRow,
		loading,
		onPageSizeChange,
		pageCount: controlledPageCount,
	} = props
	const {
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		nextPage,
		previousPage,
		setPageSize,
		pageIndex,
		pageSize
	} = props.table
	
	
	return (
		<Fragment>
			{/*{virtualized ? (*/}
			{/*        <ListContainer*/}
			{/*            page={page}*/}
			{/*            renderRow={renderRow}*/}
			{/*            prepareRow={prepareRow}*/}
			{/*        />*/}
			{/*    )*/}
			{/*    : (*/}
			{/*        page.map((row, index, array) => {*/}
			{/*            prepareRow(row)*/}
			{/*            return renderRow(row, index, page)*/}
			{/*        })*/}
			{/*    )}*/}
			{(
				page.map((row, index, array) => {
					prepareRow(row)
					return renderRow(row, index, array)
				})
			)}
			{!noPagination && !usingInfiniteScroll && (
				<Pagination
					pageSize={pageSize}
					setPageSize={(size) => {
						setPageSize(size)
						if (onPageSizeChange) {
							onPageSizeChange(size)
						}
					}}
					canPreviousPage={canPreviousPage}
					canNextPage={canNextPage}
					nextPage={nextPage}
					pageIndex={pageIndex}
					previousPage={previousPage}
					// noMeta={noMeta}
					page={page}
					controlledPageCount={controlledPageCount}
				/>
			)}
		</Fragment>
	)
}

export default UnstructuredTableView