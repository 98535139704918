import type {Banner, UnmappedBanner} from "./type";
import {API} from "../../helpers/constants";

export const mapBanner: Banner = (datum: UnmappedBanner) => {
    if (!datum) return
    return {
        id: Number(datum.id),
        image: datum.Image ? API + '/uploads/b2b/banners/' + datum.Image : null,
        textColor: datum.TextColor && datum.TextColor.startsWith('#') ? datum.TextColor.substring(1) : datum.TextColor,
        weight: Number(datum.OrderNum),
        buttonLink: datum.ButtonLink,
        description: {
            en: datum.DescriptionEn,
            el: datum.Description,
        },
        title: {
            en: datum.TitleEn,
            el: datum.Title,
        },
        subtitle: {
            en: datum.SubTitleEn,
            el: datum.SubTitle,
        },
        buttonText: {
            en: datum.ButtonTextEn,
            el: datum.ButtonText,
        },
        videoLink: datum.VideoLink,
        backgroundColor: datum.BackgroundColor && datum.BackgroundColor.startsWith('#') ? datum.BackgroundColor.substring(1) : datum.BackgroundColor
    }
}
