export default function AccordionSummary(theme) {
    return {
        MuiAccordionSummary: {
            defaultProps: {},
            styleOverrides: {
                root: {},
                expandIconWrapper: {},
                content: {}
            }
        }
    };
}
