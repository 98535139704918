import _ from "lodash";
import {roundToDecimal} from "./helpers";

export const getBonusFilters = (data) => {
	const years = _.sortBy(_.uniqBy(data, 'year').map(i => i?.year))
	return {
		years: {
			description: 'Year',
			uniqueValue: true,
			values: years.map(year => ({
				description: year,
				value: year === Math.max(...years)
			}))
		}
	}
}

export const getBudgetFilters = (data) => {
	const years = _.sortBy(_.uniqBy(data, 'year').map(i => i?.year))
	const quarters = _.sortBy(_.uniqBy(data, 'quarter').map(i => i?.quarter))
	const categories = _.sortBy(_.uniqBy(data, 'category1').map(i => i?.category1))
	return {
		years: {
			description: 'Year',
			uniqueValue: true,
			values: years.map(year => ({
				description: year,
				value: year === Math.max(...years)
			}))
		},
		quarters: {
			description: 'Quarter',
			uniqueValue: false,
			values: quarters.map(quarter => ({
				description: quarter,
				value: true
			}))
		},
		categories: {
			description: 'Category',
			uniqueValue: false,
			values: categories.map(category => ({
				description: category,
				value: true
			}))
		},
	}
}

export const getBonusData = (data, filters) => {
	const yearFilter = filters.years.values.find(year => year.value === true)?.description
	const filterData = data.filter(f => Number(f.year) === yearFilter)
	const groupByDiameter = _.groupBy(filterData, 'diameter')
	const rows = []
	for (const [key, value] of Object.entries(groupByDiameter)) {
		const quantity = value.reduce((acc, i) => (acc + i.quantity), 0)
		const bonus = value.reduce((acc, i) => (acc + i.bonus), 0)
		rows.push({
			diameter: key,
			quantity: quantity,
			bonus: bonus,
			unit: value.length > 0 ? value[0]?.unit : 0
		})
	}
	return rows
}

export const getBudgetData = (data, filters) => {
	const yearFilter = filters.years.values.find(year => year.value === true)?.description
	const quarterFilters = filters.quarters.values.filter(quarter => quarter.value === true)?.map(i => i.description)
	const categoryFilters = filters.categories.values.filter(category => category.value === true)?.map(i => i.description)
	const filterData = data.filter(f => Number(f.year) === yearFilter && categoryFilters.includes(f.category1) && quarterFilters.includes(Number(f.quarter)))
	const groupByCategory2 = _.groupBy(filterData, 'category2')
	const rows = []
	for (const [key, value] of Object.entries(groupByCategory2)) {
		const salesQuantity = value.reduce((acc, i) => (acc + i.salesQuantity), 0)
		const budgetQuantity = value.reduce((acc, i) => (acc + i.budgetQuantity), 0)
		rows.push({
			category2: key,
			salesQuantity: salesQuantity,
			budgetQuantity: budgetQuantity,
			percentage: budgetQuantity > 0 ? roundToDecimal((salesQuantity * 100 / budgetQuantity), 2) : 0
		})
	}
	return rows.filter(f => f.budgetQuantity > 0)
}

export const getQuarterDescription = (value) => {
	switch (value) {
		case '1,2,3':
			return 1
		case '4,5,6':
			return 2
		case '7,8,9':
			return 3
		case '10,11,12':
			return 4
		default:
			return value
	}
}