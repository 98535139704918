export default function OutlinedInput(theme) {
	return {
		MuiOutlinedInput: {
			styleOverrides: {
				input: {
					padding: 5
				},
				notchedOutline: {},
				root: {},
				inputSizeSmall: {},
				inputMultiline: {},
			}
		}
	};
}
