import React, {useContext} from "react";
import {InvoiceContext} from "./invoice";

const CustomerDetails = () => {
	const {data} = useContext(InvoiceContext)
	return (
		<div className="form-box form-box_6">
			<div className="box_title">{data.customerDetailsHeader}</div>
			<div className="box_column">
				{data && data.customerDetails.map((item, index) => (
					<div className="box_column_item" key={index}>
						<span className="box_column_itemTitle">{item.title}:</span>
						<span className="box_column_itemValue">{item.value}</span>
					</div>
				))}
			</div>
			
			<div className="box_column" style={{marginTop: '10px'}}>
				<div className="box_column_item">
					<span className="box_column_itemTitle">{data.customerDetailsComments.title}:</span>
					<span
						className="box_column_itemValue">{data.customerDetailsComments.value}</span>
				</div>
			</div>
		</div>
	)
}

export default CustomerDetails;