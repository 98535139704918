export default function Accordion(theme) {
    return {
        MuiAccordion: {
            defaultProps: {
                // disableGutters: true,
                // square: true,
                // elevation: 0
            },
            styleOverrides: {
                root: {}
            }
        }
    };
}
