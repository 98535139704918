export default function Button(theme) {
	
	return {
		MuiButton: {
			defaultProps: {
				disableElevation: true
			},
			styleOverrides: {
				root: {},
				contained: {},
				outlined: {},
				text: {},
				endIcon: {},
				startIcon: {},
				dashed: {}
			}
		}
	};
}
