import Box from "@mui/material/Box";
import React, {useEffect, useMemo, useState} from 'react';
import {
    Divider,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {PAGE_NEW_WARRANTY} from "../../../../locales/pages/namespaces";
import {enqueueSnackbar} from "notistack";
import {handleAxiosErrors} from "../../../../helpers/errorHandling";
import useUserData from "../../../../hooks/user/useUserData";
import axios from "axios";
import {API, Permissions} from "../../../../helpers/constants";
import dayjs from "dayjs";
import {NumericFormat} from "react-number-format";
import List from "@mui/material/List";
import ImageWithPreview from "../accident/partials/imageWithPreview/imageWithPreview";
import {FormikProvider, useFormik} from "formik";
import {withPermissionHOC} from "../../../../modules/permission/components/permissionHOC/permissionHOC";
import Button from "@mui/material/Button";

const data = [
    {id:'1', carBrand: 'Falken', width: '135',line:'75',rim:'12',speedIndicator:'85T',carSole:'SINCERA SN',XL:'0',dot1:'0-0',dot2:'0-0'},
];
const data3 = [
    {id:'', carBrand: '', width: '0',line:'0',rim:'0',speedIndicator:'',carSole:'',XL:'0',dot3:'0-0',dot4:'0-0'},
];

const WarrantyDetails =({id,handleClose})=>{
    const { t } = useTranslation(PAGE_NEW_WARRANTY);
    const [warrantyDetails,setWarrantyDetails] = useState([])
     const [tabledot1,setTabledot1] = useState([])
     const [tabledot2,setTabledot2] = useState([])
    const [loading,setLoading] = useState(false)
    const {token,dateFormat,thousandSeparator, decimalSeparator} = useUserData()
// const Table1 = []
// const Table2 = []

    useEffect(() => {
        setLoading(true)
        axios({
            method: 'post',
            url: `${API}/en/b2b/guarantee/details`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data:{
                GuaranteeId:id
            }
        }).then(response => {
            const data = response.data.data;
            setWarrantyDetails({
                ...data,
                ReceiptImg: data.ReceiptImg?.split('\\')?.pop(),
            });
            const table1 = data?.items && data.items.length > 0 ? [data.items[0]] : []
            const table2 = data?.items && data.items.length > 1 ? [data.items[1]] : []
            setTabledot1(table1);
            setTabledot2(table2);
            setLoading(false)
        }).catch((error) => {
            console.log('error', error);
            enqueueSnackbar(handleAxiosErrors(error, t('Failed to get the details'), {
                variant: 'warning',
                autoHideDuration: 1000,
            }));
            setLoading(false)
        });

    }, [id, t, token]);

    const columns= useMemo(()=>[
        {
            field :'ItemDescriptionEn',
            headerName:t('|F| Car Brand'),

        },
        {
            field:'dot1',
            headerName:t('dot1')
        },
        {
            field:'dot2',
            headerName: t('dot2'),
        },
        {
            field:'dot3',
            headerName: t('dot3'),
        },
        {
            field:'dot4',
            headerName: t('dot4')
        }
    ],[t])

    const columns2 = useMemo(()=>[
        {
            field :'ItemDescriptionEn',
            headerName:t('|B| Car Brand'),

        },
        {
            field:'dot1',
            headerName:t('dot1')
        },
        {
            field:'dot2',
            headerName: t('dot2'),
        },
        {
            field:'dot3',
            headerName: t('dot3'),
        },
        {
            field:'dot4',
            headerName: t('dot4')
        }
    ],[t])

    const formik = useFormik({
        initialValues: {
            ReceiptImg: '',
            ReceiptImgPath: warrantyDetails?.ReceiptImg ? warrantyDetails?.ReceiptImg : '',

        },
        enableReinitialize: true
    })


//const warrantyEntries = Object.entries(warrantyDetails)
//     Table1.push(table1)
//     Table2.push(table2)
//     console.log('table',Table1)
    return  (

            <Box p={{xs: 1, sm: 1, md: 1}}
                 sx={{
                     display: 'flex',
                     flexDirection: 'column',
                     alignItems: 'center',
                     width: 'auto',
                     maxWidth: '80%',
                     maxHeight: '80vh',
                     bgcolor: 'background.paper',
                     borderRadius: '8px',
                     margin: 'auto',
                     overflowY: 'auto',
                     mt:6
                 }}>
                <Typography
                    pb={1}
                    mb={2}
                    variant={'h3'}
                    component={'div'}
                    sx={{
                        textAlign: 'center',
                    }}
                >
                    {t('Warranty Details')}
                </Typography>

                <Grid container spacing={2} sx={{width: '100%', flexGrow: 1}}>
                    <Grid item xs={12} md={12}   >
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={6} sm={12}>
                                        <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                            <Grid container spacing={5}>
                                                <Grid item xs={5} md={6} sm={5}>
                                                    <Typography sx={{
                                                        fontSize: '0.8rem',
                                                        marginTop: '1px',
                                                        marginRight: '5px'
                                                    }}>{t(`ID`)}</Typography>
                                                </Grid>

                                                <Grid item xs={5} md={6} sm={5}>
                                                    <Typography variant="subtitle1" sx={{
                                                        fontSize: '0.8rem',
                                                        marginTop: '1px',
                                                        marginBottom: '1px',
                                                        textAlign: 'left'
                                                    }}>{warrantyDetails.id}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Divider sx={{marginBottom: 1}}/>
                                    </Grid>
                                        <Grid item xs={12} md={6} sm={12}>
                                            <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                                <Grid container spacing={5}>
                                                    <Grid item xs={5} md={6} sm={5}>
                                                        <Typography sx={{
                                                            fontSize: '0.8rem',
                                                            marginTop: '1px',
                                                            marginRight: '5px'
                                                        }}>{t(`Warranty Number`)}</Typography>
                                                    </Grid>

                                                    <Grid item xs={5} md={6} sm={5}>
                                                        <Typography variant="subtitle1" sx={{
                                                            fontSize: '0.8rem',
                                                            marginTop: '1px',
                                                            marginBottom: '1px',
                                                            textAlign: 'left'
                                                        }}>{warrantyDetails.GuaranteeNumber}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                            <Divider sx={{marginBottom: 1}}/>
                                        </Grid>
                                    <Grid item xs={12} md={6} sm={12}>
                                        <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                                <Grid container spacing={5}>
                                                    <Grid item xs={5} md={6} sm={5}>
                                                        <Typography sx={{
                                                            fontSize: '0.8rem',
                                                            marginTop: '1px',
                                                            marginRight: '5px'
                                                        }}>{t(`Activation Date`)}</Typography>
                                                    </Grid>

                                                    <Grid item xs={5} md={6} sm={5}>
                                                        <Typography variant="subtitle1" sx={{
                                                            fontSize: '0.8rem',
                                                            marginTop: '1px',
                                                            marginBottom: '1px',
                                                            textAlign: 'left'
                                                        }}>{dayjs(warrantyDetails.StartDt).format(dateFormat)}</Typography>
                                                    </Grid>
                                                </Grid>
                                        </Box>
                                        <Divider sx={{marginBottom: 1}}/>
                                    </Grid>
                                    <Grid item xs={12} md={6} sm={12}>
                                        <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                                <Grid container spacing={5}>
                                                    <Grid item xs={5} md={6} sm={5}>
                                                        <Typography sx={{
                                                            fontSize: '0.8rem',
                                                            marginTop: '1px',
                                                            marginRight: '5px'
                                                        }}>{t(`End Date`)}</Typography>
                                                    </Grid>

                                                    <Grid item xs={5} md={6} sm={5}>
                                                        <Typography variant="subtitle1" sx={{
                                                            fontSize: '0.8rem',
                                                            marginTop: '1px',
                                                            marginBottom: '1px',
                                                            textAlign: 'left'
                                                        }}>{dayjs(warrantyDetails.EndDt).format(dateFormat)}</Typography>
                                                    </Grid>
                                                </Grid>
                                        </Box>
                                        <Divider sx={{marginBottom: 1}}/>
                                    </Grid>
                                    <Grid item xs={12} md={6} sm={12}>
                                        <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                                <Grid container spacing={5}>
                                                    <Grid item xs={5} md={6} sm={5}>
                                                        <Typography sx={{
                                                            fontSize: '0.8rem',
                                                            marginTop: '1px',
                                                            marginRight: '5px'
                                                        }}>{t(`Name`)}</Typography>
                                                    </Grid>

                                                    <Grid item xs={5} md={6} sm={5}>
                                                        <Typography variant="subtitle1" sx={{
                                                            fontSize: '0.8rem',
                                                            marginTop: '1px',
                                                            marginBottom: '1px',
                                                            textAlign: 'left'
                                                        }}>{warrantyDetails.FullName}</Typography>
                                                    </Grid>
                                                </Grid>
                                        </Box>
                                        <Divider sx={{marginBottom: 1}}/>
                                    </Grid>
                                    <Grid item xs={12} md={6} sm={12}>
                                        <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                                <Grid container spacing={5}>
                                                    <Grid item xs={5} md={6} sm={5}>
                                                        <Typography sx={{
                                                            fontSize: '0.8rem',
                                                            marginTop: '1px',
                                                            marginRight: '5px'
                                                        }}>{t(`Mobile`)}</Typography>
                                                    </Grid>

                                                    <Grid item xs={5} md={6} sm={5}>
                                                        <Typography variant="subtitle1" sx={{
                                                            fontSize: '0.8rem',
                                                            marginTop: '1px',
                                                            marginBottom: '1px',
                                                            textAlign: 'left'
                                                        }}>{warrantyDetails.CustomerNumber}</Typography>
                                                    </Grid>
                                                </Grid>
                                        </Box>
                                        <Divider sx={{marginBottom: 1}}/>
                                    </Grid>
                                    <Grid item xs={12} md={6} sm={12}>
                                        <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                                <Grid container spacing={5}>
                                                    <Grid item xs={5} md={6} sm={5}>
                                                        <Typography sx={{
                                                            fontSize: '0.8rem',
                                                            marginTop: '1px',
                                                            marginRight: '5px'
                                                        }}>{t(`E-mail`)}</Typography>
                                                    </Grid>

                                                    <Grid item xs={5} md={6} sm={5}>
                                                        <Typography variant="subtitle1" sx={{
                                                            fontSize: '0.8rem',
                                                            marginTop: '1px',
                                                            marginBottom: '1px',
                                                            textAlign: 'left'
                                                        }}>{warrantyDetails.CustomerEmail}</Typography>
                                                    </Grid>
                                                </Grid>
                                        </Box>
                                        <Divider sx={{marginBottom: 1}}/>
                                    </Grid>
                                    <Grid item xs={12} md={6} sm={12}>
                                        <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                                <Grid container spacing={5}>
                                                    <Grid item xs={5} md={6} sm={5}>
                                                        <Typography sx={{
                                                            fontSize: '0.8rem',
                                                            marginTop: '1px',
                                                            marginRight: '5px'
                                                        }}>{t(`Address`)}</Typography>
                                                    </Grid>

                                                    <Grid item xs={5} md={6} sm={5}>
                                                        <Typography variant="subtitle1" sx={{
                                                            fontSize: '0.8rem',
                                                            marginTop: '1px',
                                                            marginBottom: '1px',
                                                            textAlign: 'left'
                                                        }}>{warrantyDetails.Address}</Typography>
                                                    </Grid>
                                                </Grid>
                                        </Box>
                                        <Divider sx={{marginBottom: 1}}/>
                                    </Grid>
                                    <Grid item xs={12} md={6} sm={12}>
                                        <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                                <Grid container spacing={5}>
                                                    <Grid item xs={5} md={6} sm={5}>
                                                        <Typography sx={{
                                                            fontSize: '0.8rem',
                                                            marginTop: '1px',
                                                            marginRight: '5px'
                                                        }}>{t(`City`)}</Typography>
                                                    </Grid>

                                                    <Grid item xs={5} md={6} sm={5}>
                                                        <Typography variant="subtitle1" sx={{
                                                            fontSize: '0.8rem',
                                                            marginTop: '1px',
                                                            marginBottom: '1px',
                                                            textAlign: 'left'
                                                        }}>{warrantyDetails.ucDistrict}</Typography>
                                                    </Grid>
                                                </Grid>
                                        </Box>
                                        <Divider sx={{marginBottom: 1}}/>
                                    </Grid>
                                    <Grid item xs={12} md={6} sm={12}>
                                        <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                                <Grid container spacing={5}>
                                                    <Grid item xs={5} md={6} sm={5}>
                                                        <Typography sx={{
                                                            fontSize: '0.8rem',
                                                            marginTop: '1px',
                                                            marginRight: '5px'
                                                        }}>{t(`Zipcode`)}</Typography>
                                                    </Grid>

                                                    <Grid item xs={5} md={6} sm={5}>
                                                        <Typography variant="subtitle1" sx={{
                                                            fontSize: '0.8rem',
                                                            marginTop: '1px',
                                                            marginBottom: '1px',
                                                            textAlign: 'left'
                                                        }}>{warrantyDetails.ZipCode}</Typography>
                                                    </Grid>
                                                </Grid>
                                        </Box>
                                        <Divider sx={{marginBottom: 1}}/>
                                    </Grid>
                                    <Grid item xs={12} md={6} sm={12}>
                                        <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                                <Grid container spacing={5}>
                                                    <Grid item xs={5} md={6} sm={5}>
                                                        <Typography sx={{
                                                            fontSize: '0.8rem',
                                                            marginTop: '1px',
                                                            marginRight: '5px'
                                                        }}>{t(`License Pl.`)}</Typography>
                                                    </Grid>

                                                    <Grid item xs={5} md={6} sm={5}>
                                                        <Typography variant="subtitle1" sx={{
                                                            fontSize: '0.8rem',
                                                            marginTop: '1px',
                                                            marginBottom: '1px',
                                                            textAlign: 'left'
                                                        }}>{warrantyDetails.LicensePlate}</Typography>
                                                    </Grid>
                                                </Grid>
                                        </Box>
                                        <Divider sx={{marginBottom: 1}}/>
                                    </Grid>
                                    <Grid item xs={12} md={6} sm={12}>
                                        <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                                <Grid container spacing={5}>
                                                    <Grid item xs={5} md={6} sm={5}>
                                                        <Typography sx={{
                                                            fontSize: '0.8rem',
                                                            marginTop: '1px',
                                                            marginRight: '5px'
                                                        }}>{t(`Km`)}</Typography>
                                                    </Grid>

                                                    <Grid item xs={5} md={6} sm={5}>
                                                        <Typography variant="subtitle1" sx={{
                                                            fontSize: '0.8rem',
                                                            marginTop: '1px',
                                                            marginBottom: '1px',
                                                            textAlign: 'left'
                                                        }}><NumericFormat
                                                            thousandSeparator={thousandSeparator}
                                                            decimalSeparator={decimalSeparator}
                                                            displayType="text"
                                                            value={warrantyDetails.Km}
                                                        /> </Typography>
                                                    </Grid>
                                                </Grid>
                                        </Box>
                                        <Divider sx={{marginBottom: 1}}/>
                                    </Grid>
                                    <Grid item xs={12} md={6} sm={12}>
                                        <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                                <Grid container spacing={5}>
                                                    <Grid item xs={5} md={6} sm={5}>
                                                        <Typography sx={{
                                                            fontSize: '0.8rem',
                                                            marginTop: '1px',
                                                            marginRight: '5px'
                                                        }}>{t(`Car Brand`)}</Typography>
                                                    </Grid>

                                                    <Grid item xs={5} md={6} sm={5}>
                                                        <Typography variant="subtitle1" sx={{
                                                            fontSize: '0.8rem',
                                                            marginTop: '1px',
                                                            marginBottom: '1px',
                                                            textAlign: 'left'
                                                        }}>{warrantyDetails.CarManufacturer}</Typography>
                                                    </Grid>
                                                </Grid>
                                        </Box>
                                        <Divider sx={{marginBottom: 1}}/>
                                    </Grid>
                                    <Grid item xs={12} md={6} sm={12}>
                                        <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                                <Grid container spacing={5}>
                                                    <Grid item xs={5} md={6} sm={5}>
                                                        <Typography sx={{
                                                            fontSize: '0.8rem',
                                                            marginTop: '1px',
                                                            marginRight: '5px'
                                                        }}>{t(`Car Model`)}</Typography>
                                                    </Grid>

                                                    <Grid item xs={5} md={6} sm={5}>
                                                        <Typography variant="subtitle1" sx={{
                                                            fontSize: '0.8rem',
                                                            marginTop: '1px',
                                                            marginBottom: '1px',
                                                            textAlign: 'left'
                                                        }}>{warrantyDetails.CarModel}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                            <Divider sx={{marginBottom: 1}}/>
                                        </Grid>
                                </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>

                            {tabledot1.length > 0 &&
                                <TableContainer sx={{mb:5}}>
                                    <Table sx={{width: '100%'}}>
                                        <TableHead sx={{mb:2}}>
                                            <TableRow>
                                                {columns.map((column, index) => (
                                                    <TableCell key={column.field} sx={{minWidth: '80px'}}>
                                                        {column.headerName}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {tabledot1.map((row) => (
                                                <TableRow key={row.id}>
                                                    {columns.map((column, index) => (
                                                        <TableCell key={column.field} sx={{minWidth: '80px'}}>
                                                            {row[column.field]}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            }

                            {tabledot2.length > 0 &&
                                <TableContainer>
                                    <Table sx={{width: '100%'}}>
                                        <TableHead>
                                            <TableRow>
                                                {columns2.map((column, index) => (
                                                    <TableCell key={column.field} sx={{minWidth: '80px'}}>
                                                        {column.headerName}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {tabledot2.map((row) => (
                                                <TableRow key={row.id}>
                                                    {columns2.map((column, index) => (
                                                        <TableCell key={column.field} sx={{minWidth: '80px'}}>
                                                            {row[column.field]}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            }
                            <Box>
                                {!loading  && <FormikProvider value={formik}>
                                    {warrantyDetails.Status !== 'Initialized' && <List>
                                        < ImageWithPreview
                                        formik={formik}
                                        label={t('Receipt')}
                                        fileField={'ReceiptImg'}
                                        pathField={'ReceiptImgPath'}
                                        getImageUrl={`${API}/en/guarantee/${id}/image/Receipt`}
                                        isDetails={true}
                                        />
                                        </List>}
                                </FormikProvider>}
                            </Box>

                    </Grid>
            </Grid>
                <Box sx={{display: "flex", justifyContent: "flex-start", width: '100%'}}>
                    <Button sx={{textAlign: "center"}} onClick={handleClose}>
                        {t("Cancel")}
                    </Button>
                </Box>
        </Box>
    )
}

export default withPermissionHOC(WarrantyDetails, [Permissions.AccessGuarantees])