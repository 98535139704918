import React, {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {PAGE_WARRANTIES} from "../../../../locales/pages/namespaces";
import {
	DataGridPremium,
	GRID_AGGREGATION_ROOT_FOOTER_ROW_ID,
	GridActionsCellItem,
	GridToolbar
} from "@mui/x-data-grid-premium";
import {Dialog, Modal, Tooltip, Typography, useMediaQuery} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {useMuiGridServerSide} from "../../../../hooks/muiGrid/useMuiGridServerSide";
import useUserData from "../../../../hooks/user/useUserData";
import dayjs from "dayjs";
import {muiGridLocales} from "../../../../helpers/muiLocales";
import {CustomPagination} from "../../../../components/grids/dataGridCustom/customPagination";
import axios from "axios";
import {API, OrderStatus as Status} from "../../../../helpers/constants";
import {enqueueSnackbar} from "notistack";
import {handleAxiosErrors} from "../../../../helpers/errorHandling";
import NewReturn from "../../components/newReturn";
import DataGridWrapper from "../../../../components/grids/dataGridWrapper/dataGridWrapper";
import OrderStatus from "../../../../components/app/orderStatus/orderStatus";
import ApproveReturnGrid from "../../components/approveReturnGrid/approveReturnGrid";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Invoice from "../../../../components/app/pdfInvoice/invoice";
import {useTheme} from "@mui/material/styles";
import HeaderInvoice from "../../../../components/app/pdfInvoice/header";
import TableItemsInvoice from "../../../../components/app/pdfInvoice/tableItemsInvoice";

const Index = () => {
	const {t} = useTranslation(PAGE_WARRANTIES)
	const {selectedStore, dateFormat, language} = useUserData()
	const [open, setOpen] = useState(false)
	const [isSecondGridVisible, setIsSecondGridVisible] = useState(false)
	const [selectedRow, setSelectedRow] = useState(null)
	const [dataInvoice, setData] = useState(null)
	const [items, setItems] = useState([])
	const [openInvoice, setOpenInvoice] = React.useState(false);
	const theme = useTheme();
	const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
	const handleOpenInvoice = (id) => {
		setOpenInvoice(true);
		axios
		.get(`${API}/en/transaction/data/` + id)
		.then((res) => {
			const data = res.data.data.length > 0 ? res.data.data[0] : {};
			setData(data);
			setItems(data.items);
		})
		.catch((error) => {
			enqueueSnackbar(handleAxiosErrors(error, t("Failed to download PDF"), {
				variant: "warning",
				autoHideDuration: 1000,
			}));
		});
	}
	
	const handleRowClick = React.useCallback((params) => {
		const newSelectedRow = params.row;
		// Toggle visibility if the clicked row is the same as the previously selected one
		if (selectedRow && newSelectedRow.id === selectedRow.id) {
			setIsSecondGridVisible((prev) => !prev);
		} else {
			// Show the second grid if the clicked row is different
			setSelectedRow(newSelectedRow);
			setIsSecondGridVisible(true);
		}
	}, [selectedRow, setIsSecondGridVisible]);
	
	const handleClose = (evt, reason) => {
		if (reason && (reason === "backdropClick" || reason === "escapeKeyDown")) return
		setOpen(false)
	}
	
	const {
		isLoading,
		data,
		total,
		page,
		pageSize,
		groupModel,
		onPaginationModelChange,
		onSortModelChange,
		onFilterModelChange,
		onGroupModelChange,
		refreshGrid
	} = useMuiGridServerSide({
		url: `/en/b2b/user/orders`,
		initialPageSize: 20,
		initialSortModel: [{name: 'id', direction: 'descending'}],
		otherDataParams: {
			AddressId: selectedStore?.id,
			type: 3
		}
	})
	
	const columns = useMemo(() => [
		{
			field: 'InsertedDt',
			headerName: t('Date'),
			flex: 1,
			minWidth: 140,
			type: 'date',
			valueGetter: (value, row, column, apiRef) => {
				return value && new Date(value)
			},
			valueFormatter: (value, row, column, apiRef) => {
				return (value ? dayjs(value).format(dateFormat) : '-')
			}
		},
		{
			field: 'Qty',
			headerName: t('Quantity'),
			flex: 1,
			minWidth: 160,
		},
		{
			field: 'QtyAccepted',
			headerName: t('Accepted Quantity'),
			flex: 1,
			minWidth: 140,
		},
		{
			field: 'B2BStatus',
			headerName: t('Status'),
			minWidth: 150,
			flex: 1,
			renderCell: ({row}) => (<OrderStatus isReturn={true} status={row.B2BStatus}/>),
			type: 'singleSelect',
			valueOptions: Object.entries(Status).map(status => {
				const value = status[1]
				return {
					value: value,
					label: t(value)
				}
			}),
		},
		{
			field: 'warehouse',
			headerName: t('Warehouse'),
			flex: 1,
			minWidth: 150,
		},
		{
			field: 'actions',
			headerName: t('Actions'),
			type: 'actions',
			minWidth: 110,
			flex: 1,
			getActions: (params) => {
				if (params.id === GRID_AGGREGATION_ROOT_FOOTER_ROW_ID) {
					return [];
				}
				return params.row.B2BStatus === 'Submitted' ? [
					<GridActionsCellItem
						component={Button}
						onClick={() => handleOpenInvoice(params.id)}
						icon={
							<Tooltip title={t('Open Pdf')}>
								<PictureAsPdfIcon/>
							</Tooltip>
						}
						label={'Download Pdf'}
					/>
				] : []
			}
		},
	], [dateFormat, handleOpenInvoice, t])
	
	const header = useMemo(() => {
		return (
			<HeaderInvoice/>
		)
	}, [])
	
	
	const dataInv = {
		invoiceIndexHeaders: {
			exportPdfButton: t('Export PDF'),
			documentTitle: t('Accompanying Return Document'),
			commentsBox: t('ETIOLOGY'),
		},
		invoiceIndexData: {
			commentsBox: /*dataInvoice && dataInvoice.Comments ? dataInvoice.Comments :*/ null
		},
		invoiceInfo: [
			{
				title: t('INVOICE TYPE'),
				value: dataInvoice && dataInvoice.TransactionType ? dataInvoice.TransactionType : null,
			},
			{
				title: t('NUMBER'),
				value: dataInvoice && dataInvoice.SeriesCode ? dataInvoice.SeriesCode : null,
			},
			{
				title: t('DATE'),
				value: dataInvoice && dataInvoice.SubmitDate ? dayjs(dataInvoice.SubmitDate).format("DD-MM-YYYY") : null,
			},
			{
				title: t('START TIME'),
				value: dataInvoice && dataInvoice.SubmitTime ? dataInvoice.SubmitTime : null
			},
		],
		customerDetailsHeader: t('CUSTOMER DETAILS'),
		customerDetails: [
			{
				title: t('CODE'),
				value: dataInvoice && dataInvoice.Code ? dataInvoice.Code : null,
			}, {
				title: t('NAME'),
				value: dataInvoice && dataInvoice.CustName ? dataInvoice.CustName : null,
			}, {
				title: t('JOB'),
				value: dataInvoice && dataInvoice.job ? dataInvoice.job : null,
			}, {
				title: t('ADDRESS'),
				value: dataInvoice && dataInvoice.Address ? dataInvoice.Address : null,
			}, {
				title: t('CITY'),
				value: dataInvoice && (dataInvoice.ZipCode || dataInvoice.City) ? dataInvoice.ZipCode + ' ' + dataInvoice.City : null,
			}, {
				title: t('PHONE NUMBER'),
				value: dataInvoice && dataInvoice.MobilePhone ? dataInvoice.MobilePhone : null,
			}, {
				title: t('VAT REG NO'),
				value: dataInvoice && dataInvoice.VatRegNo ? dataInvoice.VatRegNo : null,
			}, {
				title: t('TAX OFFICE'),
				value: dataInvoice && dataInvoice.TaxOffice ? dataInvoice.TaxOffice : null,
			}
		],
		customerDetailsComments: {
			title: t('RELATED INVOICE'),
			value: dataInvoice && dataInvoice.RelatedInvoice ? dataInvoice.RelatedInvoice : null
		},
		
		transactionDetails: [
			{
				title: t('TRANSACTION PLACE'),
				value: dataInvoice && dataInvoice.Warehouse ? dataInvoice.Warehouse : null
			},
			{
				title: t('TRANSACTION TYPE'),
				value: dataInvoice && dataInvoice.Agency ? dataInvoice.Agency : null
			},
		],
		footerInvoiceHeader: t('INVOICE TOTALS'),
		footerInvoice: [
			{
				title: t('VALUE BEFORE DISCOUNT'),
				value: dataInvoice && dataInvoice.AfterDiscount ? dataInvoice.AfterDiscount : '0.00'
			},
			{
				title: t('SALE'),
				value: dataInvoice && dataInvoice.DiscountTotal ? dataInvoice.DiscountTotal : '0.00'
			},
			{
				title: t('NET VALUE'),
				value: dataInvoice && dataInvoice.NetValue ? dataInvoice.NetValue : '0.00'
			},
			{
				title: t('VAT VALUE'),
				value: dataInvoice && dataInvoice.VatValue ? dataInvoice.VatValue : '0.00'
			},
			{
				title: t('CHARGES'),
				value: dataInvoice && dataInvoice.ChargeValue ? dataInvoice.ChargeValue : '0.00'
			},
			{
				title: t('PAYMENT TYPE DISCOUNT'),
				value: dataInvoice && dataInvoice.PaymentTypeDiscount ? dataInvoice.PaymentTypeDiscount : '0.00'
			},
			{
				title: t('VAT CHARGES'),
				value: dataInvoice && dataInvoice.ChargeVatValue ? dataInvoice.ChargeVatValue : '0.00'
			},
			{
				title: t('TOTAL VALUE'),
				value: dataInvoice && dataInvoice.TotalValue ? dataInvoice.TotalValue : '0.00'
			}
		],
		footerInvoiceComments: [
			{
				title: t('COMMENTS'),
				value: dataInvoice && dataInvoice.Comments ? dataInvoice.Comments : null,
				customStyle: {
					fontWeight: 'bold'
				}
			}
		]
	}
	
	const updateItems = items && items.map(item => ({
		column1: item.Code,
		column2: item.AltCode,
		column3: item.ItemDescription,
		column4: item.MM,
		column5: item.Qty,
		column6: item.ItemValue,
		column7: item.PCTDiscount,
		column8: item.DiscountValue,
		column9: item.AfterDiscount,
		column10: item.ChargeValue,
		column11: item.ChargeVatValue,
		column12: item.Vat,
	}));
	
	const handleCloseInvoice = () => setOpenInvoice(false);
	
	return (
		<Box>
			<Box p={1}>
				<Button variant={'outlined'} component={Button}
						onClick={() => setOpen(true)}>{t('New Return')}</Button>
				<Typography
					pb={1}
					variant={'h2'}
					component={'div'}
					sx={{
						textAlign: 'center'
					}}
				>
					{t('Returns')}
				</Typography>
				<DataGridWrapper sx={{height: 300}}>
					<DataGridPremium
						onRowClick={handleRowClick}
						sx={{background: '#FFFFFF'}}
						columns={columns}
						rows={data}
						slots={{
							toolbar: GridToolbar,
							pagination: CustomPagination
						}}
						slotProps={{
							toolbar: {
								printOptions: {disableToolbarButton: true},
							},
						}}
						initialState={{density: "compact"}}
						pagination
						paginationModel={{page, pageSize}}
						paginationMode="server"
						onPaginationModelChange={onPaginationModelChange}
						rowCount={Number(total)}
						pageSizeOptions={[5, 10, 15, 20, 50, 100]}
						sortingMode="server"
						onSortModelChange={(sortModel) => onSortModelChange(sortModel)}
						filterMode="server"
						onFilterModelChange={onFilterModelChange}
						rowGroupingModel={groupModel}
						onRowGroupingModelChange={onGroupModelChange}
						localeText={muiGridLocales(language)}
						loading={isLoading}
						disableVirtualization={matchesMd}
					/>
				</DataGridWrapper>
			</Box>
			{isSecondGridVisible &&
				<div key={selectedRow?.id}>
					<ApproveReturnGrid
						refresh={refreshGrid}
						selectedRow={selectedRow}
					/>
				</div>
			}
			<Dialog
				maxWidth={'lg'}
				open={open}
				onClose={handleClose}
			>
				<NewReturn refresh={refreshGrid} onClose={handleClose}/>
			</Dialog>
			<Modal
				open={openInvoice}
				onClose={handleCloseInvoice}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				sx={{maxWidth: '1350px', maxHeight: '1000px', overflow: 'scroll', m: 4, justifySelf: 'center'}}
			>
				<Invoice
					data={dataInv}
					items={updateItems}
					header={header}
					handleCloseInvoice={handleCloseInvoice}
					closeButton={true}
				>
					<TableItemsInvoice/>
				</Invoice>
			</Modal>
		
		</Box>
	)
}
export default Index