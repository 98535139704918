import useReportsAction from "./useReportsAction";
import {useEffect, useMemo, useState} from "react";
import useReportsData from "./useReportsData";
import {useFormik} from "formik";

const useSalesReport = () => {
	const [dateRange, setDateRange] = useState([null, null])
	const {loading, salesReportData, salesReportFilters} = useReportsData()
	const {getSalesReportData, getSalesReportFilters} = useReportsAction()
	useEffect(() => {
		getSalesReportFilters()
	}, [getSalesReportFilters])
	useEffect(() => {
		getSalesReportData({
			quarters: '',
			years: '',
			attributes: '',
			
		})
	}, [getSalesReportData])
	const initialValues = useMemo(() => {
		const topFilterValues = salesReportFilters.reduce((acc, filter) => {
			filter.values.forEach(value => {
				acc[`value-${value.id}`] = false
			});
			return acc;
		}, {})
		return {
			...topFilterValues
		}
	}, [salesReportFilters])
	const onSubmit = (values) => {
		let attributeValueIds = []
		let years = []
		let quarters = []
		for (const [key, value] of Object.entries(values)) {
			if (value) {
				if (key.includes('value-year-')) {
					years.push(key.split('-').pop())
				} else if (key.includes('value-quarter-')) {
					quarters.push(key.split('-').pop())
				} else {
					attributeValueIds.push(key.split('-').pop())
				}
			}
		}
		getSalesReportData({
			quarters: quarters.join(','),
			years: years.join(','),
			attributes: attributeValueIds.join(','),
			dateRange: dateRange
		})
	}
	const formik = useFormik({
		initialValues: {
			...initialValues
		},
		validateOnChange: false,
		enableReinitialize: true,
		onSubmit: onSubmit
	})
	
	const totalSales = useMemo(() => (salesReportData?.items?.reduce((acc, year) => (
		acc + year.quantity
	), 0)), [salesReportData?.items])
	
	const chartData = useMemo(() => (
		salesReportData?.items?.map(item => item.quantity)
	), [salesReportData?.items])
	
	const chartLabels = useMemo(() => (
		salesReportData?.items?.map(item => String(item.year))
	), [salesReportData?.items])
	
	return {
		loading,
		salesReportData,
		salesReportFilters,
		formik,
		totalSales,
		chartData,
		chartLabels,
		dateRange,
		setDateRange
	}
}

export default useSalesReport