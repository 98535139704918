//@flow
import {createSelector} from 'reselect'
import {useSelector} from "react-redux";
import {parseStore, parseUser, parseUserMessage} from "../../types/user/parse";
import {parseMultiple} from "../../helpers/parse";
import type {Store} from "../../types/user/types";
import {parseWarehouse} from "../../types/warehouse/parse";
import {parseFilter} from "../../types/filters/parse";
import type {Warehouse} from "../../types/warehouse/types";
import type {Filter} from "../../types/filters/types";
import {useMemo} from "react";
import _ from "lodash";

const selectLoading = createSelector(
	(state) => state.currentUser,
	(item) => item.get('loading')
)

const selectMessage = createSelector(
	(state) => state.currentUser.get('message'),
	(item) => parseUserMessage(item)
)

const selectLanguage = createSelector(
	(state) => state.currentUser,
	(item) => item.get('language')
)

const selectDecimalSeparator = createSelector(
	(state) => state.currentUser,
	(item) => item.get('decimalSeparator')
)

const selectThousandSeparator = createSelector(
	(state) => state.currentUser,
	(item) => item.get('thousandSeparator')
)

const selectDateFormat = createSelector(
	(state) => state.currentUser,
	(item) => item.get('dateFormat')
)

const selectTimeFormat = createSelector(
	(state) => state.currentUser,
	(item) => item.get('timeFormat')
)

const selectIsLoggedIn = createSelector(
	(state) => state.currentUser,
	(item) => item.get('isLoggedIn')
)

const selectToken = createSelector(
	(state) => state.currentUser,
	(item) => item.get('token')
)

const selectTokenExpirationDateTime = createSelector(
	(state) => state.currentUser,
	(item) => item.get('tokenExpirationDateTime')
)

const selectPasswordReset = createSelector(
	(state) => state.currentUser,
	(item) => item.get('passwordReset')
)

const selectRecoveryPwdTime = createSelector(
	(state) => state.currentUser,
	(item) => item.get('recoveryPwdTime')
)

const selectResetTime = createSelector(
	(state) => state.currentUser,
	(item) => item.get('resetTime')
)

const selectLoadingStores = createSelector(
	(state) => state.currentUser,
	(item) => item.get('loadingStores')
)

const selectSelectedStore = createSelector(
	[
		(state) => state.currentUser.get('storesById'),
		(state) => state.currentUser.get('selectedStore'),
		(state) => state.currentUser.get('language'),
	],
	(byId, storeId, language) => parseStore(byId.get(storeId), language)
)

const selectStores = createSelector(
	[
		(state) => state.currentUser.get('storesById'),
		(state) => state.currentUser.get('language'),
	],
	(items, language) => parseMultiple(items, (i) => parseStore(i, language))
)

const selectData = createSelector(
	[
		(state) => state.currentUser.get('data'),
		(state) => state.currentUser.get('language'),
	],
	(item, language) => parseUser(item, language)
)

const selectWarehouses = createSelector([
		(state) => state.currentUser.get('warehouses'),
		(state) => state.currentUser.get('language')
	],
	(warehouses, language) => parseMultiple(warehouses, (warehouse) => parseWarehouse(warehouse, language))
)

const selectTopFilters = createSelector([
		(state) => state.currentUser.get('topFilters'),
		(state) => state.currentUser.get('language')
	],
	(filters, language) => parseMultiple(filters, (filter) => parseFilter(filter, language))
)

const selectMaxAllowedProductsInCart = createSelector(
	(state) => state.currentUser,
	(item) => item.getIn(['appParameters', 'maxAllowedProductsInCart'])
)

const selectDisplayStock = createSelector(
	(state) => state.currentUser,
	(item) => item.getIn(['appParameters', 'displayStock'])
)

const selectMaxAllowedReturnItems = createSelector(
	(state) => state.currentUser,
	(item) => item.getIn(['appParameters', 'maxAllowedReturnItems'])
)

const selectDayLimitForReturns = createSelector(
	(state) => state.currentUser,
	(item) => item.getIn(['appParameters', 'dayLimitForReturns'])
)

const selectMonthLimitForWarranties = createSelector(
	(state) => state.currentUser,
	(item) => item.getIn(['appParameters', 'monthLimitForWarranties'])
)

const selectSuccessDailyCode = createSelector(
	(state) => state.currentUser,
	(item) => item.get('successDailyCode')
)

const selectSuccessResetPass = createSelector(
	(state) => state.currentUser,
	(item) => item.get('successResetPass')
)

const selectRequiredDailyCodeTime = createSelector(
	(state) => state.currentUser.get('requiredDailyCodeTime'),
	(item) => item
)

const selectExpandedAnnouncements = createSelector(
	(state) => state.currentUser,
	(item) => item.getIn(['data', 'hasExpandedAnnouncements'])
)

const useUserData = () => {
	const loading = useSelector(selectLoading)
	const message = useSelector(selectMessage)
	const language = useSelector(selectLanguage)
	const decimalSeparator = useSelector(selectDecimalSeparator)
	const thousandSeparator = useSelector(selectThousandSeparator)
	const dateFormat = useSelector(selectDateFormat)
	const timeFormat = useSelector(selectTimeFormat)
	const isLoggedIn = useSelector(selectIsLoggedIn)
	const token = useSelector(selectToken)
	const tokenExpirationDateTime = useSelector(selectTokenExpirationDateTime)
	const passwordReset = useSelector(selectPasswordReset)
	const recoveryPwdTime = useSelector(selectRecoveryPwdTime)
	const resetTime = useSelector(selectResetTime)
	const loadingStores = useSelector(selectLoadingStores)
	const selectedStore = useSelector(selectSelectedStore)
	const stores: Array<Store> = useSelector(selectStores)
	const data = useSelector(selectData)
	const maxAllowedProductsInCart = useSelector(selectMaxAllowedProductsInCart)
	const displayStock = useSelector(selectDisplayStock)
	const maxAllowedReturnItems = useSelector(selectMaxAllowedReturnItems)
	const dayLimitForReturns = useSelector(selectDayLimitForReturns)
	const monthLimitForWarranties = useSelector(selectMonthLimitForWarranties)
	const warehouses: Array<Warehouse> = useSelector(selectWarehouses)
	const topFilters: Array<Filter> = useSelector(selectTopFilters)
	const successDailyCode = useSelector(selectSuccessDailyCode)
	const successResetPass = useSelector(selectSuccessResetPass)
	const requiredDailyCodeTime = useSelector(selectRequiredDailyCodeTime)
	const hasExpandedAnnouncements = useSelector(selectExpandedAnnouncements)
	
	const topFiltersSorted = useMemo(() => {
		return _.orderBy(topFilters.map(f => ({
			...f,
			values: _.orderBy(f.values, ['weight', 'description'], ['asc', 'asc'])
		})), ['weight', 'description'], ['asc', 'asc'])
	}, [topFilters])
	
	const ordersheetId = useMemo(() => (
		data?.orderTypes.length > 0 ? data.orderTypes[0]?.id : null
	), [data?.orderTypes, data?.orderTypes[0]?.id])

	const warehousesSorted = useMemo(() => (
		_.orderBy(warehouses, 'address', 'asc')
	), [warehouses])
	
	const hasSecondPaymentDiscount = useMemo(() => (
		data?.secondPaymentDiscount && data?.secondPaymentDiscount > 0
	), [data?.secondPaymentDiscount])

	return {
		loading,
		message,
		language,
		decimalSeparator,
		thousandSeparator,
		dateFormat,
		timeFormat,
		isLoggedIn,
		token,
		tokenExpirationDateTime,
		passwordReset,
		recoveryPwdTime,
		resetTime,
		loadingStores,
		selectedStore,
		stores,
		data,
		warehouses: warehousesSorted,
		topFiltersSorted,
		ordersheetId,
		maxAllowedProductsInCart,
		displayStock,
		maxAllowedReturnItems,
		dayLimitForReturns,
		monthLimitForWarranties,
		successDailyCode,
		successResetPass,
		requiredDailyCodeTime,
		hasExpandedAnnouncements,
		hasSecondPaymentDiscount
	}
}

export default useUserData