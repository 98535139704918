// @flow
import React from 'react';
import Box from "@mui/material/Box";
import TopFilters from "../../components/app/topFilters/topFilters";
import HomeNews from "../../components/app/homeNews/homeNews";
import Banners from "../../components/app/banners/banners";
import SearchResults from "../searchResults/searchResults";
import useFilters from "../../hooks/filters/useFilters";
import Confirmation from "../../components/app/dialogs/confirmation/confirmation";
import {Typography} from "@mui/material";
import Attachments from "../../components/app/attachments/attachments";
import useMessageData from "../../hooks/message/useMessageData";
import useMessageAction from "../../hooks/message/useMessageAction";

type Props = {};

const Home = (props: Props) => {
	const {
		path,
		formik,
		sideFilters,
		topFilters,
		urlFilters,
		attributeValueIds,
		search,
		search1Ref,
		search2Ref,
		search3Ref,
		clearFilters
	} = useFilters()
	const {loading, importantAnnouncement, showImportantAnnouncement} = useMessageData()
	const {toggleImportantAnnouncement, getMessageAttachments} = useMessageAction()
	return (
		<Box
			sx={{
				display: 'flex',
				flexWrap: 'wrap'
			}}
			width={'100%'}>
			<Box width={'100%'}>
				<TopFilters
					formik={formik}
					attributeValueIds={attributeValueIds}
					search={search}
					topFilters={topFilters}
					search1Ref={search1Ref}
					search2Ref={search2Ref}
					search3Ref={search3Ref}
					clearFilters={clearFilters}
				/>
			</Box>
			{path === '/search-results' ? (
				<SearchResults
					formik={formik}
					sideFilters={sideFilters}
					urlFilters={urlFilters}
				/>
			) : (
				<Box sx={{display: 'flex', flexWrap: 'wrap', width: '100%'}}>
					<Box width={{sm: '100%'}} mb={1}>
						<Banners/>
					</Box>
					<Box width={{sm: '100%'}}>
						<HomeNews/>
						{importantAnnouncement && importantAnnouncement?.id && !loading && (
							<Confirmation
								open={showImportantAnnouncement}
								handleDialog={(newValue) => toggleImportantAnnouncement(newValue)}
								noAction={false}
								onYes={() => toggleImportantAnnouncement(false)}
								yesBtnText={'OK'}
								hasBtnIcons={false}
								maxWidth={'md'}
							>
								<Typography
									variant={'subtitle1'}
									component={'div'}
									color={'#255b82'}
								>
									{importantAnnouncement.title}
								</Typography>
								<Typography variant="body1" component={'div'}>
									<div className={'announcement'}
										 dangerouslySetInnerHTML={{__html: importantAnnouncement.text}}/>
								</Typography>
								<Attachments id={importantAnnouncement.id} files={importantAnnouncement.files}
											 getMessageAttachments={getMessageAttachments}/>
							</Confirmation>
						)}
					</Box>
				</Box>
			)}
		</Box>
	);
};

export default Home