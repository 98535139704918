import React from "react";
import Logo from "../logo/logo";
import './invoice.css'

const HeaderInvoice = () => {
    return (
        <div className="form-box form-box_12 custom-box">
            <div className="form-grid-image">
                <div>
                    <div className="form_field">
                        <div className="userImage">
                            <Logo
                                style={{
                                    width: '300px',
                                    height: '150px'
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <h5 className="main-title-header-invoice">SUPER TIRE SERVICE A.E. (S.T.S. A.E.)</h5>
                        <div className="container_1">
                            <div className="container_2">
                                <div className="titleHeaderInvoice">
                                    <h5 style={{fontWeight: 'bold'}}>Έδρα</h5>
                                </div>
                                <div>
                                    <p>Μποζαΐτικα Πατρών (πρώην αποθήκες ΑΤΕ)</p>
                                    <p>Τ.Κ.: 264 42, Πάτρα</p>
                                    <p>Τηλ.: 2610 436006</p>
                                    <p>2610 453351</p>
                                    <p>Φαξ: 2610 436006</p>
                                </div>
                            </div>
                            <div className="container_2">
                                <div className="titleHeaderInvoice">
                                    <h5 style={{fontWeight: 'bold'}}>Υποκατάστημα Αθηνών</h5>
                                </div>
                                <div>
                                    <p>Αγίου Νικολάου 12</p>
                                    <p>Τ.Κ.: 135 61, Άγιοι Ανάργυροι Αττικής, Αθήνα</p>
                                    <p>Τηλ.: 210 6121215</p>
                                    <p>210 8320400</p>
                                    <p>210 6121232</p>
                                    <p>Φαξ : 210 8320402</p>
                                </div>
                            </div>
                            <div className="container_2">
                                <div className="titleHeaderInvoice">
                                    <h5 style={{fontWeight: 'bold'}}>Υποκατάστημα Θεσ/νίκης</h5>
                                </div>
                                <div>
                                    <p>6.5χλμ Λαγκαδά - Θεσ/νίκης</p>
                                    <p>Τ.Κ.: 135 61, Θεσσαλονίκη</p>
                                    <p>Τηλ.: 2310 680359</p>
                                    <p>Φαξ : 2310 685843</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderInvoice;