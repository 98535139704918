import {parseMultiple} from '../../../../helpers/parse'
import {connect} from 'react-redux'
import PermissionHOCDefault from './permissionHOC.default'
import React from "react";
import {useTranslation} from "react-i18next";
import {PAGE_LOGIN} from "../../../../locales/pages/namespaces";
import {parsePermission} from "../../types/parse";
import NotFound from "../../../../pages/notFound/notFound";

const mapStateToProps = (state) => ({
	loading: state.permissions.get('loading'),
	currentUserPermissions: parseMultiple(state.permissions.get('currentUser'), parsePermission)
})

const HOC = connect(mapStateToProps, null)(PermissionHOCDefault)

const NotAllowedComponent = () => {
	return (
		<div className={'bg-container contained-page'} style={{textAlign: "center"}}>
			<NotFound/>
		</div>
	)
}

const LoadingComponent = () => {
	const {t} = useTranslation(PAGE_LOGIN)
	return (
		<div className={'bg-container contained-page'}>
			{t('loading')}...
		</div>
	)
}

export function withPermissionHOC(WrappedComponent, requiredPermissions) {
	return class extends React.Component {
		render() {
			return (
				<HOC
					requiredPermissions={requiredPermissions}
					notAllowedComponent={NotAllowedComponent}
					loadingComponent={LoadingComponent}
				>
					<WrappedComponent
						{...this.props}
					/>
				</HOC>
			)
		}
	}
}


export default HOC