import {useState} from "react";

export const useProductPopUps = () => {
	const [calculatorOpen, setCalculatorOpen] = useState({
		open: false,
		prices: null
	})
	const handleCalculator = (newValue) => {
		setCalculatorOpen({...newValue})
	}
	
	const [productInfo, setProductInfo] = useState({
		open: false,
		product: null
	})
	const handleProductInfo = (newValue) => {
		setProductInfo({...newValue})
	}
	
	return {
		calculatorOpen,
		handleCalculator,
		productInfo,
		handleProductInfo
	}
}