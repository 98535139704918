export default function PaginationItem(theme) {
	return {
		MuiPaginationItem: {
			styleOverrides: {
				root: {},
				text: {},
				contained: {},
				combined: {},
				outlined: {}
			}
		}
	};
}
