import type {UnmappedWarehouse, Warehouse} from "./types";

export const mapWarehouse: Warehouse = (datum: UnmappedWarehouse) => {
	if (!datum) return
	return {
		id: Number(datum.id),
		description: {
			el: datum.DescriptionEl,
			en: datum.DescriptionEl
		}
	}
}