import useReportsData from "./useReportsData";
import useReportsAction from "./useReportsAction";
import {useEffect, useMemo, useState} from "react";
import {getBudgetData, getBudgetFilters} from "../../helpers/reports";
import _ from "lodash";

const useBudgetReport = () => {
	const {loading, budgetReport} = useReportsData()
	const {getBudgetAndGoalsReport} = useReportsAction()
	useEffect(() => {
		getBudgetAndGoalsReport()
	}, [getBudgetAndGoalsReport])
	const [filters, setFilters] = useState(getBudgetFilters(budgetReport))
	useEffect(() => {
		setFilters(getBudgetFilters(budgetReport))
	}, [budgetReport])
	
	const data = useMemo(() => (
		getBudgetData(budgetReport, filters)
	), [budgetReport, filters])
	
	const handleFilters = (newFilterValue) => {
		const {filter, description, value, uniqueValue} = newFilterValue
		const currentValues = filters[filter].values
		const mappedValues = _.map(currentValues, 'value')
		const mapFalseValues = mappedValues.filter(f => f === false)
		const updateValues = currentValues.map(val => ({
			...val,
			value: val.description === description ? (
				uniqueValue ? (
					value === false ? !value : value
				) : (
					value === false && val.value === true && mapFalseValues.length === mappedValues.length - 1 ? val.value : value
				)
			) : (
				uniqueValue ? (
					value === false && mapFalseValues.length === mappedValues.length - 1 ? val.value : !value
				) : val.value
			)
		}))
		setFilters(prevState => ({
			...prevState,
			[filter]: {
				...prevState[filter],
				values: [...updateValues]
			}
		}))
	}
	return {
		data,
		filters,
		loading,
		handleFilters
	}
	
}

export default useBudgetReport