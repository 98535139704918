import React, {useEffect} from 'react';
import {Badge, Box, IconButton} from '@mui/material';
import {ShoppingCartOutlined} from "@mui/icons-material";
import {useHistory} from "react-router-dom";
import {getRouteUrl} from "../../../helpers/getRouteUrl";
import {ROUTE_PAGE_ORDER_FINALIZE} from "../../../routers/routes";
import {useOrderData} from "../../../hooks/order/useOrderData";
import useOrderAction from "../../../hooks/order/useOrderAction";
import {enqueueSnackbar} from "notistack";
import {NAVIGATION} from "../../../locales/components/namespaces";
import {useTranslation} from "react-i18next";
import {menuIconStyle} from "../../layout/header/header";

const Cart = () => {
	const {t} = useTranslation(NAVIGATION)
	const history = useHistory()
	const {activeOrder, totalProductsInCart, message} = useOrderData()
	const {getActiveOrder, setOrderMessage} = useOrderAction()
	
	useEffect(() => {
		getActiveOrder()
	}, [getActiveOrder])
	
	useEffect(() => {
		if (message) {
			enqueueSnackbar(t(message?.text), {
					variant: message?.variant
				}
			)
			setOrderMessage(null)
		}
	}, [message, setOrderMessage, t])
	
	return (
		<Box sx={{flexShrink: 0, ml: 0.75}}>
			<IconButton
				color="primary"
				sx={{bgcolor: '#faf0e6', ...menuIconStyle}}
				onClick={() => {
					getActiveOrder()
					history.push(getRouteUrl(ROUTE_PAGE_ORDER_FINALIZE, {id: activeOrder[0]?.id}))
				}}
			>
				<Badge badgeContent={totalProductsInCart} color="secondary">
					<ShoppingCartOutlined/>
				</Badge>
			</IconButton>
		</Box>
	);
};

export default Cart;
