// @flow
import React from 'react';
import useSalesReport from "../../../hooks/reports/useSalesReport";
import Box from "@mui/material/Box";
import ContentLoader from "../../../components/general/contentLoader/contentLoader";
import {useTranslation} from "react-i18next";
import {PAGE_REPORTS} from "../../../locales/pages/namespaces";
import {FormikProvider} from "formik";
import Button from "@mui/material/Button";
import {Search} from "@mui/icons-material";
import SalesFilters from "./partials/salesFilters";
import SalesKpi from "./partials/salesKpi";
import SalesChart from "./partials/salesChart";
import './sales.css'
import {Typography} from "@mui/material";

type Props = {};

const Sales = (props: Props) => {
    const {t} = useTranslation(PAGE_REPORTS)
    const {
        loading,
        salesReportData,
        salesReportFilters,
        formik,
        totalSales,
        chartData,
        chartLabels,
        dateRange,
        setDateRange
    } = useSalesReport()
    return !loading ? (
        <FormikProvider value={formik}>
            <Box
                p={2}
                component={'form'}
            >
                <SalesFilters
                    formik={formik}
                    filters={salesReportFilters}
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                />
                <Box>
                    <Button
                        size={"medium"}
                        variant={'contained'}
                        color={'secondary'}
                        fullWidth
                        onClick={(evt) => formik.submitForm(evt)}
                    >
                        <Search/>
                        {t('Search')}
                    </Button>
                </Box>
            </Box>
            {salesReportData.items.length > 0 ? (
                !salesReportData?.loading ? (
                    <Box p={2} className={'sales-report'}>
                        <SalesKpi
                            title={t('SALES TOTAL')}
                            totalSales={totalSales}
                        />
                        {salesReportData.items.map(item => (
                            <SalesKpi
                                key={item.year}
                                title={item.year}
                                totalSales={item.quantity}
                            />
                        ))}
                        <SalesChart
                            chartData={chartData}
                            chartLabels={chartLabels}
                        />
                    </Box>
                ) : (
                    <ContentLoader loading={salesReportData?.loading}/>
                )
            ) : (
                <Typography variant="h5" component={'div'} sx={{textAlign: 'center', width: '100%', p: 1}}>
                    {t('No data found')}
                </Typography>
            )}
        </FormikProvider>
    ) : (
        <ContentLoader loading={loading}/>
    )
};

export default Sales