import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {useTranslation} from "react-i18next";
import {Download} from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import {FILES} from "../../../locales/components/namespaces";
import {AuthenticatedLink} from "../../general/authenticatedLink/authenticatedLink";

type Props = {
	onEdit: Function,
	onDelete: Function
}

const FileRowMenu = (props: Props) => {
	const {t} = useTranslation(FILES)
	const [anchorEl, setAnchorEl] = React.useState(null)
	const open = Boolean(anchorEl)
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = (action) => () => {
		if (action) {
			action()
		}
		setAnchorEl(null)
	}
	const {showAliasInput, onEdit, onDelete, downloadUrl, downloadFileName} = props
	return (<div>
		<IconButton onClick={handleClick}>
			<MoreVertIcon/>
		</IconButton>
		<Menu
			transformOrigin={{horizontal: 'right', vertical: 'top'}}
			anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
			anchorEl={anchorEl}
			open={open}
			onClose={handleClose()}
		>
			<AuthenticatedLink
				filename={downloadFileName}
				url={downloadUrl}
			>
				<MenuItem
					onClick={handleClose()}
					disableRipple
				>
					<ListItemIcon>
						<Download fontSize="small"/>
					</ListItemIcon>
					<ListItemText>{t('Download')}</ListItemText>
				</MenuItem>
			</AuthenticatedLink>
			{showAliasInput && (
				<MenuItem onClick={handleClose(onEdit)} disableRipple>
					<ListItemIcon>
						<EditIcon fontSize="small"/>
					</ListItemIcon>
					<ListItemText>{t('Edit')}</ListItemText>
				</MenuItem>
			)}
			<MenuItem onClick={handleClose(onDelete)} disableRipple>
				<ListItemIcon>
					<DeleteIcon fontSize="small"/>
				</ListItemIcon>
				<ListItemText>{t('Delete')}</ListItemText>
			</MenuItem>
		</Menu>
	</div>)
}

export default FileRowMenu