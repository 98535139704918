// @flow
import React, {useEffect, useState} from 'react';
import {Autocomplete, FormControl, FormHelperText, Paper, TextField, Typography} from "@mui/material";
import {ErrorMessage, FormikProvider} from "formik";
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";
import {PAGE_WARRANTIES} from "../../../../locales/pages/namespaces";
import axios from "axios";
import {API, Permissions} from "../../../../helpers/constants";
import {enqueueSnackbar} from "notistack";
import {handleAxiosErrors} from "../../../../helpers/errorHandling";
import useUserData from "../../../../hooks/user/useUserData";
import useWarrantyAccident from "../../../../hooks/warranties/useWarrantyAccident";
import {useParams} from "react-router";
import ContentLoader from "../../../../components/general/contentLoader/contentLoader";
import Button from "@mui/material/Button";
import {getRouteUrl} from "../../../../helpers/getRouteUrl";
import {ROUTE_PAGE_WARRANTIES} from "../../../../routers/routes";
import {useHistory} from "react-router-dom";
import List from "@mui/material/List";
import TireSelection from "./partials/tireSelection/tireSelection";
import ImageWithPreview from "./partials/imageWithPreview/imageWithPreview";
import {withPermissionHOC} from "../../../../modules/permission/components/permissionHOC/permissionHOC";

type Props = {};

const AccidentForm = (props: Props) => {
	const {code, id} = useParams()
	const history = useHistory()
	const {t} = useTranslation(PAGE_WARRANTIES);
	const {token} = useUserData()
	const [list, setList] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const {formik, accidentDetails, loading} = useWarrantyAccident({list, id: code})
	
	useEffect(() => {
		setIsLoading(true)
		axios({
			method: 'get',
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			url: `${API}/el/guarantee/address/list`,
		}).then(response => {
			setList(response.data.addresses)
			setIsLoading(false)
		}).catch((error) => {
			enqueueSnackbar(handleAxiosErrors(error, t('Failed!'), {
				variant: 'warning',
				autoHideDuration: 1000,
			}));
			setIsLoading(false)
		})
	}, [t, token])
	
	return !loading && !isLoading ? (
		<FormikProvider value={formik}>
			<Box p={1} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
				<Typography
					variant={'h2'}
					component={'div'}
					pb={1}
					sx={{
						textAlign: 'center',
					}}
				>
					{t("Accident Report")}
				</Typography>
				<Paper elevation={0} sx={{maxWidth: '1000px', width: '100%'}}>
					<Box p={1}>
						<Box display={'flex'} justifyContent={'space-between'} mb={1}>
							<Button
								variant={'outlined'}
								color={'error'}
								onClick={() => history.push(getRouteUrl(ROUTE_PAGE_WARRANTIES))}
							>
								{t('Cancel')}
							</Button>
							{ (accidentDetails.StatusDescription === 'Activated' || accidentDetails.StatusDescription === 'Pending Approval') &&
								<Button
									color={'success'}
									variant={'outlined'}
									type={'submit'}
									onClick={formik.submitForm}
								>
									{t('Send')}
								</Button>
							}

						</Box>
						<Box>
							<Box sx={{maxWidth: '800px'}} p={1}>
								<FormControl
									variant="standard"
									fullWidth
									error={
										formik.touched['PlacedAddrId'] &&
										Boolean(formik.errors['PlacedAddrId'])
									}
								>
									<Autocomplete
										disabled
										sx={{
											"& .MuiFormLabel-root.Mui-disabled": {
												WebkitTextFillColor: "#034b80",
											},
											"& .MuiInputBase-input.Mui-disabled": {
												WebkitTextFillColor: "#034b80",
											},
										}}
										options={list}
										isOptionEqualToValue={(opt, val) => {
											return opt.id === val.id || !val
										}}
										getOptionLabel={option => option?.Address.concat('/', option?.CustName)}
										value={formik.values.PlacedAddrId || null}
										onChange={(event, newValue) => {
											formik.setFieldValue('PlacedAddrId', newValue)
										}}
										onBlur={formik.handleBlur}
										size="medium"
										fullWidth
										renderInput={(params) => (
											<TextField
												label={t('Store')}
												size={'medium'}
												variant={'standard'}
												{...params}
												inputProps={{
													...params.inputProps,
													autoComplete: 'off',
												}}
											/>
										)}
									/>
									<FormHelperText>
										<ErrorMessage name={'PlacedAddrId'}/>
									</FormHelperText>
								</FormControl>
							</Box>
							<Box p={1} sx={{textAlign: 'center'}}>
								<Typography
									variant={'body1'}
									component={'div'}
								>
									{t('Tire selection')}
								</Typography>
								<TireSelection formik={formik} accidentDetails={accidentDetails}/>
							</Box>
							<Box p={1}>
								<List>
									<ImageWithPreview
										formik={formik}
										label={'Tire Problem'}
										fileField={'tireProblemImg'}
										pathField={'tireProblemImgPath'}
										getImageUrl={`${API}/en/guarantee/${id}/image/4`}
										isDetails={accidentDetails.StatusDescription !== 'Activated'
											&& accidentDetails.StatusDescription !== 'Pending Approval' && true}
									/>
									<ImageWithPreview
										formik={formik}
										label={'Dot'}
										fileField={'dotImg'}
										pathField={'dotImgPath'}
										getImageUrl={`${API}/en/guarantee/${id}/image/1`}
										isDetails={accidentDetails.StatusDescription !== 'Activated'
											&& accidentDetails.StatusDescription !== 'Pending Approval' && true}
									/>
									<ImageWithPreview
										formik={formik}
										label={'Km'}
										fileField={'KmImg'}
										pathField={'KmImgPath'}
										getImageUrl={`${API}/en/guarantee/${id}/image/5`}
										isDetails={accidentDetails.StatusDescription !== 'Activated'
											&& accidentDetails.StatusDescription !== 'Pending Approval' && true}
									/>
									<ImageWithPreview
										formik={formik}
										label={'License Plate'}
										fileField={'licensePlateImg'}
										pathField={'licensePlateImgPath'}
										getImageUrl={`${API}/en/guarantee/${id}/image/3`}
										isDetails={accidentDetails.StatusDescription !== 'Activated'
											&& accidentDetails.StatusDescription !== 'Pending Approval' && true}
									/>
								</List>
							</Box>
						</Box>
					</Box>
				</Paper>
			</Box>
		</FormikProvider>
	) : (
		<ContentLoader loading={loading}/>
	);
};

export default withPermissionHOC(AccidentForm, [Permissions.AccessGuarantees])