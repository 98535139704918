// @flow
import React from 'react';
import Box from "@mui/material/Box";
import {Typography} from "@mui/material";
import {NumericFormat} from "react-number-format";
import Kpi from "../../../../components/general/kpi/kpi";
import {useTranslation} from "react-i18next";
import {PAGE_REPORTS} from "../../../../locales/pages/namespaces";
import useUserData from "../../../../hooks/user/useUserData";

type Props = {};

const SalesKpi = (props: Props) => {
    const {totalSales, title} = props
    const {t} = useTranslation(PAGE_REPORTS)
    const {thousandSeparator, decimalSeparator} = useUserData()
    return (
        <Kpi customStyle={{maxHeight: '200px', maxWidth: '200px', width: '100%', marginBottom: '1rem'}}>
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Typography variant="h5" component={'div'} color="secondary">
                    {title}
                </Typography>
                <Typography variant="body1" component={'div'} color={'primary'}>
                    ({t('pieces')})
                </Typography>
                <Typography variant="h4" component={'div'} color={'primary'}>
                    <NumericFormat
                        thousandSeparator={thousandSeparator}
                        decimalSeparator={decimalSeparator}
                        displayType="text"
                        value={totalSales}
                    />
                </Typography>
            </Box>
        </Kpi>
    );
};

export default SalesKpi