// @flow
import React, {useEffect} from 'react';
import {useParams} from "react-router";
import {useTranslation} from "react-i18next";
import {PAGE_ORDERS} from "../../locales/pages/namespaces";
import useOrderAction from "../../hooks/order/useOrderAction";
import useOrderDetailsData from "../../hooks/order/useOrderDetailsData";
import {useOrderData} from "../../hooks/order/useOrderData";
import Box from "@mui/material/Box";
import {Divider, Typography} from "@mui/material";
import Overview from "../../components/app/overview/overview";
import OrderPrices from "../../components/app/orderPrices/orderPrices";
import ContentLoader from "../../components/general/contentLoader/contentLoader";
import ProductPreview from "../../components/app/productPreview/productPreview";
import '../order/order.css'
import {OrderStatus} from "../../helpers/constants";
import {getRouteUrl} from "../../helpers/getRouteUrl";
import {ROUTE_PAGE_HOME} from "../../routers/routes";
import {useHistory} from "react-router-dom";

type Props = {};

const OrderPlaced = (props: Props) => {
	const {id} = useParams()
	const history = useHistory()
	const {t} = useTranslation(PAGE_ORDERS)
	const {getOrderDetails} = useOrderAction()
	const {order} = useOrderDetailsData(id)
	const {loading} = useOrderData()
	
	useEffect(() => {
		getOrderDetails(id)
	}, [getOrderDetails, id])
	
	useEffect(() => {
		setTimeout(() => (history.push(getRouteUrl(ROUTE_PAGE_HOME))), 2500)
	}, [history])
	
	return !loading && order?.status ? (
		<>
			<Box>
				<Box p={1}>
					<Typography
						pb={1}
						variant={'h2'}
						component={'div'}
						sx={{
							textAlign: 'center'
						}}
					>
						{t('Thank you for your order!')}
					</Typography>
					<Overview order={order} finalize/>
				</Box>
				<Box p={4} sx={{backgroundColor: '#FFFFFF'}}>
					{order?.products && (
						order.products.map(product => (
							<ProductPreview
								key={`product-${product.id}`}
								product={{...product}}
								orderSubmitted={order.status !== OrderStatus.InProgress}
							/>
						))
					)}
				</Box>
				<Box p={4} className={'order-info'}>
					<Box sx={{width: {xs: '100%', sm: '100%', md: '50%'}}}>
						<Typography
							pb={1}
							variant={'h4'}
							component={'div'}
						>
							{t('Notes')}
						</Typography>
						<div className={'order-notes'}>
							{order?.notes}
						</div>
					</Box>
					<Box pt={1} sx={{width: {xs: '100%', sm: '100%', md: '50%'}}}>
						<OrderPrices order={order} orderSubmitted={order.status !== OrderStatus.InProgress}/>
						<Divider/>
					</Box>
				</Box>
			</Box>
		</>
	) : (
		<ContentLoader loading={loading}/>
	)
};

export default OrderPlaced