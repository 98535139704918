//@flow
import React from 'react';
import axios from "axios";
import {useTranslation} from "react-i18next";
import IconButton from "@mui/material/IconButton";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {enqueueSnackbar} from "notistack";
import {handleAxiosErrors} from "../../helpers/errorHandling";
import {PAGE_WARRANTIES} from "../../locales/pages/namespaces";
import useUserData from "../../hooks/user/useUserData";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Button from "@mui/material/Button";



type Props ={
    url:string,
    data: Object,
    isPdf: boolean
}

export const ExportExcelPdf = (props:Props)=>{
    const {url, data,isPdf} = props;
    const { t } = useTranslation(PAGE_WARRANTIES);
    const {token} = useUserData()



    const handleExportExcel = () => {
        axios({
            method: 'post',
            data: data,
            url: url,
            responseType: 'blob',
        })
            .then(response => {
                const blob = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'exported_data.xlsx';

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(link.href);
            })
            .catch((error) => {
                enqueueSnackbar(handleAxiosErrors(error, t('Failed!'), {
                    variant: 'warning',
                    autoHideDuration: 1000,
                }))
            })
    }
    const handlePdf = () => {
        axios({
            method: 'get',
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
            url: url,
            responseType: 'blob'
        }).then(response => {
            const blob = new Blob([response.data], {type: 'application/pdf'});
            const url = window.URL.createObjectURL(blob);

            // Create a link element
            const a = document.createElement('a');
            a.href = url;
            a.download = 'downloaded.pdf'; // Set the filename for download
            a.target = '_blank'; // Open in a new tab
            a.style.display = 'none';

            // Append the link element to the document and trigger the click event
            document.body.appendChild(a);
            a.click();

            // Open a new tab with the PDF
            window.open(url, '_blank');

            // Clean up by revoking the blob URL
            window.URL.revokeObjectURL(url);

            enqueueSnackbar(t('Successfully downloaded PDF!'), {
                variant: 'success',
                autoHideDuration: 1000,
            });
        }).catch(error => {
            enqueueSnackbar(handleAxiosErrors(error, t('Unable to download. PDF not found.!'), {
                    variant: 'error',
                }
            ))
        }).catch(error => {
            enqueueSnackbar(handleAxiosErrors(error, t('Failed to download PDF!'), {
                    variant: 'error',
                }
            ))
        })
    }
        return (

                isPdf ?
                    <Button onClick={handlePdf} size={'xs'} variant={'text'}>
                        <PictureAsPdfIcon/>
                    </Button>
                    :
                    <IconButton onClick={handleExportExcel} size={'xs'} variant={'text'}>
                        <FileDownloadIcon/>
                    </IconButton>


        )
    }
