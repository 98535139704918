import {useMemo} from "react";
import useUserData from "../user/useUserData";

const useProductCalculations = ({
									priceWithoutTax,
									priceWithTax,
									priceAfterDiscounts,
									priceAfterDiscountsWithTax,
									priceWithPaymentDiscount,
									priceWithPaymentDiscountWithTax,
									priceAfterDiscountsWithEnvTax,
									priceAfterDiscountsWithEnvTaxAndItemTax,
									priceWithPaymentDiscountWithEnvTax,
									priceWithPaymentDiscountWithEnvTaxAndItemTax,
									warehouses,
									displayWithTax,
									displayWithEnvTax,
									displayWithPaymentDiscount,
									priceWithSecondPaymentDiscount,
									priceWithSecondPaymentDiscountWithTax,
									priceWithSecondPaymentDiscountWithEnvTax,
									priceWithSecondPaymentDiscountWithEnvTaxAndItemTax,
									paymentDiscount,
									secondPaymentDiscount
								}) => {
	const {warehouses: sortedWarehouses} = useUserData()
	const mappedWarehouses = useMemo(() => (
		sortedWarehouses.map(warehouse => {
			const productWarehouse = warehouses?.find(f => f.id === warehouse.id)
			return {
				...productWarehouse,
				...warehouse
			}
		})
	), [sortedWarehouses, warehouses])
	
	const priceWithDisplayPaymentDiscountWithEnvTaxAndItemTax = useMemo(() => (
		!paymentDiscount && secondPaymentDiscount > 0 ? priceWithSecondPaymentDiscountWithEnvTaxAndItemTax : priceWithPaymentDiscountWithEnvTaxAndItemTax
	), [paymentDiscount, priceWithPaymentDiscountWithEnvTaxAndItemTax, priceWithSecondPaymentDiscountWithEnvTaxAndItemTax, secondPaymentDiscount])
	const priceWithDisplayPaymentDiscountWithEnvTax = useMemo(() => (
		!paymentDiscount && secondPaymentDiscount > 0 ? priceWithSecondPaymentDiscountWithEnvTax : priceWithPaymentDiscountWithEnvTax
	), [paymentDiscount, priceWithPaymentDiscountWithEnvTax, priceWithSecondPaymentDiscountWithEnvTax, secondPaymentDiscount])
	const priceWithDisplayPaymentDiscountWithTax = useMemo(() => (
		!paymentDiscount && secondPaymentDiscount > 0 ? priceWithSecondPaymentDiscountWithTax : priceWithPaymentDiscountWithTax
	), [paymentDiscount, priceWithPaymentDiscountWithTax, priceWithSecondPaymentDiscountWithTax, secondPaymentDiscount])
	const priceWithDisplayPaymentDiscount = useMemo(() => (
		!paymentDiscount && secondPaymentDiscount > 0 ? priceWithSecondPaymentDiscount : priceWithPaymentDiscount
	), [paymentDiscount, priceWithPaymentDiscount, priceWithSecondPaymentDiscount, secondPaymentDiscount])
	
	const netValue = useMemo(() => (
		displayWithTax ? priceWithTax : priceWithoutTax
	), [displayWithTax, priceWithTax, priceWithoutTax])
	
	// const discountValue = useMemo(() => (
	// 	displayWithTax ? priceAfterDiscountsWithTax : priceAfterDiscounts
	// ), [displayWithTax, priceAfterDiscounts, priceAfterDiscountsWithTax])
	
	const discountValue = useMemo(() => (
		displayWithEnvTax ? (
			displayWithTax ? priceAfterDiscountsWithEnvTaxAndItemTax : priceAfterDiscountsWithEnvTax
		) : (
			displayWithTax ? priceAfterDiscountsWithTax : priceAfterDiscounts
		)
	), [displayWithEnvTax, displayWithTax, priceAfterDiscounts, priceAfterDiscountsWithEnvTax, priceAfterDiscountsWithEnvTaxAndItemTax, priceAfterDiscountsWithTax])
	
	const envValue = useMemo(() => (
		displayWithPaymentDiscount ? (
			displayWithEnvTax ? (
				displayWithTax ? (priceWithDisplayPaymentDiscountWithEnvTaxAndItemTax) : (priceWithDisplayPaymentDiscountWithEnvTax)
			) : (
				displayWithTax ? (priceWithDisplayPaymentDiscountWithTax) : (priceWithDisplayPaymentDiscount)
			)
		) : (
			displayWithEnvTax ? (
				displayWithTax ? (priceAfterDiscountsWithEnvTaxAndItemTax) : (priceAfterDiscountsWithEnvTax)
			) : (
				displayWithTax ? (priceAfterDiscountsWithTax) : (priceAfterDiscounts)
			)
		)
	), [displayWithPaymentDiscount, displayWithEnvTax, displayWithTax, priceWithDisplayPaymentDiscountWithEnvTaxAndItemTax, priceWithDisplayPaymentDiscountWithEnvTax, priceWithDisplayPaymentDiscountWithTax, priceWithDisplayPaymentDiscount, priceAfterDiscountsWithEnvTaxAndItemTax, priceAfterDiscountsWithEnvTax, priceAfterDiscountsWithTax, priceAfterDiscounts])
	
	return {
		envValue,
		netValue,
		discountValue,
		mappedWarehouses,
		priceWithDisplayPaymentDiscount,
		priceWithDisplayPaymentDiscountWithTax,
		priceWithDisplayPaymentDiscountWithEnvTax,
		priceWithDisplayPaymentDiscountWithEnvTaxAndItemTax
	}
}

export default useProductCalculations