// @flow
import React from 'react';
import {BarChart} from "@mui/x-charts";
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";
import {PAGE_REPORTS} from "../../../../locales/pages/namespaces";

type Props = {};

const SalesChart = (props: Props) => {
	const {chartData, chartLabels} = props
	const {t} = useTranslation(PAGE_REPORTS)
	return (
		<Box>
			<BarChart
				width={500}
				height={300}
				series={[{data: chartData, label: t('Quantity'), type: 'bar'}]}
				xAxis={[{scaleType: 'band', data: chartLabels}]}
			/>
		</Box>
	);
};

export default SalesChart