// @flow
import React from 'react';
import {Chip, Tooltip} from "@mui/material";
import {useTranslation} from "react-i18next";
import {PAGE_ORDERS, PAGE_RETURNS} from "../../../locales/pages/namespaces";
import {normalizeGreek} from "../../../helpers/normalizeGreek";
import {OrderStatus as Status} from "../../../helpers/constants";

type Props = {};

const OrderStatus = (props: Props) => {
	const {status, isReturn} = props
	const {t} = useTranslation(isReturn ? PAGE_RETURNS : PAGE_ORDERS)
	let color = ''
	switch (status) {
		case Status.InProgress:
			color = 'secondary'
			break;
		case Status.Submitted:
			color = 'success'
			break;
		default:
			color = 'info'
	}
	return (
		<Tooltip title={normalizeGreek(t(status)).toUpperCase()}>
			<Chip label={normalizeGreek(t(status)).toUpperCase()} size={'small'} color={color}/>
		</Tooltip>
	);
};

export default OrderStatus