import {Grid, Stack, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import MainCard from "../../components/general/mainCard/mainCard";
import {useTranslation} from "react-i18next";
import {PAGE_CONTACT} from "../../locales/pages/namespaces";
import AlphaBank from "../../assets/banksLogo/alphaBank";
import NationalBank from "../../assets/banksLogo/nationalBank";
import PiraeusBank from "../../assets/banksLogo/piraeusBank";
import Eurobank from "../../assets/banksLogo/eurobank";
import PancretaBank from "../../assets/banksLogo/pancretaBank";
import './contact.css'

const Contact = () => {
    const {t} = useTranslation(PAGE_CONTACT)
    return (
        <Box p={1}>
            <Typography
                pb={1}
                variant={'h2'}
                component={'div'}
                sx={{
                    textAlign: 'center'
                }}
            >
                {t('Contact')}
            </Typography>
            <Box sx={{display: 'flex', width: '100%', flexWrap: 'wrap', justifyContent: 'space-between'}}>
                <Box width={'100%'} m={1}>
                    <MainCard sx={{
                        mb: 2,
                        borderColor: '#075c90',
                        textAlign: 'center',
                        height: '100%'
                    }}>
                        <Typography fontWeight={"bold"} variant={'h5'}>Έδρα</Typography>
                        <Typography>Μποζαΐτικα Πατρών (πρώην αποθήκες ΑΤΕ)</Typography>
                        <Typography>Τ.Κ.: 264 42, Πάτρα</Typography>
                        <Typography>Τηλ.: 2610 436006</Typography>
                        <Typography>2610 453351</Typography>
                        <Typography>Φαξ: 2610 436006</Typography>
                    </MainCard>
                </Box>
                <Box width={{xs: '100%', sm: '48%'}} m={1}>
                    <MainCard sx={{
                        mb: 2,
                        borderColor: '#075c90',
                        textAlign: 'center',
                        height: '100%'
                    }}>
                        <Typography fontWeight={"bold"} variant={'h5'}>Υποκατάστημα Αθηνών</Typography>
                        <Typography>Αγίου Νικολάου 12</Typography>
                        <Typography>Τ.Κ.: 135 61, Άγιοι Ανάργυροι Αττικής, Αθήνα</Typography>
                        <Typography>Τηλ.: 210 6121215</Typography>
                        <Typography>210 8320400</Typography>
                        <Typography>210 6121232</Typography>
                        <Typography>Φαξ : 210 8320402</Typography>
                    </MainCard>
                </Box>
                <Box width={{xs: '100%', sm: '48%'}} m={1}>
                    <MainCard sx={{
                        mb: 2,
                        borderColor: '#075c90',
                        textAlign: 'center',
                        height: '100%'
                    }}>
                        <Typography fontWeight={"bold"} variant={'h5'}>Υποκατάστημα Θεσ/νίκης</Typography>
                        <Typography>6.5χλμ Λαγκαδά - Θεσ/νίκης</Typography>
                        <Typography>Τ.Κ.: 135 61, Θεσσαλονίκη</Typography>
                        <Typography>Τηλ.: 2310 680359</Typography>
                        <Typography>Φαξ : 2310 685843</Typography>
                    </MainCard>
                </Box>
                <Box width={'100%'} m={1}>
                    <MainCard
                        sx={{
                            mb: 2,
                            borderColor: '#075c90',
                            textAlign: 'center',
                            height: '100%'
                        }}
                    >
                        <Typography sx={{textAlign: 'center', mt: 1}}>
                            {t('Contact Email')}: <strong>info@sts-sa.gr</strong></Typography>
                        <Box className={'logos-contact-boxes'}>
                            <Box className={'logos-contact-box'}>
                                <Box className={'logo-contact-box'}>

                                    <AlphaBank/>
                                </Box>
                                <Typography>
                                    {'543.00.2320.00.1723'}
                                </Typography>
                                <Typography>
                                    {'(IBAN'}: <strong>GR 1501405340534002320001723</strong>)</Typography>
                            </Box>
                            <Box className={'logos-contact-box'}>
                                <Box className={'logo-contact-box'}>

                                    <NationalBank/>
                                </Box>
                                <Typography>
                                    {'225/470586-63'}
                                </Typography>
                                <Typography>
                                    {'(IBAN'}: <strong>GR 1401102250000022547058663</strong>)</Typography>
                            </Box>
                            <Box className={'logos-contact-box'}>
                                <Box className={'logo-contact-box'}>
                                    <PiraeusBank/>
                                </Box>
                                <Typography>
                                    {'5504-070395-645'}
                                </Typography>
                                <Typography>
                                    {'(IBAN'}: <strong>GR 4101725040005504070395645</strong>)</Typography>
                            </Box>
                            <Box className={'logos-contact-box'}>
                                <Box className={'logo-contact-box'}>

                                    <Eurobank/>
                                </Box>
                                <Typography>
                                    {'0026.0015.76.0200931996'}
                                </Typography>
                                <Typography>
                                    {'(IBAN'}: <strong>GR 2702600150000760200931996</strong>)</Typography>
                            </Box>
                            <Box
                                className={'logos-contact-box'}>
                                <Box className={'logo-contact-box'}>
                                    <PancretaBank/>
                                </Box>
                                <Typography>
                                    {'(IBAN'}: <strong>GR 2308701000000300003445888</strong>)</Typography>
                            </Box>
                        </Box>
                    </MainCard>
                </Box>
            </Box>
        </Box>
    )
}

export default Contact;