import {useEffect, useState} from "react";
import axios from "axios";
import {API} from "../../helpers/constants";
import {enqueueSnackbar} from "notistack";
import {handleAxiosErrors} from "../../helpers/errorHandling";
import {useTranslation} from "react-i18next";
import {PAGE_SEARCH_RESULTS} from "../../locales/pages/namespaces";

export const usePriceOptions = () => {
    const {t} = useTranslation(PAGE_SEARCH_RESULTS);
    const [displayWithTax, setDisplayWithTax] = useState(false)
    const [displayWithEnvTax, setDisplayWithEnvTax] = useState(false)
    const [displayWithPaymentDiscount, setDisplayWithPaymentDiscount] = useState(false)

    useEffect(() => {
        axios({
            method: 'get',
            url: `${API}/en/b2b/sts/flags/get`,
        }).then(response => {
            const data = response.data.flags
            setDisplayWithTax(data.isVat !== '0')
            setDisplayWithEnvTax(data.isOther !== '0')
            setDisplayWithPaymentDiscount(data.isMetritis !== '0')
        }).catch((error) => {
            enqueueSnackbar(handleAxiosErrors(error, t('Failed to load your options.'), {
                variant: 'error',
                autoHideDuration: 1000,
            }));
        })
    }, [t])

    return {
        displayWithTax,
        setDisplayWithTax,
        displayWithEnvTax,
        setDisplayWithEnvTax,
        displayWithPaymentDiscount,
        setDisplayWithPaymentDiscount
    }
}