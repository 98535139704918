import * as ActionTypes from '../../actions/types'
import {fromJS} from "immutable";
import {mapBonus, mapBudget, mapCustomerCard, mapSalesData} from "../../types/reports/map";
import {mapMultiple} from "../../helpers/map";
import {mapFilter} from "../../types/filters/map";
import {getQuarterDescription} from "../../helpers/reports";

export const INITIAL_STATE = {
	loading: false,
	message: null,
	budgetReport: [],
	customerCard: [],
	bonusReport: [],
	sales: {
		filters: {
			attributes: {},
			years: [],
			quarters: []
		},
		data: {
			items: [],
			loading: false
		}
	}
}

function setReportsMessage(state, action) {
	const message = action.payload
	return state
	.set('message', fromJS(message ? {
		text: message?.text, variant: message?.variant
	} : null))
}

function getBudgetAndGoalsReport(state) {
	return state
	.set('loading', true)
	.set('sales', fromJS(INITIAL_STATE.sales))
}

function getBudgetAndGoalsReportSucceeded(state, action) {
	const {bonus, budget} = action.payload.data
	const mappedBudget = budget.map(i => mapBudget(i))
	const mappedBonus = bonus.map(i => mapBonus(i))
	return state
	.set('loading', false)
	.set('budgetReport', fromJS(mappedBudget))
	.set('bonusReport', fromJS(mappedBonus))
}

function getBudgetAndGoalsReportFailed(state, action) {
	const {message} = action.payload
	return state
	.set('loading', false)
	.set('message', fromJS({
		text: message, variant: 'error'
	}))
}

function getCustomerCard(state) {
	return state
	.set('loading', true)
	.set('sales', fromJS(INITIAL_STATE.sales))
}

function getCustomerCardSucceeded(state, action) {
	const {payload} = action
	const data = payload.map(i => mapCustomerCard(i))
	return state
	.set('loading', false)
	.set('customerCard', fromJS(data))
}

function getCustomerCardFailed(state, action) {
	const {message} = action.payload
	return state
	.set('loading', false)
	.set('message', fromJS({
		text: message, variant: 'error'
	}))
}

function getSalesReportFilters(state) {
	return state
	.set('loading', true)
	.set('sales', fromJS(INITIAL_STATE.sales))
}

function getSalesReportFiltersSucceeded(state, action) {
	const {filters, quarters, years} = action.payload
	const mappedYears = {
		id: 'yearFilter',
		DescriptionEl: 'YEAR',
		DescriptionEn: 'ΧΡΟΝΟΣ',
		OrderNumber: '300001',
		values: years.map(year => ({
			valueId: `year-${year.BoughtYear}`,
			valueEn: year.BoughtYear,
			valueEl: year.BoughtYear,
			OrderNumber: 0,
			count: 0
		}))
	}
	const mappedQuarters = {
		id: 'quarterFilter',
		DescriptionEl: 'QUARTER',
		DescriptionEn: 'ΤΡΙΜΗΝΟ',
		OrderNumber: '300000',
		values: quarters.map(quarter => ({
			valueId: `quarter-${quarter}`,
			valueEn: getQuarterDescription(quarter),
			valueEl: getQuarterDescription(quarter),
			OrderNumber: 0,
			count: 0
		}))
	}
	const allFilters = [
		...filters,
		mappedYears,
		mappedQuarters
	]
	const mappedFilters = mapMultiple(allFilters, mapFilter)
	return state
	.set('loading', false)
	.setIn(['sales', 'filters'], fromJS(mappedFilters))
}

function getSalesReportFiltersFailed(state, action) {
	const {message} = action.payload
	return state
	.set('loading', false)
	.set('message', fromJS({
		text: message, variant: 'error'
	}))
}

function getSalesReportData(state) {
	return state
	.setIn(['sales', 'data', 'loading'], true)
}

function getSalesReportDataSucceeded(state, action) {
	const {data} = action.payload
	const mappedData = data.map(i => mapSalesData(i))
	return state
	.set('loading', false)
	.setIn(['sales', 'data', 'loading'], false)
	.setIn(['sales', 'data', 'items'], fromJS(mappedData))
}

function getSalesReportDataFailed(state, action) {
	const {message} = action.payload
	return state
	.setIn(['sales', 'data', 'loading'], false)
	.set('message', fromJS({
		text: message, variant: 'error'
	}))
}

const reports = {
	initialState: INITIAL_STATE,
	handlers: {
		[ActionTypes.SET_REPORTS_MESSAGE]: setReportsMessage,
		[ActionTypes.GET_BUDGET_AND_GOALS_REPORT]: getBudgetAndGoalsReport,
		[ActionTypes.GET_BUDGET_AND_GOALS_REPORT_SUCCEEDED]: getBudgetAndGoalsReportSucceeded,
		[ActionTypes.GET_BUDGET_AND_GOALS_REPORT_FAILED]: getBudgetAndGoalsReportFailed,
		[ActionTypes.GET_CUSTOMER_CARD]: getCustomerCard,
		[ActionTypes.GET_CUSTOMER_CARD_SUCCEEDED]: getCustomerCardSucceeded,
		[ActionTypes.GET_CUSTOMER_CARD_FAILED]: getCustomerCardFailed,
		[ActionTypes.GET_SALES_REPORT_FILTERS]: getSalesReportFilters,
		[ActionTypes.GET_SALES_REPORT_FILTERS_SUCCEEDED]: getSalesReportFiltersSucceeded,
		[ActionTypes.GET_SALES_REPORT_FILTERS_FAILED]: getSalesReportFiltersFailed,
		[ActionTypes.GET_SALES_REPORT_DATA]: getSalesReportData,
		[ActionTypes.GET_SALES_REPORT_DATA_SUCCEEDED]: getSalesReportDataSucceeded,
		[ActionTypes.GET_SALES_REPORT_DATA_FAILED]: getSalesReportDataFailed,
	}
}

export default reports