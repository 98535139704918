export default function Autocomplete(theme) {
	return {
		MuiAutocomplete: {
			styleOverrides: {
				root: {}
			},
			popupIndicator: {},
			clearIndicator: {}
		}
	}
}
