import React, {useContext} from "react";
import {NumericFormat} from "react-number-format";
import {useTranslation} from "react-i18next";
import {INVOICE} from "../../../locales/pages/namespaces";
import {InvoiceContext} from "./invoice";

const FooterInvoice = () => {
    const {data} = useContext(InvoiceContext)
    return (
        <>
            <div className="form-box form-box_7 custom-box">
            </div>
            <div className="form-box form-box_5 custom-box">
                <div className="box_title">{data.footerInvoiceHeader}</div>
                <div className="box_column" style={{minWidth: '250px'}}>
                    {data && data.footerInvoice.map((item, index) => (
                        <div className="box_column_item" key={index} style={{gridTemplateColumns: '4fr 1fr'}}>
                            <div>
                                <span className="box_column_itemTitle">{item.title}</span>
                            </div>
                            <div className="box_column_itemValue_invoice_total">
                            <span>
                                 <NumericFormat
                                     thousandSeparator={window.FORMAT_DIGIT}
                                     decimalSeparator={window.FORMAT_DECIMAL}
                                     displayType="text"
                                     value={item.value}
                                     decimalScale={2}
                                 />
                            </span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="form-box form-box_12" style={{marginTop: '-10px'}}>
                <div className="box_column" style={{minHeight: '50px'}}>
                    {data && data.footerInvoiceComments.map((item, index) => (
                        <div className="box_column_item" key={index} style={{gridTemplateColumns: '0.1fr 4fr'}}>
                            <span className="box_column_itemTitle">{item.title}:</span>
                            <span
                                className="box_column_itemValue" style={item.customStyle}>{item.value}</span>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default FooterInvoice;