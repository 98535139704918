export default function Badge(theme) {
	return {
		MuiBadge: {
			styleOverrides: {
				standard: {},
				light: {}
			}
		}
	};
}
