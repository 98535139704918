//@flow
import {useCallback} from 'react'
import {useDispatch} from 'react-redux'
import * as actions from '../../actions/actions'
import useUserData from "../user/useUserData";

const useProductAction = () => {
	const dispatch = useDispatch()
	const {selectedStore, ordersheetId} = useUserData()
	
	const setProductMessage = useCallback((message) => {
		dispatch(actions.setProductMessage(message))
	}, [dispatch])
	
	const setFilters = useCallback((values) => {
		dispatch(actions.setFilters({
			values
		}))
	}, [dispatch])
	
	const updateFilter = useCallback((field, value) => {
		dispatch(actions.updateFilter({
			field,
			value
		}))
	}, [dispatch])
	
	const getSearchResults = useCallback((pageIndex, pageSize, values, userData) => {
		const search = [values?.search1?.trim(), values?.search2?.trim(), values?.search3?.trim()].filter(Boolean)
		const attributeValues = Object.entries(values).filter(([key, value]) => key.includes('value-') && value).map(([key]) => key.slice(key.indexOf('-') + 1))
		if (search.length === 0 && attributeValues.length === 0) return
		dispatch(actions.getSearchResults({
			OrdersheetId: ordersheetId,
			AddressId: selectedStore.id,
			isAltCode: values.isAltCode,
			hasStock: values.hasStock,
			hasIncoming: values.hasIncoming,
			search: search,
			AttrVals: attributeValues,
			...userData,
			requiresCounts: true,
			skip: pageIndex * pageSize,
			take: pageSize
		}))
	}, [dispatch, ordersheetId, selectedStore.id])
	
	const getSearchResultsFilters = useCallback((values, userData) => {
		const search = [values?.search1?.trim(), values?.search2?.trim(), values?.search3?.trim()].filter(Boolean)
		const attributeValues = Object.entries(values).filter(([key, value]) => key.includes('value-') && value).map(([key]) => key.slice(key.indexOf('-') + 1))
		if (search.length === 0 && attributeValues.length === 0) return
		dispatch(actions.getSearchResultsFilters({
			OrdersheetId: ordersheetId,
			AddressId: selectedStore.id,
			isAltCode: values.isAltCode,
			hasStock: values.hasStock,
			hasIncoming: values.hasIncoming,
			search: search,
			AttrVals: attributeValues,
			...userData,
		}))
	}, [dispatch, ordersheetId, selectedStore.id])
	
	return {
		setProductMessage,
		setFilters,
		updateFilter,
		getSearchResults,
		getSearchResultsFilters
	}
}

export default useProductAction