export const PAGE_LOGIN = 'pg_login';
export const PAGE_HOME = 'pg_home';
export const PAGE_ANNOUNCEMENTS = 'pg_announcements';
export const PAGE_ORDERS = 'pg_orders';
export const PAGE_RETURNS ='pg_returns';
export const PAGE_WARRANTIES = 'pg_warranties';
export const PAGE_NEW_WARRANTY = 'page.newWarranty';
export const PAGE_SEARCH_RESULTS = 'page.searchResults';
export const PAGE_REPORTS = 'page.reports'
export const PAGE_CONTACT = 'page.contact'
export const PAGE_PRIVACY = 'page.privace'
export const INVOICE = 'page.invoice'