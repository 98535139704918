import {useEffect, useMemo, useState} from 'react'
import {useExpanded, useFilters, useGroupBy, usePagination, useSortBy, useTable} from "react-table";

function DefaultColumnFilter() {
	return null
}

const useTableIndex = ({
						   columns,
						   data,
						   controlledPageCount,
						   initialState,
						   fetchData,
						   loading
					   }) => {
	const defaultColumn = useMemo(
		() => ({
			// Let's set up our default Filter UI
			Filter: DefaultColumnFilter,
		}),
		[]
	)
	// noinspection JSUnusedLocalSymbols
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		nextPage,
		previousPage,
		setPageSize,
		gotoPage,
		// eslint-disable-next-line no-unused-vars
		state: {pageIndex, pageSize, groupBy, sortBy, expanded},
	} = useTable(
		{
			defaultColumn,
			columns,
			data,
			// filterTypes,
			initialState: initialState,
			manualPagination: true,
			autoResetPage: false,
			pageCount: controlledPageCount,
		},
		useFilters,
		useGroupBy,
		useSortBy,
		useExpanded,
		usePagination,
	)
	
	const [index, setIndex] = useState(initialState.pageIndex * initialState.pageSize)
	
	useEffect(() => {
		// console.log('%c pageIndex changed to: ' + pageIndex, 'background: #222; color: #bada55');
	}, [pageIndex])
	
	useEffect(() => {
		fetchData({pageIndex, pageSize})
	}, [fetchData, pageIndex, pageSize])
	
	// useEffect(() => {
	//     fetchData({pageIndex, pageSize, sortBy})
	// }, [fetchData, pageIndex, pageSize, sortBy])
	
	useEffect(() => {
		gotoPage(0)
	}, [fetchData])
	
	
	useEffect(() => {
		const newIndex = pageIndex * pageSize
		if (!(loading)) {
			setIndex(newIndex)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading])
	
	return {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		nextPage,
		previousPage,
		setPageSize,
		index,
		pageIndex,
		pageSize
	}
}

export default useTableIndex