// @flow
import React, {useMemo} from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, Skeleton, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import './productInfo.css'
import Box from "@mui/material/Box";
import Images from "../../../../assets";
import {Bookmarks} from "@mui/icons-material";
import {normalizeGreek} from "../../../../helpers/normalizeGreek";
import {PRODUCT_DETAIL} from "../../../../locales/components/namespaces";

type Props = {
	open: boolean,
	handleDialog: Function
};

const ProductInfo = (props: Props) => {
	const {open, handleDialog} = props
	const {t} = useTranslation(PRODUCT_DETAIL)
	const handleClose = () => {
		handleDialog({
			open: false,
			product: null
		})
	}
	const images = useMemo(() => (
		open?.product?.images ? open?.product?.images.map((image) => ({
			original: image.filePath,
			thumbnail: image.filePath,
			loading: 'lazy'
		})) : []
	), [open?.product?.images])

	return (
		<Dialog
			open={open?.open}
			onClose={handleClose}
			maxWidth={'lg'}
			sx={{minWidth: '300px'}}
		>
			<DialogTitle>
				<Typography
					variant={'h4'}
					component={'div'}
				>
					{`${t('Product Details')} - ${open?.product?.description}`}
				</Typography>
			</DialogTitle>
			{open?.product ? (
				<DialogContent>
					<ImageGallery
						items={images}
						lazyload={true}
						thumbnailPosition={'right'}
						showNav={false}
						showPlayButton={false}
						onErrorImageURL={Images.imageNotFound}
					/>
					<Box>
						<Typography variant={'body2'} component={'div'}>
							<strong>EAN CODE</strong>: {open.product?.eanCode}
						</Typography>
						<Typography variant={'body2'} component={'div'}>
							<strong>{t('Alternative Code')}</strong>: {open.product?.alternativeCode}
						</Typography>
						<Typography variant={'body2'} component={'div'}>
							<strong>{t('EPREL - European Product Registry for Energy Labelling')}</strong>
							<a
								href={open.product?.externalLabelLink}
								target={'_blank'}
								rel="noreferrer"
							>
								<Button
									variant="text"
									sx={{
										padding: 0,
										minWidth: 30,
										maxHeight: 30,
										marginLeft: 0.1,
										marginRight: 0.1
									}}
								
								>
									<Bookmarks fontSize={'small'} color={'secondary'}/>
								</Button>
							</a>
						</Typography>
						<Box>
							<Typography variant={'body2'} component={'div'}>
								<strong>{t('OEM Constructor')}</strong>: {open.product?.oemConstructor ? open.product?.oemConstructor : '-'}
							</Typography>
							<Typography variant={'body2'} component={'div'}>
								<strong>{t('OEM Vehicle Type')}</strong>: {open.product?.oemVehicleType ? open.product?.oemVehicleType : '-'}
							</Typography>
						</Box>
						<Box>
							<Typography
								component={'div'}
								variant={'overline'}
							>
								<strong>{normalizeGreek(t('Category'))}</strong>: <span>{normalizeGreek(open.product?.displayAttribute)}</span>
							</Typography>
						</Box>
					</Box>
					<img src={open.product?.manufacturerImage} alt={'logo'}
						 style={{maxHeight: "40px", maxWidth: '120px'}}/>
				</DialogContent>
			) : (
				<>
					<Skeleton/>
					<Skeleton/>
					<Skeleton/>
				</>
			)}
			<DialogActions>
				<Button
					color="error"
					size={"small"}
					onClick={handleClose}
					sx={{
						marginRight: 'auto'
					}}
				>
					{t('Cancel')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ProductInfo