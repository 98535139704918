// material-ui
import {createTheme} from '@mui/material/styles';

// ==============================|| DEFAULT THEME - PALETTE  ||============================== //

const Palette = () => {
	return createTheme({
		palette: {
			mode: 'light',
			primary: {
				main: '#255b82',
			},
			secondary: {
				main: '#fbc324',
			},
			background: {
				default: '#fcfcfcd4',
			},
			text: {
				primary: '#050505',
				secondary: '#020700',
				hint: '#616161',
			},
			info: {
				main: '#1877f2',
			},
			error: {
				main: '#d32f2f',

			},
			success: {
				main: '#2E7D32',
			},
			warning: {
				main: '#ffa726'
			}
		}
	});
};

export default Palette;
