import React from 'react'
import {Route, Switch} from 'react-router'
import {ROUTES} from '../App'
import NotFound from "../pages/notFound/notFound";
import ActiveOrderHOC from "../hocs/activeOrderHOC";

const ContentRouter = () => {
	return (
		<Switch>
			{ROUTES.map(route => {
				const {key, path, exact, component} = route;
				const Component = component
				return (
					<Route
						key={key}
						path={path}
						exact={exact}
						render={() => (
							<ActiveOrderHOC>
								<Component/>
							</ActiveOrderHOC>
						)}
					/>
				)
			})}
			<Route path={'/'}>
				<NotFound/>
			</Route>
		</Switch>
	)
}

export default ContentRouter