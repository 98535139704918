// @flow
import React, {useCallback, useMemo, useState} from 'react';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	Tooltip,
	Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {PAGE_SEARCH_RESULTS} from "../../../../locales/pages/namespaces";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {NumericFormat} from "react-number-format";
import useUserData from "../../../../hooks/user/useUserData";
import {applyPercentage, roundToDecimal} from "../../../../helpers/helpers";
import {ClearOutlined} from "@mui/icons-material";

type Props = {
	open: boolean,
	handleDialog: Function
};

const Calculator = (props: Props) => {
	const {open, handleDialog} = props
	const {thousandSeparator, decimalSeparator, data} = useUserData()
	const {t} = useTranslation(PAGE_SEARCH_RESULTS)
	const handleClose = (evt, details) => {
		if (details === 'backdropClick') return
		setPieces(1)
		setEarnNet(0)
		setEarnPercentage(0)
		handleDialog({
			open: false,
			prices: null
		})
	}
	const [pieces, setPieces] = useState(1)
	const [earnNet, setEarnNet] = useState(0)
	const [earnPercentage, setEarnPercentage] = useState(0)
	
	const priceListValue = useMemo(() => (
		pieces > 0 ? roundToDecimal(pieces * open.prices?.priceAfterDiscounts, 2) : '-'
	), [open.prices?.priceAfterDiscounts, pieces])
	
	const priceListValueWithTax = useMemo(() => (
		pieces > 0 ? roundToDecimal(pieces * open.prices?.priceAfterDiscountsWithTax, 2) : '-'
	), [open.prices?.priceAfterDiscountsWithTax, pieces])
	
	const finalCost = useMemo(() => (
		pieces > 0 ? roundToDecimal(pieces * open.prices?.priceWithPaymentDiscountWithEnvTax, 2) : '-'
	), [open.prices?.priceWithPaymentDiscountWithEnvTax, pieces])
	
	const finalCostWithVat = useMemo(() => (
		pieces > 0 ? roundToDecimal(pieces * open.prices?.priceWithPaymentDiscountWithEnvTaxAndItemTax, 2) : '-'
	), [open.prices?.priceWithPaymentDiscountWithEnvTaxAndItemTax, pieces])
	
	const earnings = useMemo(() => (
		pieces > 0 ? earnNet : '-'
	), [earnNet, pieces])
	
	const earningsWithTax = useMemo(() => (
		pieces > 0 ? roundToDecimal(applyPercentage(earnings, open.prices?.taxPercentage), 2) : '-'
	), [earnings, open.prices?.taxPercentage, pieces])
	
	const salesValue = useMemo(() => (
		pieces > 0 && earnings > 0 ? (roundToDecimal(finalCost + earnings, 2)) : '-'
	), [earnings, finalCost, pieces])
	
	const salesValueWithTax = useMemo(() => (
		pieces > 0 && earnings > 0 ? roundToDecimal((earningsWithTax + finalCostWithVat), 2) : '-'
	), [earnings, earningsWithTax, finalCostWithVat, pieces])
	
	const margin = useMemo(() => (
		pieces > 0 && earnings > 0 ? roundToDecimal(((salesValue - finalCost) / salesValue * 100), 2) : '-'
	), [earnings, finalCost, pieces, salesValue])
	
	const clearCalculator = useCallback(() => {
		setPieces(1)
		setEarnNet(0)
		setEarnPercentage(0)
	}, [])
	
	return (
		<Dialog open={open.open} onClose={handleClose} maxWidth={'xs'}>
			<DialogTitle>
				<Typography
					variant={'h5'}
					component={'div'}
					sx={{textAlign: 'center'}}
				>
					{t('Prices Calculations')}
					<br/>
					{open.prices?.description || ' - '}
				</Typography>
			</DialogTitle>
			<DialogContent>
				<Typography
					variant={'h6'}
					component={'div'}
					sx={{fontWeight: 'bold', textAlign: 'center', color: '#FFFFFF', backgroundColor: '#000000'}}
					m={0.5}
				>
					{t('Prices Per Piece')}
				</Typography>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell></TableCell>
							<TableCell
								sx={{textAlign: 'right', backgroundColor: '#f0f8ff'}}>{t('Without Tax')} (€)</TableCell>
							<TableCell
								sx={{textAlign: 'right', backgroundColor: '#f0f8ff'}}>{t('With Tax')} (€)</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell
								sx={{paddingLeft: 0.5, backgroundColor: 'secondary.light'}}>{t('Wholesale')}</TableCell>
							<TableCell sx={{textAlign: 'right'}}>
								<NumericFormat
									thousandSeparator={thousandSeparator}
									decimalSeparator={decimalSeparator}
									displayType="text"
									value={open.prices?.priceWithoutTax}
								/>
							</TableCell>
							<TableCell sx={{textAlign: 'right'}}>
								<NumericFormat
									thousandSeparator={thousandSeparator}
									decimalSeparator={decimalSeparator}
									displayType="text"
									value={open.prices?.priceWithTax}
								/>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell sx={{
								paddingLeft: 0.5,
								backgroundColor: 'secondary.light'
							}}>{t('On Invoice')}</TableCell>
							<TableCell sx={{textAlign: 'right'}}>
								<NumericFormat
									thousandSeparator={thousandSeparator}
									decimalSeparator={decimalSeparator}
									displayType="text"
									value={open.prices?.priceAfterDiscounts}
								/>
							</TableCell>
							<TableCell sx={{textAlign: 'right'}}>
								<NumericFormat
									thousandSeparator={thousandSeparator}
									decimalSeparator={decimalSeparator}
									displayType="text"
									value={open.prices?.priceAfterDiscountsWithTax}
								/>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell sx={{paddingLeft: 0.5, backgroundColor: 'secondary.light', maxWidth: '140px'}}>
								{t('Final Cost (payment discount & env. tax')}
							</TableCell>
							<TableCell sx={{textAlign: 'right'}}>
								<NumericFormat
									thousandSeparator={thousandSeparator}
									decimalSeparator={decimalSeparator}
									displayType="text"
									value={open.prices?.priceWithPaymentDiscountWithEnvTax}
								/>
							</TableCell>
							<TableCell sx={{textAlign: 'right'}}>
								<NumericFormat
									thousandSeparator={thousandSeparator}
									decimalSeparator={decimalSeparator}
									displayType="text"
									value={open.prices?.priceWithPaymentDiscountWithEnvTaxAndItemTax}
								/>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
				<Box
					m={1.1}
					sx={{textAlign: 'center'}}
				>
					<NumericFormat
						thousandSeparator={thousandSeparator}
						decimalSeparator={decimalSeparator}
						value={pieces}
						onValueChange={(values, sourceInfo) => {
							setPieces(values.floatValue)
						}}
						decimalScale={0}
						customInput={TextField}
						size={'small'}
						InputLabelProps={{shrink: true}}
						variant={"outlined"}
						label={t('Pieces')}
					/>
					<Tooltip title={t('Clear')}>
						<Button
							variant="text"
							color={'error'}
							onClick={clearCalculator}
							size={'small'}
						>
							<ClearOutlined fontSize={'small'}/>
						</Button>
					</Tooltip>
				</Box>
				<Typography
					variant={'h6'}
					component={'div'}
					sx={{fontWeight: 'bold', textAlign: 'center', color: '#FFFFFF', backgroundColor: '#000000'}}
					m={1}
				>
					{t('Calculated Price')}
				</Typography>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell></TableCell>
							<TableCell
								sx={{textAlign: 'right', backgroundColor: '#f0f8ff'}}>{t('Without Tax')} (€)</TableCell>
							<TableCell
								sx={{textAlign: 'right', backgroundColor: '#f0f8ff'}}>{t('With Tax')} (€)</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell sx={{
								paddingLeft: 0.5,
								backgroundColor: 'secondary.light'
							}}>{t('On Invoice')}</TableCell>
							<TableCell sx={{textAlign: 'right'}}>
								<NumericFormat
									thousandSeparator={thousandSeparator}
									decimalSeparator={decimalSeparator}
									displayType="text"
									value={priceListValue}
								/>
							</TableCell>
							<TableCell sx={{textAlign: 'right'}}>
								<NumericFormat
									thousandSeparator={thousandSeparator}
									decimalSeparator={decimalSeparator}
									displayType="text"
									value={priceListValueWithTax}
								/>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell sx={{
								paddingLeft: 0.5,
								backgroundColor: 'secondary.light',
								maxWidth: '140px'
							}}>{t('Final Cost (payment discount & env. tax')}</TableCell>
							<TableCell sx={{textAlign: 'right', fontWeight: 'bold'}}>
								<NumericFormat
									thousandSeparator={thousandSeparator}
									decimalSeparator={decimalSeparator}
									displayType="text"
									value={finalCost}
								/>
							</TableCell>
							<TableCell sx={{textAlign: 'right', fontWeight: 'bold'}}>
								<NumericFormat
									thousandSeparator={thousandSeparator}
									decimalSeparator={decimalSeparator}
									displayType="text"
									value={finalCostWithVat}
								/>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
				<Typography
					variant={'h6'}
					component={'div'}
					sx={{fontWeight: 'bold', textAlign: 'center', color: '#FFFFFF', backgroundColor: '#000000'}}
					m={1}
				>
					{t('Calculated Sale Price')}
				</Typography>
				<Box
					m={1}
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center'
					}}
				>
					<Box m={1}
						 sx={{
							 display: 'flex',
							 flexDirection: 'row',
							 alignItems: 'center',
							 justifyContent: 'center',
							 width: '100%'
						 }}
					>
						<NumericFormat
							thousandSeparator={thousandSeparator}
							decimalSeparator={decimalSeparator}
							value={earnPercentage}
							onValueChange={(values, sourceInfo) => {
								if (sourceInfo?.source !== 'prop') {
									setEarnPercentage(values.floatValue)
									setEarnNet(roundToDecimal(values.floatValue * finalCost / 100, 2))
								}
							}}
							decimalScale={2}
							customInput={TextField}
							size={'small'}
							InputLabelProps={{shrink: true}}
							variant={"outlined"}
							label={t('Desired Profit Rate (on total pieces)')}
							fullWidth
							name={'earnPercentage'}
						/>
						<span>%</span>
					</Box>
					<Box m={1}
						 sx={{
							 display: 'flex',
							 flexDirection: 'row',
							 alignItems: 'center',
							 justifyContent: 'center',
							 width: '100%'
						 }}
					>
						<NumericFormat
							thousandSeparator={thousandSeparator}
							decimalSeparator={decimalSeparator}
							value={earnNet}
							onValueChange={(values, sourceInfo) => {
								if (sourceInfo?.source !== 'prop') {
									setEarnNet(values.floatValue)
									setEarnPercentage(roundToDecimal(values.floatValue * 100 / finalCost, 2))
								}
							}}
							decimalScale={2}
							customInput={TextField}
							size={'small'}
							variant={"outlined"}
							InputLabelProps={{shrink: true}}
							label={t('Desired Net Profit (in total pieces)')}
							fullWidth
							name={'earnNet'}
						/>
						<span>€</span>
					</Box>
				</Box>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell></TableCell>
							<TableCell
								sx={{textAlign: 'right', backgroundColor: '#f0f8ff'}}>{t('Without Tax')} (€)</TableCell>
							<TableCell
								sx={{textAlign: 'right', backgroundColor: '#f0f8ff'}}>{t('With Tax')} (€)</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell
								sx={{paddingLeft: 0.5, backgroundColor: 'secondary.light'}}>{t('Profit')}</TableCell>
							<TableCell sx={{textAlign: 'right', fontWeight: 'bold', color: 'success.main'}}>
								<NumericFormat
									thousandSeparator={thousandSeparator}
									decimalSeparator={decimalSeparator}
									displayType="text"
									value={earnings}
								/>
							</TableCell>
							<TableCell sx={{textAlign: 'right', fontWeight: 'bold', color: 'success.main'}}>
								<NumericFormat
									thousandSeparator={thousandSeparator}
									decimalSeparator={decimalSeparator}
									displayType="text"
									value={earningsWithTax}
								/>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell sx={{
								paddingLeft: 0.5,
								backgroundColor: 'secondary.light'
							}}>{t('Sale Value')}</TableCell>
							<TableCell sx={{textAlign: 'right', fontWeight: 'bold'}}>
								<NumericFormat
									thousandSeparator={thousandSeparator}
									decimalSeparator={decimalSeparator}
									displayType="text"
									value={salesValue}
								/>
							</TableCell>
							<TableCell sx={{textAlign: 'right', fontWeight: 'bold'}}>
								<NumericFormat
									thousandSeparator={thousandSeparator}
									decimalSeparator={decimalSeparator}
									displayType="text"
									value={salesValueWithTax}
								/>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell sx={{
								paddingLeft: 0.5,
								backgroundColor: 'secondary.light'
							}}>{t('Profit Margin (%)')}</TableCell>
							<TableCell sx={{textAlign: 'right', fontWeight: 'bold'}}>
								<NumericFormat
									thousandSeparator={thousandSeparator}
									decimalSeparator={decimalSeparator}
									displayType="text"
									value={margin}
								/>
							</TableCell>
							<TableCell sx={{textAlign: 'right', fontWeight: 'bold'}}>
								-
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</DialogContent>
			<DialogActions>
				<Button
					color="error"
					size={"small"}
					onClick={handleClose}
					sx={{
						marginRight: 'auto'
					}}
				>
					{t('Close')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default Calculator