//@flow
import React, {Fragment, useState} from 'react'
import {useTranslation} from 'react-i18next'
import Pagination from "../pagination/pagination";
import Images from "../images";
import "./defaultView.css"
import {TABLE_COMPONENT} from "../../../../../locales/components/namespaces";

type Props = {
	noHeader?: boolean,
	noMeta?: boolean,
	noPagination?: boolean,
	showIndex?: boolean,
	getRowProps?: Function,
	initialState: {
		pageIndex: number,
		pageSize: number,
	}
}

const defaultPropGetter = () => ({})

const HeaderCell = ({column}) => {
	const [showFilter, setShowFilter] = useState(false)
	const FilterComponent = column.canFilter && column.Filter.name !== 'DefaultColumnFilter' ?
		column.render('Filter') : null
	
	return (
		<div className={'dv-header'}>
			<div className={'dv-row-1'}>
				{column.render('Header')}
				<div className="dv-actions">
					{column.canGroupBy ? (
						// If the column can be grouped, let's add a toggle
						<div {...column.getGroupByToggleProps()}>
							{/*{!column.isGrouped ? '⚫ ' : '⚪ '}*/}
							{!column.isGrouped ?
								<Images.CollapseIcon/> :
								<Images.ExpandIcon/>
							}
						</div>
					) : null}
					{FilterComponent && (
						<div onClick={() => setShowFilter(!showFilter)}>
							<Images.FilterIcon/>
						</div>
					)}
					{column.isSorted ?
						column.isSortedDesc ?
							<div>
								<Images.SortDown/>
							</div> :
							<div>
								<Images.SortUp/>
							</div>
						: null
					}
				</div>
			</div>
			{FilterComponent && showFilter && (
				<div className={'dv-row-2'}>
					{column.render('Filter')}
				</div>
			)}
		</div>
	)
}
const Header = ({headerGroups, showIndex}) => headerGroups.map(headerGroup => (
	<tr {...headerGroup.getHeaderGroupProps()}>
		{showIndex && <th/>}
		{headerGroup.headers.map(column => (
			<th {...column.getHeaderProps(column.getSortByToggleProps())}>
				<HeaderCell column={column}/>
			</th>
		))}
	</tr>
))

const DefaultView = (props: Props) => {
	const {t} = useTranslation(TABLE_COMPONENT)
	const {
		customStyle,
		noPagination,
		noMeta,
		showIndex,
		onRowClick,
		loading,
		noHeader,
		getRowProps = defaultPropGetter,
		pageCount: controlledPageCount,
		onPageSizeChange
	} = props
	
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		nextPage,
		previousPage,
		setPageSize,
		index,
		pageIndex,
		pageSize
	} = props.table
	
	return (
		<Fragment>
			<table
				{...getTableProps()}
				// className={customStyle ? `${customStyle}` : classNames('table', {
				//     'table--has-row-action': Boolean(onRowClick).toString()
				// })}
				className={customStyle ? `${customStyle}` : 'table--has-row-action'}
			>
				{!noHeader && (
					<thead>
					<Header
						headerGroups={headerGroups}
						showIndex={showIndex}
					/>
					</thead>
				)}
				<tbody {...getTableBodyProps()}>
				{page.map((row, i) => {
					prepareRow(row)
					return (
						<tr
							key={`row-${row.original.id}`}
							onClick={(evt) => onRowClick && onRowClick(row.original, i, evt)}
						>
							{showIndex && <td style={{width: 0}}>{index + i + 1}</td>}
							{row.cells.map(cell => {
								return (
									<td
										// For educational purposes, let's color the
										// cell depending on what type it is given
										// from the useGroupBy hook
										{...cell.getCellProps()}
										style={{
											background: cell.isGrouped
												? '#FED33C'
												: cell.isAggregated
													? '#CCF5E4'
													: cell.isPlaceholder
														? '#F5F5F5'
														: 'transparent',
										}}
									>
										{cell.isGrouped ? (
											<>
                                                <span {...row.getToggleRowExpandedProps()} className={'expand-toggle'}>
                                                    {row.isExpanded ? '-' : '+'}
                                                </span>{' '}
												{cell.render('Cell')} ({row.subRows.length})
											</>
										) : cell.isAggregated ? (
											// If the cell is aggregated, use the Aggregated
											// renderer for cell
											cell.render('Aggregated')
										) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
											// Otherwise, just render the regular cell
											cell.render('Cell')
										)}
									</td>
								)
							})}
						</tr>
					)
				})}
				</tbody>
			</table>
			{!noPagination && (
				<Pagination
					pageSize={pageSize}
					setPageSize={(size) => {
						setPageSize(size)
						if (onPageSizeChange) {
							onPageSizeChange(size)
						}
					}}
					canPreviousPage={canPreviousPage}
					canNextPage={canNextPage}
					nextPage={nextPage}
					pageIndex={pageIndex}
					previousPage={previousPage}
					loading={loading}
					noMeta={noMeta}
					page={page}
					controlledPageCount={controlledPageCount}
				/>
			)}
		</Fragment>
	)
}

export default DefaultView