import {routeCreator} from "../helpers/routeCreator";
import Login from "../pages/login/login";
import Home from "../pages/home/home";
import NotFound from "../pages/notFound/notFound";
import Orders from "../pages/orders/orders";
import Order from "../pages/order/order";
import Reports from "../pages/reports/reports";
import Announcements from "../pages/announcements/announcements";
import Contact from "../pages/contact/contact";
import Warranties from "../pages/warranties/pages/index/warranties";
import NewWarranty from "../pages/warranties/pages/create/newWarranty";
import WarrantyDetails from "../pages/warranties/pages/details/warrantyDetails";
import OrderPlaced from "../pages/orderPlaced/orderPlaced";
import OrderFinalize from "../pages/orderFinalize/orderFinalize";
import Index from "../pages/returns/pages/index";
import NewReturn from "../pages/returns/components/newReturn";
import Invoices from "../pages/invoices/invoices";
import PendingOrders from "../pages/pendingOrders/pendingOrders";
import Privacy from "../pages/privacy/privacy";
import Profile from "../pages/profile/profile";
import AccidentForm from "../pages/warranties/pages/accident/accidentForm";

export const ROUTE_PAGE_LOGIN = routeCreator(Login, '/', 'login', 'Login', 'page.login')

export const ROUTE_PAGE_HOME = routeCreator(Home, '/', 'Home', 'page.home', {exact: true})
export const ROUTE_PAGE_SEARCH_RESULTS = routeCreator(Home, '/search-results', 'SearchResults', 'page.searchResults')

export const ROUTE_PAGE_ORDERS = routeCreator(Orders, '/orders', 'Orders', 'page.orders', {exact: true})
export const ROUTE_PAGE_ORDER = routeCreator(Order, '/order/:id', 'Order', 'page.order.single')
// export const ROUTE_PAGE_ORDER_EDIT = routeCreator(OrderEdit, '/orders/:id/edit', 'Order Edit', 'page.order.edit')
export const ROUTE_PAGE_ORDER_FINALIZE = routeCreator(OrderFinalize, '/orders/:id/finalize', 'Order Finalize', 'page.order.finalize')
export const ROUTE_PAGE_ORDER_PLACED = routeCreator(OrderPlaced, '/orders/:id/placed', 'Order Placed', 'page.order.placed')

export const ROUTE_PAGE_PENDING_ORDERS = routeCreator(PendingOrders, '/pending-orders', 'Pending Orders', 'page.pending.orders', {exact: true})

export const ROUTE_PAGE_INVOICES = routeCreator(Invoices, '/invoices', 'Invoices', 'page.invoices', {exact: true})

export const ROUTE_PAGE_WARRANTIES = routeCreator(Warranties,'/warranties','Warranties','page.warranties',{exact:true})
export const ROUTE_PAGE_NEW_WARRANTY = routeCreator(NewWarranty,'/new-warranty','NewWarranty','page.newWarranty',{exact:true})
export const ROUTE_PAGE_WARRANTY_ACCIDENT = routeCreator(AccidentForm, '/warranty-accident/:code/:id', 'WarrantyAccident', 'page.warrantyAccident', {exact: true})

export const ROUTE_PAGE_DETAILS_WARRANTY = routeCreator(WarrantyDetails,'/details-warranty','WarrantyDetails.page.detailsWarranty',{exact:true})
export const ROUTE_PAGE_RETURNS = routeCreator(Index,'/returns','Returns','page.returns',{exact:true})
export const ROUTE_PAGE_NEW_RETURN = routeCreator(NewReturn,'/new-return','NewReturn','page.newReturn',{exact:true})

export const ROUTE_PAGE_ANNOUNCEMENT = routeCreator(Announcements, '/announcement/:id', 'Announcement', 'page.announcement')
export const ROUTE_PAGE_ANNOUNCEMENTS = routeCreator(Announcements, '/announcements', 'Announcements', 'page.announcements.index', {exact: true})

export const ROUTE_PAGE_REPORTS = routeCreator(Reports, '/reports', 'Reports', 'page.reports', {exact: true})

export const ROUTE_PAGE_CONTACT = routeCreator(Contact, '/contact', 'Contact', 'page.contact', {exact: true})
export const ROUTE_PAGE_PRIVACY = routeCreator(Privacy, '/privacy-policy', 'Privacy', 'page.privacy.policy', {exact: true})
export const ROUTE_PAGE_PROFILE = routeCreator(Profile, '/my-profile', 'Profile', 'page.profile', {exact: true})

export const ROUTE_PAGE_NOT_FOUND = routeCreator(NotFound, '/', 'Not Found', 'page.404')

const router = {
	ROUTE_PAGE_HOME,
	ROUTE_PAGE_ORDERS,
	ROUTE_PAGE_ORDER,
	ROUTE_PAGE_NEW_RETURN,
	// ROUTE_PAGE_ORDER_EDIT,
	ROUTE_PAGE_RETURNS,
	ROUTE_PAGE_ORDER_FINALIZE,
	ROUTE_PAGE_ORDER_PLACED,
	ROUTE_PAGE_PENDING_ORDERS,
	ROUTE_PAGE_INVOICES,
	ROUTE_PAGE_ANNOUNCEMENTS,
	ROUTE_PAGE_ANNOUNCEMENT,
	ROUTE_PAGE_REPORTS,
	ROUTE_PAGE_CONTACT,
	ROUTE_PAGE_WARRANTIES,
	ROUTE_PAGE_NEW_WARRANTY,
	ROUTE_PAGE_WARRANTY_ACCIDENT,
	ROUTE_PAGE_DETAILS_WARRANTY,
	ROUTE_PAGE_SEARCH_RESULTS,
	ROUTE_PAGE_PRIVACY,
	ROUTE_PAGE_PROFILE,
	ROUTE_PAGE_NOT_FOUND,

}

export default router;