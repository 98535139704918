// @flow
import React from 'react';
import {Dialog, DialogContent, DialogTitle, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {PAGE_LOGIN} from "../../../../locales/pages/namespaces";
import ContentLoader from "../../../general/contentLoader/contentLoader";
import SelectAddress from "../../selectAddress/selectAddress";
import useUserData from "../../../../hooks/user/useUserData";

type Props = {
	open: boolean,
	handleDialog: Function,
	loadingStores: boolean
};

const UserAddresses = (props: Props) => {
	const {open, handleDialog, loadingStores} = props
	const {t} = useTranslation(PAGE_LOGIN)
	const {stores} = useUserData()
	const handleClose = (event, reason) => {
		handleDialog(false)
	}
	return (
		<Dialog
			open={open}
			onClose={handleClose}
			maxWidth={'sm'}
			sx={{
				display: (
					!loadingStores
					&& stores
					&& stores.length > 1
				) ? 'block' : 'none'
			}}
		>
			<DialogTitle>
				<Typography
					variant={'h4'}
					component={'div'}
				>
					{t('Select address')}
				</Typography>
			</DialogTitle>
			<DialogContent>
				{loadingStores ? <ContentLoader loading={loadingStores}/> : <SelectAddress requireConfirmation={true}/>}
			</DialogContent>
		</Dialog>
	);
};

export default UserAddresses