//@flow
import React from 'react';
import {useTranslation} from "react-i18next";
import {TABLE_COMPONENT} from "../../../../../locales/components/namespaces";
import Button from "@mui/material/Button";

type Props = {
	pageIndex: number,
	pageSize: number,
	setPageSize: Function,
	previousPage: Function,
	nextPage: Function,
	canPreviousPage: boolean,
	canNextPage: boolean,
}

const Pagination = (props: Props) => {
	const {t} = useTranslation(TABLE_COMPONENT)
	const {
		pageSize,
		setPageSize,
		canPreviousPage,
		canNextPage,
		nextPage,
		previousPage,
		pageIndex,
		loading,
		page,
		noMeta,
		controlledPageCount
	} = props
	return (
		<div className="pagination">
			<div style={{marginRight: 'auto'}}>
				{loading ? (
					// Use our custom loading state to show a loading indicator
					<span>{t('Loading')}...</span>
				) : page.length > 0 ? !noMeta && (
					<span>
						{t('Showing')} {page.length} {t('of')} ~{controlledPageCount * pageSize}{' '}
						{t('results')}
					</span>
				) : (
					<span>
						{t('no_results')}
					</span>
				)}
			</div>
			<select
				className={'form-control form-control-sm'}
				value={pageSize}
				onChange={e => {
					setPageSize(Number(e.target.value))
				}}
			>
				{[10, 20, 30, 40, 50].map(pageSize => (
					<option key={pageSize} value={pageSize}>
						{pageSize} {t('per page')}
					</option>
				))}
			</select>
			<>
				<Button
					variant={"text"}
					onClick={() => previousPage()} disabled={!canPreviousPage}
				>
					{'<'}
				</Button>
				<span><strong>{pageIndex * pageSize} - {pageSize * pageIndex + pageSize}</strong></span>
				<Button
					variant={"text"}
					onClick={() => nextPage()} disabled={!canNextPage}
				>
					{'>'}
				</Button>
			</>
		</div>
	);
};

export default Pagination;