// @flow
import React, {useEffect, useState} from 'react';
import {Box, Stack, Tab, Tabs, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {PAGE_ANNOUNCEMENTS} from "../../locales/pages/namespaces";
import useMessageAction from "../../hooks/message/useMessageAction";
import {AnnouncementTypes, ReadStatus} from "../../helpers/constants";
import useMessageData from "../../hooks/message/useMessageData";
import AnnouncementsCard from "./partials/announcementsCard";
import Table from "../../components/grids/table/table";
import ContentLoader from "../../components/general/contentLoader/contentLoader";
import Button from "@mui/material/Button";
import {Circle} from "@mui/icons-material";
import {useParams} from "react-router";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import useUserData from "../../hooks/user/useUserData";
import {normalizeGreek} from "../../helpers/normalizeGreek";
import UnstructuredTableView from "../../components/grids/table/partials/views/unstructuredTableView";

type Props = {};

const Announcements = (props: Props) => {
	const {id} = useParams()
	const {hasExpandedAnnouncements} = useUserData()
	const {t} = useTranslation(PAGE_ANNOUNCEMENTS)
	const [activeTab, setActiveTab] = useState(AnnouncementTypes.All)
	const [readStatus, setReadStatus] = useState(ReadStatus.Any)
	const [openAll, setOpenAll] = useState(hasExpandedAnnouncements)
	
	const {
		getMessages,
		getMessageCategories,
		setMessageViewed,
		setMessageIsOpen,
		getMessageAttachments,
		toggleAnnouncements
	} = useMessageAction(activeTab, readStatus, id)
	const {loading, indexTable, categories} = useMessageData()
	
	useEffect(() => {
		getMessageCategories()
	}, [getMessageCategories])
	
	const selectReadStatus = (status) => () => {
		const newValue = readStatus === status ? ReadStatus.Any : status
		setReadStatus(newValue)
	}
	
	useEffect(() => {
		if (activeTab === AnnouncementTypes.All && indexTable.items.find(i => i.id === id)) {
			const element = document.getElementById(id)
			if (element) {
				getMessageAttachments(id)
				element.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
				})
			}
		}
	}, [id, indexTable.items, activeTab, setMessageIsOpen, getMessageAttachments])

	return !loading && categories.length > 0 ? (
		<Box p={1}>
			<Typography
				pb={1}
				variant={'h2'}
				component={'div'}
				sx={{
					textAlign: 'center'
				}}
			>
				{t('Announcements')}
			</Typography>
			<Stack direction={'row'} spacing={1} justifyContent={'flex-end'} alignItems={'center'}>
				<Button
					onClick={() => {
						setOpenAll(prev => !prev)
						toggleAnnouncements(!openAll)
					}}
					color={'success'}
				>
					<Typography
						variant={'overline'}
					>
						{openAll ? normalizeGreek(t('Collapse All')) : normalizeGreek(t('Expand All'))}
					</Typography>
					{openAll ? <ExpandLess sx={{fontSize: 16, ml: 0.5}}/> : <ExpandMore sx={{fontSize: 16, ml: 0.5}}/>}
				</Button>
				<Button
					size={'small'}
					onClick={selectReadStatus(ReadStatus.Read)}
					sx={{
						textDecoration: readStatus === ReadStatus.Read ? 'underline' : 'none',
						display: 'flex',
						alignItems: 'center'
					}}
				>
					<Typography
						variant={'overline'}
					>
						{t('read')}
					</Typography>
					<Circle color={'primary'} sx={{fontSize: 16, ml: 0.5}}/>
				</Button>
				<Button
					size={'small'}
					onClick={selectReadStatus(ReadStatus.Unread)}
					sx={{
						textDecoration: readStatus === ReadStatus.Unread ? 'underline' : 'none',
						display: 'flex',
						alignItems: 'center'
					}}
				>
					<Typography
						variant={'overline'}
						component={'div'}
					>
						{t('unread')}
					</Typography>
					<Circle color={'secondary'} sx={{fontSize: 16, ml: 0.5}}/>
				</Button>
			</Stack>
			<Box sx={{pb: 0, width: '100%'}}>
				<Tabs value={activeTab} onChange={(e, value) => setActiveTab(value)}
					  sx={{borderBottom: 1, borderColor: 'divider'}}>
					{categories.map((category, index) => (
						<Tab
							key={index}
							label={t(category.description)}
							value={category.id}
							iconPosition="end"
							sx={{
								'&.MuiTab-root': {
									fontWeight: 'bold',
									fontSize: '1rem'
								}
							}}
						/>
					))}
				</Tabs>
			</Box>
			<Box sx={{backgroundColor: '#FFFFFF', p: 2}}>
				<Table
					customStyle={'fullWidthTable'}
					data={indexTable}
					columns={[
						{
							header: '',
							accessor: 'id',
						}
					]}
					dataManager={getMessages}
					view={UnstructuredTableView}
					noMeta
					renderRow={(row) => {
						const id = row.original.id
						return (
							<AnnouncementsCard
								key={id}
								announcement={row?.original}
								setMessageViewed={setMessageViewed}
								setMessageIsOpen={setMessageIsOpen}
								getMessageAttachments={getMessageAttachments}
								urlId={id}
							/>
						)
					}}
					pageSize={10}
					noHeader
				/>
			</Box>
		</Box>
	) : (
		<ContentLoader loading={loading}/>
	)
};

export default Announcements



