export default function TreeItem(theme) {
	return {
		MuiTreeItem: {
			styleOverrides: {
				content: {},
				iconContainer: {}
			}
		}
	};
}
