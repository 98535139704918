export default function Checkbox(theme) {
	return {
		MuiCheckbox: {
			defaultProps: {
				color: 'secondary'
			},
			styleOverrides: {}
		}
	};
}
