import * as ActionTypes from '../../actions/types'
import {fromJS} from "immutable";
import {mapMultiple, preserveFieldsIfNotPresentInNew} from "../../helpers/map";
import {mapFile, mapMessage} from "../../types/message/map";
import {AnnouncementTypes} from "../../helpers/constants";

const INITIAL_STATE = {
	loading: false,
	byId: {},
	unRead: 0,
	indexTable: {
		loading: false,
		items: [],
		count: 0
	},
	message: null,
	categories: [],
	showImportantAnnouncement: false,
	importantAnnouncement: null
};

function setMessageIsOpen(state, action) {
	const {id, isOpen} = action.payload
	return state
	.setIn(['byId', id, 'isOpen'], isOpen)
}

function setAnnouncementsMessage(state, action) {
	const message = action.payload
	return state
	.set('message', fromJS(message ? {
		text: message?.text, variant: message?.variant
	} : null))
}

function getMessages(state) {
	return state
	.setIn(['indexTable', 'loading'], true)
	.setIn(['indexTable', 'items'], fromJS([]))
	.setIn(['indexTable', 'count'], 0)
}

function getMessagesSucceeded(state, action) {
	const {payload: {count, result}, parameters: {urlId, hasExpandedAnnouncements}} = action
	const mappedData = mapMultiple(result, (item) => mapMessage(item, urlId, hasExpandedAnnouncements))
	return state
	.set('loading', false)
	.set('byId', state.get('byId')
	.mergeWith(preserveFieldsIfNotPresentInNew(['files']), fromJS(mappedData)))
	.setIn(['indexTable', 'count'], parseInt(count))
	.setIn(['indexTable', 'items'], fromJS(Object.keys(mappedData).reverse()))
	.setIn(['indexTable', 'loading'], false)
}

function getMessagesFailed(state, action) {
	const {message} = action.payload
	return state
	.setIn(['indexTable', 'loading'], false)
	.set('message', fromJS({
		text: message, variant: 'error'
	}))
}

function setMessageViewed(state) {
	return state
}

function setMessageViewedSucceeded(state, action) {
	const {parameters: {data: id}} = action
	const now = new Date()
	return state
	.setIn(['byId', id, 'viewedDate'], now)
}

function setMessageViewedFailed(state, action) {
	const {message} = action.payload
	return state
	.set('message', fromJS({
		text: message, variant: 'error'
	}))
}

function getMessageAttachments(state) {
	return state
}

function getMessageAttachmentsSucceeded(state, action) {
	const {parameters: {id}, payload: {files}} = action
	const mappedData = mapMultiple(files, mapFile)
	return state
	.setIn(['byId', id, 'files'], fromJS(mappedData))
}

function getMessageAttachmentsFailed(state, action) {
	const {message} = action.payload
	return state
	.set('message', fromJS({
		text: message, variant: 'error'
	}))
}

function getCountUnreadMessages(state) {
	return state
}

function getCountUnreadMessagesSucceeded(state, action) {
	const {data} = action.payload
	const unread = data.length > 0 && data[0]?.unRead ? Number(data[0]?.unRead) : 0
	return state
	.set('unRead', unread)
}

function getCountUnreadMessagesFailed(state, action) {
	const {message} = action.payload
	return state
	.set('message', fromJS({
		text: message, variant: 'error'
	}))
}

function getMessageCategories(state) {
	return state
	.set('loading', true)
}

function getMessageCategoriesSucceeded(state, action) {
	const {data} = action.payload
	const categories = data.map(category => ({
		id: category.id,
		description: category.Type
	}))
	categories.unshift({
		id: AnnouncementTypes.All,
		description: 'announcement_type_' + AnnouncementTypes.All
	})
	return state
	.set('loading', false)
	.set('categories', fromJS(categories))
}

function getMessageCategoriesFailed(state, action) {
	const {message} = action.payload
	return state
	.set('loading', false)
	.set('message', fromJS({
		text: message, variant: 'error'
	}))
}

function getImportantAnnouncement(state) {
	return state
	.set('loading', true)
}

function getImportantAnnouncementSucceeded(state, action) {
	const {data} = action.payload
	const mappedData = data.length > 0 ? mapMessage(data[0]) : null
	return state
	.set('loading', false)
	.set('showImportantAnnouncement', true)
	.set('importantAnnouncement', fromJS(mappedData))
	.set('byId', state.get('byId')
	.mergeWith(preserveFieldsIfNotPresentInNew(['files']), fromJS(mappedData ? {[mappedData.id]: mappedData} : {})))
}

function getImportantAnnouncementFailed(state, action) {
	const {message} = action.payload
	return state
	.set('loading', false)
	.set('message', fromJS({
		text: message, variant: 'error'
	}))
	.set('showImportantAnnouncement', false)
}

function toggleImportantAnnouncement(state, action) {
	const {payload} = action
	return state
	.set('showImportantAnnouncement', payload)
}

function toggleAnnouncements(state, action) {
	const {isOpen} = action.payload
	const announcements = state.get('byId').toJS()
	const mappedData = {}
	for (const [key, value] of Object.entries(announcements)) {
		mappedData[key] = {
			...value,
			isOpen: isOpen
		}
	}
	return state
	.set('byId', state.get('byId')
	.mergeWith(preserveFieldsIfNotPresentInNew(['files']), fromJS(mappedData)))
	.setIn(['indexTable', 'items'], fromJS(Object.keys(mappedData).reverse()))
	.setIn(['indexTable', 'loading'], false)
}

const message = {
	initialState: INITIAL_STATE,
	handlers: {
		[ActionTypes.SET_ANNOUNCEMENTS_MESSAGE]: setAnnouncementsMessage,
		[ActionTypes.GET_MESSAGES]: getMessages,
		[ActionTypes.GET_MESSAGES_SUCCEEDED]: getMessagesSucceeded,
		[ActionTypes.GET_MESSAGES_FAILED]: getMessagesFailed,
		[ActionTypes.SET_MESSAGE_VIEWED]: setMessageViewed,
		[ActionTypes.SET_MESSAGE_VIEWED_SUCCEEDED]: setMessageViewedSucceeded,
		[ActionTypes.SET_MESSAGE_VIEWED_FAILED]: setMessageViewedFailed,
		[ActionTypes.GET_MESSAGE_ATTACHMENTS]: getMessageAttachments,
		[ActionTypes.GET_MESSAGE_ATTACHMENTS_SUCCEEDED]: getMessageAttachmentsSucceeded,
		[ActionTypes.GET_MESSAGE_ATTACHMENTS_FAILED]: getMessageAttachmentsFailed,
		[ActionTypes.GET_COUNT_UNREAD_MESSAGES]: getCountUnreadMessages,
		[ActionTypes.GET_COUNT_UNREAD_MESSAGES_SUCCEEDED]: getCountUnreadMessagesSucceeded,
		[ActionTypes.GET_COUNT_UNREAD_MESSAGES_FAILED]: getCountUnreadMessagesFailed,
		[ActionTypes.GET_MESSAGE_CATEGORIES]: getMessageCategories,
		[ActionTypes.GET_MESSAGE_CATEGORIES_SUCCEEDED]: getMessageCategoriesSucceeded,
		[ActionTypes.GET_MESSAGE_CATEGORIES_FAILED]: getMessageCategoriesFailed,
		[ActionTypes.SET_MESSAGE_IS_OPEN]: setMessageIsOpen,
		[ActionTypes.GET_IMPORTANT_ANNOUNCEMENT]: getImportantAnnouncement,
		[ActionTypes.GET_IMPORTANT_ANNOUNCEMENT_SUCCEEDED]: getImportantAnnouncementSucceeded,
		[ActionTypes.GET_IMPORTANT_ANNOUNCEMENT_FAILED]: getImportantAnnouncementFailed,
		[ActionTypes.TOGGLE_IMPORTANT_ANNOUNCEMENT]: toggleImportantAnnouncement,
		[ActionTypes.TOGGLE_ANNOUNCEMENTS]: toggleAnnouncements,
	}
}

export default message