// @flow
import React, {useEffect, useMemo, useState} from 'react';
import useUserData from "../../../hooks/user/useUserData";
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";
import {PAGE_REPORTS} from "../../../locales/pages/namespaces";
import {
	DataGridPremium,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector, GridToolbarExport,
	GridToolbarFilterButton
} from "@mui/x-data-grid-premium";
import {muiDatePickersLocales, muiGridLocales} from "../../../helpers/muiLocales";
import DataGridWrapper from "../../../components/grids/dataGridWrapper/dataGridWrapper";
import useReportsAction from "../../../hooks/reports/useReportsAction";
import useReportsData from "../../../hooks/reports/useReportsData";
import dayjs from "dayjs";
import _ from "lodash";
import {AdapterDayjs} from "@mui/x-date-pickers-pro/AdapterDayjs";
import {DateRangePicker, LocalizationProvider, SingleInputDateRangeField} from "@mui/x-date-pickers-pro";
import {enqueueSnackbar} from "notistack";
import {Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {API} from "../../../helpers/constants";
import axios from "axios";
import {handleAxiosErrors, logoutUnauthorizedUser} from "../../../helpers/errorHandling";
import useUserAction from "../../../hooks/user/useUserAction";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

type Props = {};

function CustomToolbar({handleExport, t}) {
	return (
		<GridToolbarContainer>
			<GridToolbarColumnsButton/>
			<GridToolbarFilterButton/>
			<GridToolbarDensitySelector/>
			<GridToolbarExport printOptions={{disableToolbarButton: true}}/>
			<Button size={"small"} onClick={handleExport}>{t('Print')}<PictureAsPdfIcon/></Button>
		</GridToolbarContainer>
	);
}

const CustomerCard = (props: Props) => {
	const {t} = useTranslation(PAGE_REPORTS)
	const [dateRange, setDateRange] = useState([dayjs().startOf('year'), dayjs()])
	const {dateFormat, language} = useUserData()
	const {loading, customerCard} = useReportsData()
	const {getCustomerCard} = useReportsAction()
	const {selectedStore, token, isLoggedIn} = useUserData()
	const {userLogout} = useUserAction()
	
	useEffect(() => {
		if (dateRange && dateRange[0] && dateRange[1] && dateRange[0].format('DD/MM/YYYY') !== 'Invalid Date' && dateRange[1]?.format('DD/MM/YYYY') !== 'Invalid Date') {
			getCustomerCard(dateRange)
		} else {
			enqueueSnackbar(t('Insert a valid date filter!'), {
					variant: 'warning',
					autoHideDuration: 2000
				}
			)
		}
	}, [dateRange, getCustomerCard, t])
	
	const types = useMemo(() => (_.sortBy(_.uniqBy(customerCard, 'type').map(i => i.type))), [customerCard])
	
	// const dateLimit = useMemo(() => (dayjs().subtract(2, 'year')), []) TODO::To limit search range 2 years from current date
	const dateLimit = useMemo(() => (dayjs('2022-10-30')), []) // Limit to date SEN has data
	
	const columns = useMemo(() => [
		{
			field: 'date',
			headerName: t('Date'),
			flex: 1,
			minWidth: 150,
			type: 'date',
			valueGetter: (value, row, column, apiRef) => {
				return value && new Date(value)
			},
			valueFormatter: (value, row, column, apiRef) => {
				return (value ? dayjs(value).format(dateFormat) : '-')
			}
		},
		{
			field: 'type',
			headerName: t('Type'),
			flex: 1,
			type: 'singleSelect',
			valueOptions: Object.entries(types).map(type => {
				const value = type[1]
				return {
					value: value,
					label: t(value)
				}
			}),
			minWidth: 160,
		},
		{
			field: 'docNum',
			headerName: t('Doc Number'),
			flex: 1,
			minWidth: 150,
		},
		{
			field: 'debit',
			headerName: t('Debit'),
			flex: 1,
			type: 'number',
			minWidth: 140,
		},
		{
			field: 'credit',
			headerName: t('Credit'),
			flex: 1,
			type: 'number',
			minWidth: 140,
		},
		{
			field: 'balance',
			headerName: t('Balance'),
			flex: 1,
			type: 'number',
			minWidth: 160,
		}
	], [dateFormat, t, types])
	
	
	const handleExport = () => {
		axios({
			method: 'post',
			responseType: 'blob',
			url: `${API}/en/b2b/customer/card/pdf`,
			headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
			data: {
				AddressId: selectedStore.id,
				CustomerId: selectedStore.customerId,
				FromDt: dateRange && dateRange[0] ? dateRange[0]?.format('DD/MM/YYYY') : dayjs().startOf('Year').format('DD/MM/YYYY'),
				ToDt: dateRange && dateRange[1] ? dateRange[1]?.format('DD/MM/YYYY') : dayjs().format('DD/MM/YYYY')
			}
		}).then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `customer_card_${selectedStore.customer}.pdf`);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			
			enqueueSnackbar(t('PDF downloaded successfully!'), {
				variant: 'success',
				autoHideDuration: 2000
			});
		}).catch((error) => {
			enqueueSnackbar(handleAxiosErrors(error, t('Failed to download PDF!'), {
				variant: 'error',
				autoHideDuration: 1000,
			}))
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}
	
	return (
		<Box p={2} maxWidth={'98%'}>
			<Box p={0.5} display={'flex'} alignItems={'center'}>
				<Typography
					variant={'subtitle1'}
					component={'span'}
					pr={1}
				>
					{t('Date range filter')}
				</Typography>
				<LocalizationProvider dateAdapter={AdapterDayjs} localeText={muiDatePickersLocales(language)}>
					<DateRangePicker
						format={dateFormat}
						slots={{field: SingleInputDateRangeField}}
						value={dateRange}
						onChange={(newValue) => setDateRange(newValue)}
						minDate={dateLimit}
					/>
				</LocalizationProvider>
			</Box>
			<DataGridWrapper>
				<DataGridPremium
					loading={loading}
					rows={customerCard}
					columns={columns}
					localeText={muiGridLocales(language)}
					sx={{background: '#FFFFFF'}}
					initialState={{density: "compact"}}
					slots={{
						toolbar: () => <CustomToolbar handleExport={handleExport} t={t}/>
					}}
					columnBufferPx={2000}
				/>
			</DataGridWrapper>
		</Box>
	);
};

export default CustomerCard