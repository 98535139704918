// @flow
import React, {useEffect, useState} from 'react';
import useReportsAction from "../../hooks/reports/useReportsAction";
import useReportsData from "../../hooks/reports/useReportsData";
import {enqueueSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import {PAGE_REPORTS} from "../../locales/pages/namespaces";
import Box from "@mui/material/Box";
import {Tab, Tabs, Typography} from "@mui/material";
import Budget from "./budget/budget";
import {TabPanel} from "../../components/general/tabPanel/tabPanel";
import CustomerCard from "./customerCard/customerCard";
import Bonus from "./bonus/bonus";
import Sales from "./sales/sales";
import Invoices from "../invoices/invoices";
import {Permissions, timeLimitToRequireDailyCode} from "../../helpers/constants";
import DailyCodeConfirmation from "../../components/app/dialogs/dailyCodeConfirmation/dailyCodeConfirmation";
import dayjs from "dayjs";
import useUserData from "../../hooks/user/useUserData";
import {useHasPermission} from "../../modules/permission/hooks/useHasPermission";

type Props = {};

const Reports = (props: Props) => {
		const {t} = useTranslation(PAGE_REPORTS)
		const {message} = useReportsData()
		const {setReportsMessage} = useReportsAction()
		const {requiredDailyCodeTime} = useUserData()
		const [tabValue, setTabValue] = useState(0)
		const [openRequireDailyPass, setOpenRequireDailyPass] = useState((Boolean(requiredDailyCodeTime) && dayjs().diff(requiredDailyCodeTime, 'minutes') > timeLimitToRequireDailyCode) || !requiredDailyCodeTime)
		const hasReportAccess = useHasPermission([Permissions.AccessReports], {shouldView: true})
		const handleTabChange = (event, newValue) => {
			setTabValue(newValue);
		};
		
		useEffect(() => {
			if (message) {
				enqueueSnackbar(t(message?.text), {
					variant: message?.variant
				})
				setReportsMessage(null)
			}
		}, [message, setReportsMessage, t])
		
		const checkIfCodeRequired = () => {
			if (((Boolean(requiredDailyCodeTime) && dayjs().diff(requiredDailyCodeTime, 'minutes') > timeLimitToRequireDailyCode) || !requiredDailyCodeTime) && !openRequireDailyPass) setOpenRequireDailyPass(true)
		}
		
		useEffect(() => {
			checkIfCodeRequired()
			const intervalId = setInterval(() => {
				checkIfCodeRequired()
			}, 600000); //  milliseconds ~ 10min
			// Cleanup the interval when the component unmounts
			return () => clearInterval(intervalId);
		}, [])
		
		return (
			<>
				{!openRequireDailyPass && (
					<Box>
						<Box p={1}>
							<Typography
								pb={1}
								variant={'h2'}
								component={'div'}
								sx={{
									textAlign: 'center'
								}}
							>
								{t('Reports')}
							</Typography>
						</Box>
						<Box
							sx={{flexGrow: 1, bgcolor: 'background.paper', display: 'flex'}}
						>
							<Tabs
								orientation="vertical"
								variant="standard"
								value={tabValue}
								onChange={handleTabChange}
								sx={{borderRight: 1, borderColor: 'divider', minWidth: '250px', minHeight: '800px'}}
							>
								<Tab label={t('Customer Card')} value={0}/>
								{hasReportAccess && <Tab label={t('Budget')} value={1}/>}
								{hasReportAccess && <Tab label={t('Bonus')} value={2}/>}
								<Tab label={t('Sales')} value={3}/>
								<Tab label={t('Invoices')} value={4}/>
							</Tabs>
							<TabPanel isVisible={tabValue === 0} width={'100%'} style={{backgroundColor: '#FFFFFF'}}>
								<CustomerCard/>
							</TabPanel>
							{hasReportAccess && (
								<TabPanel isVisible={tabValue === 1} width={'100%'}
										  style={{backgroundColor: '#FFFFFF'}}>
									<Budget/>
								</TabPanel>
							)}
							{hasReportAccess && (
								<TabPanel isVisible={tabValue === 2} width={'100%'}
										  style={{backgroundColor: '#FFFFFF'}}>
									<Bonus/>
								</TabPanel>
							)}
							<TabPanel isVisible={tabValue === 3} width={'100%'}
									  style={{backgroundColor: '#FFFFFF'}}>
								<Sales/>
							</TabPanel>
							<TabPanel isVisible={tabValue === 4} width={'100%'}
									  style={{backgroundColor: '#FFFFFF'}}>
								<Invoices/>
							</TabPanel>
						</Box>
					</Box>
				)}
				<DailyCodeConfirmation
					open={openRequireDailyPass}
					handleDialog={(newValue) => setOpenRequireDailyPass(newValue)}
				/>
			</>
		
		)
	}
;

export default Reports