// @flow
import React from 'react'
import {checkPermissions} from '../../helpers/checkPermissions'

type Props = {
	requiredPermissions: Array<string>,
	notAllowedComponent: any,
	loadingComponent: any,
}

const PermissionHOCDefault = (props: Props) => {
	const {
		requiredPermissions,
		currentUserPermissions,
		loading,
		notAllowedComponent: NotAllowedComponent,
		loadingComponent: LoadingComponent,
	} = props
	
	if (loading) {
		return LoadingComponent ? <LoadingComponent/> : null
	}
	const {canView, canEdit} = checkPermissions(requiredPermissions, currentUserPermissions)
	if (!canView) {
		if (NotAllowedComponent) {
			return <NotAllowedComponent/>
		}
		return null
	} else if (!canEdit) {
		return React.Children.map(props.children, el => (
			React.cloneElement(el, {disabled: true})
		))
	}
	return props.children
}

export default PermissionHOCDefault