//@flow
import React from 'react';
import {AuthenticatedFile} from "../authenticatedLink/authenticatedFile";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import {Print} from "@mui/icons-material";
import {printFile} from "../../../helpers/fileHandle";

type Props = {
	autoReloadFile?: boolean,
	url: string
}

const ButtonComponent = ({
							 loading,
							 fetchFile,
							 localLink,
							 autoReloadFile,
							 component: Component
						 }) => {
	const onClick = (evt) => {
		evt.stopPropagation()
		if (!autoReloadFile) {
			fetchFile()
		} else {
			printFile(localLink)
		}
	}
	return loading ? (
		<CircularProgress
			color="inherit"
			size={16}
			sx={{mx: 1.25, alignSelf: "center"}}
		/>
	) : (!autoReloadFile || (autoReloadFile && localLink)) ? (
		Component ? (
			<Component
				onClick={onClick}
			/>
		) : (
			<IconButton
				color="primary"
				onClick={onClick}
			>
				<Print/>
			</IconButton>
		)
	) : null;
}

const PrintButton = (props: Props) => {
	const {autoReloadFile, component, url} = props
	
	return (
		<AuthenticatedFile
			autoReloadFile={autoReloadFile}
			url={url}
			automaticallyUsePrintFile={true}
		>
			{(params) => (
				<ButtonComponent
					{...params}
					component={component}
					autoReloadFile={autoReloadFile}
				/>
			)}
		</AuthenticatedFile>
	)
}

export default PrintButton;
