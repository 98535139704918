export default function ButtonBase(theme) {
    return {
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true
            },
            styleOverrides: {
                root: {
                    '&:hover': {
                        color: theme.palette.secondary
                    }
                }
            }
        }
    }
}
