// @flow
import React from 'react';
import {useTranslation} from "react-i18next";
import {PAGE_PRIVACY} from "../../locales/pages/namespaces";
import Box from "@mui/material/Box";
import {Divider, Typography} from "@mui/material";

type Props = {};

const Privacy = (props: Props) => {
	const {t} = useTranslation(PAGE_PRIVACY)
	return (
		<Box>
			<Box p={1}>
				<Typography
					pb={1}
					variant={'h2'}
					component={'div'}
					sx={{
						textAlign: 'center'
					}}
				>
					{t('Privacy Policy')}
				</Typography>
				<Box p={4} sx={{backgroundColor: '#FFFFFF'}}>
					<Typography
						variant={'h4'}
						component={'div'}
					>
						{t('Personal Data Protection')}
					</Typography>
					<Divider/>
					<Typography
						variant={'body1'}
					>
						{t('privacy_text')}
					</Typography>
				</Box>
			</Box>
		</Box>
	);
};

export default Privacy