import type {Store, UnmappedStore, UnmappedUser, User} from "./types";
import {AppParameters, UnmappedAppParameters} from "./types";

export const mapStore: Store = (datum: UnmappedStore) => {
	if (!datum) return
	return {
		id: datum.AddressId,
		address: {
			el: datum.Address,
			en: datum.AddressEn
		},
		countryCode: datum.AddressCountryCode,
		customerId: datum.CustId,
		customer: datum.CustomerName,
		isAdmin: datum.isAdmin === '1',
		region: datum.Region
	}
}

export const mapUser: User = (datum: UnmappedUser) => {
	if (!datum) return
	return {
		id: datum.UserId,
		loginName: {
			el: datum.LoginName,
			en: datum.LoginNameEn,
		},
		username: datum.username,
		customer: datum.CustomerName,
		userId: datum.UserId,
		addressId: datum.AddressId,
		hasExpandedAnnouncements: datum.hasExpandedAnnouncements === '1',
		customerId: datum.CustomerId,
		currencySymbol: datum.UserCurrencySymbol ? datum.UserCurrencySymbol : '€',
		orderTypes: datum.userOrdersheets ?
			datum.userOrdersheets.map(t => ({
				id: Number(t.id),
				title: {
					el: t.Description,
					en: t.DescriptionEn,
				}
			})) : [],
		paymentDiscount: Number(datum.PaymentTypeDiscount),
		secondPaymentDiscount: Number(datum.SecondPaymentDiscount),
		preserveForBackend: datum,
		erpCode: datum.ErpCode
	}
}

export const mapAppParams: AppParameters = (datum: UnmappedAppParameters) => {
	if (!datum) return
	return {
		maxAllowedProductsInCart: Number(datum.AllowedQuantity),
		displayStock: Number(datum.Stock),
		maxAllowedReturnItems: Number(datum.ReturnItems),
		dayLimitForReturns: Number(datum.CheckDays),
		monthLimitForWarranties: Number(datum.CheckMonths)
	}
} 

