// @flow
import React, {useEffect} from 'react';
import {Grid, List, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";
import {PAGE_ANNOUNCEMENTS} from "../../../locales/pages/namespaces";
import useMessageData from "../../../hooks/message/useMessageData";
import useMessageAction from "../../../hooks/message/useMessageAction";
import {AnnouncementTypes, ReadStatus} from "../../../helpers/constants";
import './homeNews.css'
import ListItem from "@mui/material/ListItem";
import {Link as RouterLink} from "react-router-dom";
import {getRouteUrl} from "../../../helpers/getRouteUrl";
import {ROUTE_PAGE_ANNOUNCEMENT} from "../../../routers/routes";
import Link from "@mui/material/Link";
import {formatDate} from "../../../helpers/date";
import useUserData from "../../../hooks/user/useUserData";
import {normalizeGreek} from "../../../helpers/normalizeGreek";

const HomeNews = () => {
	const {t} = useTranslation(PAGE_ANNOUNCEMENTS)
	const {getMessages} = useMessageAction(AnnouncementTypes.All, ReadStatus.Any, null, true)
	const {indexTable} = useMessageData()
	const {dateFormat, timeFormat} = useUserData()
	useEffect(() => {
		getMessages(0, 12, true)
	}, [getMessages])
	return (
		<Box
			p={1}
			sx={{
				width: '100%',
				background: '#FFFFFF'
			}}
			className={'home-announcements'}
		>
			<Typography
				variant={'h3'}
				component={'div'}
				pb={0.3}
				sx={{
					textAlign: 'center'
				}}
			>
				{t('Latest Announcements')}
			</Typography>
			<List
				dense
				sx={{
					display: 'flex',
					flexDirection: 'row',
					flexWrap: 'wrap',
					width: '100%',
					justifyContent: 'space-between',
				}}
			>
				{indexTable.items?.slice(0, 20)?.map((item, index) => {
					return (
						<ListItem key={item.id}
								  sx={{
									  display: 'flex',
									  justifyContent: 'space-between',
									  flexWrap: 'wrap',
									  backgroundColor: index % 2 === 0 ? 'grey.100' : 'inherit',
								  }}>
							<Grid container>
								<Grid item xs={12} sm={6} md={6}>
									<Typography
										variant={'subtitle1'}
										component={'div'}
									>
										<Link
											component={RouterLink}
											to={getRouteUrl(ROUTE_PAGE_ANNOUNCEMENT, {id: item.id})}
										>
											{item.title}
										</Link>
									</Typography>
								</Grid>
								<Grid item xs={6} sm={3} md={3}>
									<Typography
										variant={'subtitle1'}
										component={'div'}
									>
										{formatDate(item.date, `${dateFormat} ${timeFormat}`)}
									</Typography>
								</Grid>
								<Grid item xs={6} sm={3} md={3}>
									<Typography
										variant={'caption'}
										component={'div'}
										color={'error'}
										sx={{
											fontWeight: 'bold'
										}}
									>
										{normalizeGreek(t(item.type))}
									</Typography>
								</Grid>
							</Grid>
						</ListItem>
					)
				})}
			</List>
		</Box>
	);
};

export default HomeNews