// @flow
import React from 'react';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControl,
	FormHelperText,
	TextField,
	Typography
} from "@mui/material";
import Button from "@mui/material/Button";
import {Trans, useTranslation} from "react-i18next";
import {PAGE_LOGIN} from "../../../../locales/pages/namespaces";
import useDailyCode from "../../../../hooks/login/useDailyCode";
import {ErrorMessage, FormikProvider} from "formik";
import Box from "@mui/material/Box";
import {LoadingButton} from "@mui/lab";
import {SendOutlined} from "@mui/icons-material";
import useUserData from "../../../../hooks/user/useUserData";

type Props = {
	open: boolean,
	handleDialog: Function
};

const DailyCode = (props: Props) => {
	const {open, handleDialog} = props
	const {t} = useTranslation(PAGE_LOGIN)

	const handleClose = () => {
		formik.resetForm()
		handleDialog(false)
	}
	const {formik} = useDailyCode({handleClose})
	const {loading} = useUserData()

	return (
		<Dialog open={open} onClose={handleClose} maxWidth={'xs'}>
			<FormikProvider value={formik}>
				<Box
					component="form"
					onSubmit={formik.handleSubmit}
					noValidate
					pt={1}
				>
					<DialogTitle>
						<Typography
							variant={'h4'}
							component={'div'}
						>
							{t('Get you daily code')}
						</Typography>
					</DialogTitle>
					<DialogContent>
						<DialogContentText>
							<Trans
								i18nKey={'get_daily_code'}
								ns={PAGE_LOGIN}
							>
								To get your daily code, please enter your <strong>user email
								address</strong> here. <br/> You will receive a
								new code to your email. Use this code to login.
							</Trans>
						</DialogContentText>
						<Box
							pt={1}
						>
							<FormControl
								variant="standard"
								fullWidth
								error={
									formik.touched['email'] &&
									Boolean(formik.errors['email'])
								}
							>
								<TextField
									id={'email'}
									value={formik.values.email}
									name={'email'}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									placeholder={t('Enter your email')}
									size={'small'}
									inputProps={{maxLength: 250}}
									variant="outlined"
									required
								/>
								<FormHelperText>
									<ErrorMessage name={'email'}/>
								</FormHelperText>
							</FormControl>
						</Box>
					</DialogContent>
					<DialogActions>
						<Button
							color="error"
							size={"small"}
							onClick={handleClose}
							sx={{
								marginRight: 'auto'
							}}
						>
							{t('Cancel')}
						</Button>
						<LoadingButton
							loading={loading}
							loadingPosition="start"
							size={"small"}
							variant={"contained"}
							color={'success'}
							type={'submit'}
							startIcon={<SendOutlined/>}
						>
							{t('Send Code')}
						</LoadingButton>
					</DialogActions>
				</Box>
			</FormikProvider>
		</Dialog>
	);
};

export default DailyCode