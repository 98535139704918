import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {Badge, Box, IconButton} from '@mui/material';
import {DoorbellOutlined} from "@mui/icons-material";
import {getRouteUrl} from "../../../helpers/getRouteUrl";
import {enqueueSnackbar} from "notistack";
import useMessageAction from "../../../hooks/message/useMessageAction";
import useMessageData from "../../../hooks/message/useMessageData";
import {useTranslation} from "react-i18next";
import {NAVIGATION} from "../../../locales/components/namespaces";
import {ROUTE_PAGE_ANNOUNCEMENTS} from "../../../routers/routes";
import {menuIconStyle} from "../../layout/header/header";

const Notification = () => {
	const {t} = useTranslation(NAVIGATION)
	const history = useHistory()
	const {setAnnouncementsMessage, getCountUnreadMessages} = useMessageAction()
	const {message, countUnreadMessages} = useMessageData()
	
	useEffect(() => {
		if (message) {
			enqueueSnackbar(t(message?.text), {
					variant: message?.variant
				}
			)
			setAnnouncementsMessage(null)
		}
	}, [message, setAnnouncementsMessage, t])
	
	useEffect(() => {
		// Fetch notifications initially
		getCountUnreadMessages();
		// Set up an interval to fetch notifications every 1 minute (adjust as needed)
		const intervalId = setInterval(() => {
			getCountUnreadMessages()
		}, 600000); // 10 minute in milliseconds
		// Cleanup the interval when the component unmounts
		return () => clearInterval(intervalId);
	}, [getCountUnreadMessages])
	
	return (
		<Box sx={{flexShrink: 0, ml: 0.75}}>
			<IconButton
				color="primary"
				sx={{bgcolor: '#faf0e6', ...menuIconStyle}}
				onClick={() => history.push(getRouteUrl(ROUTE_PAGE_ANNOUNCEMENTS))}
			>
				<Badge badgeContent={countUnreadMessages} color="secondary">
					<DoorbellOutlined/>
				</Badge>
			</IconButton>
		</Box>
	);
};

export default Notification;
