import * as actions from '../../actions/actions'
import {useCallback} from "react";
import {useDispatch} from "react-redux";
import useUserData from "../user/useUserData";
import dayjs from "dayjs";

const useReportsAction = () => {
	const dispatch = useDispatch()
	const {selectedStore} = useUserData()
	
	const setReportsMessage = useCallback((message) => {
		dispatch(actions.setReportsMessage(message))
	}, [dispatch])
	
	const getBudgetAndGoalsReport = useCallback(() => {
		dispatch(actions.getBudgetAndGoalsReport({
			AddressId: selectedStore.id
		}))
	}, [dispatch, selectedStore.id])
	
	const getCustomerCard = useCallback((dateRange) => {
		dispatch(actions.getCustomerCard({
			AddressId: selectedStore.id,
			CustomerId: selectedStore.customerId,
			FromDt: dateRange && dateRange[0] ? dateRange[0]?.format('DD/MM/YYYY') : dayjs().startOf('Year').format('DD/MM/YYYY'),
			ToDt: dateRange && dateRange[1] ? dateRange[1]?.format('DD/MM/YYYY') : dayjs().format('DD/MM/YYYY'),
		}))
	}, [dispatch, selectedStore.customerId, selectedStore.id])
	
	const getSalesReportFilters = useCallback(() => {
		dispatch(actions.getSalesReportFilters({
			AddressId: selectedStore.id
		}))
	}, [dispatch, selectedStore.id])
	
	const getSalesReportData = useCallback(({quarters, years, attributes, dateRange = null}) => {
		dispatch(actions.getSalesReportData({
			AddressId: selectedStore.id,
			quarters: quarters,
			years: years,
			AttrVals: attributes,
			...(dateRange && dateRange.length === 2 && dateRange[0]?.format('YYYY-MM-DD') !== 'Invalid Date' ? {FromDt: dateRange[0]?.format('YYYY-MM-DD')} : {}),
			...(dateRange && dateRange.length === 2 && dateRange[1]?.format('YYYY-MM-DD') !== 'Invalid Date' ? {ToDt: dateRange[1]?.format('YYYY-MM-DD')} : {})
		}))
	}, [dispatch, selectedStore.id])
	
	return {
		setReportsMessage,
		getBudgetAndGoalsReport,
		getCustomerCard,
		getSalesReportFilters,
		getSalesReportData
	}
}

export default useReportsAction