// @flow
import React, {useMemo} from 'react';
import Box from "@mui/material/Box";
import {Grid, Tooltip, Typography, useMediaQuery, useTheme} from "@mui/material";
import useUserData from "../../../hooks/user/useUserData";
import {roundToDecimal} from "../../../helpers/helpers";
import './productPreview.css'
import {ClearOutlined} from "@mui/icons-material";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";
import {PAGE_ORDERS} from "../../../locales/pages/namespaces";
import useOrderAction from "../../../hooks/order/useOrderAction";
import {NumericFormat} from "react-number-format";

type Props = {};

const ProductPreview = (props: Props) => {
	const {
		product: {
			id,
			alternativeCode,
			eanCode,
			description,
			priceWithPaymentDiscountWithEnvTaxAndItemTax,
			warehouses,
			totalValueWithQty,
			totalValuePerUnit
		},
		orderSubmitted,
		orderId
	} = props
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'))
	const {t} = useTranslation(PAGE_ORDERS)
	const {removeProductFromOrder} = useOrderAction()
	const {warehouses: sortedWarehouses, thousandSeparator, decimalSeparator} = useUserData()
	const mappedWarehouses = useMemo(() => (
		sortedWarehouses.map(warehouse => {
			const productWarehouse = warehouses?.find(f => f.id === warehouse.id)
			return {
				...productWarehouse,
				...warehouse
			}
		})
	), [sortedWarehouses, warehouses])
	
	const totalProductQuantity = useMemo(() => (
		mappedWarehouses.reduce((acc, warehouse) => (
			acc + warehouse.quantity
		), 0)
	), [mappedWarehouses])
	
	const total = useMemo(() => (
		orderSubmitted ? totalValueWithQty : roundToDecimal(parseFloat(totalProductQuantity * priceWithPaymentDiscountWithEnvTaxAndItemTax).toFixed(2), 2)
	), [orderSubmitted, priceWithPaymentDiscountWithEnvTaxAndItemTax, totalProductQuantity, totalValueWithQty])
	
	return (
		<Grid
			container
			sx={{
				background: '#FFFFFF',
				boxShadow: 'rgba(37, 91, 130, 0.25) 0 0.0625em 0.0625em, rgba(37, 91, 130, 0.25) 0 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0 0 0 1px inset',
				padding: {xs: 1, md: 0.8},
				// border: '1px solid #F5F5F5',
				marginBottom: 1,
				maxWidth: '1200px'
			}}
			className={'product-preview'}
		>
			{!orderSubmitted && (
				<Grid item className={'remove-product'}>
					<Tooltip title={t('Remove from cart')}>
						<Button
							variant="text"
							color={'error'}
							onClick={() => removeProductFromOrder(orderId, id)}
							size={'small'}
						>
							<ClearOutlined fontSize={'small'}/>
							{matches && (t('Remove from cart'))}
						</Button>
					</Tooltip>
				</Grid>
			)}
			<Grid item xs={12} md={4}>
				<Typography variant={'h5'} component={'div'} sx={{wordWrap: 'break-word'}}>
					{description}
				</Typography>
				<Typography variant={'body2'} component={'div'}>
					{t('EAN CODE')}: {eanCode}
				</Typography>
				<Typography variant={'body2'} component={'div'}>
					{t('Alternative Code')}: {alternativeCode}
				</Typography>
			</Grid>
			<Grid item xs={12} md={4}>
				<Box className={'preview-warehouses'} sx={{justifyContent: {xs: 'space-between', md: 'space-around'}}}>
					{mappedWarehouses.map(warehouse => {
						return (
							<Box className={'preview-warehouse'} key={warehouse.id}>
								<Typography variant={'body2'} component={'div'}>
									{warehouse.address}
								</Typography>
								<Typography variant={'body2'} component={'div'} fontWeight={'bold'}>
									{warehouse.quantity}
								</Typography>
							</Box>
						)
					})}
				</Box>
			</Grid>
			<Grid item xs={12} md={3}>
				<Box>
					<Grid container pt={1}>
						<Grid item xs={8}>
							<Typography component={'div'} variant={'body2'}>
								{t('Final piece value')}
							</Typography>
						</Grid>
						<Grid item xs={4}>
							<Typography component={'div'} variant={'body2'} fontWeight={'bold'} textAlign={'right'}>
								<NumericFormat
									thousandSeparator={thousandSeparator}
									decimalSeparator={decimalSeparator}
									displayType="text"
									value={orderSubmitted ? roundToDecimal(totalValuePerUnit, 2) : priceWithPaymentDiscountWithEnvTaxAndItemTax}
								/> €
							</Typography>
						</Grid>
						<Grid item xs={8}>
							<Typography component={'div'} variant={'body2'}>
								{t('Final total value')}
							</Typography>
						</Grid>
						<Grid item xs={4}>
							<Typography component={'div'} variant={'body2'} fontWeight={'bold'} textAlign={'right'}>
								<NumericFormat
									thousandSeparator={thousandSeparator}
									decimalSeparator={decimalSeparator}
									displayType="text"
									value={total}
								/> €
							</Typography>
						</Grid>
					</Grid>
				</Box>
			</Grid>
		</Grid>
	);
};

export default ProductPreview