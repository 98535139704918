// @flow
import React, {useMemo, useState} from 'react';
import {useTranslation} from "react-i18next";
import {PAGE_ORDERS} from "../../locales/pages/namespaces";
import Box from "@mui/material/Box";
import {Modal, Tooltip, Typography} from "@mui/material";
import useUserData from "../../hooks/user/useUserData";
import {useMuiGridServerSide} from "../../hooks/muiGrid/useMuiGridServerSide";
import DataGridWrapper from "../../components/grids/dataGridWrapper/dataGridWrapper";
import {
	DataGridPremium,
	GRID_AGGREGATION_ROOT_FOOTER_ROW_ID,
	GridActionsCellItem,
	GridToolbar
} from "@mui/x-data-grid-premium";
import OrderStatus from "../../components/app/orderStatus/orderStatus";
import dayjs from "dayjs";
import {muiGridLocales} from "../../helpers/muiLocales";
import {CustomPagination} from "../../components/grids/dataGridCustom/customPagination";
import PrintButton from "../../components/general/printButton/printButton";
import {API, OrderStatus as Status} from "../../helpers/constants";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import {getRouteUrl} from "../../helpers/getRouteUrl";
import {ROUTE_PAGE_ORDER} from "../../routers/routes";
import VisibilityIcon from "@mui/icons-material/Visibility";
import axios from "axios";
import {enqueueSnackbar} from "notistack";
import {handleAxiosErrors} from "../../helpers/errorHandling";
import HeaderInvoice from "../../components/app/pdfInvoice/header";
import Invoice from "../../components/app/pdfInvoice/invoice";
import TableItemsInvoice from "../../components/app/pdfInvoice/tableItemsInvoice";

const PendingOrders = () => {
	const {t} = useTranslation(PAGE_ORDERS)
	const {dateFormat, timeFormat, selectedStore, language} = useUserData()
	const [dataInvoice, setData] = useState(null)
	const [items, setItems] = useState([])
	const [openInvoice, setOpenInvoice] = React.useState(false);
	
	const {
		isLoading,
		data,
		total,
		page,
		pageSize,
		groupModel,
		onPaginationModelChange,
		onSortModelChange,
		onFilterModelChange,
		onGroupModelChange,
	} = useMuiGridServerSide({
		url: '/en/b2b/user/orders',
		initialPageSize: 50,
		initialSortModel: [{name: 'id', direction: 'descending'}],
		otherDataParams: {
			AddressId: selectedStore?.id,
			type: 2
		}
	})
	
	const handleOpenInvoice = (id) => {
		setOpenInvoice(true);
		axios
		.get(`${API}/en/transaction/data/` + id)
		.then((res) => {
			const data = res.data.data.length > 0 ? res.data.data[0] : {};
			setData(data);
			setItems(data.items);
		})
		.catch((error) => {
			enqueueSnackbar(handleAxiosErrors(error, t("Failed to download PDF"), {
				variant: "warning",
				autoHideDuration: 1000,
			}));
		});
	}
	
	const columns = useMemo(() => [
		{
			field: 'actions',
			headerName: t('Quick view'),
			type: 'actions',
			minWidth: 100,
			maxWidth: 100,
			flex: 1,
			getActions: (params) => {
				if (params.id === GRID_AGGREGATION_ROOT_FOOTER_ROW_ID) {
					return [];
				}
				return params.row.B2BStatus === 'Submitted' ? [
					<GridActionsCellItem
						component={Button}
						onClick={() => handleOpenInvoice(params.id)}
						icon={
							<Tooltip title={t('Quick view')}>
								<VisibilityIcon/>
							</Tooltip>
						}
						label={'Download Pdf'}
					/>
				] : []
			}
		},
		{
			field: 'id',
			minWidth: 140,
			headerName: t('Code'),
			flex: 1,
			renderCell: ({row}) => (
				<Button
					sx={{
						color: '#485776',
						fontSize: '14px',
						fontStyle: 'normal',
						fontWeight: 700,
						lineHeight: 'normal',
						'&:hover': {
							backgroundColor: 'rgba(244, 245, 250, 0.38)',
						},
					}}
					component={Link}
					variant={'info'}
					to={getRouteUrl(ROUTE_PAGE_ORDER, {
						id: row.id
					})}
				>
					{row.id}
				</Button>
			)
		},
		{
			field: 'Status',
			headerName: t('Status'),
			flex: 1,
			minWidth: 220,
			renderCell: ({row}) => (row.Status ? <OrderStatus status={row.Status}/> : '-'),
			type: 'singleSelect',
			valueOptions: Object.entries(Status).map(status => {
				const value = status[1]
				return {
					value: value,
					label: t(value)
				}
			}),
		},
		{
			field: 'InsertedDt',
			headerName: t('Date'),
			flex: 1,
			minWidth: 180,
			type: 'date',
			valueGetter: (value, row, column, apiRef) => {
				return new Date(value)
			},
			valueFormatter: (value, row, column, apiRef) => {
				return (value ? dayjs(value).format(dateFormat) + " " + dayjs(value).format(timeFormat) : null)
			}
		},
		{
			field: 'FilePath',
			headerName: t('Invoice'),
			type: 'actions',
			renderCell: (({row, value}) => row.FilePath ? (
				<PrintButton
					url={`${API}/en/b2b/customer/card/invoice/pdf?AddressId=${selectedStore.id}&PDFPath=${row.FilePath}`}
				/>
			) : '-'),
			flex: 1,
			minWidth: 100,
		}
	], [dateFormat, handleOpenInvoice, selectedStore.id, t, timeFormat])
	
	const header = useMemo(() => {
		return (
			<HeaderInvoice/>
		)
	}, [])
	
	
	const dataInv = {
		invoiceIndexHeaders: {
			exportPdfButton: t('Export PDF'),
			documentTitle: t('Order'),
			commentsBox: t('ETIOLOGY'),
		},
		invoiceIndexData: {
			commentsBox: dataInvoice && dataInvoice.reason ? dataInvoice.reason : null
		},
		invoiceInfo: [
			{
				title: t('INVOICE TYPE'),
				value: dataInvoice && dataInvoice.TransactionType ? dataInvoice.TransactionType : null,
			},
			{
				title: t('NUMBER'),
				value: dataInvoice && dataInvoice.SeriesCode ? dataInvoice.SeriesCode : null,
			},
			{
				title: t('DATE'),
				value: dataInvoice && dataInvoice.SubmitDate ? dayjs(dataInvoice.SubmitDate).format("DD-MM-YYYY") : null,
			},
			{
				title: t('START TIME'),
				value: dataInvoice && dataInvoice.SubmitTime ? dataInvoice.SubmitTime : null
			},
		],
		customerDetailsHeader: t('CUSTOMER DETAILS'),
		customerDetails: [
			{
				title: t('CODE'),
				value: dataInvoice && dataInvoice.Code ? dataInvoice.Code : null,
			}, {
				title: t('NAME'),
				value: dataInvoice && dataInvoice.CustName ? dataInvoice.CustName : null,
			}, {
				title: t('JOB'),
				value: dataInvoice && dataInvoice.job ? dataInvoice.job : null,
			}, {
				title: t('ADDRESS'),
				value: dataInvoice && dataInvoice.Address ? dataInvoice.Address : null,
			}, {
				title: t('CITY'),
				value: dataInvoice && (dataInvoice.ZipCode || dataInvoice.City) ? dataInvoice.ZipCode + ' ' + dataInvoice.City : null,
			}, {
				title: t('PHONE NUMBER'),
				value: dataInvoice && dataInvoice.MobilePhone ? dataInvoice.MobilePhone : null,
			}, {
				title: t('VAT REG NO'),
				value: dataInvoice && dataInvoice.VatRegNo ? dataInvoice.VatRegNo : null,
			}, {
				title: t('TAX OFFICE'),
				value: dataInvoice && dataInvoice.TaxOffice ? dataInvoice.TaxOffice : null,
			}
		],
		customerDetailsComments: {
			title: t('RELATED INVOICE'),
			value: dataInvoice && dataInvoice.RelatedInvoice ? dataInvoice.RelatedInvoice : null
		},
		
		transactionDetails: [
			{
				title: t('TRANSACTION PLACE'),
				value: dataInvoice && dataInvoice.Warehouse ? dataInvoice.Warehouse : null
			},
			{
				title: t('TRANSACTION TYPE'),
				value: dataInvoice && dataInvoice.Agency ? dataInvoice.Agency : null
			},
		],
		footerInvoiceHeader: t('INVOICE TOTALS'),
		footerInvoice: [
			{
				title: t('VALUE BEFORE DISCOUNT'),
				value: dataInvoice && dataInvoice.AfterDiscount ? dataInvoice.AfterDiscount : '0.00'
			},
			{
				title: t('SALE'),
				value: dataInvoice && dataInvoice.DiscountTotal ? dataInvoice.DiscountTotal : '0.00'
			},
			{
				title: t('NET VALUE'),
				value: dataInvoice && dataInvoice.NetValue ? dataInvoice.NetValue : '0.00'
			},
			{
				title: t('VAT VALUE'),
				value: dataInvoice && dataInvoice.VatValue ? dataInvoice.VatValue : '0.00'
			},
			{
				title: t('CHARGES'),
				value: dataInvoice && dataInvoice.ChargeValue ? dataInvoice.ChargeValue : '0.00'
			},
			{
				title: t('PAYMENT TYPE DISCOUNT'),
				value: dataInvoice && dataInvoice.PaymentTypeDiscount ? dataInvoice.PaymentTypeDiscount : '0.00'
			},
			{
				title: t('VAT CHARGES'),
				value: dataInvoice && dataInvoice.ChargeVatValue ? dataInvoice.ChargeVatValue : '0.00'
			},
			{
				title: t('TOTAL VALUE'),
				value: dataInvoice && dataInvoice.TotalValue ? dataInvoice.TotalValue : '0.00'
			}
		],
		footerInvoiceComments: [
			{
				title: t('COMMENTS'),
				value: dataInvoice && dataInvoice.Comments ? dataInvoice.Comments : null,
				customStyle: {
					fontWeight: 'bold'
				}
			}
		]
	}
	
	const updateItems = items && items.map(item => ({
		column1: item.Code,
		column2: item.AltCode,
		column3: item.ItemDescription,
		column4: item.MM,
		column5: item.Qty,
		column6: item.ItemValue,
		column7: item.PCTDiscount,
		column8: item.DiscountValue,
		column9: item.AfterDiscount,
		column10: item.ChargeValue,
		column11: item.ChargeVatValue,
		column12: item.Vat,
	}));
	
	const handleCloseInvoice = () => setOpenInvoice(false);
	
	return (
		<Box>
			<Box p={1}>
				<Typography
					pb={1}
					variant={'h2'}
					component={'div'}
					sx={{
						textAlign: 'center'
					}}
				>
					{t('Pending Orders')}
				</Typography>
				<DataGridWrapper>
					<DataGridPremium
						loading={isLoading}
						rows={data}
						columns={columns}
						pagination
						paginationModel={{page, pageSize}}
						paginationMode='server'
						onPaginationModelChange={onPaginationModelChange}
						pageSizeOptions={[5, 10, 15, 20, 50]}
						rowCount={Number(total)}
						sortingMode="server"
						onSortModelChange={(sortModel) => onSortModelChange(sortModel)}
						filterMode='server'
						onFilterModelChange={onFilterModelChange}
						rowGroupingModel={groupModel}
						onRowGroupingModelChange={onGroupModelChange}
						localeText={muiGridLocales(language)}
						sx={{background: '#FFFFFF'}}
						initialState={{density: 'compact'}}
						slots={{
							toolbar: GridToolbar,
							pagination: CustomPagination
						}}
						slotProps={{
							toolbar: {
								printOptions: {disableToolbarButton: true},
							},
						}}
						columnBufferPx={1000}
					/>
				</DataGridWrapper>
			</Box>
			<Modal
				open={openInvoice}
				onClose={handleCloseInvoice}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				sx={{maxWidth: '1350px', maxHeight: '1000px', overflow: 'scroll', m: 4, justifySelf: 'center'}}
			>
				<Invoice
					data={dataInv}
					items={updateItems}
					header={header}
					handleCloseInvoice={handleCloseInvoice}
					closeButton={true}
				>
					<TableItemsInvoice/>
				</Invoice>
			</Modal>
		</Box>
	);
};

export default PendingOrders