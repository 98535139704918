export const textTranslate = (language) => (item = {en: '', el: ''}) => {
	return item[language]
}

export const roundToDecimal = (number, decimalPlaces) => {
	const factor = Math.pow(10, decimalPlaces);
	return Math.round(number * factor) / factor;
}

export const applyPercentage = (number: number, percentage: number, minusPercentage: boolean = false) => {
	if (minusPercentage) {
		return parseFloat((number - roundToDecimal((number * percentage / 100), 2)).toFixed(2))
	}
	return parseFloat((number + roundToDecimal((number * percentage / 100), 2)).toFixed(2))
}

export const applyDiscounts = (discounts, netValue) => {
	return discounts.reduce((acc, discount) => {
		const {percentage, priority, value} = discount
		let calculateDiscount = acc
		if (percentage > 0) calculateDiscount = applyPercentage(calculateDiscount, percentage, true)
		if (value > 0) calculateDiscount -= value
		return calculateDiscount
	}, netValue)
}

export const getPriceOfConsecutiveDiscounts = (discounts, netValue) => {
	const calculatedDiscounts = []
	let i = 0
	for (let discount of discounts) {
		calculatedDiscounts.push({
			...discount,
			priceAfterDiscount: applyDiscounts([discount], i === 0 ? netValue : calculatedDiscounts[i - 1]?.priceAfterDiscount)
		})
		i++
	}
	return calculatedDiscounts
}

const mapProductsPerWarehouse = (product, warehouses, pctDiscounts, totalDiscountValue) => {
	return warehouses.map(warehouse => {
		const quantity = warehouse.quantity ? warehouse.quantity : 0
		const envCharge = product.envCharge?.value ? product.envCharge.value : 0
		const taxPercentage = product.taxPercentage
		const netValue = quantity > 0 ? parseFloat((product.priceWithoutTax * quantity).toFixed(2)) : 0
		const pctDiscountValue = quantity > 0 ? parseFloat(((netValue - applyDiscounts(pctDiscounts, netValue))).toFixed(2)) : 0
		const pctDiscount = parseFloat((pctDiscounts.length === 0 ? 0 : pctDiscounts.length === 1 ? pctDiscounts[0]?.percentage : pctDiscounts.reduce((acc, discount) => (acc * (1 - discount?.percentage / 100)), 1)).toFixed(2))
		const discountValue = quantity > 0 ? parseFloat((totalDiscountValue * quantity).toFixed(2)) : 0
		const discountTotal = quantity > 0 ? parseFloat((pctDiscountValue + discountValue).toFixed(2)) : 0
		const chargeValue = quantity > 0 ? parseFloat((envCharge * quantity).toFixed(2)) : 0
		const chargeVatValue = quantity > 0 ? parseFloat(((chargeValue * taxPercentage) / 100).toFixed(2)) : 0
		const finalNetValue = quantity > 0 ? parseFloat((netValue - discountTotal).toFixed(2)) : 0
		const vatValue = quantity > 0 ? parseFloat(((finalNetValue * taxPercentage) / 100).toFixed(2)) : 0
		const vatTotal = quantity > 0 ? parseFloat((vatValue + chargeVatValue).toFixed(2)) : 0
		const totalValue = quantity > 0 ? parseFloat((finalNetValue + chargeValue + vatTotal).toFixed(2)) : 0
		return {
			ItemId: Number(product.id),
			Qty: quantity,
			WarhId: warehouse.id,
			ItemPrice: product.priceWithoutTax,
			NetValue: netValue,
			PCTDiscountValue: pctDiscountValue,
			PCTDiscount: pctDiscount,
			DiscountValue: discountValue,
			DiscountTotal: discountTotal,
			ChargeValue: chargeValue,
			ChargeVatValue: chargeVatValue,
			VatValue: vatValue,
			VatTotal: vatTotal,
			TotalValue: totalValue,
			finalNetValue: finalNetValue,
			discounts: product?.discounts?.map(discount => ({
				id: discount.id,
				DiscountPCT: discount.percentage,
				DiscountValue: discount.value,
				Priority: discount.priority,
				PriceAfterDiscount: discount.priceAfterDiscount
			}))
		}
	})
}

export const convertRequestProducts = (product, quantity) => {
	const pctDiscounts = product.discounts.filter(f => f.percentage)
	const valueDiscounts = product.discounts.filter(f => f.value)
	const totalDiscountValue = valueDiscounts.reduce((acc, discount) => (acc + discount.value), 0)
	pctDiscounts.push({
		percentage: product.paymentDiscount,
		priority: 10,
		value: 0
	})
	return mapProductsPerWarehouse(product, quantity, pctDiscounts, totalDiscountValue)
}

export const convertActiveOrderProducts = (products, withSecondPaymentDiscount = false) => {
	if (!(products && products.length > 0)) return []
	const warehouseProducts = products.map(product => {
		const pctDiscounts = product.discounts.filter(f => f.percentage)
		const valueDiscounts = product.discounts.filter(f => f.value)
		const totalDiscountValue = valueDiscounts.reduce((acc, discount) => (acc + discount.value), 0)
		pctDiscounts.push({
			percentage: withSecondPaymentDiscount ? product.secondPaymentDiscount : product.paymentDiscount,
			priority: 10,
			value: 0
		})
		return mapProductsPerWarehouse(product, product.warehouses, pctDiscounts, totalDiscountValue)
	})
	return warehouseProducts.flat()
}


export const calculateOrderSums = (products) => {
	if (!(products && products.length > 0)) return ({
		sumWithTax: 0,
		sumWithoutTax: 0,
		sumDiscount: 0,
		sumTax: 0
	})
	return products.reduce((p, n) => {
		return {
			sumWithTax: parseFloat((n.TotalValue + p.sumWithTax).toFixed(2)),
			sumWithoutTax: parseFloat((n.finalNetValue + p.sumWithoutTax).toFixed(2)),
			sumDiscount: parseFloat((n.DiscountTotal + p.sumDiscount).toFixed(2)),
			sumTax: parseFloat((n.VatTotal + p.sumTax).toFixed(2))
		}
	}, {
		sumWithTax: 0,
		sumWithoutTax: 0,
		sumDiscount: 0,
		sumTax: 0
	})
}

export const getTotalProductsInCart = (products, excludeItems = []) => {
	if (products.length === 0) return 0
	return products.reduce((acc, product) => {
		return acc + product.warehouses.reduce((warAcc, warehouse) => {
			const quantity = warehouse?.Qty ? warehouse.Qty : warehouse?.quantity
			return warAcc + (excludeItems.length > 0 && excludeItems.includes(product.id) ? 0 : (quantity ? Number(quantity) : 0))
		}, 0)
	}, 0)
}