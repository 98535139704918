// @flow
import React from 'react';
import Box from "@mui/material/Box";
import {Checkbox, FormControl, FormHelperText} from "@mui/material";
import Images from "../../../../../../assets";
import {ErrorMessage} from "formik";

type Props = {};

const TireSelection = (props: Props) => {
	const {formik,accidentDetails} = props
	return (
		<Box sx={{mt: 2, width: "100%", maxWidth: 200, mx: 'auto'}}>
			<Box sx={{display: "flex", justifyContent: "space-between"}}>
				<FormControl
					variant="standard"
					fullWidth
					error={
						formik.touched['topBack'] &&
						Boolean(formik.errors['topBack'])
					}
				>
				<Checkbox
					disabled={accidentDetails.StatusDescription !== 'Activated'
						&& accidentDetails.StatusDescription !== 'Pending Approval'}
					checked={formik.values.topBack}
					onChange={evt => {
						formik.setFieldValue('topBack', evt.target.checked)
						formik.setFieldValue('topFront', false)
						formik.setFieldValue('endBack', false)
						formik.setFieldValue('endFront', false)
					}}
					name={`topBack`}
				/>
					<FormHelperText>
						<ErrorMessage name={'topBack'}/>
					</FormHelperText>
				</FormControl>
				<FormControl
					variant="standard"
					fullWidth
					error={
						formik.touched['topFront'] &&
						Boolean(formik.errors['topFront'])
					}
				>
				<Checkbox
					disabled={accidentDetails.StatusDescription !== 'Activated'
						&& accidentDetails.StatusDescription !== 'Pending Approval'}
					checked={formik.values.topFront}                  //formik.values.topBack
					onChange={evt => {
						formik.setFieldValue('topBack', false)
						formik.setFieldValue('topFront', evt.target.checked)
						formik.setFieldValue('endBack', false)
						formik.setFieldValue('endFront', false)
					}}
					name={`topFront`}
				/>
					<FormHelperText>
						<ErrorMessage name={'topFront'}/>
					</FormHelperText>
				</FormControl>
			</Box>
			<Box>
				<img src={Images.carPlan} style={{width: "100%", maxWidth: 200}} alt={'car'}/>
			</Box>
			<Box sx={{display: "flex", justifyContent: "space-between"}}>
				<FormControl
					variant="standard"
					fullWidth
					error={
						formik.touched['endBack'] &&
						Boolean(formik.errors['endBack'])
					}
				>
				<Checkbox
					disabled={accidentDetails.StatusDescription !== 'Activated'
						&& accidentDetails.StatusDescription !== 'Pending Approval'}
					checked={formik.values.endBack}                  //formik.values.topBack
					onChange={evt => {
						formik.setFieldValue('topBack', false)
						formik.setFieldValue('topFront', false)
						formik.setFieldValue('endBack', evt.target.checked)
						formik.setFieldValue('endFront', false)
					}}
					name={`endBack`}
				/>
					<FormHelperText>
						<ErrorMessage name={'endBack'}/>
					</FormHelperText>
				</FormControl>
				<FormControl
					variant="standard"
					fullWidth
					error={
						formik.touched['endFront'] &&
						Boolean(formik.errors['endFront'])
					}
				>
				<Checkbox
					disabled={accidentDetails.StatusDescription !== 'Activated'
						&& accidentDetails.StatusDescription !== 'Pending Approval'}
					checked={formik.values.endFront}                  //formik.values.topBack
					onChange={evt => {
						formik.setFieldValue('topBack', false)
						formik.setFieldValue('topFront', false)
						formik.setFieldValue('endBack', false)
						formik.setFieldValue('endFront', evt.target.checked)
					}}
					name={`endFront`}
				/>
					<FormHelperText>
						<ErrorMessage name={'endFront'}/>
					</FormHelperText>
				</FormControl>
			</Box>
		</Box>
	);
};

export default TireSelection