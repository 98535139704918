// @flow
import React, {useMemo, useState} from 'react';
import Box from "@mui/material/Box";
import {Select, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {PAGE_ORDERS} from "../../locales/pages/namespaces";
import useUserData from "../../hooks/user/useUserData";
import {useMuiGridServerSide} from "../../hooks/muiGrid/useMuiGridServerSide";
import OrderStatus from "../../components/app/orderStatus/orderStatus";
import dayjs from "dayjs";
import {DataGridPremium, GridToolbar} from "@mui/x-data-grid-premium";
import {muiDatePickersLocales, muiGridLocales} from "../../helpers/muiLocales";
import {CustomPagination} from "../../components/grids/dataGridCustom/customPagination";
import DataGridWrapper from "../../components/grids/dataGridWrapper/dataGridWrapper";
import PrintButton from "../../components/general/printButton/printButton";
import {API, InvoiceTypes, OrderStatus as Status} from "../../helpers/constants";
import {DateRangePicker, LocalizationProvider, SingleInputDateRangeField} from "@mui/x-date-pickers-pro";
import {AdapterDayjs} from "@mui/x-date-pickers-pro/AdapterDayjs";
import MenuItem from "@mui/material/MenuItem";
import {MenuProps} from "../../components/app/selectAddress/selectAddress";
import Button from "@mui/material/Button";

type Props = {};

const Invoices = (props: Props) => {
	const {t} = useTranslation(PAGE_ORDERS)
	const {dateFormat, timeFormat, selectedStore, language} = useUserData()
	const [dateRange, setDateRange] = useState([dayjs().startOf('year'), dayjs()])
	const [selectedType, setSelectedType] = useState('all');
	const {
		isLoading,
		data,
		total,
		page,
		pageSize,
		groupModel,
		onPaginationModelChange,
		onSortModelChange,
		onFilterModelChange,
		onGroupModelChange,
		refreshGrid
	} = useMuiGridServerSide({
		url: '/en/b2b/invoices/index',
		initialPageSize: 50,
		initialSortModel: [{name: 'SubmitDt', direction: 'descending'}],
		otherDataParams: {
			AddressId: selectedStore?.id,
			...(selectedType !== 'all' && {Type: selectedType}),
			...(dateRange && dateRange.length === 2 && dateRange[0]?.format('YYYY-MM-DD') !== 'Invalid Date' ? {FromDt: dateRange[0]?.format('YYYY-MM-DD')} : {}),
			...(dateRange && dateRange.length === 2 && dateRange[1]?.format('YYYY-MM-DD') !== 'Invalid Date' ? {ToDt: dateRange[1]?.format('YYYY-MM-DD')} : {})
		}
	})
	
	const types = useMemo(() => (Object.entries(InvoiceTypes).map(status => {
		const value = status[1]
		return {
			value: value,
			label: t(value)
		}
	})), [t])
	
	const columns = useMemo(() => [
		{
			field: 'id',
			headerName: t('B2B Code'),
			flex: 1,
			minWidth: 140,
		},
		{
			field: 'InsertedDt',
			headerName: t('Creation Date'),
			flex: 1,
			type: 'date',
			valueGetter: (value, row, column, apiRef) => {
				return value && new Date(value)
			},
			valueFormatter: (value, row, column, apiRef) => {
				return (value ? dayjs(value).format(dateFormat) + " " + dayjs(value).format(timeFormat) : '-')
			},
			minWidth: 140,
		},
		{
			field: 'SeriesNumber',
			headerName: t('Invoice Code'),
			flex: 1,
			minWidth: 150
		},
		{
			field: 'Status',
			headerName: t('Status'),
			flex: 1,
			renderCell: ({row}) => (row.Status ? <OrderStatus status={row.Status}/> : '-'),
			type: 'singleSelect',
			valueOptions: Object.entries(Status).map(status => {
				const value = status[1]
				return {
					value: value,
					label: t(value)
				}
			}),
			minWidth: 220
		},
		{
			field: 'TypeDescription',
			headerName: t('Type'),
			flex: 1,
			type: 'singleSelect',
			valueOptions: types,
			minWidth: 160
		},
		{
			field: 'SubmitDt',
			headerName: t('Invoice Date'),
			flex: 1,
			type: 'date',
			valueGetter: (value, row, column, apiRef) => {
				return value && new Date(value)
			},
			valueFormatter: (value, row, column, apiRef) => {
				return (value ? dayjs(value).format(dateFormat) : '-')
			},
			minWidth: 140
		},
		{
			field: 'FilePath',
			type: 'actions',
			headerName: t('PDF'),
			renderCell: (({row, value}) => row.FilePath ? (
				<PrintButton
					url={`${API}/en/b2b/customer/card/invoice/pdf?AddressId=${selectedStore.id}&PDFPath=${row.FilePath}`}
				/>
			) : '-'),
			flex: 1,
			minWidth: 120
		}
	], [dateFormat, selectedStore.id, t, timeFormat, types])
	
	return (
		<Box>
			<Box p={1}>
				{/*<Typography*/}
				{/*	pb={1}*/}
				{/*	variant={'h2'}*/}
				{/*	component={'div'}*/}
				{/*	sx={{*/}
				{/*		textAlign: 'center'*/}
				{/*	}}*/}
				{/*>*/}
				{/*	{t('Invoices')}*/}
				{/*</Typography>*/}
				<Box display={'flex'} flexWrap={'wrap'}>
					<Box p={0.5} display={'flex'} alignItems={'center'}>
						<Typography
							variant={'subtitle1'}
							component={'span'}
							pr={1}
						>
							{t('Invoice date range')}
						</Typography>
						<LocalizationProvider dateAdapter={AdapterDayjs} localeText={muiDatePickersLocales(language)}>
							<DateRangePicker
								format={dateFormat}
								slots={{field: SingleInputDateRangeField}}
								value={dateRange}
								onChange={(newValue) => setDateRange(newValue)}
							/>
						</LocalizationProvider>
					</Box>
					<Box p={0.5} display={'flex'} alignItems={'center'}>
						<Typography
							variant={'subtitle1'}
							component={'span'}
							pr={1}
						>
							{t('Type')}
						</Typography>
						<Select
							value={selectedType}
							onChange={(evt) => setSelectedType(evt.target.value)}
							MenuProps={MenuProps}
						>
							<MenuItem dense value={'all'}>
								{t('All invoice types')}
							</MenuItem>
							{types.map((type, index) => (
								<MenuItem dense key={index} value={type.value}>
									{type.label}
								</MenuItem>
							))}
						</Select>
					</Box>
					<Box p={0.5} display={'flex'} alignItems={'center'}>
						<Button
							onClick={() => refreshGrid()}
						>
							{t('Search')}
						</Button>
					</Box>
				</Box>
				<DataGridWrapper>
					<DataGridPremium
						loading={isLoading}
						rows={data}
						columns={columns}
						pagination
						paginationModel={{page, pageSize}}
						paginationMode='server'
						onPaginationModelChange={onPaginationModelChange}
						pageSizeOptions={[5, 10, 15, 20, 50]}
						rowCount={Number(total)}
						sortingMode="server"
						onSortModelChange={(sortModel) => onSortModelChange(sortModel)}
						filterMode='server'
						onFilterModelChange={onFilterModelChange}
						rowGroupingModel={groupModel}
						onRowGroupingModelChange={onGroupModelChange}
						localeText={muiGridLocales(language)}
						sx={{background: '#FFFFFF'}}
						initialState={{density: 'compact'}}
						slots={{
							toolbar: GridToolbar,
							pagination: CustomPagination
						}}
						slotProps={{
							toolbar: {
								printOptions: {disableToolbarButton: true},
							},
						}}
						columnBufferPx={2500}
					/>
				</DataGridWrapper>
			</Box>
		</Box>
	);
};

export default Invoices