import {useTranslation} from "react-i18next";
import {PAGE_REPORTS} from "../../locales/pages/namespaces";
import * as yup from "yup";
import {useFormik} from "formik";
import useUserAction from "../user/useUserAction";
import {useEffect} from "react";
import {enqueueSnackbar} from "notistack";
import useUserData from "../user/useUserData";

const useRequireDailyCode = () => {
	const {t} = useTranslation(PAGE_REPORTS)
	const {requireDailyCode, setUserMessage} = useUserAction()
	const {message} = useUserData()
	const onSubmit = (values) => {
		requireDailyCode(values)
	}
	useEffect(() => {
		if (message) {
			enqueueSnackbar(t(message?.text), {
					variant: message?.variant
				}
			)
			setUserMessage(null)
		}
	}, [message, setUserMessage, t])
	const validationSchema = yup.object({
		dailyPassword: yup.string().required(t('Required'))
	})
	const formik = useFormik({
		initialValues: {
			dailyPassword: ''
		},
		validationSchema: validationSchema,
		onSubmit: onSubmit
	})
	return {
		formik
	}
}

export default useRequireDailyCode