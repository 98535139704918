import {DataGridPremium} from "@mui/x-data-grid-premium";
import {muiGridLocales} from "../../../../helpers/muiLocales";
import Box from "@mui/material/Box";
import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {useMuiGridServerSide} from "../../../../hooks/muiGrid/useMuiGridServerSide";
import {PAGE_WARRANTIES} from "../../../../locales/pages/namespaces";
import DataGridWrapper from "../../../../components/grids/dataGridWrapper/dataGridWrapper";
import useUserData from "../../../../hooks/user/useUserData";

const ApproveReturnGrid = ({refresh, selectedRow}) => {
	const {t} = useTranslation(PAGE_WARRANTIES)
	const {language} = useUserData()
	const {
		isLoading,
		data,
		total,
		page,
		pageSize,
		groupModel,
		onPaginationModelChange,
		onSortModelChange,
		onFilterModelChange,
		onGroupModelChange,
	} = useMuiGridServerSide({
		url: `/en/b2b/return/tires/details/${selectedRow?.id}`,
		initialPageSize: 10,
		initialSortModel: [{name: 'id', direction: 'descending'}],
	})
	
	const columns2 = useMemo(() => [
		{
			field: 'supplierCode',
			headerName: t('Code'),
			flex: 1,
			minWidth: 110,
		},
		{
			field: 'ItemDescriptionEN',
			headerName: t('Description'),
			flex: 1,
			minWidth: 280,
		},
		{
			field: 'dot',
			headerName: t('Dot'),
			flex: 1,
			minWidth: 110,
		},
		{
			field: 'isApproved',
			headerName: t('Approved'),
			flex: 1,
			type: 'boolean',
			minWidth: 180,
			renderCell: ({row}) => {
				return row.isApproved === '1' ? <CheckOutlinedIcon/> : <CloseOutlinedIcon/>
			}
		},
	], [t])
	
	return (
		<Box p={1}>
			<br/>
			<br/>
			<DataGridWrapper sx={{height: 300}}>
				<DataGridPremium
					sx={{background: '#FFFFFF'}}
					columns={columns2}
					rows={data}
					initialState={{density: 'compact'}}
					pagination
					paginationModel={{page, pageSize}}
					paginationMode="server"
					onPaginationModelChange={onPaginationModelChange}
					rowCount={Number(total)}
					pageSizeOptions={[5, 10, 15, 20, 50, 100]}
					sortingMode="server"
					onSortModelChange={(sortModel) => onSortModelChange(sortModel)}
					filterMode="server"
					onFilterModelChange={onFilterModelChange}
					rowGroupingModel={groupModel}
					onRowGroupingModelChange={onGroupModelChange}
					localeText={muiGridLocales(language)}
					loading={isLoading}
				/>
			</DataGridWrapper>
		</Box>
	)
}
export default ApproveReturnGrid