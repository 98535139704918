export default function Radio(theme) {
	return {
		MuiRadio: {
			defaultProps: {},
			styleOverrides: {
				root: {},
			}
		}
	};
}
