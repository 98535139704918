import {actionCreator} from "../helpers/actionCreator";
import { CHECK_FOR_PENDING_ORDER_ITEMS, TOGGLE_ORDER_CONFIRMATION } from "./types";
import * as ActionTypes from "./types";

export * from './currentUser/actions'

export const setProductMessage = actionCreator(ActionTypes.SET_PRODUCT_MESSAGE);
export const setRedirectToOrder = actionCreator(ActionTypes.SET_REDIRECT_TO_ORDER);
export const setFilters = actionCreator(ActionTypes.SET_FILTERS);
export const updateFilter = actionCreator(ActionTypes.UPDATE_FILTER);

export const getSearchResults = actionCreator(ActionTypes.GET_SEARCH_RESULTS);
export const getSearchResultsSucceeded = actionCreator(ActionTypes.GET_SEARCH_RESULTS_SUCCEEDED);
export const getSearchResultsFailed = actionCreator(ActionTypes.GET_SEARCH_RESULTS_FAILED);

export const getSearchResultsFilters = actionCreator(ActionTypes.GET_SEARCH_RESULTS_FILTERS)
export const getSearchResultsFiltersSucceeded = actionCreator(ActionTypes.GET_SEARCH_RESULTS_FILTERS_SUCCEEDED)
export const getSearchResultsFiltersFailed = actionCreator(ActionTypes.GET_SEARCH_RESULTS_FILTERS_FAILED)

export const setOrderMessage = actionCreator(ActionTypes.SET_ORDER_MESSAGE);

export const getActiveOrder = actionCreator(ActionTypes.GET_ACTIVE_ORDER);
export const getActiveOrderSucceeded = actionCreator(ActionTypes.GET_ACTIVE_ORDER_SUCCEEDED);
export const getActiveOrderFailed = actionCreator(ActionTypes.GET_ACTIVE_ORDER_FAILED);

export const getOrderDetails = actionCreator(ActionTypes.GET_ORDER_DETAILS);
export const getOrderDetailsSucceeded = actionCreator(ActionTypes.GET_ORDER_DETAILS_SUCCEEDED);
export const getOrderDetailsFailed = actionCreator(ActionTypes.GET_ORDER_DETAILS_FAILED);

export const createOrder = actionCreator(ActionTypes.CREATE_ORDER);
export const createOrderSucceeded = actionCreator(ActionTypes.CREATE_ORDER_SUCCEEDED);
export const createOrderFailed = actionCreator(ActionTypes.CREATE_ORDER_FAILED);

export const updateOrder = actionCreator(ActionTypes.UPDATE_ORDER);
export const updateOrderSucceeded = actionCreator(ActionTypes.UPDATE_ORDER_SUCCEEDED);
export const updateOrderFailed = actionCreator(ActionTypes.UPDATE_ORDER_FAILED)

export const completeOrder = actionCreator(ActionTypes.COMPLETE_ORDER);
export const completeOrderSucceeded = actionCreator(ActionTypes.COMPLETE_ORDER_SUCCEEDED);
export const completeOrderFailed = actionCreator(ActionTypes.COMPLETE_ORDER_FAILED);

export const deleteOrder = actionCreator(ActionTypes.DELETE_ORDER);
export const deleteOrderSucceeded = actionCreator(ActionTypes.DELETE_ORDER_SUCCEEDED);
export const deleteOrderFailed = actionCreator(ActionTypes.DELETE_ORDER_FAILED);

export const removeProductFromOrder = actionCreator(ActionTypes.REMOVE_PRODUCT_FROM_ORDER)
export const removeProductFromOrderSucceeded = actionCreator(ActionTypes.REMOVE_PRODUCT_FROM_ORDER_SUCCEEDED)
export const removeProductFromOrderFailed = actionCreator(ActionTypes.REMOVE_PRODUCT_FROM_ORDER_FAILED)

export const setAnnouncementsMessage = actionCreator(ActionTypes.SET_ANNOUNCEMENTS_MESSAGE);

export const getMessages = actionCreator(ActionTypes.GET_MESSAGES);
export const getMessagesSucceeded = actionCreator(ActionTypes.GET_MESSAGES_SUCCEEDED);
export const getMessagesFailed = actionCreator(ActionTypes.GET_MESSAGES_FAILED);

export const setMessageViewed = actionCreator(ActionTypes.SET_MESSAGE_VIEWED);
export const setMessageViewedSucceeded = actionCreator(ActionTypes.SET_MESSAGE_VIEWED_SUCCEEDED);
export const setMessageViewedFailed = actionCreator(ActionTypes.SET_MESSAGE_VIEWED_FAILED);

export const getMessageAttachments = actionCreator(ActionTypes.GET_MESSAGE_ATTACHMENTS);
export const getMessageAttachmentsSucceeded = actionCreator(ActionTypes.GET_MESSAGE_ATTACHMENTS_SUCCEEDED);
export const getMessageAttachmentsFailed = actionCreator(ActionTypes.GET_MESSAGE_ATTACHMENTS_FAILED);

export const setMessageIsOpen = actionCreator(ActionTypes.SET_MESSAGE_IS_OPEN);

export const getCountUnreadMessages = actionCreator(ActionTypes.GET_COUNT_UNREAD_MESSAGES)
export const getCountUnreadMessagesSucceeded = actionCreator(ActionTypes.GET_COUNT_UNREAD_MESSAGES_SUCCEEDED)
export const getCountUnreadMessagesFailed = actionCreator(ActionTypes.GET_COUNT_UNREAD_MESSAGES_FAILED)

export const getMessageCategories = actionCreator(ActionTypes.GET_MESSAGE_CATEGORIES)
export const getMessageCategoriesSucceeded = actionCreator(ActionTypes.GET_MESSAGE_CATEGORIES_SUCCEEDED)
export const getMessageCategoriesFailed = actionCreator(ActionTypes.GET_MESSAGE_CATEGORIES_FAILED)

export const createWarranty = actionCreator(ActionTypes.CREATE_WARRANTY);
export const createWarrantySucceeded = actionCreator(ActionTypes.CREATE_WARRANTY_SUCCEEDED);
export const createWarrantyFailed = actionCreator(ActionTypes.CREATE_WARRANTY_FAILED);

export const getWarrantyDetails = actionCreator(ActionTypes.GET_WARRANTY_DETAILS);
export const getWarrantyDetailsSucceeded = actionCreator(ActionTypes.GET_WARRANTY_DETAILS_SUCCEEDED);
export const getWarrantyDetailsFailed = actionCreator(ActionTypes.GET_WARRANTY_DETAILS_FAILED);
export const setWarrantyNumber = actionCreator(ActionTypes.SET_WARRANTY_NUMBER);
export const setWarrantyMessage = actionCreator(ActionTypes.SET_WARRANTY_MESSAGE);

export const getBudgetAndGoalsReport = actionCreator(ActionTypes.GET_BUDGET_AND_GOALS_REPORT)
export const getBudgetAndGoalsReportSucceeded = actionCreator(ActionTypes.GET_BUDGET_AND_GOALS_REPORT_SUCCEEDED)
export const getBudgetAndGoalsReportFailed = actionCreator(ActionTypes.GET_BUDGET_AND_GOALS_REPORT_FAILED)
export const getCustomerCard = actionCreator(ActionTypes.GET_CUSTOMER_CARD)
export const getCustomerCardSucceeded = actionCreator(ActionTypes.GET_CUSTOMER_CARD_SUCCEEDED)
export const getCustomerCardFailed = actionCreator(ActionTypes.GET_CUSTOMER_CARD_FAILED)
export const setReportsMessage = actionCreator(ActionTypes.SET_REPORTS_MESSAGE)

export const getSalesReportFilters = actionCreator(ActionTypes.GET_SALES_REPORT_FILTERS)
export const getSalesReportFiltersSucceeded = actionCreator(ActionTypes.GET_SALES_REPORT_FILTERS_SUCCEEDED)
export const getSalesReportFiltersFailed = actionCreator(ActionTypes.GET_SALES_REPORT_FILTERS_FAILED)

export const getSalesReportData = actionCreator(ActionTypes.GET_SALES_REPORT_DATA)
export const getSalesReportDataSucceeded = actionCreator(ActionTypes.GET_SALES_REPORT_DATA_SUCCEEDED)
export const getSalesReportDataFailed = actionCreator(ActionTypes.GET_SALES_REPORT_DATA_FAILED)

export const getBanners = actionCreator(ActionTypes.GET_BANNERS)
export const getBannersSucceeded = actionCreator(ActionTypes.GET_BANNERS_SUCCEEDED)
export const getBannersFailed = actionCreator(ActionTypes.GET_BANNERS_FAILED)

export const requiredDailyCode = actionCreator(ActionTypes.REQUIRE_DAILY_CODE)
export const requiredDailyCodeSucceeded = actionCreator(ActionTypes.REQUIRE_DAILY_CODE_SUCCEEDED)
export const requiredDailyCodeFailed = actionCreator(ActionTypes.REQUIRE_DAILY_CODE_FAILED)

export const getImportantAnnouncement = actionCreator(ActionTypes.GET_IMPORTANT_ANNOUNCEMENT)
export const getImportantAnnouncementSucceeded = actionCreator(ActionTypes.GET_IMPORTANT_ANNOUNCEMENT_SUCCEEDED)
export const getImportantAnnouncementFailed = actionCreator(ActionTypes.GET_IMPORTANT_ANNOUNCEMENT_FAILED)
export const toggleImportantAnnouncement = actionCreator(ActionTypes.TOGGLE_IMPORTANT_ANNOUNCEMENT)

export const toggleAnnouncements = actionCreator(ActionTypes.TOGGLE_ANNOUNCEMENTS)

export const checkForPendingOrderItems = actionCreator(ActionTypes.CHECK_FOR_PENDING_ORDER_ITEMS)
export const checkForPendingOrderItemsSucceeded = actionCreator(ActionTypes.CHECK_FOR_PENDING_ORDER_ITEMS_SUCCEEDED)
export const checkForPendingOrderItemsFailed = actionCreator(ActionTypes.CHECK_FOR_PENDING_ORDER_ITEMS_FAILED)

export const toggleOrderConfirmation = actionCreator(ActionTypes.TOGGLE_ORDER_CONFIRMATION)