import React, {useContext} from "react";
import {NumericFormat} from "react-number-format";
import {useTranslation} from "react-i18next";
import {INVOICE} from "../../../locales/pages/namespaces";
import {InvoiceContext} from "./invoice";

const TableItemsInvoice = () => {
    const {t} = useTranslation(INVOICE)
    const {items} = useContext(InvoiceContext)
    return (
        <div className="form-box form-box_12 custom-box">
            <table id={'pdf-wrapper'}>
                <thead>
                <tr>
                    <th scope="col" rowSpan="2">{t('Code')}</th>
                    <th scope="col" rowSpan="2">{t('Supplier Code')}</th>
                    <th scope="col" rowSpan="2">{t('Description')}</th>
                    <th scope="col" rowSpan="2">{t('UoM')}</th>
                    <th scope="col" rowSpan="2">{t('Quantity')}</th>
                    <th scope="col" rowSpan="2">{t('Price')}</th>
                    <th scope="col" colSpan="2" style={{textAlign: 'center'}}>{t('Discount')}</th>
                    <th scope="col" rowSpan="2">{t('Net value')}</th>
                    <th scope="col" rowSpan="2">{t('Contribution')}</th>
                    <th scope="col" rowSpan="2">{t('Contribution VAT')}</th>
                    <th scope="col" rowSpan="2">{t('VAT')}</th>
                </tr>

                <tr>
                    <th scope="col">{t('PCT Value')}</th>
                    <th scope="col">{t('Value')}</th>
                </tr>
                </thead>
                <tbody>
                {items && items.map((item, index) => (
                    <tr key={index}>
                        <td>{item.column1 ? item.column1 : null}</td>
                        <td>{item.column2 ? item.column2 : null}</td>
                        <td>{item.column3 ? item.column3 : null}</td>
                        <td>{item.column4 ? item.column4 : null}</td>
                        <td>
                            <NumericFormat
                                thousandSeparator={window.FORMAT_DIGIT}
                                decimalSeparator={window.FORMAT_DECIMAL}
                                displayType="text"
                                value={item.column5 ? item.column5 : '0.00'}
                                decimalScale={2}
                            />
                        </td>
                        <td>
                            <NumericFormat
                                thousandSeparator={window.FORMAT_DIGIT}
                                decimalSeparator={window.FORMAT_DECIMAL}
                                displayType="text"
                                value={item.column6 ? item.column6 : '0.00'}
                                decimalScale={2}
                            />
                        </td>
                        <td>
                            <NumericFormat
                                thousandSeparator={window.FORMAT_DIGIT}
                                decimalSeparator={window.FORMAT_DECIMAL}
                                displayType="text"
                                value={item.column7 ? item.column7 : '0.00'}
                                decimalScale={2}
                            />
                        </td>
                        <td>
                            <NumericFormat
                                thousandSeparator={window.FORMAT_DIGIT}
                                decimalSeparator={window.FORMAT_DECIMAL}
                                displayType="text"
                                value={item.column8 ? item.column8 : '0.00'}
                                decimalScale={2}
                            />
                        </td>
                        <td>
                            <NumericFormat
                                thousandSeparator={window.FORMAT_DIGIT}
                                decimalSeparator={window.FORMAT_DECIMAL}
                                displayType="text"
                                value={item.column9 ? item.column9 : '0.00'}
                                decimalScale={2}
                            />
                        </td>
                        <td>
                            <NumericFormat
                                thousandSeparator={window.FORMAT_DIGIT}
                                decimalSeparator={window.FORMAT_DECIMAL}
                                displayType="text"
                                value={item.column10 ? item.column10 : '0.00'}
                                decimalScale={2}
                            />
                        </td>
                        <td>
                            <NumericFormat
                                thousandSeparator={window.FORMAT_DIGIT}
                                decimalSeparator={window.FORMAT_DECIMAL}
                                displayType="text"
                                value={item.column11 ? item.column11 : '0.00'}
                                decimalScale={2}
                            />
                        </td>
                        <td>
                            <NumericFormat
                                thousandSeparator={window.FORMAT_DIGIT}
                                decimalSeparator={window.FORMAT_DECIMAL}
                                displayType="text"
                                value={item.column12 ? parseFloat(item.column12).toFixed(2) : '0.00'}
                                decimalScale={2}
                            />
                        </td>
                    </tr>
                ))}
                {items && items.length < 6 && <tr style={{height: '50px'}}></tr>}
                {/*{items && items.length < 8 && items.length >= 6 && <tr style={{height: '200px'}}></tr>}
                {items && items.length < 10 && items.length >= 8 && <tr style={{height: '100px'}}></tr>}*/}
                </tbody>
            </table>
        </div>
    )
}

export default TableItemsInvoice;