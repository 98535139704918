// @flow
import React from 'react';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormHelperText,
	TextField,
	Typography
} from "@mui/material";
import useReportsData from "../../../../hooks/reports/useReportsData";
import useRequireDailyCode from "../../../../hooks/reports/useRequireDailyCode";
import {useTranslation} from "react-i18next";
import {PAGE_REPORTS} from "../../../../locales/pages/namespaces";
import Box from "@mui/material/Box";
import {ErrorMessage, FormikProvider} from "formik";
import {LoadingButton} from "@mui/lab";
import {SendOutlined} from "@mui/icons-material";
import Button from "@mui/material/Button";
import {getRouteUrl} from "../../../../helpers/getRouteUrl";
import {ROUTE_PAGE_HOME} from "../../../../routers/routes";
import {useHistory} from "react-router-dom";

type Props = {
	open: boolean,
	handleDialog: Function
};

const DailyCodeConfirmation = (props: Props) => {
	const {open, handleDialog} = props
	const history = useHistory()
	const {loading, requiredDailyCodeTime} = useReportsData()
	const handleClose = (event, reason) => {
		if (reason && (reason === "backdropClick" || reason === "escapeKeyDown")) return
		formik.resetForm()
		handleDialog(false)
	}
	const {formik} = useRequireDailyCode({handleClose})
	const {t} = useTranslation(PAGE_REPORTS)
	return (
		<Dialog open={open} onClose={handleClose} maxWidth={'xs'}>
			<FormikProvider value={formik}>
				<Box
					component="form"
					onSubmit={formik.handleSubmit}
					noValidate
					pt={1}
				>
					<DialogTitle>
						<Typography
							variant={'h4'}
							component={'div'}
						>
							{t('Re-enter your daily code to access reports')}
						</Typography>
					</DialogTitle>
					<DialogContent>
						<Box
							pt={1}
						>
							<FormControl
								variant="standard"
								fullWidth
								error={
									formik.touched['dailyPassword'] &&
									Boolean(formik.errors['dailyPassword'])
								}
							>
								<TextField
									value={formik.values.dailyPassword}
									name={'dailyPassword'}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									placeholder={t('Enter your daily code')}
									size={'small'}
									inputProps={{maxLength: 250}}
									variant="outlined"
									required
								/>
								<FormHelperText>
									<ErrorMessage name={'dailyPassword'}/>
								</FormHelperText>
							</FormControl>
						</Box>
					</DialogContent>
					<DialogActions>
						<Button
							variant={"contained"}
							color="primary"
							size={"small"}
							onClick={() => {
								history.push(getRouteUrl(ROUTE_PAGE_HOME))
							}}
							sx={{
								marginRight: 'auto'
							}}
						>
							{t('Return to home')}
						</Button>
						<LoadingButton
							loading={loading}
							loadingPosition="start"
							size={"small"}
							variant={"contained"}
							color={'success'}
							type={'submit'}
							startIcon={<SendOutlined/>}
						>
							{t('Submit')}
						</LoadingButton>
					</DialogActions>
				</Box>
			</FormikProvider>
		</Dialog>
	);
};

export default DailyCodeConfirmation