import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {INVOICE} from "../../../locales/pages/namespaces";
import {InvoiceContext} from "./invoice";

const TransactionDetails = () => {
	const {t} = useTranslation(INVOICE)
	const {data} = useContext(InvoiceContext)
	return (
		<div className="form-box form-box_6">
			<div className="box_title">{t('TRANSACTION DETAILS')}</div>
			<div className="box_column">
				{data && data.transactionDetails.map((item, index) => (
					<div className="box_column_item" key={index}>
						<span className="box_column_itemTitle">{item.title}:</span>
						<span
							className="box_column_itemValue">{item.value}</span>
					</div>
				))}
			</div>
		</div>
	)
}

export default TransactionDetails;