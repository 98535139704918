import {useDispatch} from "react-redux";
import {useCallback} from "react";
import * as actions from "../../actions/actions";
import useUserData from "../user/useUserData";
import dayjs from "dayjs";


const useWarrantyAction = () => {
    const dispatch = useDispatch()
    const {selectedStore} = useUserData()

    const getWarrantyDetails = useCallback((id) => {
        dispatch(actions.getWarrantyDetails({
            id
        }))
    }, [dispatch])
    
    const setWarrantyNumber = useCallback((warrantyNumber)=>{
        dispatch(actions.setWarrantyNumber({warrantyNumber}))
    },[dispatch])

    const setWarrantyMessage = useCallback((message)=>{
        dispatch(actions.setWarrantyMessage(message))
    },[dispatch])

    const createWarranty = useCallback((values) => {
        dispatch(actions.createWarranty({
            id:selectedStore?.id,
            StartDt: dayjs(values.StartDt).format('YYYY-MM-DD'),
            EndDt:dayjs(values.EndDt).format('YYYY-MM-DD'),
            LicensePlate:values.LicensePlate,
            Km:values.Km,
            CarManufacturer:values.CarManufacturer.Description,
            CarModel:values.CarModel.Description,
            CubicMeters:values.CubicMeters,
            TireBrand:values.TireBrand,
            dimensionCheck:values.dimensionCheck,
            SupplierCode:values.SupplierCode,
            Description:values.Description,
            dot1:values.dot1,
            dot2:values.dot2,
            dot3:values.dot3,
            dot4:values.dot4,
            SupplierCode2:values.SupplierCode2,
            Description2:values.Description2,
            items: values.dimensionCheck ? `${values.itemId1}` : `${values.itemId1}, ${values.itemId2}`
        }))
    }, [dispatch, selectedStore?.id])
    return {
        getWarrantyDetails,
        createWarranty,
        setWarrantyNumber,
        setWarrantyMessage
    }
}
export default useWarrantyAction