import {createSelector} from 'reselect'
import {useSelector} from "react-redux";
import {parseMultipleFromReference} from "../../helpers/parse";
import {parseMessage} from "../../types/message/parse";
import {parseUserMessage} from "../../types/user/parse";

const selectLoading = createSelector(
	(state) => state.message.get('loading'),
	(item) => item
)

const selectMessage = createSelector(
	(state) => state.message.get('message'),
	(item) => parseUserMessage(item)
)

const selectCountUnreadMessages = createSelector(
	(state) => state.message.get('unRead'),
	(item) => item
)

const selectCategories = createSelector(
	(state) => state.message.get('categories'),
	(item) => item.toJS()
)

const selectIndexTable = createSelector(
	[
		(state) => state.message.get('indexTable'),
		(state) => state.message.get('byId'),
		(state) => state.currentUser.get('language'),
	],
	(indexTable, byId, language) => ({
		loading: indexTable.get('loading'),
		count: indexTable.get('count'),
		items: parseMultipleFromReference(indexTable.get('items'), byId, (i) => parseMessage(i, language)),
	})
)

const selectShowImportantAnnouncement = createSelector(
	(state) => state.message.get('showImportantAnnouncement'),
	(item) => item
)

const selectImportantAnnouncement = createSelector(
	[
		(state) => state.currentUser.get('language'),
		(state) => state.message.get('importantAnnouncement')
	],
	(language, importantAnnouncement) => parseMessage(importantAnnouncement, language)
)

const useMessageData = () => {
	const loading = useSelector(selectLoading)
	const indexTable = useSelector(selectIndexTable)
	const message = useSelector(selectMessage)
	const countUnreadMessages = useSelector(selectCountUnreadMessages)
	const categories = useSelector(selectCategories)
	const showImportantAnnouncement = useSelector(selectShowImportantAnnouncement)
	const importantAnnouncement = useSelector(selectImportantAnnouncement)
	return {
		loading,
		indexTable,
		message,
		countUnreadMessages,
		categories,
		showImportantAnnouncement,
		importantAnnouncement
	}
}

export default useMessageData