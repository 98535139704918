import React, {useState} from "react";
import Box from "@mui/material/Box";
import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormHelperText,
	Grid,
	TextField,
	Tooltip,
	Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import useReturnForm from "../hooks/useReturnForm"; // Import your custom hook
import {DeleteForever as DeleteForeverIcon} from '@mui/icons-material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {PAGE_WARRANTIES} from "../../../locales/pages/namespaces";
import axios from "axios";
import {API} from "../../../helpers/constants";
import {enqueueSnackbar} from "notistack";
import useUserData from "../../../hooks/user/useUserData";
import {ErrorMessage, FormikProvider} from "formik";
import {LoadingButton} from "@mui/lab";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Textarea from "../../../components/form/textarea/textarea";
import {NumericFormat} from "react-number-format";

const NewReturn = ({refresh, onClose}) => {
	const {t} = useTranslation(PAGE_WARRANTIES);
	const {formik, loading} = useReturnForm({refresh, onClose});
	const {
		token,
		selectedStore,
		thousandSeparator,
		decimalSeparator,
		maxAllowedReturnItems,
		dayLimitForReturns
	} = useUserData();
	const [code, setCode] = useState('');
	const [loadingSearch, setLoadingSearch] = useState(false)
	
	const handleChange = (value, listIndex, dotIndex) => {
		formik.setFieldValue(`returnList[${listIndex}].dots[${dotIndex}]`, !value ? '' : value);
	};
	
	const handle2Change = (index) => {
		setLoadingSearch(true)
		axios({
			method: "get",
			url: `${API}/en/b2b/warranty/search/data?isAltCode=true&AltCode=${code}&returns=1&AddressId=${selectedStore?.id}`,
			headers: {
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/json",
			},
		})
		.then((response) => {
			const responseData = response.data.results[0];
			if (responseData) {
				formik.setFieldValue(`returnList[${index}].description`, responseData.ItemDescriptionEn);
				formik.setFieldValue(`returnList[${index}].tireBrand`, responseData.ManufacturerEN);
				formik.setFieldValue(`returnList[${index}].itemId`, responseData.ItemId);
			} else {
				formik.setFieldValue(`returnList[${index}].description`, '');
				formik.setFieldValue(`returnList[${index}].tireBrand`, '');
				formik.setFieldValue(`returnList[${index}].itemId`, '');
			}
			setLoadingSearch(false)
		})
		.catch((error) => {
			setLoadingSearch(false)
			enqueueSnackbar(t(error?.response?.data?.message, {dayLimitForReturns: dayLimitForReturns}), {
				variant: "warning",
				autoHideDuration: 3000
			})
		})
	};
	
	const handleRemoveItem = (index) => {
		const updatedList = [...formik.values.returnList];
		updatedList.splice(index, 1);
		formik.setFieldValue("returnList", updatedList);
	};
	
	return (
		<FormikProvider value={formik}>
			<Box sx={{p: 2, maxHeight: '80vh',  maxWidth: '100%',}}>
				<DialogTitle>
					<Typography
						variant={'h3'}
						component={'div'}
						sx={{
							textAlign: 'center',
						}}
					>
						{t('New Return')}
					</Typography>
				</DialogTitle>
				<DialogActions>
					<Button
						variant={'outlined'}
						color={'error'}
						onClick={onClose}
						sx={{
							marginRight: 'auto'
						}}
					>
						{t('Cancel')}
					</Button>
					<LoadingButton
						type="submit"
						color={'success'}
						variant={'outlined'}
						onClick={formik.submitForm}
						loading={loading}
					>
						{t("Submit")}
					</LoadingButton>
				</DialogActions>
				<DialogContent>
					<Box>
						{formik.values.returnList.map((item, index) => (
							<Box
								key={index}
								sx={{mt: 0.1, p: 1, backgroundColor: index % 2 === 0 ? '#f5f5f5' : '#FFFFFF'}}
							>
								<Grid container spacing={1} sx={{ flexGrow: 1}}>
									<Grid item xs={12} md={5} sm={12} p={0}>
										<Box sx={{display: 'flex', flexWrap: 'no-wrap'}}>
										<FormControl
											variant="standard"
											error={
												formik.touched[`returnList[${index}].itemId`] &&
												Boolean(formik.errors[`returnList[${index}].itemId`])
											}
										>
											<TextField
												sx={{maxWidth: '200px'}}
												label={t("Supplier Code")}
												variant="standard"
												value={formik.values.returnList[index].supplierCode}
												onChange={(e) => {
													formik.setFieldValue(`returnList[${index}].supplierCode`, e.target.value);
													setCode(e.target.value);
												}}
											/>
											<FormHelperText error>
												<ErrorMessage name={`returnList[${index}].itemId`}/>
											</FormHelperText>
										</FormControl>
										<LoadingButton
											loading={loadingSearch}
											// loadingPosition="end"
											sx={{mt: 2, ml: 2, minWidth: '90px'}}
											onClick={() => handle2Change(index)}>
											{t('Search')}
										</LoadingButton>
										</Box>
									</Grid>
									<Grid item xs={12} sm={12} md={7}>
										<Box sx={{display: 'flex', flexWrap: 'no-wrap'}}>
										<FormControl
											sx={{minWidth: '50px'}}
											variant="standard"
											error={
												formik.touched[`returnList[${index}].quantity`] &&
												Boolean(formik.errors[`returnList[${index}].quantity`])
											}
										>
											<NumericFormat
												thousandSeparator={thousandSeparator}
												decimalSeparator={decimalSeparator}
												value={formik.values.returnList[index]?.quantity}
												onValueChange={(values, sourceInfo) => {
													if (values.floatValue > 10) {
														enqueueSnackbar(t('You cannot add more that 10 tires for single code.'), {
															variant: 'warning'
														})
														formik.setFieldValue(`returnList[${index}].quantity`, formik.values.returnList[index]?.quantity)
													} else {
														formik.setFieldValue(`returnList[${index}].quantity`, values.floatValue)
														formik.setFieldValue(`returnList[${index}].dots`, !values.floatValue ? [] : Array(values.floatValue).fill(''))
													}
												}}
												allowNegative={false}
												decimalScale={0}
												customInput={TextField}
												name={`returnList[${index}].quantity`}
												size={'small'}
												variant={"standard"}
												label={t("Quantity")}
												onBlur={formik.handleBlur}
												type={'number'}
												inputProps={{min: 0, style: {textAlign: 'right', type: 'number'}}}
											/>
											<FormHelperText error>
												<ErrorMessage name={`returnList[${index}].quantity`}/>
											</FormHelperText>
										</FormControl>
										<Tooltip title={t('Clear')}>
											<Button
												sx={{mt: 2, ml: 3}}
												onClick={() => {
													const updateList = [...formik.values.returnList]
													updateList[index] = {
														supplierCode: '',
														itemId: '',
														quantity: 0,
														tireBrand: '',
														description: '',
														dots: []
													}
													formik.setFieldValue("returnList", updateList);
												}}
											>
												<RestartAltIcon/>
											</Button>
										</Tooltip>
										{index !== 0 && (
											<Tooltip title={t('Delete')}>
												<Button
													sx={{mt: 2, ml: 3}}
													onClick={() => {
														handleRemoveItem(index)
													}}
												>
													<DeleteForeverIcon/>
												</Button>
											</Tooltip>
										)}
										</Box>
									</Grid>
									<Grid item xs={10} sm={10} md={5}>
										<FormControl
											variant="standard"
											error={
												formik.touched[`returnList[${index}].tireBrand`] &&
												Boolean(formik.errors[`returnList[${index}].tireBrand`])
											}
										>
											<TextField
												sx={{
													mr: 1,
													mb: 1,
													"& .MuiInputBase-input.Mui-disabled": {
														WebkitTextFillColor: "#6082B6",
													},
													"& .MuiFormLabel-root.Mui-disabled": {
														WebkitTextFillColor: "#6082B6",
													},
												}}
												label={t('Tire Brand')}
												variant={'standard'}
												disabled
												onBlur={formik.handleBlur}
												value={formik.values.returnList[index].tireBrand}
											/>
											<FormHelperText error>
												<ErrorMessage name={`returnList[${index}].tireBrand`}/>
											</FormHelperText>
										</FormControl>
									</Grid>
									<Grid item xs={10} sm={10} md={5}>
										<FormControl
											variant="standard"
											error={
												formik.touched[`returnList[${index}].description`] &&
												Boolean(formik.errors[`returnList[${index}].description`])
											}
										>
											<TextField
												label={t('Description')}
												disabled variant={'standard'}
												id="Description"
												value={formik.values.returnList[index].description}
												onBlur={formik.handleBlur}
												sx={{
													minWidth: '100px',
													"& .MuiInputBase-input.Mui-disabled": {
														WebkitTextFillColor: "#6082B6",
													},
													"& .MuiFormLabel-root.Mui-disabled": {
														WebkitTextFillColor: "#6082B6",
													},
												}}
											/>
											<FormHelperText error>
												<ErrorMessage name={`returnList[${index}].description`}/>
											</FormHelperText>
										</FormControl>
									</Grid>
									<Grid item xs={12} sm={12} md={5}>
										<FormControl
											variant="standard"
											error={
												formik.touched[`returnList[${index}].dots`] &&
												Boolean(formik.errors[`returnList[${index}].dots`])
											}
										>
											<Box sx={{display: 'flex', flexWrap: 'wrap'}}>
												{formik.values.returnList[index].dots.map((dot, i) => (
													<Box key={`dots${index}[${i}]`} ml={2}>
														<NumericFormat
															thousandSeparator={''}
															decimalSeparator={decimalSeparator}
															value={formik.values.returnList[index].dots[i]}
															onValueChange={(values, sourceInfo) => {
																handleChange(values.value, index, i)
															}}
															decimalScale={0}
															allowNegative={false}
															id={`dots${index}[${i}]`}
															customInput={TextField}
															label={t(`DOT${i + 1}`)}
															variant={"standard"}
															size={'small'}
															allowLeadingZeros={true}
															inputProps={{
																min: 0,
																style: {
																	width: "60px"
																},
																maxLength: 4
															}}
														/>
													</Box>
												))}
											</Box>
											<FormHelperText error>
												<ErrorMessage name={`returnList[${index}].dots`}/>
											</FormHelperText>
										</FormControl>
									</Grid>
								</Grid>
							</Box>
						))}
						{formik.values.returnList.length < maxAllowedReturnItems && (
							<Box sx={{display: 'flex', justifyContent: 'space-between'}}>
								<Button
									onClick={() => {
										formik.setFieldValue("returnList", [...formik.values.returnList, {
											supplierCode: '',
											itemId: '',
											quantity: 0,
											tireBrand: '',
											description: '',
											dots: []
										}])
									}}
								>
									<AddCircleOutlineIcon sx={{pr: 0.2}}/>
									{t('Add new tire code')}
								</Button>
							</Box>
						)}
					</Box>
					<Textarea
						value={formik.values.comments}
						onChange={formik.handleChange}
						name={'comments'}
						placeholder={t('Comments')}
						minRows={5}
					/>
				</DialogContent>
			</Box>
		</FormikProvider>
	)
		;
};

export default NewReturn;
