import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import {ENABLED_MODULES} from '../App'

i18n
.use(initReactI18next)
.init({
	react: {
		useSuspense: false,
		transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'ul', 'li', 'a']
	},
	lng: 'el',
	fallbackLng: "en",
	keySeparator: true,
	interpolation: {
		escapeValue: false
	},
});

const moduleResources = ENABLED_MODULES.getTranslations();

Object.keys(moduleResources).forEach(module => {
	const namespaceObject = moduleResources[module];
	Object.keys(namespaceObject).forEach(namespace => {
		i18n.addResourceBundle('en', namespace, namespaceObject[namespace].en)
		i18n.addResourceBundle('el', namespace, namespaceObject[namespace].el)
	});
})


export default i18n;