import * as ActionTypes from '../../actions/types'
import { fromJS } from "immutable";
import { mapMultiple } from "../../helpers/map";
import { mapOrder } from "../../types/order/map";
import { getTotalProductsInCart } from "../../helpers/helpers";
import { OrderStatus } from "../../helpers/constants";
import { mapPendingOrderItem } from "../../types/product/map";

export const INITIAL_STATE = {
	loading: false,
	creatingOrder: false,
	byId: {},
	activeOrder: {},
	indexTable: {
		loading: false,
		items: [],
		count: 0
	},
	totalProductsInCart: 0,
	message: null,
	redirectToOrder: false,
	orderCompleted: false,
	orderNoLongerExists: false,
	loadingSubmitting: false,
	pendingOrderItems: [],
	loadingPendingItems: false,
	openOrderConfirmation: false
}

function setRedirectToOrder(state, action) {
	const redirectToOrder = action.payload
	return state
	.set('redirectToOrder', redirectToOrder)
	.set('orderNoLongerExists', false)
	.set('orderCompleted', false)
}

function setOrderMessage(state, action) {
	const message = action.payload
	return state
	.set('message', fromJS(message ? {
		text: message?.text, variant: message?.variant
	} : null))
}

function getActiveOrder(state) {
	return state
	.set('loading', true)
	.set('message', null)
	.set('orderNoLongerExists', false)
	.set('orderCompleted', false)
	.set('pendingOrderItems', fromJS([]))
	.set('openOrderConfirmation', false)
	.set('loadingPendingItems', false)
}

function getActiveOrderSucceeded(state, action) {
	const { order } = action.payload
	const totalProductsInCart = order.length > 0 && order[0]?.products ? getTotalProductsInCart(order[0].products) : 0
	const mappedData = mapMultiple(order, mapOrder)
	return state
	.set('loading', false)
	.set('activeOrder', fromJS(mappedData))
	.set('totalProductsInCart', totalProductsInCart)
	.set('message', null)
	.set('orderCompleted', false)
}

function getActiveOrderFailed(state, action) {
	const { message } = action.payload
	return state
	.set('loading', false)
	.set('message', fromJS({
		text: message, variant: 'error'
	}))
	.set('orderCompleted', false)
}

function createOrder(state) {
	return state
	.set('creatingOrder', true)
	.set('loading', true)
	.set('message', null)
	.set('redirectToOrder', false)
	.set('orderNoLongerExists', false)
	.set('orderCompleted', false)
	.set('pendingOrderItems', fromJS([]))
	.set('openOrderConfirmation', false)
	.set('loadingPendingItems', false)
}

function createOrderSucceeded(state, action) {
	const { order } = action.payload
	const mappedData = mapMultiple(order, mapOrder)
	const totalProductsInCart = order.length > 0 && order[0]?.products ? getTotalProductsInCart(order[0].products) : 0
	return state
	.set('creatingOrder', false)
	.set('loading', false)
	.set('activeOrder', fromJS(mappedData))
	.set('totalProductsInCart', totalProductsInCart)
	.set('message', fromJS({
		text: 'Order created successfully!', variant: 'success'
	}))
	.set('redirectToOrder', true)
	.set('orderCompleted', false)
}

function createOrderFailed(state, action) {
	const { message } = action.payload
	return state
	.set('creatingOrder', false)
	.set('loading', false)
	.set('message', fromJS({
		text: message, variant: 'error'
	}))
	.set('redirectToOrder', false)
	.set('orderCompleted', false)
}

function updateOrder(state, action) {
	return state
	.set('loading', true)
	.set('message', null)
	.set('redirectToOrder', false)
	.set('orderNoLongerExists', false)
	.set('orderCompleted', false)
	.set('pendingOrderItems', fromJS([]))
	.set('openOrderConfirmation', false)
	.set('loadingPendingItems', false)
}

function updateOrderSucceeded(state, action) {
	const { order } = action.payload
	const mappedActiveOrderData = order.length > 0 && order[0]?.B2BStatus !== OrderStatus.Submitted ? mapMultiple(order, mapOrder) : {}
	const mappedData = mapMultiple(order, mapOrder)
	const totalProductsInCart = order.length > 0 && order[0]?.B2BStatus !== OrderStatus.Submitted && order[0]?.products ? getTotalProductsInCart(order[0].products) : 0
	const message = order[0]?.B2BStatus === OrderStatus.Submitted ? 'Order submitted successfully!' : 'Order updated successfully!'
	return state
	.set('loading', false)
	.set('activeOrder', fromJS(mappedActiveOrderData))
	.set('byId', fromJS(mappedData))
	.set('totalProductsInCart', totalProductsInCart)
	.set('message', fromJS({
		text: message, variant: 'success'
	}))
	.set('redirectToOrder', (order.length > 0 && order[0]?.B2BStatus !== OrderStatus.Submitted))
	.set('orderCompleted', false)
}

function updateOrderFailed(state, action) {
	const { message } = action.payload
	return state
	.set('loading', false)
	.set('message', fromJS({
		text: message, variant: 'error'
	}))
	.set('redirectToOrder', false)
	.set('orderCompleted', false)
}

function completeOrder(state, action) {
	return state
	.set('loadingSubmitting', true)
	.set('message', null)
	.set('redirectToOrder', false)
	.set('orderCompleted', false)
	.set('orderNoLongerExists', false)
	.set('orderCompleted', false)
	.set('loadingPendingItems', false)
}

function completeOrderSucceeded(state, action) {
	const { order } = action.payload
	const mappedData = mapMultiple(order, mapOrder)
	const message = 'Order submitted successfully!'
	return state
	.set('loadingSubmitting', false)
	.set('activeOrder', fromJS({}))
	.set('byId', fromJS(mappedData))
	.set('totalProductsInCart', 0)
	.set('message', fromJS({
		text: message, variant: 'success'
	}))
	.set('redirectToOrder', false)
	.set('orderCompleted', true)
	.set('orderNoLongerExists', false)
	.set('pendingOrderItems', fromJS([]))
	.set('openOrderConfirmation', false)
}

function completeOrderFailed(state, action) {
	const { message } = action.payload
	const { id } = action.parameters
	const orderNoLongerExists = message === 'No Order Found' || message === 'order_not_in_progress'
	const activeOrderProducts = state.getIn(['activeOrder', id, 'products']).toJS()
	const totalProductsInCart = activeOrderProducts && !orderNoLongerExists ? getTotalProductsInCart(Object.values(activeOrderProducts)) : 0
	return state
	.set('loadingSubmitting', false)
	.set('message', fromJS({
		text: message, variant: 'error'
	}))
	.set('redirectToOrder', false)
	.set('orderCompleted', false)
	.set('orderNoLongerExists', orderNoLongerExists)
	.set('totalProductsInCart', totalProductsInCart)
}

function getOrderDetails(state) {
	return state
	.set('loading', true)
	.set('message', null)
	.set('orderNoLongerExists', false)
	.set('orderCompleted', false)
	.set('pendingOrderItems', fromJS([]))
	.set('openOrderConfirmation', false)
	.set('loadingPendingItems', false)
}

function getOrderDetailsSucceeded(state, action) {
	const { order } = action.payload
	const mappedData = order.length > 0 ? mapOrder(order[0]) : {}
	return state
	.set('loading', false)
	.setIn(['byId', mappedData.id], fromJS(mappedData))
	.set('orderCompleted', false)
}

function getOrderDetailsFailed(state, action) {
	const { message } = action.payload
	return state
	.set('loading', false)
	.set('message', fromJS({
		text: message, variant: 'error'
	}))
	.set('orderCompleted', false)
}

function deleteOrder(state) {
	return state
	.set('loading', true)
	.set('orderNoLongerExists', false)
	.set('orderCompleted', false)
	.set('pendingOrderItems', fromJS([]))
	.set('loadingPendingItems', false)
	.set('openOrderConfirmation', false)
}

function deleteOrderSucceeded(state, action) {
	const { parameters: { id } } = action
	return state
	.set('loading', false)
	.removeIn(['activeOrder', id])
	.removeIn(['byId', id])
	.set('totalProductsInCart', 0)
	.set('message', fromJS({
		text: 'Order deleted successfully.', variant: 'success'
	}))
	.set('orderCompleted', false)
}

function deleteOrderFailed(state, action) {
	const { id } = action.parameters
	const { message } = action.payload
	const orderNoLongerExists = message === 'order_not_found'
	const activeOrderProducts = state.getIn(['activeOrder', id, 'products']).toJS()
	const totalProductsInCart = activeOrderProducts && !orderNoLongerExists ? getTotalProductsInCart(Object.values(activeOrderProducts)) : 0
	return state
	.set('loading', false)
	.set('message', fromJS({
		text: message, variant: 'error'
	}))
	.set('orderCompleted', false)
	.set('orderNoLongerExists', orderNoLongerExists)
	.set('totalProductsInCart', totalProductsInCart)
}

function removeProductFromOrder(state) {
	return state
	.set('loading', true)
	.set('orderNoLongerExists', false)
	.set('orderCompleted', false)
	.set('pendingOrderItems', fromJS([]))
	.set('loadingPendingItems', false)
	.set('openOrderConfirmation', false)
}

function removeProductFromOrderSucceeded(state, action) {
	const { parameters: { id, ItemId } } = action
	const activeOrderProducts = state.getIn(['activeOrder', id, 'products']).toJS()
	const totalProductsInCart = activeOrderProducts ? getTotalProductsInCart(Object.values(activeOrderProducts), [ItemId]) : 0
	return state
	.set('loading', false)
	.removeIn(['activeOrder', id, 'products', `${ItemId}`])
	.removeIn(['byId', id, 'products', `${ItemId}`])
	.set('totalProductsInCart', totalProductsInCart)
	.set('message', fromJS({
		text: 'Product removed from cart.', variant: 'success'
	}))
	.set('orderCompleted', false)
}

function removeProductFromOrderFailed(state, action) {
	const { parameters: { id, ItemId } } = action
	const { message } = action.payload
	const orderNoLongerExists = message === 'order_not_found'
	const activeOrderProducts = state.getIn(['activeOrder', id, 'products']).toJS()
	const productAlreadyRemovedFromOrder = message === 'order_item_not_found'
	const totalProductsInCart = activeOrderProducts && !orderNoLongerExists ? getTotalProductsInCart(Object.values(activeOrderProducts), [ItemId]) : 0
	
	if (productAlreadyRemovedFromOrder) {
		return state
		.set('loading', false)
		.set('message', fromJS({
			text: message, variant: 'error'
		}))
		.removeIn(['activeOrder', id, 'products', `${ItemId}`])
		.removeIn(['byId', id, 'products', `${ItemId}`])
		.set('orderCompleted', false)
		.set('orderNoLongerExists', orderNoLongerExists)
		.set('totalProductsInCart', totalProductsInCart)
	}
	
	return state
	.set('loading', false)
	.set('message', fromJS({
		text: message, variant: 'error'
	}))
	.set('orderCompleted', false)
	.set('orderNoLongerExists', orderNoLongerExists)
	.set('totalProductsInCart', totalProductsInCart)
}

function checkForPendingOrderItems(state) {
	return state
	.set('loadingPendingItems', true)
	.set('loadingSubmitting', true)
	.set('pendingOrderItems', fromJS([]))
	.set('openOrderConfirmation', false)
}

function checkForPendingOrderItemsSucceeded(state, action) {
	const { data } = action.payload
	const pendingOrderItems = data.map(i => mapPendingOrderItem(i))
	return state
	.set('loadingPendingItems', false)
	.set('loadingSubmitting', false)
	.set('openOrderConfirmation', true)
	.set('pendingOrderItems', fromJS(pendingOrderItems))
}

function checkForPendingOrderItemsFailed(state, action) {
	const { message } = action.payload
	return state
	.set('loadingPendingItems', false)
	.set('loadingSubmitting', false)
	.set('message', fromJS({
		text: message, variant: 'error'
	}))
}

function toggleOrderConfirmation(state, action) {
	const { value } = action.payload
	return state
	.set('openOrderConfirmation', value)
}

const order = {
	initialState: INITIAL_STATE,
	handlers: {
		[ActionTypes.SET_ORDER_MESSAGE]: setOrderMessage,
		[ActionTypes.SET_REDIRECT_TO_ORDER]: setRedirectToOrder,
		
		[ActionTypes.GET_ACTIVE_ORDER]: getActiveOrder,
		[ActionTypes.GET_ACTIVE_ORDER_SUCCEEDED]: getActiveOrderSucceeded,
		[ActionTypes.GET_ACTIVE_ORDER_FAILED]: getActiveOrderFailed,
		
		[ActionTypes.GET_ORDER_DETAILS]: getOrderDetails,
		[ActionTypes.GET_ORDER_DETAILS_SUCCEEDED]: getOrderDetailsSucceeded,
		[ActionTypes.GET_ORDER_DETAILS_FAILED]: getOrderDetailsFailed,
		
		[ActionTypes.CREATE_ORDER]: createOrder,
		[ActionTypes.CREATE_ORDER_SUCCEEDED]: createOrderSucceeded,
		[ActionTypes.CREATE_ORDER_FAILED]: createOrderFailed,
		
		[ActionTypes.UPDATE_ORDER]: updateOrder,
		[ActionTypes.UPDATE_ORDER_SUCCEEDED]: updateOrderSucceeded,
		[ActionTypes.UPDATE_ORDER_FAILED]: updateOrderFailed,
		
		[ActionTypes.COMPLETE_ORDER]: completeOrder,
		[ActionTypes.COMPLETE_ORDER_SUCCEEDED]: completeOrderSucceeded,
		[ActionTypes.COMPLETE_ORDER_FAILED]: completeOrderFailed,
		
		[ActionTypes.DELETE_ORDER]: deleteOrder,
		[ActionTypes.DELETE_ORDER_SUCCEEDED]: deleteOrderSucceeded,
		[ActionTypes.DELETE_ORDER_FAILED]: deleteOrderFailed,
		
		[ActionTypes.REMOVE_PRODUCT_FROM_ORDER]: removeProductFromOrder,
		[ActionTypes.REMOVE_PRODUCT_FROM_ORDER_SUCCEEDED]: removeProductFromOrderSucceeded,
		[ActionTypes.REMOVE_PRODUCT_FROM_ORDER_FAILED]: removeProductFromOrderFailed,
		
		[ActionTypes.CHECK_FOR_PENDING_ORDER_ITEMS]: checkForPendingOrderItems,
		[ActionTypes.CHECK_FOR_PENDING_ORDER_ITEMS_SUCCEEDED]: checkForPendingOrderItemsSucceeded,
		[ActionTypes.CHECK_FOR_PENDING_ORDER_ITEMS_FAILED]: checkForPendingOrderItemsFailed,
		
		[ActionTypes.TOGGLE_ORDER_CONFIRMATION]: toggleOrderConfirmation,
	}
};

export default order