// @flow
import React, {useMemo} from 'react';
import './budget.css'
import {useTranslation} from "react-i18next";
import {PAGE_REPORTS} from "../../../locales/pages/namespaces";
import Box from "@mui/material/Box";
import {DataGridPremium} from "@mui/x-data-grid-premium";
import useBudgetReport from "../../../hooks/reports/useBudgetReport";
import _ from "lodash";
import ContentLoader from "../../../components/general/contentLoader/contentLoader";
import Filter from "./partials/filter";
import {muiGridLocales} from "../../../helpers/muiLocales";
import {API} from "../../../helpers/constants";
import useUserData from "../../../hooks/user/useUserData";

type Props = {};

const Budget = (props: Props) => {
	const {language} = useUserData()
	const {t} = useTranslation(PAGE_REPORTS)
	const {data, filters, loading, handleFilters} = useBudgetReport()
	const columns = useMemo(() => [
		{
			field: 'category2',
			headerName: t('Brand'),
			flex: 1,
			minWidth: 140,
			renderCell: ({row, value}) => {
				if (value === "FALKEN") {
					return <img style={{maxHeight: "40px", maxWidth: '120px'}}
								src={` ${API}/uploads/itemImgs/falken.png`} alt={'Falken logo'}/>
				} else if (value === "SUMITOMO") {
					return <img style={{maxHeight: "40px", maxWidth: '120px'}}
								src={` ${API}/uploads/itemImgs/sumitomo.png`} alt={'Sumitomo logo'}/>
				} else {
					return value
				}
			}
		},
		// TODO::Uncomment όταν ζητήσει ο Στάθης να ξανά εμφανιστούν
		// {
		// 	field: 'budgetQuantity',
		// 	headerName: t('Budget'),
		// 	flex: 1,
		// minWidth: 100,
		// },
		{
			field: 'salesQuantity',
			headerName: t('Sales'),
			flex: 1,
			minWidth: 120,
		},
		// TODO::Uncomment όταν ζητήσει ο Στάθης να ξανά εμφανιστούν
		// {
		// 	field: 'percentage',
		// 	headerName: t('Percentage'),
		// 	flex: 1,
		// minWidth: 100,
		// },
	], [t])
	
	const displayYears = useMemo(() => {
		const years = filters?.years
		if (!_.isEmpty(years)) {
			return (
				<Filter
					filterKey={'years'}
					filter={years}
					handleFilters={handleFilters}
					bgColor={'#f5f5f5'}
				/>
			)
		}
	}, [filters?.years, handleFilters])
	
	const displayQuarters = useMemo(() => {
		const quarters = filters?.quarters
		if (!_.isEmpty(quarters)) {
			return (
				<Filter
					filterKey={'quarters'}
					filter={quarters}
					handleFilters={handleFilters}
					bgColor={'#FFFFFF'}
				/>
			)
		}
	}, [filters?.quarters, handleFilters])
	
	const displayCategories = useMemo(() => {
		const categories = filters?.categories
		if (!_.isEmpty(categories)) {
			return (
				<Filter
					filterKey={'categories'}
					filter={categories}
					handleFilters={handleFilters}
					bgColor={'#f5f5f5'}
				/>
			)
		}
	}, [filters?.categories, handleFilters])
	
	return !loading ? (
		<Box p={4} width={'100%'} displa={'flex'} flexDirection={'column'} maxWidth={'1000px'}>
			<Box mb={3} pl={2} width={'90%'}>
				{displayYears}
				{displayQuarters}
				{displayCategories}
			</Box>
			<Box width={'90%'}>
				<DataGridPremium
					loading={loading}
					rows={data}
					columns={columns}
					localeText={muiGridLocales(language)}
					getRowId={row => row.category2}
					sx={{background: '#FFFFFF'}}
					initialState={{density: "compact"}}
				/>
			</Box>
		</Box>
	) : <ContentLoader loading={loading}/>
};

export default Budget