// @flow
import React from 'react';
import Box from "@mui/material/Box";
import {Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {PAGE_ORDERS} from "../../../locales/pages/namespaces";
import {normalizeGreek} from "../../../helpers/normalizeGreek";

type Props = {};

const ProductsHeader = (props: Props) => {
	const {t} = useTranslation(PAGE_ORDERS)
	return (
		<Box
			p={0.2}
			pl={1}
			sx={{
				display: 'flex',
				flexDirection: 'row',
				flexWrap: 'wrap',
				width: '100%',
				backgroundColor: '#f5f5f5'
			}}
		>
			<Box
				sx={{
					width: {
						xs: '100%',
						sm: '50%',
						md: '25%',
						lg: '25%'
					},
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between'
				}}
			
			>
				<Typography
					variant={'overline'}
					component={'div'}
					sx={{fontWeight: 'bold'}}
				>
					{normalizeGreek(t('Description'))}
				</Typography>
			</Box>
			<Box
				sx={{
					width: {
						xs: '100%',
						sm: '100%',
						md: '55%',
						lg: '50%'
					},
					p: 0.2
				}}
				className={'warehouses'}
			>
				<Box className={'warehouse'}>
					<Box
						pl={0.4}
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							wordWrap: 'break-word'
						}}
					>
						<Box pr={0.1}
							 sx={{
								 width: {
									 'xs': '26%',
									 'sm': '26%',
									 'md': '32%',
									 'lg': '26%',
									 'xl': '26%'
								 }
							 }}
						>
							<Typography
								variant={'overline'}
								component={'div'}
								sx={{fontWeight: 'bold'}}
							>
								{normalizeGreek(t('Warehouses'))}
							</Typography>
						</Box>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'flex-start',
								justifyContent: 'space-between',
								flexWrap: 'wrap',
								width: {
									'xs': '45%',
									'sm': '45%',
									'md': '45%',
									'lg': '45%',
									'xl': '45%'
								}
							}}
						>
							<Typography
								variant={'overline'}
								component={'div'}
								sx={{fontWeight: 'bold'}}
							>
								{normalizeGreek(t('Availability'))}
							</Typography>
						</Box>
						<Box
							sx={{
								width: {
									'xs': '25%',
									'sm': '25%',
									'md': '25%',
									'lg': '25%',
									'xl': '25%'
								}
							}}
						>
							<Typography
								variant={'overline'}
								component={'div'}
								sx={{fontWeight: 'bold'}}
							>
								{normalizeGreek(t('Order Quantity'))}
							</Typography>
						</Box>
					</Box>
				</Box>
			</Box>
			<Box
				sx={{
					width: {
						xs: '100%',
						sm: '50%',
						md: '20%',
						lg: '25%'
					},
					position: 'relative'
				}}
			>
				<Typography
					variant={'overline'}
					component={'div'}
					sx={{fontWeight: 'bold'}}
				>
					{normalizeGreek(t('Prices'))}
				</Typography>
			</Box>
		</Box>
	);
};

export default ProductsHeader