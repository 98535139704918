import {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {PAGE_WARRANTIES} from "../../../../locales/pages/namespaces";
import {DataGridPremium, GridToolbar} from "@mui/x-data-grid-premium";
import {Link, useHistory} from "react-router-dom";
import {getRouteUrl} from "../../../../helpers/getRouteUrl";
import {ROUTE_PAGE_NEW_WARRANTY, ROUTE_PAGE_WARRANTY_ACCIDENT} from "../../../../routers/routes";
import {FormControlLabel, Icon, Modal, TextField, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import InfoIcon from '@mui/icons-material/Info';
import {useMuiGridServerSide} from "../../../../hooks/muiGrid/useMuiGridServerSide";
import useUserData from "../../../../hooks/user/useUserData";
import dayjs from "dayjs";
import Switch from "@mui/material/Switch";
import WarrantyDetails from "../details/warrantyDetails";
import {muiGridLocales} from "../../../../helpers/muiLocales";
import {CustomPagination} from "../../../../components/grids/dataGridCustom/customPagination";
import {API, Permissions, WarrantyStatus as Status} from "../../../../helpers/constants";
import WarrantyStatus from "../../../../components/app/warrantyStatus/warrantyStatus";
import CarCrashIcon from '@mui/icons-material/CarCrash';
import DataGridWrapper from "../../../../components/grids/dataGridWrapper/dataGridWrapper";
import {ExportExcelPdf} from "../../../../components/exportExcelPdf/exportExcelPdf";
import {withPermissionHOC} from "../../../../modules/permission/components/permissionHOC/permissionHOC";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	bgcolor: "background.paper",
	border: "1px solid #000",
	boxShadow: 24,
	p: 0,
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	width: "60%", // Initial width

	'@media (max-width: 600px)': { // Adjust values for smaller screens
		width: "80%", // Decrease width for smaller screens
		// Add more adjustments as needed
	},
};

const Warranties = () => {
	const history = useHistory()
	const {t} = useTranslation(PAGE_WARRANTIES)
	const {selectedStore, dateFormat, language} = useUserData()
	const [tireSupCode, setTireSupCode] = useState('')
	const [warrantyNumber, setWarrantyNumber] = useState('')
	const [isAltCode, setIsAltCode] = useState(false)
	const [open, setOpen] = useState(false);
	const [warrantyId, setWarrantyId] = useState('')
	const handleClose = () => {
		setOpen(false);
	};
	
	const {
		isLoading,
		data,
		total,
		page,
		pageSize,
		groupModel,
		onPaginationModelChange,
		onSortModelChange,
		onFilterModelChange,
		onGroupModelChange,
		refreshGrid,
	} = useMuiGridServerSide({
		url: '/el/b2b/guarantee/index',
		initialPageSize: 20,
		initialSortModel: [{name: 'id', direction: 'descending'}],
		otherDataParams: {
			AddressId: selectedStore?.id,
			GuaranteeNumber: warrantyNumber,
			AltCode: tireSupCode
		}
	})
	
	const columns = useMemo(() => [
		{
			field: 'details',
			headerName: t('Details'),
			flex: 1,
			minWidth: 150,
			filterable: false,
			sortable: false,
			renderCell: ({row}) => (
				<Button
					component={Icon}
					sx={{ml: 2}}
					onClick={() => {
						setOpen(true)
						setWarrantyId(row.id)
					}}><InfoIcon/></Button>
			)
		},
		{
			field: 'pdf1',
			headerName: t('Final Pdf'),
			flex: 1,
			minWidth: 100,
			filterable: false,
			sortable: false,
			renderCell: ({row}) => {
				const status = 1
				const PdfUrl = `${API}/en/b2b/guarantee/pdf?GuaranteeNumber=${row.GuaranteeNumber}&final=${status}`
				return row.Status !== 'Initialized' && (
					<ExportExcelPdf url={PdfUrl} isPdf={true}/>
				)
			}
		},
		{
			field: 'pdf',
			headerName: t('Initial Pdf'),
			flex: 1,
			minWidth: 100,
			filterable: false,
			sortable: false,
			renderCell: ({row}) => {
				const status = 0
				const PdfUrl = `${API}/en/b2b/guarantee/pdf?GuaranteeNumber=${row.GuaranteeNumber}&final=${status}`
				return (
					<ExportExcelPdf url={PdfUrl} isPdf={true}/>
				)
			}
		},
		{
			field: 'report',
			headerName: t('Accident Report'),
			flex: 1,
			minWidth: 150,
			filterable: false,
			sortable: false,
			renderCell: ({row}) => (
				row.Status !== 'Initialized' && row.Status !== 'Activation Time Expired' && row.Status !== 'Expired' && row.Status !== 'Rejected'
					? <Button
						sx={{ml: 2}}
						onClick={() => {
							history.push(getRouteUrl(ROUTE_PAGE_WARRANTY_ACCIDENT, {
								code: row.GuaranteeNumber,
								id: row.id
							}))
						}}><CarCrashIcon/></Button>
					: row.flag === '1' ?
						<Button
							sx={{ml: 2}}
							onClick={() => {
								history.push(getRouteUrl(ROUTE_PAGE_WARRANTY_ACCIDENT, {
									code: row.GuaranteeNumber,
									id: row.id
								}))
							}}><CarCrashIcon/></Button> : ''
			)
		},
		{
			field: 'GuaranteeNumber',
			headerName: t('Warranty Number'),
			flex: 1,
			minWidth: 150,
		},
		{
			field: 'Status',
			headerName: t('Status'),
			minWidth: 180,
			flex: 1,
			renderCell: ({row}) => (<WarrantyStatus status={row.Status}/>),
			type: 'singleSelect',
			valueOptions: Object.entries(Status).map(status => {
				const value = status[1]
				return {
					value: value,
					label: t(value)
				}
			}),
		},
		{
			field: 'FullName',
			headerName: t('Name'),
			flex: 1,
			minWidth: 200,
		},
		{
			field: 'Address',
			headerName: t('Warranty registration shop'),
			flex: 1,
			minWidth: 250
			
		},
		{
			field: 'StartDt',
			headerName: t('Activation Date'),
			flex: 1,
			type: 'date',
			valueGetter: (value, row, column, apiRef) => {
				return new Date(value)
			},
			valueFormatter: (value, row, column, apiRef) => {
				return (value ? dayjs(value).format(dateFormat) : null)
			},
			minWidth: 140,
		},
		{
			field: 'EndDt',
			headerName: t('End Date'),
			flex: 1,
			type: 'date',
			valueGetter: (value, row, column, apiRef) => {
				return new Date(value)
			},
			valueFormatter: (value, row, column, apiRef) => {
				return (value ? dayjs(value).format(dateFormat) : null)
			},
			minWidth: 160,
		},
		{
			field: 'InsertedDt',
			headerName: t('Accident Report Date'),
			flex: 1,
			minWidth: 160,
			type: 'date',
			valueGetter: (value, row, column, apiRef) => {
				return value && new Date(value)
			},
			valueFormatter: (value, row, column, apiRef) => {
				return (value ? dayjs(value).format(dateFormat) : '-')
			}
		},
		{
			field: 'TireDimensions',
			headerName: t('Tire Dimensions'),
			flex: 1,
			minWidth: 240,
		},
		{
			field: 'LicensePlate',
			headerName: t('License Pl.'),
			flex: 1,
			minWidth: 140,
		},
		{
			field: 'CarManufacturer',
			headerName: t('Car brand'),
			flex: 1,
			minWidth: 150,
		},
		{
			field: 'Km',
			headerName: t('Km'),
			flex: 1,
			minWidth: 150,
		},
	], [dateFormat, history, t])
	
	return (
		<Box>
			<Box p={1}>
				<Button variant={'outlined'} component={Link}
						to={getRouteUrl(ROUTE_PAGE_NEW_WARRANTY)}>{t('New warranty')}</Button>
				<Typography
					pb={1}
					variant={'h2'}
					component={'div'}
					sx={{
						textAlign: 'center'
					}}
				>
					{t('Guarantees')}
				</Typography>
				<Box sx={{width: '100%'}}>
					<Box display={'flex'} flexWrap={'wrap'}>
						{!isAltCode ?
							<Box p={0.5} display={'flex'} alignItems={'center'}>
								<Typography
									variant={'subtitle1'}
									component={'span'}
									pr={1}
								>
									{t('Search by warranty number')}
								</Typography>
								<TextField
									value={warrantyNumber}
									name={'warrantyNumber'}
									onChange={(evt) => {
										setWarrantyNumber(evt.target.value)
										setTireSupCode("")
									}}
									size={'small'}
									inputProps={{maxLength: 250}}
									variant="standard"
								/>
							</Box>
							:
							<Box p={0.5} display={'flex'} alignItems={'center'}>
								<Typography
									variant={'subtitle1'}
									component={'span'}
									pr={1}
								>
									{t('Supplier Code')}
								</Typography>
								<TextField
									value={tireSupCode}
									name={'supplierCode'}
									onChange={(evt) => {
										setTireSupCode(evt.target.value)
										setWarrantyNumber("")
									}}
									size={'small'}
									inputProps={{maxLength: 250}}
									variant="standard"
								/>
							</Box>
						}
						<Box p={0.5} display={'flex'} alignItems={'center'}>
							<FormControlLabel
								sx={{ml: 'auto'}}
								value={t('Search by tire supplier code')}
								control={
									<Switch
										checked={isAltCode}
										onChange={(evt) => {
											setIsAltCode(evt.target.checked)
										}}
										size={'small'}
									/>
								}
								label={
									<Typography
										variant={'subtitle1'}
										component={'span'}
										pr={1}
									>
										{t('Search by tire supplier code')}
									</Typography>
								}
								labelPlacement="end"
							/>
						</Box>
						<Box p={0.5} display={'flex'} alignItems={'center'}>
							<Button
								onClick={() => refreshGrid()}
							>
								{t('Search')}
							</Button>
						</Box>
					</Box>
					<DataGridWrapper>
						<DataGridPremium
							sx={{background: '#FFFFFF'}}
							loading={isLoading}
							columns={columns}
							rows={data}
							slots={{
								toolbar: GridToolbar,
								pagination: CustomPagination
							}}
							slotProps={{
								toolbar: {
									printOptions: {disableToolbarButton: true},
								},
							}}
							initialState={{density: "compact"}}
							pagination
							paginationModel={{page, pageSize}}
							paginationMode="server"
							onPaginationModelChange={onPaginationModelChange}
							rowCount={Number(total)}
							pageSizeOptions={[5, 10, 15, 20, 50, 100]}
							sortingMode="server"
							onSortModelChange={(sortModel) => onSortModelChange(sortModel)}
							filterMode="server"
							onFilterModelChange={onFilterModelChange}
							rowGroupingModel={groupModel}
							onRowGroupingModelChange={onGroupModelChange}
							localeText={muiGridLocales(language)}
							columnBufferPx={2000}
						/>
					</DataGridWrapper>
				</Box>
			</Box>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}>
						<WarrantyDetails
							id={warrantyId}
							handleClose={handleClose}
						/>

				</Box>
			</Modal>
		</Box>
	)
	
}
export default withPermissionHOC(Warranties, [Permissions.AccessGuarantees])