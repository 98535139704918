import './locales/i18n'
import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from "react-redux";
import store, {persistor} from './store/store'
import {PersistGate} from "redux-persist/integration/react";
import reportWebVitals from './reportWebVitals';
import App from './App';
import {LicenseInfo} from '@mui/x-license';
import {MUI_LICENSE_KEY} from "./helpers/constants";
import './index.css';

LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	// <React.StrictMode>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<App/>
			</PersistGate>
		</Provider>
	// </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
