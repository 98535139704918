//@flow
import {useCallback} from 'react'
import {useDispatch} from 'react-redux'
import * as actions from '../../actions/actions'
import {AnnouncementTypes, ReadStatus} from "../../helpers/constants";
import useUserData from "../user/useUserData";

const useMessageAction = (categoryId = null, readStatus = ReadStatus.Any, urlId = null, fromHome = false) => {
	const dispatch = useDispatch()
	const {selectedStore, hasExpandedAnnouncements} = useUserData()
	
	const setAnnouncementsMessage = useCallback((message) => {
		dispatch(actions.setAnnouncementsMessage(message))
	}, [dispatch])
	
	const getMessages = useCallback((pageIndex, pageSize, isHome = false) => {
		const hasCategory = (categoryId && categoryId !== AnnouncementTypes.All)
		const hasReadStatus = (readStatus && readStatus !== ReadStatus.Any)
		dispatch(actions.getMessages({
				sorted: fromHome ? [
					{direction: 'descending', name: 'SendDt'}
				] : [
					{direction: 'descending', name: 'ViewDt'},
					{direction: 'descending', name: 'SendDt'}
				],
				...(hasCategory ? {
					TypeId: categoryId,
				} : {}),
				...(hasReadStatus ? {
					ViewDt: readStatus === ReadStatus.Read,
				} : {}),
				requiresCounts: true,
				skip: pageIndex * pageSize,
				take: pageSize,
			AddressId: selectedStore?.id,
			urlId: urlId,
			isHome: isHome,
			hasExpandedAnnouncements: hasExpandedAnnouncements
			})
		)
	}, [categoryId, readStatus, dispatch, fromHome, selectedStore?.id, urlId])
	
	const getMessageAttachments = useCallback((id) => {
		dispatch(actions.getMessageAttachments({
			id: id
		}))
	}, [dispatch])
	
	const setMessageViewed = useCallback((id) => {
		dispatch(actions.setMessageViewed({
			data: id
		}))
	}, [dispatch])
	
	const setMessageIsOpen = useCallback((id, isOpen) => {
		dispatch(actions.setMessageIsOpen({
			id,
			isOpen
		}))
	}, [dispatch])
	
	const toggleAnnouncements = useCallback((isOpen) => {
		dispatch(actions.toggleAnnouncements({
			isOpen
		}))
	}, [dispatch])
	
	const getCountUnreadMessages = useCallback((id) => {
		dispatch(actions.getCountUnreadMessages({
			AddressId: selectedStore?.id,
			reason: 'UnRead'
		}))
	}, [dispatch, selectedStore?.id])
	
	const getMessageCategories = useCallback((id) => {
		dispatch(actions.getMessageCategories({
			AddressId: selectedStore?.id
		}))
	}, [dispatch, selectedStore?.id])
	
	const getImportantAnnouncement = useCallback(() => {
		dispatch(actions.getImportantAnnouncement({
			AddressId: selectedStore?.id
		}))
	}, [dispatch, selectedStore?.id])
	
	const toggleImportantAnnouncement = useCallback((isOpen) => {
		dispatch(actions.toggleImportantAnnouncement(isOpen))
	}, [dispatch])
	
	return {
		getMessages,
		getMessageAttachments,
		setMessageViewed,
		setAnnouncementsMessage,
		getCountUnreadMessages,
		getMessageCategories,
		setMessageIsOpen,
		getImportantAnnouncement,
		toggleImportantAnnouncement,
		toggleAnnouncements
	}
}

export default useMessageAction