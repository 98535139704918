import * as React from 'react';
import {useMemo} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Logo from "../../app/logo/logo";
import {getRouteUrl} from "../../../helpers/getRouteUrl";
import {useTranslation} from "react-i18next";
import {NAVIGATION} from "../../../locales/components/namespaces";
import {
	ROUTE_PAGE_ANNOUNCEMENTS,
	ROUTE_PAGE_HOME,
	ROUTE_PAGE_ORDERS,
	ROUTE_PAGE_PENDING_ORDERS,
	ROUTE_PAGE_REPORTS,
	ROUTE_PAGE_RETURNS
} from "../../../routers/routes";
import LanguageSelect from "../../general/languageSelect/languageSelect";
import Notification from "../../app/notifications/notifications";
import AccountMenu from "../../app/accountMenu/accountMenu";
import Cart from "../../app/cart/cart";
import AppDrawer from "../appDrawer/appDrawer";
import MainMenu from "../mainMenu/mainMenu";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";

export const menuStyle = {
	color: '#224B69',
	fontWeight: 'bold',
	'&:hover': {
		bgcolor: 'secondary.lighter',
		color: 'primary.darker'
	},
}

export const menuIconStyle = {
	color: '#224B69',
	'&:hover': {
		backgroundColor: '#224B69',
		color: '#FFFFFF',
	},
}

const Header = (props) => {
	const {t} = useTranslation(NAVIGATION)
	const [mobileOpen, setMobileOpen] = React.useState(false);
	
	const handleDrawerToggle = () => {
		setMobileOpen((prevState) => !prevState);
	};
	const navItems = useMemo(() => [
		{
			id: 1,
			url: getRouteUrl(ROUTE_PAGE_HOME),
			title: t("Home"),
		},
		{
			id: 2,
			url: getRouteUrl(ROUTE_PAGE_ORDERS),
			title: t("Orders"),
		},
		// {
		// 	id: 3,
		// 	url: getRouteUrl(ROUTE_PAGE_INVOICES),
		// 	title: t("Invoices")
		// },
		{
			id: 4,
			url: getRouteUrl(ROUTE_PAGE_PENDING_ORDERS),
			title: t("Pending Orders"),
		},
		{
			id: 5,
			url: getRouteUrl(ROUTE_PAGE_REPORTS),
			title: t("Reports"),
		},
		{
			id: 7,
			url: getRouteUrl(ROUTE_PAGE_RETURNS),
			title: t("Returns"),
		}
	], [t])
	
	return (
		<Box>
			<AppBar
				component="nav"
				elevation={0}
				sx={{bgcolor: '#FFFFFF', minHeight: 73, justifyContent: 'center'}}
			>
				<Toolbar
					variant={'dense'}
					sx={{justifyContent: 'space-between'}}
				>
					<Box>
						<IconButton
							color="inherit"
							aria-label="open drawer"
							edge="start"
							onClick={handleDrawerToggle}
							sx={{mr: 'auto', display: {md: 'none'}}}
						>
							<MenuIcon color={'primary'}/>
						</IconButton>
						<Box
							sx={{
								display: {xs: 'none', sm: 'none', md: 'block'},
								marginRight: 1
							}}
						>
							<Logo
								style={{
									maxWidth: '130px',
									height: 'auto'
								}}
							/>
						</Box>
					</Box>
					<Box>
						<MainMenu navItems={navItems}/>
					</Box>
					<Box sx={{display: 'flex', alignItems: 'center'}}>
						<Box>
							<Button
								component={Link}
								to={getRouteUrl(ROUTE_PAGE_ANNOUNCEMENTS)}
								sx={{...menuStyle}}
								aria-controls="submenu"
								aria-haspopup="true"
							>
								{t('Announcements')}
							</Button>
						</Box>
						<Cart/>
						<Notification/>
						<LanguageSelect/>
						<AccountMenu/>
					</Box>
				</Toolbar>
			</AppBar>
			<Toolbar/>
			<AppDrawer
				mobileOpen={mobileOpen}
				handleDrawerToggle={handleDrawerToggle}
				navItems={navItems}
			/>
		</Box>
	);
}

export default Header;
