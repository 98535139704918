// @flow
import { Typography } from "@mui/material";
import './card.css'
import Box from "@mui/material/Box";
import * as React from 'react';
import { useMemo } from 'react';
import { normalizeGreek } from "../../../../helpers/normalizeGreek";

type Props = {
	image?: string,
	preText?: string,
	title?: string,
	text?: string,
	action?: React.Node,
	video?: string,
	textColor?: string,
	backgroundColor?: string,
	index: number,
	length: number,
};

const Card = (props: Props) => {
	const {title, preText, text, action, image, textColor, video, index, length, backgroundColor} = props
	const bannerWidth = useMemo(() => {
		switch (length) {
			case 1:
				return '100%'
			case 2:
				return '44%'
			default:
				return '30%'
			
		}
	}, [length])

	return (
		<Box
			className={'cardKpi'}
			sx={{
				...(image ? { backgroundImage: `url("${image}")` } : {}),
				...(!image ? {backgroundColor: backgroundColor ? `#${backgroundColor}` : '#485776'} : {}),
				color: textColor ? `#${textColor}` : null,
				width: {
					sm: '100%',
					md: bannerWidth
				},
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'contain',
				backgroundPosition: 'center center'
			}}
		>
			<Box className={'cardKpi__content'}>
				{preText && (
					<Typography
						component={'div'}
						variant={'overline'}
						className={'cardKpi__pre-text'}
						pb={0.3}
					>
						{normalizeGreek(preText)}
					</Typography>
				)}
				<Typography
					component={'div'}
					variant={'h3'}
					pb={1}
				>
					{title}
				</Typography>
				<Typography
					className={'cardKpi__description'}
					component={'div'}
					variant={'body1'}
				>
					{text}
				</Typography>
				{video && (
					<Box pb={2}>
						<iframe
							width="100%"
							height="200"
							src={video.indexOf('&') > -1 ? `${video}&rel=0` : `${video}?rel=0`}
							title="YouTube video player"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
							referrerPolicy="strict-origin-when-cross-origin"
							allowFullScreen
						/>
					</Box>
				)}
				{action && (
					<Box className={'cardKpi__action'}>
						{action}
					</Box>
				)}
			</Box>
		</Box>
	);
};

export default Card