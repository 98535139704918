// @flow
import { Divider, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useEffect } from 'react';
import PriceOptions from "../../components/app/priceOptions/priceOptions";
import Products from "../../components/app/products/products";
import SideFilters from "../../components/app/sideFilters/sideFilters";
import { usePriceOptions } from "../../hooks/product/usePriceOptions";
import useProductAction from "../../hooks/product/useProductAction";
import useUserData from "../../hooks/user/useUserData";

type Props = {};

const SearchResults = (props: Props) => {
	const {
		formik,
		sideFilters,
		urlFilters
	} = props
	const {data} = useUserData()
	const {getSearchResultsFilters} = useProductAction()
	const {
		displayWithTax,
		setDisplayWithTax,
		displayWithEnvTax,
		setDisplayWithEnvTax,
		displayWithPaymentDiscount,
		setDisplayWithPaymentDiscount
	} = usePriceOptions()
	
	useEffect(() => {
		getSearchResultsFilters(urlFilters, data.preserveForBackend)
	}, [])
	
	return (
		<Box width={'100%'} sx={{background: '#FFFFFF'}}>
			<Divider/>
			<Grid container sx={{display: 'flex', justifyContent: 'center'}}>
				<Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
					<SideFilters
						formik={formik}
						sideFilters={sideFilters}
					/>
				</Grid>
				<Grid item xs={12} sm={8} md={9} lg={10} xl={8} sx={{
					paddingTop: 1,
					paddingBottom: 1,
					paddingLeft: 1,
					paddingRight: 1,
					backgroundColor: '#FFFFFF'
				}}>
					<PriceOptions
						displayWithTax={displayWithTax}
						setDisplayWithTax={setDisplayWithTax}
						displayWithEnvTax={displayWithEnvTax}
						setDisplayWithEnvTax={setDisplayWithEnvTax}
						displayWithPaymentDiscount={displayWithPaymentDiscount}
						setDisplayWithPaymentDiscount={setDisplayWithPaymentDiscount}
					/>
					<Products
						urlFilters={urlFilters}
						displayWithTax={displayWithTax}
						displayWithEnvTax={displayWithEnvTax}
						displayWithPaymentDiscount={displayWithPaymentDiscount}
					/>
				</Grid>
			</Grid>
		</Box>
	);
};

export default SearchResults